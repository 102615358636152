const CryptoJS = require("crypto-js");
const SecureStorage = require("secure-web-storage");

const SECRET_KEY = "@#TCDAMCSSOFTSOLUTIONS";

const secureStorage = new SecureStorage(localStorage, {
  hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    return data.toString();
  },
  decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    return data.toString(CryptoJS.enc.Utf8);
  },
});

export const localConfig = {
  userLoginToken: "token",
  userEmail: "email",
  logoutToken: "logOutToken",
};

//Set Secure Storage
export const saveDataInLocalStorage = (key, data) =>
  secureStorage.setItem(key, data);
//Get Secure Storage
export const retrieveDataFromLocalStorage = (key) => secureStorage.getItem(key);
//Remove Data from Storage
export const removeDataFromLocalStorage = (key) =>
  secureStorage.removeItem(key);
//Clear all the Data from Session & Local Storage
export const clearLocalStorage = () => secureStorage.clear();

export default secureStorage;
