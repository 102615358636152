import React from "react";
import { CommonLayoutStyled } from "./styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FooterLanguageChange from "Layout/AdminLayout/Footer/components/FooterLanguageChange";

const CommonLayout = ({ children, headingName, extraParentClass }) => {
  const imageUrl = "./images/register3.png";
  const { t } = useTranslation();

  return (
    <CommonLayoutStyled>
      <div
        className={`commanloginPages ${
          extraParentClass ? extraParentClass : ""
        }`}
      >
        <div className="sideImage">
          <img src={imageUrl} alt="logo" className="logotypeContainer" />
        </div>
        <div className="rightContainer">
          <FooterLanguageChange />
          <div className="loginFields">
            <div className="inputFieldsBlock">
              <div className="logoBlock">
                <img
                  src="./logos/full-stack-color.svg"
                  alt="logoImage"
                  className="logoImg"
                />
              </div>
              <div className="childrenHeading">
                <div className="headingB">{headingName}</div>
                <div className="commonChildren">{children}</div>
              </div>
            </div>
            <div className="copyRights">
              <Typography color="primary" className="copyright">
                <span
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="http://tcdam.csdevhub.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  © Turner Construction Company. {t("allRightReserved")}
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </CommonLayoutStyled>
  );
};

export default CommonLayout;
