import React from "react";
import classnames from "classnames";
import { styled } from "@mui/system";

export const DotStyle = styled("div")(({ theme }) => ({
  ".dotBase": {
    width: 5,
    height: 5,
    backgroundColor: theme.palette.text.hint,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color"),
    "&.primaryTrue": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  ".dotSmall": {
    width: 5,
    height: 5,
  },
  ".dotLarge": {
    width: 11,
    height: 11,
  },
}));

export default function Dot({ size, color }) {
  return (
    <DotStyle>
      <div
        className={classnames("dotBase", {
          dotLarge: size === "large",
          dotSmall: size === "small",
          primaryTrue: color,
        })}
      />
    </DotStyle>
  );
}
