import { DialogActions } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { useTranslation } from "react-i18next";

const DeleteConfirmationPopup = (props) => {
  const { t } = useTranslation();
  const {
    openDelete,
    setOpenDelete,
    rowsToBeDeleted,
    handleDelete,
    setRowsToBeDeleted,
    gridRef,
    currentRowData,
  } = props;
  return (
    <DialogCommon
      open={openDelete}
      handleClose={() => {
        gridRef.current?.api.deselectAll();
        setOpenDelete(false);
      }}
      contentText={
        rowsToBeDeleted?.length > 1
          ? t("fieldMultipleDeleteConfirmationText")
          : t("fielduserRoleDeleteConfirmationText")
      }
      title={" "}
      fullwidth={true}
    >
      <DialogActions className={"popup-button-outer"}>
        <CustomButton
          variant="contained"
          onClick={() =>
            handleDelete(currentRowData?._id ? [currentRowData?._id] : false)
          }
        >
          {t("yes")}
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => {
            setOpenDelete(false);
            setRowsToBeDeleted([]);
          }}
        >
          {t("no")}
        </CustomButton>
      </DialogActions>
    </DialogCommon>
  );
};
export default DeleteConfirmationPopup;
