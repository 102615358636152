import React from "react";
import { Link } from "react-router-dom";
import { routeConfigs } from "utils/routeConfig";
import withoutAuth from "Auth/withoutAuth";
import CommonLayout from "Layout/AuthLayout/CommonLayout";
import { useTranslation } from "react-i18next";

function Thankyou() {
  const { t } = useTranslation();
  return (
    <CommonLayout>
      <div className={"loginMain"}>
        <div className={"center"}>
          <span>{t("thanksForRegistering")}</span>
          <span>{t("accountStatusText")}</span>
        </div>
        <div
          className={`center loginHere notaMemberText formButtons centerbtnR`}
        >
          <Link to={routeConfigs.userLogin}>{t("loginHere")}</Link>
        </div>
      </div>
    </CommonLayout>
  );
}

export default withoutAuth(Thankyou, routeConfigs.userHome);
