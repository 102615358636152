import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropUp,
  Inbox as InboxIcon,
} from "@mui/icons-material";
import Dot from "../Dot";
import { checkPermissionFunction } from "utils/permissions/checkPermission";
import { LayoutLink } from "./styles";
import { intersection } from "lodash";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  isCollapseOpen,
}) {
  const [isCollapseToggle, setIsCollapseToggle] = useState(false);

  useEffect(() => {
    if (isCollapseOpen) {
      setIsCollapseToggle(true);
    } else {
      setIsCollapseToggle(false);
    }
  }, [location.pathname, isCollapseOpen]);

  const isLinkActive =
    link &&
    (location.pathname === link ||
      location.pathname.indexOf(link) !== -1 ||
      isCollapseOpen ||
      intersection(location.pathname?.split("/"), link.split("/")).length >= 4);

  if (type === "title")
    return <Typography className="labelText">{label}</Typography>;

  if (type === "divider") return <Divider className={"divider"} />;
  if (link && link.includes("http")) {
    return (
      <ListItem className="link linking">
        <a className="externalLink" href={link}>
          <ListItemIcon>
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText primary={label} className="InnerText" />
        </a>
      </ListItem>
    );
  }
  if (!children)
    return (
      <ListItem
        className={`${isLinkActive && "active "} link`}
        component={link !== location.pathname ? Link : "a"}
        to={link ? link : ""}
      >
        <ListItemIcon className={`${isLinkActive && "active"} linkIcon`}>
          {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
        </ListItemIcon>
        <ListItemText className="InnerText" primary={label} />
      </ListItem>
    );

  return (
    <LayoutLink>
      <ListItem
        component={
          link &&
          link.split("/")[2] !== location.pathname.split("/")[2] &&
          !isSidebarOpened
            ? Link
            : "div"
        }
        onClick={() => {
          setIsCollapseToggle((prev) => !prev);
        }}
        className={`link ${isLinkActive && "active"}`}
        to={link ? link : ""}
      >
        <ListItemIcon className={`${isLinkActive && "active"} linkIcon`}>
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText className="InnerText1" primary={label} />
        {children && isSidebarOpened ? (
          isSidebarOpened && isCollapseToggle ? (
            <div className={`linkText`}>
              <ArrowDropUp />
            </div>
          ) : (
            <div className={`linkText`}>
              <ArrowDropDown />
            </div>
          )
        ) : null}
      </ListItem>
      <div className="dropDownBlock">
        {children && (
          <Collapse
            in={isSidebarOpened && isCollapseToggle}
            timeout="auto"
            unmountOnExit
            className={"nestedList"}
          >
            <List
              component="div"
              disablePadding
              className={`${isLinkActive && "active"}`}
            >
              {children.map((childrenLink) => {
                //below checking if viewPid key is defined
                //if it is defined then checking if that view permission is available to the user
                const seeLink =
                  childrenLink?.viewPid !== undefined &&
                  !checkPermissionFunction(childrenLink?.viewPid)
                    ? false
                    : true;
                return (
                  seeLink && (
                    <SidebarLink
                      key={childrenLink && childrenLink.link}
                      location={location}
                      isSidebarOpened={isSidebarOpened}
                      nested
                      {...childrenLink}
                    />
                  )
                );
              })}
            </List>
          </Collapse>
        )}
      </div>
    </LayoutLink>
  );
}
