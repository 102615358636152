import { styled } from "@mui/system";

export const LayoutLink = styled("div")(({ theme }) => ({
  ".singleLink": {
    a: {
      ":is(.active)": {
        color: "#f5f5f4",
      },
    },
  },
  ".link": {
    textDecoration: "none",
    cursor: "pointer",
    whiteSpace: "break-spaces",
    ".linkIcon": {
      justifyContent: "center",
    },
    ".InnerText1": {
      paddingLeft: "18px",
      "@media screen and (max-width:1366px)": {
        span: {
          fontSize: "14px",
        },
      },
    },
    "&.active": {
      color: "#000",
      ".linkIcon": {
        justifyContent: "center",
        svg: {
          fill: theme.palette.primary.main,
          width: "30px",
          height: "30px",
        },
      },
    },
    "&:hover, &:focus": {
      background: theme.palette.lightBlue.main,
    },
    ".externalLink": {
      background: "green",
    },
  },
}));

export default LayoutLink;
