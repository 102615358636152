import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getallCustomTags = createAsyncThunk(
  "addAssets/getallCustomTags",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes?.adminAddAssetsGetAllCustomTags}?data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getSubcategoryById = createAsyncThunk(
  "addAssets/getSubcategoryById",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes?.adminSubCategoryByCategory}${id}?data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getMetaDataApi = createAsyncThunk(
  "addAssets/getMetaDataApi",
  async (file) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(`${apiRoutes?.adminAddAssetsGetMetaData}`, file),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const creatAssetApi = createAsyncThunk(
  "addAssets/creatAssetApi",
  async (formData) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(`${apiRoutes?.adminCreateAsset}`, formData),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
