import { borderRadius, styled } from "@mui/system";

export const FooterLanguageStyle = styled("div")(({ theme }) => ({
  ".footerLanguageChange": {
    textAlign: "right",
    padding: "15px",
    ".MuiFormControl-root": {
      width: "100%",
      maxWidth: "195px",
      height: "53px",
      ' [data-testid="ArrowDropDownIcon"]': {
        display: "none",
      },
      ".MuiSelect-select": {
        height: "36px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        paddingLeft: "16px",
        span: {
          color: theme.palette.gray_600.main,
        },

        svg: { width: "28px", height: "20px" },
      },
      fieldset: { height: "56px", borderRadius: "8px", },
      ".languageDownIcon": {
        marginRight: "16px",
        position: "absolute",
        right: "0",
        pointerEvents: "none",
      },
    },
    "&.footerLogo": {
      textAlign: "left",
      padding: "0",
    },
    ".MuiSelect-select": {
      padding: "8px",
      display: "flex",
      gap: "10px",
      svg: { width: "24px", height: "24px" },
    },
  },
}));
