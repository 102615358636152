import React from 'react'

const ProfileIconSetting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.096" height="22.096" viewBox="0 0 22.096 22.096">
    <path id="Icon_awesome-user-circle" data-name="Icon awesome-user-circle" d="M11.048.563A11.048,11.048,0,1,0,22.1,11.611,11.046,11.046,0,0,0,11.048.563Zm0,4.277a3.92,3.92,0,1,1-3.92,3.92A3.92,3.92,0,0,1,11.048,4.839Zm0,15.325a8.537,8.537,0,0,1-6.526-3.038A4.967,4.967,0,0,1,8.91,14.462a1.09,1.09,0,0,1,.316.049,5.9,5.9,0,0,0,1.822.307,5.876,5.876,0,0,0,1.822-.307,1.09,1.09,0,0,1,.316-.049,4.967,4.967,0,0,1,4.388,2.664A8.537,8.537,0,0,1,11.048,20.164Z" transform="translate(0 -0.563)" fill="#041e42"/>
  </svg>
  
  )
}

export default ProfileIconSetting