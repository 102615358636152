import React from "react";
import Layout from "Layout/AdminLayout/Layout";
import AssetCategory from "pages/Admin-Pages/asset-administration/AssetCategory/AssetCategory";
import AssetAll from "pages/Admin-Pages/asset-administration/AssetsAll/AssetsAll";
import AssetSubCategory from "pages/Admin-Pages/asset-administration/AssetSubCategory/AssetSubCategory";
import AssetType from "pages/Admin-Pages/asset-administration/AssetType/AssetType";
import CustomTags from "pages/Admin-Pages/aui-form-fields/CustomTags/CustomTags";
import ScopeOfWork from "pages/Admin-Pages/aui-form-fields/CustomTags/ScopeOfWork/ScopeOfWork";
import IptcCoreFields from "pages/Admin-Pages/aui-form-fields/IptcCoreFields/IptcCoreFields";
import Banners from "pages/Admin-Pages/banners/Banners";
import CuratedGallery from "pages/Admin-Pages/cms-changes/CuratedGallery/CuratedGallery";
import FeaturedAssets from "pages/Admin-Pages/cms-changes/FeaturedAssets";
import SearchByFilters from "pages/Admin-Pages/cms-changes/SearchByFilters";
import TrendingSearches from "pages/Admin-Pages/cms-changes/trending-searches/trendingSearches";
import AssetsCollections from "pages/Admin-Pages/collections/AssetsCollections";
import AssetsAdd from "pages/Admin-Pages/contribute-assets/AssetsAdd";
import Dashboard from "pages/Admin-Pages/dashboard/Dashboard";
import FieldsManagement from "pages/Admin-Pages/fields-management/fields-management";
import Groups from "pages/Admin-Pages/groups/Groups";
import FooterGeneralPages from "pages/Admin-Pages/pages-cms/FooterGeneralPages/footerGeneralPages";
import FooterGeneralPagesEdit from "pages/Admin-Pages/pages-cms/FooterGeneralPages/footerGeneralPagesEdit";
import FooterMenu from "pages/Admin-Pages/pages-cms/footerMenu/footerMenu";
import PagesAdd from "pages/Admin-Pages/pages-cms/PagesAdd";
import PagesAll from "pages/Admin-Pages/pages-cms/PagesAll";
import ChangePassword from "pages/Admin-Pages/profile-pages/ChangePassword/ChangePassword";
import ProfileSettingPages from "pages/Admin-Pages/profile-pages/ProfileSettngPages";
import SubGroup from "pages/Admin-Pages/sub-groups/SubGroups";
import Departments from "pages/Admin-Pages/tertiaryGroup/TertiaryGroup";
import UserRecover from "pages/Admin-Pages/user-administration/userRecover";
import UserRolesAndPermissions from "pages/Admin-Pages/user-administration/UserRolesAndPermissions";
import UsersAll from "pages/Admin-Pages/user-administration/UsersAll";
import UsersRequests from "pages/Admin-Pages/user-administration/UsersRequests";
import Error from "pages/General-Pages/error/Error";
import UserForgotPassword from "pages/General-Pages/WithoutAuthPages/ForgotPassword/userForgotPassword";
import UserRegister from "pages/General-Pages/WithoutAuthPages/Register/userRegister";
import UserResetPassword from "pages/General-Pages/WithoutAuthPages/ResetPassword/userResetPassword";
import ThankyouPage from "pages/General-Pages/WithoutAuthPages/ThankyouPage/thankyouPage";
import UserLogin from "pages/General-Pages/WithoutAuthPages/UserLogin/userLogin";
import CmsPage from "pages/User-Pages/CmsPage/CmsPage";
import GeneralFooterCmsPage from "pages/User-Pages/CmsPage/GeneralFooterCmsPage";
import DesignSystemPage from "pages/User-Pages/DesignSystem/designSystemPage";
import ContactUsPage from "pages/User-Pages/UserPages/contactUs/contactUsPage";
import Home from "pages/User-Pages/UserPages/home/Home";
import HomeDuplicate from "pages/User-Pages/UserPages/home/HomeDuplicate";
import SearchPage from "pages/User-Pages/UserPages/searchPage/searchPage";
import { routeConfigs } from "utils/routeConfig";
import HomeDuplicateNew from "pages/User-Pages/UserPages/home/HomeDuplicateNew";

const AllRouting = [
  {
    path: "/",
    element: <UserLogin />,
  },
  {
    path: routeConfigs.userLogin,
    element: <UserLogin />,
  },
  {
    path: routeConfigs.userRegister,
    element: <UserRegister />,
  },
  {
    path: routeConfigs.userForgotPassword,
    element: <UserForgotPassword />,
  },
  {
    path: routeConfigs.userResetPassword,
    element: <UserResetPassword />,
  },
  {
    path: routeConfigs.userThankyouPage,
    element: <ThankyouPage />,
  },
  {
    path: routeConfigs?.adminLogin,
    element: <UserLogin />,
  },
  {
    path: routeConfigs?.adminForgotpassword,
    element: <UserForgotPassword />,
  },
  {
    path: routeConfigs?.adminResetpassword,
    element: <UserResetPassword />,
  },
  {
    path: routeConfigs.adminProfileSettings,
    element: <ProfileSettingPages />,
  },
  {
    path: routeConfigs.adminChangePassword,
    element: <ChangePassword />,
  },
  {
    path: routeConfigs.userHome,
    element: <Home />,
  },
  {
    path: routeConfigs.userHomeDup,
    element: <HomeDuplicate />,
  },
  {
    path: routeConfigs.userHomeDupNew,
    element: <HomeDuplicateNew />,
  },
  { path: routeConfigs.searchPage, element: <SearchPage /> },
  {
    path: `${routeConfigs.customCmsPage}/:id`,
    element: <CmsPage />,
  },
  {
    path: `${routeConfigs.customFooterGeneralCmsPage}/:id`,
    element: <GeneralFooterCmsPage />,
  },
  {
    path: routeConfigs.contactUsPage,
    element: <ContactUsPage />,
  },
  {
    path: routeConfigs.error,
    element: <Error />,
  },
  {
    path: "*",
    element: <Error />,
  },
  { path: routeConfigs.designSystem, element: <DesignSystemPage /> },
  {
    element: <Layout />,
    path: "/admin",
    children: [
      {
        path: routeConfigs.adminDashboard,
        element: <Dashboard />,
      },
      {
        path: routeConfigs.adminUserRolesAndPermisiions,
        element: <UserRolesAndPermissions />,
      },

      {
        path: routeConfigs.adminUserRequests,
        element: <UsersRequests />,
      },
      {
        path: routeConfigs.adminUserAll,
        element: <UsersAll />,
      },
      { path: routeConfigs.adminUserRecover, element: <UserRecover /> },
      {
        path: routeConfigs.adminAssetCategory,
        element: <AssetCategory />,
      },
      {
        path: routeConfigs.adminAssetSubcategory,
        element: <AssetSubCategory />,
      },
      {
        path: routeConfigs.adminAssetType,
        element: <AssetType />,
      },
      {
        path: routeConfigs.adminAssetsAdd,
        element: <AssetsAdd />,
      },
      {
        path: routeConfigs.adminAssetsAll,
        element: <AssetAll />,
      },
      {
        path: routeConfigs.adminAssetsCollections,
        element: <AssetsCollections />,
      },
      {
        path: routeConfigs.adminFieldsManagement,
        element: <FieldsManagement />,
      },
      {
        path: routeConfigs.adminAuiIptcCorefields,
        element: <IptcCoreFields />,
      },
      {
        path: routeConfigs.adminAuiCustomTags,
        element: <CustomTags />,
        children: [
          {
            path: "Scope-of-Work/:id",
            element: <ScopeOfWork />,
          },
        ],
      },

      {
        path: routeConfigs.adminDepartments,
        element: <Departments />,
      },
      {
        path: routeConfigs.adminRegions,
        element: <Groups />,
      },
      {
        path: routeConfigs.adminBusinessunits,
        element: <SubGroup />,
      },
      {
        path: routeConfigs.adminBanners,
        element: <Banners />,
      },
      {
        path: routeConfigs.adminPagesAll,
        element: <PagesAll />,
      },
      {
        path: routeConfigs.adminPagesAdd,
        element: <PagesAdd />,
      },
      {
        path: routeConfigs.adminPagesEdit,
        element: <PagesAdd />,
      },
      {
        path: routeConfigs.adminCmschangesFeaturedassets,
        element: <FeaturedAssets />,
      },
      {
        path: routeConfigs.adminCmschangesCuratedgallery,
        element: <CuratedGallery />,
      },
      {
        path: routeConfigs.adminCmschangesSearchbyfilters,
        element: <SearchByFilters />,
      },
      { path: routeConfigs.adminCmsFooterMenu, element: <FooterMenu /> },
      {
        path: routeConfigs.adminPagesFooterGeneralPages,
        element: <FooterGeneralPages />,
      },
      {
        path: routeConfigs.adminPagesFooterGeneralPagesEdit,
        element: <FooterGeneralPagesEdit />,
      },
      {
        path: routeConfigs.adminCmsTrendingSearches,
        element: <TrendingSearches />,
      },
    ],
  },
];

export default AllRouting;
