import { styled } from "@mui/system";

const NotificationStyle = styled("div")(({ theme }) => ({
  ".notificationCard": {
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  ".notificationContainer": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  ".notificationBox": {
    span: {
      display: "block",
      textAlign: "center",
      minWidth: "358px",
      minHeight: "70px",
      alignContent: "center",
    },
    ".headerMenuItem": {
      padding: "0",
      justifyContent: "center",
      textTransform: "unset",
      ".notificationContainer": {
        padding: "5px 15px",
        marginBottom: "5px",
        width: "100%",
        "&:is(.unread)": {
          backgroundColor: theme.palette.lightBlue.main,
        },
        "&:is(.read)": {
          backgroundColor: theme.palette.white.main,
        },
      },
    },
  },
  ".readButtons": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    button: {
      background: "transparent",
      color: "black",
      border: "0",
      textDecoration: "underline",
      fontSize: "12px!important",
    },
  },
}));

export default NotificationStyle;
