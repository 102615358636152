import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "../../../../utils/apiRoutes";
import axiosConfig from "../../../../utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getDepartments = createAsyncThunk(
  "department/getDepartments",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetDepartment}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetDepartment}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllDepartments = createAsyncThunk(
  "department/getAllDepartments",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetDepartment}?limit=5000&data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addDepartment = createAsyncThunk(
  "department/addDepartment",
  async (newDepartment, { dispatch }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAddDepartment, newDepartment),
      );
      // return dispatch(getDepartments());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (depIds, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteDepartment, depIds.body),
      );
      return dispatch(getDepartments(depIds.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateDepartment = createAsyncThunk(
  "department/updateDepartment",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminUpdateDepartment, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateDepartmentStatus = createAsyncThunk(
  "department/updateDepartmentStatus",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateDepartmentStatus}/${data?.body?.departmentId}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getActiveDeartment = createAsyncThunk(
  "department/getActiveDeartment",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminGetActiveDepartments),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getDepartemtsByBusinessUnitApi = createAsyncThunk(
  "department/getDepartemtsByBusinessUnitApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetDepartemtsByBusinessUnit}/${data?.id}`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// filter API's
export const getDepartmentFields = createAsyncThunk(
  "user/getDepartmentFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetDepartmentFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetDepartmentFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
