const Sorting = (myArray, key) => {
  if (!Array.isArray(myArray)) {
    console.error("Provided value is not an array.");
    return [];
  }

  const NewArray = [...myArray];
  NewArray.sort((a, b) => {
    const nameA = a[key]?.toUpperCase() || ""; // Handle potential undefined values
    const nameB = b[key]?.toUpperCase() || "";
    return nameA.localeCompare(nameB);
  });

  return NewArray;
};

export default Sorting;
