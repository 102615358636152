import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { Loader } from "components/Loader/Loader";

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  margin: "0 15px 0 0",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  "&.linkBtn": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: 0,
  },
  "&:disabled": {
    opacity: "0.4",
    color: theme.palette.white.main,
  },
}));

function CustomButton({
  color,
  onClick,
  disabled,
  startIcon,
  endIcon,
  size,
  children,
  type,
  className,
  loading,
  ...props
}) {
  return loading ? (
    <Loader />
  ) : (
    <StyledButton
      color={color}
      {...(onClick ? { onClick } : {})}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      type={type ? "submit" : "button"}
      className={className}
      disableRipple
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default CustomButton;
