import React from "react";
import { styled } from "@mui/system";

export const buttonVariants = {
  blue: "blue",
  grey: "grey",
  transparent: "transparent",
};

const commonStyles = ({ theme }) => ({
  width: "100%",
  minWidth: "10.875rem",
  maxWidth: "max-content",
  height: "3.313rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 24px 16px 24px",
  borderRadius: "8px",
  cursor: "pointer",
  fontWeight: "500!important",
  fontFamily: "poppins",
  fontSize: "14px!important",
  lineHeight: "21px",
  letterSpacing: "-0.01em",
});

const ButtonStyles = styled("button")(({ theme, variant }) => ({
  ...commonStyles({ theme }),
  ...(variant === buttonVariants.blue && {
    backgroundColor: theme.palette.primaryColor.main,
    color: theme.palette.whiteColor.main,
    border: `1px solid ${theme.palette.primaryColor.main}`,
    "&:is(:hover, .active)": {
      backgroundColor: theme.palette.darkBlueColor.main,
    },
    ":disabled": {
      backgroundColor: theme.palette.gray_300.main,
      borderColor: theme.palette.gray_300.main,
      color: theme.palette.gray_500.main,
      cursor: "auto",
    },
  }),
  ...(variant === buttonVariants.grey && {
    backgroundColor: theme.palette.gray_250.main,
    color: theme.palette.graySpaceCadet.main,
    border: `1px solid ${theme.palette.gray_300.main}`,
    "&:is(:hover, .active)": {
      backgroundColor: theme.palette.gray_300.main,
      border: `1px solid ${theme.palette.gray_400.main}`,
    },
    ":disabled": {
      backgroundColor: theme.palette.gray_300.main,
      borderColor: theme.palette.gray_300.main,
      color: theme.palette.gray_500.main,
      cursor: "auto",
    },
  }),
  ...(variant === buttonVariants.transparent && {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(153, 153, 153, 0.16) 100%)",
    color: theme.palette.whiteColor.main,
    border: `1px solid #FFFFFF14`,
    "&:is(:hover, .active)": {
      background:
        " linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(153, 153, 153, 0.32) 100%)",
    },
    ":disabled": {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(153, 153, 153, 0.16) 100%)",
      borderColor: " 1px solid #FFFFFF14",
      color: theme.palette.gray_500.main,
      cursor: "auto",
    },
  }),
  ...(variant === "default" && {
    backgroundColor: theme.palette.primaryColor.main,
    color: theme.palette.whiteColor.main,
    border: `1px solid ${theme.palette.primaryColor.main}`,
    "&:is(:hover, .active)": {
      backgroundColor: theme.palette.darkBlueColor.main,
    },
    ":disabled": {
      backgroundColor: theme.palette.gray_300.main,
      borderColor: theme.palette.gray_300.main,
      color: theme.palette.gray_500.main,
    },
  }),
}));

const MainButton = ({ title, variant = buttonVariants.blue, ...props }) => {
  return (
    <ButtonStyles variant={variant} {...props}>
      {title}
    </ButtonStyles>
  );
};

export default MainButton;
