import { styled } from "@mui/system";

const PageTitleStyle = styled("div")(({ theme }) => ({
  ".pageTitleContainer h1": {
    color: theme.palette.black.main,
    "@media screen and (max-width:1366px)": {
      fontSize: "25px",
    },
  },
}));

export default PageTitleStyle;
