import React from 'react'

const DownloadIconSetting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.096" height="22.096" viewBox="0 0 22.096 22.096">
    <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M11.611.563A11.048,11.048,0,1,0,22.659,11.611,11.046,11.046,0,0,0,11.611.563Zm2.544,15.6L10.225,13.3a.538.538,0,0,1-.218-.432v-7.5a.536.536,0,0,1,.535-.535H12.68a.536.536,0,0,1,.535.535v6.134l2.829,2.058a.534.534,0,0,1,.116.748L14.9,16.043A.538.538,0,0,1,14.154,16.159Z" transform="translate(-0.563 -0.563)" fill="#383838"/>
  </svg>
  
  )
}

export default DownloadIconSetting