import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Box } from "@mui/material";
import "moment/locale/en-gb";
import { useFormik } from "formik";
import CustomButton from "components/Button/CustomButton";

const ScheduleForLater = ({ handleClose }) => {
  const Moment = moment();
  const defaultTime = moment().add(moment.duration(15, "minutes"));
  const [disableTime, setDisableTime] = useState(true);
  const formik = useFormik({
    initialValues: { date: Moment, time: defaultTime },
    onSubmit: (values) => {},
  });
  const disable = (date) => {
    const disableBolean =
      date.format("DD-MM-yyyy") <= Moment.format("DD-MM-yyyy");
    if (disableBolean === true) {
      setDisableTime(true);
      formik?.setFieldValue("time", defaultTime);
    } else {
      setDisableTime(false);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 10 }}>
            <label className="label">Select Date And Time</label>

            <DatePicker
              name="date"
              disablePast
              value={formik?.values?.date}
              onChange={(date, error) => {
                error.validationError === null
                  ? formik?.setFieldValue("date", date)
                  : formik?.setFieldValue("date", Moment);
                disable(date);
              }}
            />
            <TimePicker
              name="time"
              clearable
              ampm={false}
              value={formik?.values?.time}
              disablePast={disableTime}
              onChange={(time, error, uu) => {
                error.validationError === null
                  ? formik?.setFieldValue("time", time)
                  : formik?.setFieldValue("time", defaultTime);
              }}
            />
          </Box>
          <Box sx={{ my: 10, textAlign: "center" }}>
            <CustomButton type="submit">Submit</CustomButton>
            <CustomButton onClick={handleClose}>Cancel</CustomButton>
          </Box>
        </form>
      </LocalizationProvider>
    </>
  );
};

export default ScheduleForLater;
