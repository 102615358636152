import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2256 9.70737L12.75 12.25M11.5833 6.41667C11.5833 8.99401 9.49401 11.0833 6.91667 11.0833C4.33934 11.0833 2.25 8.99401 2.25 6.41667C2.25 3.83934 4.33934 1.75 6.91667 1.75C9.49401 1.75 11.5833 3.83934 11.5833 6.41667Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
