import * as Yup from "yup";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
export const AddPageSchema = Yup.object().shape({
  pageTitle: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.atleastOneNumberOrAlphabet,
      validationMessages.atleastOneNumberOrAlphabet,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterPageTitle),
  pageHtml: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterPageContent)
    .notOneOf(["<p><br></p>"], validationMessages.pleaseEnterPageContent),
});

export const AddFooterGeneralPageSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.atleastOneNumberOrAlphabet,
      validationMessages.atleastOneNumberOrAlphabet,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterPageTitle),
  description: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterPageContent)
    .notOneOf(["<p><br></p>"], validationMessages.pleaseEnterPageContent),
});

export const validateContentSize = (values) => {
  const contentSize = new TextEncoder().encode(values.pageHtml).length;
  if (contentSize / 1000000 > 16.7) {
    return { pageHtml: validationMessages.contentTooLarge };
  }
  return null;
};
