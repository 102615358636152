export function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Primitive data types (including functions) are copied by value
  }

  if (Array.isArray(obj)) {
    const copy = [];
    for (const item of obj) {
      copy.push(deepCopy(item)); // Recursively copy array elements
    }
    return copy;
  }

  const copy = {};
  for (const key in obj) {
    copy[key] = deepCopy(obj[key]); // Recursively copy object properties
  }
  return copy;
}
