import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";
// import demoPng from "../../../Image/demo.png";
import { useDispatch } from "react-redux";
import { getMetaDataApi } from "../Features/addAssetAction";
import { UploadFileBlock } from "./UploadSectionStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import pdffileIcon from "./images/PDF_file_icon.svg.png";
import { getAllIptcCreators } from "pages/Admin-Pages/aui-form-fields/IptcCoreFields/features/iptcCreatorActions";
import { validationMessages } from "utils/validation/validationUtils";
import { useTranslation } from "react-i18next";

const ContainerDiv = styled("div")`
  position: relative;
  grid-template-columns: repeat(8, 120px);
  display: grid;
  width: 100vw;
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;
const InputContainer = styled("div")`
  position: relative;
  width: 110px;
  height: 170px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
`;
const CustomLabel = styled("label")`
  display: grid;
  align-content: center;
  height: -webkit-fill-available;
`;

const UploadSection = ({
  formik,
  selectedCheckboxes,
  setSelectedCheckboxes,
  setState,
  initialValue,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dropzoneError, setDropzoneError] = useState({
    lengthError: "",
    duplicatesError: "",
  });
  const { getRootProps, getInputProps } = useDropzone({
    multiple: formik.values.chooseUpload !== "single" ? true : false,
    onDrop: (file) => {
      const previousFileSize = formik?.values?.files?.reduce(
        (adder, currentValue) => adder + currentValue.size,
        0,
      );
      const currentFileSize = file.reduce(
        (adder, currentValue) => adder + currentValue.size,
        0,
      );
      const totalSize = previousFileSize + currentFileSize;
      const Duplicates = file.filter((dropfile) => {
        return formik?.values?.files?.some(
          (uploaded) => dropfile.name === uploaded.name,
        );
      });
      if (totalSize < 200000000 && Duplicates.length === 0) {
        setDropzoneError({});
        if (formik?.values?.files?.length === 0) {
          formik.setFieldValue("files", file);
        } else if (formik?.values?.files?.length > 0) {
          formik.setFieldValue("files", formik?.values?.files?.concat(file));
        }
      } else {
        totalSize > 200000000 &&
          (dropzoneError.lengthError =
            "The length of the file exceed to 200mb");
        Duplicates.length > 0 &&
          (dropzoneError.duplicatesError =
            validationMessages.donotUploadDuplicateFiles);
      }
    },
  });

  const RemoveUploadfiles = async (data) => {
    formik.setFieldValue("credit_line", "");
    formik.setFieldValue("copyright_notice", "");
    formik.setFieldValue("copyright_status", "");
    formik.setFieldValue("right_usage_terms", "");
    formik.setFieldValue("keywords", "");
    formik.setFieldValue("description", "");
    const index = formik?.values?.files?.indexOf(data);
    const newFiles = await formik?.values?.files?.toSpliced(index, 1);
    formik.setFieldValue("files", newFiles);
  };

  const handleMetaDataClick = (index) => {
    const formData = new FormData();
    formData.append("files", formik?.values?.files[index]);

    dispatch(getMetaDataApi(formData)).then((res) => {
      const data = res?.payload?.data;

      formik.setFieldValue("credit_line", data?.Credit || "");
      formik.setFieldValue("copyright_notice", data?.CopyrightNotice || "");
      formik.setFieldValue("copyright_status", "");
      formik.setFieldValue("right_usage_terms", "");
      formik.setFieldValue(
        "keywords",
        `${formik.values.keywords}${
          formik.values.keywords?.trim()?.length > 0 ? "," : ""
        }${data?.Keywords?.length > 0 ? data?.Keywords : ""}${
          data?.Keywords?.trim().length > 0 ? "," : ""
        }${res?.payload?.keywords?.length > 0 ? res?.payload?.keywords : ""}`,
      );
      formik.setFieldValue("description", data?.Description || "");

      //if creator exits
      const creatorDataName =
        data?.Creator?.[0]?.toLowerCase() || data?.Creator?.toLowerCase();

      //if creator doesnot exist
      if (!data?.Creator) {
        setState(initialValue);
      }

      if (creatorDataName !== null) {
        dispatch(getAllIptcCreators()).then((res) => {
          const creatorData = res?.payload?.data?.filter(
            (val) => val.name === creatorDataName,
          );
          if (creatorData) {
            formik.setFieldValue(
              "creator_id",
              creatorData?.[0]?._id || creatorData?._id,
            );
          }
        });
      }
    });
  };

  const style = {
    margin: "2px auto",
    height: "81px",
    width: "66px",
    objectFit: "contain",
  };

  return (
    <>
      <UploadFileBlock>
        <Box className="uploadAssetsClass">
          <h3>{t("uploadAssets")}</h3>
          <Box className="uploadFileArea">
            <ContainerDiv
              {...getRootProps()}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {formik?.values?.files?.length > 0 &&
                formik?.values?.files?.map((data, index) => {
                  return (
                    <Box className="innerFileCards">
                      <InputContainer
                        key={data.path}
                        onClick={(e) => e.stopPropagation()}
                        className="inputContainer"
                      >
                        <Box className="upladedImage">
                          {data?.type.startsWith("image") ? (
                            <img
                              src={URL.createObjectURL(data)}
                              alt={data.path}
                              style={style}
                            />
                          ) : data?.type.startsWith("video") ? (
                            <video
                              src={URL.createObjectURL(data)}
                              style={style}
                            />
                          ) : (
                            <img
                              src={pdffileIcon}
                              alt={data.path}
                              style={style}
                            />
                          )}
                        </Box>
                        <Box className="cardsButtons">
                          <span onClick={() => handleMetaDataClick(index)}>
                            <VisibilityIcon /> {t("viewMetaData")}
                          </span>
                          <span onClick={() => RemoveUploadfiles(data)}>
                            <RemoveCircleOutlineIcon /> {t("removeFile")}
                          </span>
                        </Box>
                      </InputContainer>
                    </Box>
                  );
                })}
              {formik?.values?.files?.length === 1 &&
              formik.values.chooseUpload === "single" ? (
                ""
              ) : (
                <Box className="innerFileCards">
                  <InputContainer
                    onClick={(e) => e.stopPropagation()}
                    className="uploadBtn"
                  >
                    <CustomLabel htmlFor="uploadId">
                      + {t("upload")}
                    </CustomLabel>
                    <input id="uploadId" {...getInputProps()} />
                  </InputContainer>
                </Box>
              )}
            </ContainerDiv>
          </Box>
        </Box>
      </UploadFileBlock>

      <span className="errorClass">
        {formik?.touched.files && formik.errors.files}
      </span>

      <span className="errorClass">{dropzoneError?.duplicatesError}</span>
      <span className="errorClass">{dropzoneError?.lengthError}</span>
    </>
  );
};

export default UploadSection;
