import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const UserRegisterSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterFirstName)
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
  last_name: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterLastName)
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
  email: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterEmail)
    .max(300, validationMessages.tooLong)
    .matches(
      validationRegex.checkForValidEmail,
      validationMessages.emailNotVaid,
    ),
  password: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterPassword)
    .matches(
      validationRegex.checkPasswordRequirements,
      validationMessages.passwordMeetsRequirements,
    ),
  resetPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      validationMessages.makeSurePasswordsMatch,
    )
    .required(validationMessages.pleaseEnterConfirmPassword),
  reCaptcha: Yup.string().required(validationMessages.pleaseEnterCaptcha),
});
