import { createSlice } from "@reduxjs/toolkit";
import {
  creatAssetApi,
  getMetaDataApi,
  getSubcategoryById,
  getallCustomTags,
} from "./addAssetAction";

const initialState = {
  allCustomTags: [],
  loading: false,
  subCategory: [],
  subCategoryloader: false,
  metaData: [],
  metaDataLoader: false,
  createAssetLoader: false,
};

const addAssetsSlice = createSlice({
  name: "addAsset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getallCustomTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getallCustomTags.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomTags = action.payload;
      })
      .addCase(getallCustomTags.rejected, (state, action) => {
        state.loading = false;
      });
    builder
      .addCase(getSubcategoryById.pending, (state) => {
        state.subCategoryloader = true;
      })
      .addCase(getSubcategoryById.fulfilled, (state, action) => {
        state.subCategory = action.payload;
        state.subCategoryloader = false;
      })
      .addCase(getSubcategoryById.rejected, (state, action) => {
        state.subCategoryloader = false;
      });
    builder
      .addCase(getMetaDataApi.pending, (state) => {
        state.metaDataLoader = true;
      })
      .addCase(getMetaDataApi.fulfilled, (state, action) => {
        state.metaData = action.payload;
        state.metaDataLoader = false;
      })
      .addCase(getMetaDataApi.rejected, (state, action) => {
        state.metaDataLoader = false;
      });
    builder
      .addCase(creatAssetApi.pending, (state) => {
        state.createAssetLoader = true;
      })
      .addCase(creatAssetApi.fulfilled, (state, action) => {
        state.createAssetLoader = false;
      })
      .addCase(creatAssetApi.rejected, (state, action) => {
        state.createAssetLoader = false;
      });
  },
});

export const allCustomTagsState = (state) => state.addAssets;
export default addAssetsSlice.reducer;
