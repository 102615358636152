import { createSlice } from "@reduxjs/toolkit";
import { getViewAssetsAll } from "./viewAction";

const initialState = {
  assetsAll: [],
  loading:true,

};

const viewAssetsAllSlice = createSlice({
  name: "AssetsAll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getViewAssetsAll.pending, (state) => {
        state.loading=true;
      })
      .addCase(getViewAssetsAll.fulfilled, (state, action) => {
        state.loading=false;
        state.assetsAll = action.payload;
      })
      .addCase(getViewAssetsAll.rejected, (state, action) => {
        state.loading=false;
        state.assetsAll = action.payload;
      });
    
  },
});

export const viewAssetsAll = (state) => state.viewAssets;



export default viewAssetsAllSlice.reducer;
