import _ from "lodash";
import {
  localConfig,
  retrieveDataFromLocalStorage,
} from "../utils/localStorage";

export function _getToken() {
  try {
    const token = retrieveDataFromLocalStorage(localConfig.userLoginToken)
      ? retrieveDataFromLocalStorage(localConfig.userLoginToken)
      : false;
    return { token, status: !_.isEmpty(token) };
  } catch (e) {
    return { token: null, status: false };
  }
}
