export const routeConfigs = {
  admin: "*",
  // adminLogin: "/admin-login",
  // adminForgotpassword: "/admin-forgotpassword",
  // adminResetpassword: "/admin-resetpassword",
  error: "/error",
  designSystem: "/design-system",
  adminLayout: "/admin",
  //menu routes
  adminDashboard: "/admin/dashboard",
  // adminUserRoles: "/admin/user-administration/user-roles",
  // adminUserRolesPermissions: "/admin/user-administration/permissions",
  adminUserRolesAndPermisiions:
    "/admin/user-administration/rolesAndPermissions",
  adminUserRequests: "/admin/user-administration/users/requests", //when changing also change on node for notifications
  adminUserAll: "/admin/user-administration/users/all", //when changing also change on node for notifications
  adminUserRecover: "/admin/user-administration/users/recover",
  adminAssetCategory: "/admin/asset-administration/category/categories",
  adminAssetSubcategory: "/admin/asset-administration/category/sub-categories",
  adminAssetType: "/admin/asset-administration/type",
  adminAssetsAll: "/admin/asset-administration/all-assets",
  adminAssetsAdd: "/admin/contribute",
  adminAssetsCollections: "/admin/collections/type",
  adminFieldsManagement: "/admin/collections/fields-management",
  adminAuiIptcCorefields: "/admin/aui/iptc-core-fields",
  adminAuiCustomTags: "/admin/aui/custom-tags",
  adminRegions: "/admin/user-administration/groups/primary",
  adminBusinessunits: "/admin/user-administration/groups/secondary",
  adminDepartments: "/admin/user-administration/groups/tertiary",

  adminPagesAll: "/admin/pages/all",
  adminPagesAdd: "/admin/pages/add",
  adminPagesEdit: "/admin/pages/edit",
  adminPagesFooterGeneralPages: "/admin/pages/footer-general-pages",
  adminPagesFooterGeneralPagesEdit: "/admin/pages/footer-general-pages-edit",

  adminCmschangesFeaturedassets: "/admin/cms-changes/featured-assets",
  adminCmschangesCuratedgallery: "/admin/cms-changes/curated-gallery",
  adminCmschangesSearchbyfilters: "/admin/cms-changes/search-by-filters",
  adminBanners: "/admin/cms-changes/banners",
  adminCmsTrendingSearches: "/admin/cms-changes/trending-searches",
  adminCmsFooterMenu: "/admin/cms-changes/footer-menu",

  //profile-pages
  adminProfileSettings: "/profile-settings",
  adminChangePassword: "/change-password",
  //user routes
  userRegister: "/register",
  userLogin: "/login",
  userThankyouPage: "/thankyou",
  userForgotPassword: "/forgotpassword",
  userResetPassword: "/resetpassword",
  //homepage
  userHome: "/homepage",
  userHomeDup: "/homepage-duplicate",
  userHomeDupNew: "/homepage-duplicate-new",
  //custom cms footer page
  customCmsPage: "/cmsPage",
  customFooterGeneralCmsPage: "/footer-general-cms-page",
  contactUsPage: "/contact-us",

  //search
  searchPage: "/search",
};
