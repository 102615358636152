import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "../../../../../utils/axiosConfig";

export const getViewAssetsAll = createAsyncThunk(
  "AssetsAll/getAssetsAll",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminViewAllAssets}?limit=5000`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminViewAllAssets}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// export const getViewAssetsAllFields = createAsyncThunk(
//   "AssetsAll/getViewAssetsAllFields",
//   async (data) => {
//     try {
//       const { column, filters } = data;
//       let apiUrl = `${apiRoutes.adminGetAssetTypeFields}?column=${column}`;
//       if (column && filters) {
//         const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
//         apiUrl = `${apiRoutes.adminGetAssetTypeFields}?column=${column}&filters=${encodedQueryString}`;
//       }
//       return await performApiRequest(() => axiosConfig.get(apiUrl));
//     } catch (error) {
//       return { success: false, error: error.response.data };
//     }
//   },
// );
