import React, { useEffect, useState } from "react";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import {
  checkPasswordStrength,
  validationRegex,
} from "utils/validation/validationUtils";
import i18n from "Assets/i18n/config";

const t = i18n.t;

const PasswordCheckPopup = (props) => {
  const { password } = props;
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState({
    code: 0,
    message: t("tooShort"),
    class: "Too Short",
  });

  useEffect(() => {
    const passMs = checkPasswordStrength(password);
    setPasswordStrengthMessage(passMs);
  }, [password]);

  return (
    <div className="passwordReqPopup">
      <div className="passwordStrengthBarOuter">
        <div className="passwordStrengthBar">
          <div
            className={`strengthBar1 ${
              passwordStrengthMessage.code >= 1
                ? passwordStrengthMessage.class
                : ""
            }`}
          />
          <div
            className={`strengthBar2 ${
              passwordStrengthMessage.code >= 2
                ? passwordStrengthMessage.class
                : ""
            }`}
          />
          <div
            className={`strengthBar3 ${
              passwordStrengthMessage.code >= 3
                ? passwordStrengthMessage.class
                : ""
            }`}
          />
          <div
            className={`strengthBar4 ${
              passwordStrengthMessage.code >= 4
                ? passwordStrengthMessage.class
                : ""
            }`}
          />
        </div>
        <span className={passwordStrengthMessage.class}>
          {passwordStrengthMessage.message}
        </span>
      </div>
      <ul className="passwordReqUl">
        <li
          className={
            validationRegex.checkLengthBwEightTwenty.test(password)
              ? `passwordReq passwordReqTrue`
              : `passwordReq passwordReqFalse`
          }
        >
          {validationRegex.checkLengthBwEightTwenty.test(password) ? (
            <RxCheckCircled />
          ) : (
            <RxCrossCircled />
          )}
          &nbsp;{t("useMinimumEightCharacters")}
        </li>
        <li
          className={
            validationRegex.numbers.test(password)
              ? `passwordReq passwordReqTrue`
              : `passwordReq passwordReqFalse`
          }
        >
          {validationRegex.numbers.test(password) ? (
            <RxCheckCircled />
          ) : (
            <RxCrossCircled />
          )}
          &nbsp;{t("includeAtLeastANumber")}
        </li>
        <li
          className={
            validationRegex.specialChars.test(password)
              ? `passwordReq passwordReqTrue`
              : `passwordReq passwordReqFalse`
          }
        >
          {validationRegex.specialChars.test(password) ? (
            <RxCheckCircled />
          ) : (
            <RxCrossCircled />
          )}
          &nbsp;{t("includeAtLeastOneSpecialCharacter")}
        </li>
      </ul>
    </div>
  );
};

export default PasswordCheckPopup;
