import React, { useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { ErrorStyle } from "./styles";
import CustomButton from "../../../components/Button/CustomButton";
import { routeConfigs } from "utils/routeConfig";
import { useTranslation } from "react-i18next";

export default function Error() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.pathname !== routeConfigs.error) {
      navigate(routeConfigs.error);
    }
  }, [navigate]);

  return (
    <ErrorStyle>
      <Grid container className="container">
        <div className="logotype">
          <img
            className={"logotypeIcon"}
            src="./logos/full-stack-white.svg"
            alt="logo"
          />
        </div>
        <Paper classes={{ root: "paperRoot" }}>
          <Typography
            variant="h1"
            color="primary"
            className={classnames("textRow", "errorCode")}
          >
            404
          </Typography>
          <Typography variant="h5" color="primary" className={"textRow"}>
            {t("notFoundText")}
          </Typography>
          <Typography
            variant="h6"
            color="text"
            colorBrightness="secondary"
            className={classnames("textRow", "safetyText")}
          >
            {t("backToSafetyText")}
          </Typography>
          <CustomButton
            variant="contained"
            color="primary"
            component={Link}
            onClick={() => {
              navigate(routeConfigs.userHome);
            }}
            size="large"
            className={"backButton"}
          >
            {t("backToHome")}
          </CustomButton>
        </Paper>
      </Grid>
    </ErrorStyle>
  );
}
