import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import { assetCategoryState } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import { getAssetCategoryApi } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import {
  createCuratedGalleryApi,
  getCuratedGalleries,
} from "./features/curatedGalleryAction";
import { curatedGalleriesData } from "./features/curatedGallerySlice";
import CuratedGalleryDragDrop from "./CuratedGalleryDragDrop/CuratedGalleryDragDrop";
import CuratedGalleryStyles from "./CuratedGalleryStyles";

export default function CuratedGallery() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const assetCategoryData = useSelector(assetCategoryState)?.asset_type;
  const curatedGalleryData = useSelector(curatedGalleriesData);
  const [categoryId, setCategoryId] = useState(" ");

  useEffect(() => {
    dispatch(getAssetCategoryApi());
  }, [dispatch]);

  const handlCategoryChange = (e) => {
    const category_id = e.target.value;
    setCategoryId(category_id);
    if (category_id?.trim() === "" || !category_id) {
      //when no category is selected
      return;
    }

    const body = {
      name: assetCategoryData?.filter(
        (catObj) => catObj?._id === category_id,
      )?.[0]?.name,
      category_id,
    };

    dispatch(createCuratedGalleryApi(body)).then(() => {
      dispatch(getCuratedGalleries(category_id));
    });
  };

  return (
    <CuratedGalleryStyles>
      <Box className="curatedGalleryMain">
        <PageTitle title={t("curatedGallery")} />
        <Box className="categorySelectMain">
          <label htmlFor="categorySelect">Select Category</label>
          <FormControl
            variant="outlined"
            className={"formControl selectCategory"}
            size="small"
          >
            <Select
              className="permission-role-select"
              name="categorySelect"
              value={categoryId}
              onChange={handlCategoryChange}
              id="categorySelect"
            >
              <MenuItem value={" "}>{t("select")}</MenuItem>
              {assetCategoryData?.map((data, index) => (
                <MenuItem value={data?._id} key={index}>
                  {data?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {(!categoryId || categoryId?.trim() !== "") && (
          <CuratedGalleryDragDrop
            curatedGalleryData={curatedGalleryData}
            categoryId={categoryId}
          />
        )}
      </Box>
    </CuratedGalleryStyles>
  );
}
