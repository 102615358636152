import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { TextField } from "@mui/material";
import { Formik } from "formik";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import { useQuery } from "utils/queryString";
import { routeConfigs } from "utils/routeConfig";
import {
  AddFooterGeneralPageSchema,
  validateContentSize,
} from "utils/validation/validation-schemas/pageSchema";
import { footerGeneralSinglePageData } from "./features/footerGeneralPagesSlice";
import {
  editGeneralFooterPageApi,
  getSingleGeneralFooterPageApi,
} from "./features/footerGeneralPagesAction";
import {
  generateSlugFromString,
  reactQuillFormats,
  reactQuillModules,
} from "utils/textEditorConst";
import PageAddStyle from "../PagesAddStyle";
import "react-quill/dist/quill.snow.css";

function FooterGeneralPagesEdit() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageData = useSelector(footerGeneralSinglePageData);
  const pageId = useQuery().get("id");

  useEffect(() => {
    if (
      window.location.pathname ===
        routeConfigs.adminPagesFooterGeneralPagesEdit &&
      pageId
    ) {
      dispatch(getSingleGeneralFooterPageApi(pageId));
    }
  }, [dispatch, pageId]);

  const initialValues = {
    title: (pageId && pageData?.title) || "",
    description: (pageId && pageData?.description) || "",
  };

  function handleFormSubmit(values) {
    const body = {
      title: values?.title,
      slug: generateSlugFromString(values?.title),
      description: values.description,
      id: pageId,
    };
    dispatch(editGeneralFooterPageApi(body)).then((res) => {
      if (!res?.error) {
        navigate(routeConfigs.adminPagesFooterGeneralPages);
      }
    });
  }

  return (
    <PageAddStyle>
      <PageTitle
        title={
          window.location.pathname === routeConfigs.adminPagesAdd
            ? t("addNewPage")
            : t("editPage")
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={AddFooterGeneralPageSchema}
        onSubmit={handleFormSubmit}
        validate={validateContentSize}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={`addViewTable ${i18n.language}`}
          >
            <div className="table-upper addNewPage">
              <div className="leftSideInputs">
                <div className="request-common">
                  <TextField
                    placeholder={t("pageTitle")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="title"
                    value={values?.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="errorClass">
                    {errors.title && touched.title && errors.title}
                  </span>
                </div>
              </div>
            </div>
            <div className="request-common">
              <ReactQuill
                className="richTextEditor"
                theme="snow"
                value={values?.description}
                placeholder={t("enterContentHere")}
                onChange={(e) => {
                  setFieldValue("description", e);
                }}
                name="description"
                modules={reactQuillModules}
                formats={reactQuillFormats}
              />
              <span className="errorClass">
                {errors.description &&
                  touched.description &&
                  errors.description}
              </span>
            </div>
            <div className="formButtons">
              <CustomButton variant={"contained"} type="submit">
                {t("update")}
              </CustomButton>
              <CustomButton
                variant={"contained"}
                onClick={() => {
                  navigate(routeConfigs.adminPagesFooterGeneralPages);
                }}
              >
                {t("cancel")}
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </PageAddStyle>
  );
}

export default FooterGeneralPagesEdit;
