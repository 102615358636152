import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextfieldComp from "components/Textfield/TextfieldComp";
import { ProfileSettingSchemas } from "utils/validation/validation-schemas/profileSettingSchemas";
import { selectAllRegion } from "../../groups/features/groupSlice";
import { getRegions } from "../../groups/features/groupAction";
import CustomButton from "components/Button/CustomButton";
import {
  getDepartemtsByBusinessUnitApi,
  getDepartments,
} from "../../tertiaryGroup/features/departmentAction";
import {
  getBusinessRegion,
  getUserData,
} from "../../user-administration/features/userAction";
import { userData } from "../../user-administration/features/userSlice";
import { profileSettingApi } from "../features/profileAction";
import withAuth from "Auth/withAuth";
import blankProfileIcon from "../images/profile-icon.png";
import { profileSettingState } from "../features/profileSlice";
import ImageIconProfileSetting from "components/Icons/ImageIcon/ImageIconSetting";
import TrashIcon from "components/Icons/Trash/TrashIcon";
import RightSideArrowIcon from "components/Icons/RightSideArrow/RightSideArrow";
import Sorting from "utils/sorting";
import { validationMessages } from "utils/validation/validationUtils";
import ImageCropModal from "components/ImageCropModal/ImageCropModal";
import { ProfileSettingStyle } from "./ProfileSettingStyle";
import { useTranslation } from "react-i18next";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profileLoading } = useSelector(profileSettingState);
  const user = useSelector(userData);
  const regionData = useSelector(selectAllRegion);

  const initialValues = {
    first_name: user?.[0]?.first_name || "",
    last_name: user?.[0]?.last_name || "",
    profile: user?.[0]?.profile_img,
    email: user?.[0]?.email || "",
    business_unit: user?.[0]?.business_unit || " ",
    region: user?.[0]?.region || " ",
    department: user?.[0]?.department || " ",
  };

  const formik = useFormik({
    validationSchema: ProfileSettingSchemas,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const [businessData, setBusinessData] = useState("");
  const [tertiaryGroupData, setTertiaryGroupData] = useState([]);
  const [error, setError] = useState("");
  const [tertiaryError, setTertiaryError] = useState("");
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (user?.[0]?.region) {
      dispatch(getBusinessRegion(user?.[0]?.region)).then((response) => {
        const secondaryDataArr = response?.payload?.business;
        const filteredSecondaryDataArr = fiterGroupArr(
          secondaryDataArr,
          user?.[0]?.business_unit,
        );
        if (filteredSecondaryDataArr?.length > 0) {
          setBusinessData(filteredSecondaryDataArr);
          handleSecondaryGroupChange(
            user?.[0]?.business_unit,
            user?.[0]?.department,
          );
        } else {
          setError(validationMessages.noSecondaryGroupAssociated);
        }
      });
    }
    //eslint-disable-next-line
  }, [user]);

  function fiterGroupArr(filterArr, groupId) {
    //filtering enabled group and also checking if initial value exist in array
    return filterArr?.filter(
      (filterData) => filterData?.enabled || filterData?._id === groupId,
    );
  }

  const handleRegionChange = (primaryGroupId, secondaryGroupId) => {
    setBusinessData([]);
    setError("");
    setTertiaryGroupData([]);
    setTertiaryError("");

    dispatch(getBusinessRegion(primaryGroupId)).then((response) => {
      const secondaryDataArr = response?.payload?.business;
      const filteredSecondaryDataArr = fiterGroupArr(
        secondaryDataArr,
        secondaryGroupId,
      );

      if (filteredSecondaryDataArr?.length > 0) {
        setBusinessData(filteredSecondaryDataArr);
      } else {
        setError(validationMessages.noSecondaryGroupAssociated);
      }
    });
  };

  const handleSecondaryGroupChange = (secondaryGroupId, tertiaryGroupId) => {
    setTertiaryGroupData([]);
    setTertiaryError("");
    const data = {
      id: secondaryGroupId,
    };
    dispatch(getDepartemtsByBusinessUnitApi(data)).then((resp) => {
      if (resp?.error) {
        return;
      }
      const tertiaryArr = resp?.payload?.business_units?.[0]?.departments;

      const filteredTertiaryDataArr = fiterGroupArr(
        tertiaryArr,
        tertiaryGroupId,
      );
      if (filteredTertiaryDataArr?.length > 0) {
        setTertiaryGroupData(filteredTertiaryDataArr);
      } else {
        setTertiaryError(validationMessages.noTertiaryGroupAssociated);
      }
    });
  };

  const handleImageCrop = () => {
    setCropModalOpen(true);
  };

  function handleSubmit(values) {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    if (values.profile?.type) {
      formData.append("profile", values.profile);
    } else {
      formData.append(
        "profile_img",
        values?.profile?.includes("https")
          ? values.profile.split(`${user[0]?.container}/`)?.[1]
          : "",
      );
    }
    if (values?.region?.trim()) formData.append("region", values.region);
    if (values?.business_unit?.trim())
      formData.append("business_unit", values.business_unit);
    if (values?.department?.trim())
      formData.append("department", values.department);

    dispatch(profileSettingApi(formData)).then((res) => {
      if (!res.error) {
        dispatch(getUserData());
      }
    });
  }

  return (
    <ProfileSettingStyle>
      <Box className="main">
        <Box className="container">
          <Box className="profileSetting">
            <Box className="tabPages">
              <form
                onSubmit={formik.handleSubmit}
                className="profileSettingBlock"
              >
                <Box className="profilePicture">
                  <label className="custom-file-upload fas">
                    <Box className="img-wrap img-upload">
                      {formik.values.profile ? (
                        <img
                          src={
                            formik?.values?.profile?.type
                              ? URL.createObjectURL(formik?.values?.profile)
                              : user?.[0]?.profile_img
                          }
                          alt=""
                        />
                      ) : (
                        <img src={blankProfileIcon} alt="" />
                      )}
                    </Box>
                    <span className="errorClass">
                      {formik.errors.profile &&
                        formik.touched.profile &&
                        formik.errors.profile}
                    </span>
                  </label>
                  <Box className="settingProfile">
                    <h4>{t("profileDetails")}</h4>
                    <Box className="profileSettingButotns">
                      <Box className="uploadPicBtn buttonDes">
                        <button type="button" onClick={handleImageCrop}>
                          <label htmlFor="imageUpload" />
                          <input
                            hidden
                            id="imageUpload"
                            type="file"
                            name="profile"
                            accept="image/jpeg, image/jpg, image/bmp, image/png"
                            value={""}
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <span>
                            <ImageIconProfileSetting /> {t("changePicture")}
                          </span>
                        </button>
                      </Box>
                      {formik?.values?.profile &&
                        formik?.values?.profile !== null && (
                          <Box className="deletBtnPic buttonDes">
                            <button
                              type="button"
                              className=""
                              onClick={() =>
                                formik.setFieldValue("profile", null)
                              }
                            >
                              <span>
                                <TrashIcon /> {t("deletePicture")}
                              </span>
                            </button>
                          </Box>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box className="profileInformaiton">
                  <Box className="request-common">
                    <label className="label">{t("firstName")}</label>
                    <TextfieldComp
                      name="first_name"
                      value={formik.values.first_name}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="errorClass">
                      {formik.errors.first_name &&
                        formik.touched.first_name &&
                        formik.errors.first_name}
                    </span>
                  </Box>
                  <Box className="request-common">
                    <label className="label">{t("lastName")}</label>
                    <TextfieldComp
                      name="last_name"
                      value={formik.values.last_name}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="errorClass">
                      {formik.errors.last_name &&
                        formik.touched.last_name &&
                        formik.errors.last_name}
                    </span>
                  </Box>
                  <Box className="request-common">
                    <label className="label">{t("emailAddress")}</label>
                    <TextfieldComp
                      name="email"
                      value={formik.values.email}
                      disabled
                    />
                  </Box>
                  <Box className="request-common">
                    <label className="label">{t("primaryOrganization")}</label>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className="formControl"
                        size="small"
                      >
                        <Select
                          name="region"
                          className="permission-role-select"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("business_unit", " ");
                            formik.setFieldValue("department", " ");
                            if (e.target.value?.trim() === "") {
                              //hotfix to remove error when no group is selected
                              setError("");
                            } else {
                              handleRegionChange(e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values?.region}
                          defaultValue={" "}
                        >
                          <MenuItem value={" "}>
                            {t("selectOrganization")}
                          </MenuItem>
                          {regionData?.map((val, index) => {
                            return (
                              val?.enabled && (
                                <MenuItem key={index + 1} value={val?._id}>
                                  {val?.name}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="request-common secondaryCustom">
                    <label className="label">
                      {t("secondaryOrganization")}
                    </label>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select "
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleSecondaryGroupChange(e.target.value);
                            formik.setFieldValue("department", " ");
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values?.business_unit}
                          name={"business_unit"}
                          disabled={
                            formik.values?.region.trim() === "" ||
                            businessData?.length === 0
                          }
                          defaultValue={" "}
                        >
                          <MenuItem value={" "}>
                            {t("selectOrganization")}
                          </MenuItem>
                          {Array.isArray(businessData) &&
                            businessData?.map((val, index) => {
                              return (
                                (val?._id === formik.values?.business_unit ||
                                  val?.enabled) && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    <span className="errorClass">{error}</span>
                  </Box>
                  <Box className="request-common  secondaryCustom">
                    <label className="label">{t("tertiaryOrganization")}</label>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select"
                          //  onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          name={"department"}
                          defaultValue={" "}
                          value={formik.values.department}
                          disabled={
                            formik.values?.business_unit?.trim() === "" ||
                            tertiaryGroupData?.length === 0 ||
                            !tertiaryGroupData
                          }
                        >
                          <MenuItem value={" "}>
                            {t("selectOrganization")}
                          </MenuItem>
                          {Sorting(tertiaryGroupData, "name")?.map(
                            (val, index) => {
                              return (
                                val?.enabled && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              );
                            },
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                    <span className="errorClass">{tertiaryError}</span>
                  </Box>

                  <Box className="updateButton">
                    <CustomButton loading={profileLoading} type="submit">
                      <span> {t("update")}</span>
                      <RightSideArrowIcon />
                    </CustomButton>
                  </Box>
                </Box>
                {image && (
                  <ImageCropModal
                    open={cropModalOpen}
                    onClose={() => setCropModalOpen(false)}
                    image={image}
                    formik={formik}
                    setImage={setImage}
                  />
                )}
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </ProfileSettingStyle>
  );
};

export default withAuth(ProfileSettings);
