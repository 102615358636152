import { styled } from "@mui/system";

const CmsPageStyles = styled("div")(({ theme }) => ({
  ".cmsPage": {
    marginTop: "70px",
    ".cms_page_main": {
      padding: "0px 64px",
      ".cms_pageTitle": { textAlign: "center", padding: "8px" },
    },
  },
}));

export default CmsPageStyles;
