import React from "react";
import withAuth from "../../../../Auth/withAuth";
import { routeConfigs } from "../../../../utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import SearchPageStyles from "./SearchPageStyles";
import { t } from "i18next";

function SearchPage() {
  return (
    <Layout>
      <SearchPageStyles>
        <p>{t("pageUnderDevelopment")}</p>
      </SearchPageStyles>
    </Layout>
  );
}
export default withAuth(SearchPage, routeConfigs.userLogin);
