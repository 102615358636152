import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const createTooltipApi = createAsyncThunk(
  "userAuth/createTooltipApi",
  async (data, { rejectWithValue }) => {
    try {
      await performApiRequest(() => {
        return axiosConfig.post(apiRoutes.adminCreateTooltip, data?.body);
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
