import React from "react";
import { Box } from "@mui/system";
import PageTitleStyle from "./styles";

export default function PageTitle(props) {
  return (
    <PageTitleStyle>
      <Box
        className={`pageTitleContainer ${
          props?.className ? props?.className : ""
        }`}
      >
        <h1 className={"typo"}>
          {props.icon} {props.title}
        </h1>
        {props.button && props.button}
      </Box>
    </PageTitleStyle>
  );
}
