import { styled } from "@mui/system";

const PageAddStyle = styled("div")(({ theme }) => ({
  ".table-upper": {
    ".leftSideInputs": {
      gap: "15px",
      ".request-common": {
        maxWidth: "270px",
        width: "100%",
        display: "grid",
        position: "relative",
        marginTop: "0px",
        ".addNewDivSelect": {
          maxWidth: "100%",
          width: "100%",
        },
        "span.errorClass": {
          position: "absolute",
          top: "40px",
        },
      },
    },
    "&.addNewPage": {
      minHeight: "64px",
      ".leftSideInputs": {
        display: "flex",
        WebkitBoxAlign: "center",
        alignItems: "center",
        width: "100%",
        WebkitBoxPack: "end",
        justifyContent: "flex-end",
        ".addNewDivSelect": {
          ".MuiFormControl-root": {
            width: "100%",
          },
        },
      },
    },
  },
  ".request-common": {
    marginTop: "24px",
    "span.errorClass": {
      position: "absolute",
    },
    ".ql-toolbar": {
      ".ql-picker-options": {
        ".ql-picker-item": {
          "&:before": {},
        },
      },
    },
    " .ql-editor": {
      backgroundColor: theme.palette.white.main,
      minHeight: "300px",
      "&:is(.ql-blank)": {
        "&:before": {
          fontStyle: "normal",
        },
      },
    },
    ".ql-tooltip.ql-editing": {
      zIndex: "3",
    },
  },
  ".formButtons": {
    margin: "15px 0",
    textAlign: "right",
    button: {
      "&:last-child": {
        marginRight: "0",
      },
    },
  },
  ".addViewTable.de .table-upper .leftSideInputs .request-common": {
    maxWidth: "291px",
  },
}));

export default PageAddStyle;
