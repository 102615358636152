import { TextField } from "@mui/material";
import React from "react";

const TextfieldComp = ({
  handleChange,
  handleBlur,
  value,
  name,
  type,
  placeholder,
  disabled,
  ...props
}) => {
  return (
    <TextField
      size="small"
      variant="outlined"
      type={type ? type : "text"}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      placeholder={placeholder}
      disabled={disabled ? disabled : false}
      {...props}
    />
  );
};

export default TextfieldComp;
