import React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { userData } from "pages/Admin-Pages/user-administration/features/userSlice";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  "&.avatarText": {
    fontSize: "1.125rem",
    color: "#212A36",
    textTransform: "uppercase",
    fontWeight: "500",
    background: "rgba(245, 245, 246, 1)",
  },
}));

const AvatarComp = (props) => {
  const { extraClass } = props;
  const userAllData = useSelector(userData);
  const profileImageSource = userAllData?.[0]?.profile_img;

  return profileImageSource ? (
    <StyledAvatar
      alt="user avatar"
      className={`avatarImage ${extraClass ? extraClass : ""}`}
      src={profileImageSource}
      {...props}
    />
  ) : (
    <StyledAvatar
      alt="user avatar"
      className={`avatarText ${extraClass ? extraClass : ""}`}
      {...props}
    >
      {userAllData?.[0]?.first_name?.charAt(0)}
      {userAllData?.[0]?.last_name?.charAt(0)}
    </StyledAvatar>
  );
};

export default AvatarComp;
