import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline, GlobalStyles, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { ToastContainer } from "react-toastify";
import Themes from "themes";
import App from "App";
import RoutesSite from "Routes/Routes";
import store from "store/store";
import { InjectAxiosInterceptors } from "utils/AxiosIntercepters";
import GlobalStyle from "GlobalStyle";
import "./Assets/i18n/config";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <GlobalStyles styles={GlobalStyle} />
      <ToastContainer progressClassName="loading_hideProgrssBar" />
      <Provider store={store}>
        <RoutesSite />
        <BrowserRouter>
          <InjectAxiosInterceptors />
          <App tab="home" />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
