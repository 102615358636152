import { styled } from "@mui/system";

const contactUsStyles3 = styled("div")(({ theme }) => ({
    ".contactUsMain": {
        height: "300vh",
        position: "relative",
        padding: "0 5%",
        backgroundColor: theme.palette.blue_Color.main,
        ".mainContentWrapper": {
            height: "100dvh",
            position: ["-webkit-sticky", "sticky"],
            top: "0",
            overflow: "hidden",
            "&:after": {
                content: "''",
                background:
                    "linear-gradient(180deg, rgb(0 0 255 / 0%) 0%, rgb(0 86 184 / 68%) 100%)",
                width: "100%",
                height: "100vh",
                position: "absolute",
                left: "0",
                bottom: "0",
                zIndex: "1",
            },
            ".imageWrapperMain": {
                zIndex: 1,
                position: "absolute",
                top: "0%",
                bottom: "0%",
                left: "0%",
                right: "auto",
                ".imagesListBlock": {

                    ".imagesListBox": {
                        zIndex: 1,
                        gridRowGap: "26vw",
                        flexDirection: "column",
                        paddingTop: "70vh",
                        display: "flex",
                        ".imageWrapper": {
                            width: "22vw",
                            paddingTop: "120%",
                            position: "relative",
                            img: {
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "0%",
                                bottom: "0%",
                                left: "0%",
                                right: "0%",
                            },
                            "&.imageTwo": {
                                width: "22vw", marginTop: "-46vw", left: "58vw", position: 'absolute',
                                bottom: '-9vw',
                                left: '220px'
                            },
                            "&.imageThree": { width: "20vw", marginTop: "-5vw", left: "4vw" },
                            "&.imageFour": {
                                width: "18vw",
                                marginTop: "-45vw",
                                left: "64vw",
                                position: 'absolute',
                                right: '220px',
                                bottom: '-9vw'
                            },
                        },
                    },
                },
                "&.imageWrapperRight": {
                    zIndex: 0,
                    top: "0%",
                    bottom: "0%",
                    left: "auto",
                    right: "0%",
                    ".imagesListBlock": {
                        zIndex: 1,
                        gridRowGap: "26vw",
                        flexDirection: "column",
                        paddingTop: "0",
                        display: "flex",
                        ".imageFive": { opacity: 0.75, width: "20vw" },
                        ".imageSix": {
                            opacity: 0.75,
                            width: "18vw",
                            marginTop: "-44vw",
                            right: "54vw",
                        },
                    },
                },
            },
            ".centerHeading": {
                alignItems: "center",
                maxWidth: "54.375vw",
                height: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "8rem",
                paddingBottom: "0rem",
                display: "flex",
                position: "sticky",
                justifyContent: "center",
                zIndex: 2,
                ".contentBox": {
                    fontSize: "2.917vw",
                    textAlign: "center",
                    fontWeight: 700,
                    color: theme.palette.white.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",

                    h2: {
                        fontSize: "2.917vw",
                        textAlign: "center",
                        fontWeight: 700,
                        color: theme.palette.white.main,
                        margin: "0 0 24px 0",
                    },
                    p: {
                        color: theme.palette.white.main,
                        fontSize: "16px",
                        fontFamily: "'Poppins'",
                        textAlign: "center",
                        fontWeight: "400",
                        marginBottom: "32px",
                        marginTop: "0",
                    },
                    button: {
                        background: "#ffffff17",
                        border: "1px solid #ffffff17",
                        padding: "16px 24px",
                        height: "53px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "16px",
                        borderRadius: "10px",
                        color: theme.palette.white.main,
                        fontFamily: "'Poppins'",
                        cursor: "pointer",
                    },
                    "@media screen and (max-width:1280px)": {
                        h2: {
                            fontSize: "35px",
                            maxWidth: "600px",
                        },
                    },
                },
            },
            ".newImageWrapperMain": {
                position: "absolute",
                bottom: "0",
                width: "100%",

                ".imagesListBlock": {
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1.563vw",
                    ".imageWrapper": {
                        flex: "0 0 100%",
                        maxWidth: "calc(100% / 4 - 1.563vw)",
                        img: {
                            width: "100%",
                            borderRadius: "10px",
                        },
                        "&:nth-child(1)": {
                            marginTop: "30vh",
                        },

                        "&:nth-of-type(even)": {
                            marginTop: "40vh",
                        },
                        // "&.imageTwo": {
                        //     width: "18vw", marginTop: "-46vw", left: "58vw", position: 'absolute',
                        //     bottom: '-9vw',
                        //     left: '220px'
                        // },
                        // "&.imageThree": { width: "20vw", marginTop: "-5vw", left: "4vw" },
                        // "&.imageFour": {
                        //     width: "18vw",
                        //     marginTop: "-45vw",
                        //     left: "64vw",
                        //     position: 'absolute',
                        //     right: '220px',
                        //     bottom: '-9vw'
                        // },
                    },
                },
            },
        },
    },
}));

export default contactUsStyles3
