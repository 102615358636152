import { CircularProgress } from "@mui/material";
import { LoaderStyle } from "./LoaderStyle";

const Loader = ({ status = true, size }) => {
  if (status) {
    return (
      <LoaderStyle>
        <CircularProgress size={size} />
      </LoaderStyle>
    );
  }
};
export { Loader };
