//getDeletedUserApi
import { createSlice } from "@reduxjs/toolkit";
import {
  getDeletedUserApi,
  getDeletedUserFieldsApi,
  permanentDeleteUsersApi,
  recoverUserApi,
} from "./recoverUserAction";

const initialState = {
  deletedUsers: [],
  recoverUserLoader: false,
  deletedUsersFields: [],
};

const recoverUserSlice = createSlice({
  name: "recoverUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeletedUserApi.pending, (state) => {
        state.recoverUserLoader = true;
      })
      .addCase(getDeletedUserApi.fulfilled, (state, action) => {
        state.deletedUsers = action.payload;
        state.recoverUserLoader = false;
      })
      .addCase(getDeletedUserApi.rejected, (state) => {
        state.recoverUserLoader = false;
      });
    //recoverUserApi
    builder
      .addCase(recoverUserApi.pending, (state) => {
        state.recoverUserLoader = true;
      })
      .addCase(recoverUserApi.fulfilled, (state) => {
        state.recoverUserLoader = false;
      })
      .addCase(recoverUserApi.rejected, (state) => {
        state.recoverUserLoader = false;
      });
    //permanentDeleteUsersApi
    builder
      .addCase(permanentDeleteUsersApi.pending, (state) => {
        state.recoverUserLoader = true;
      })
      .addCase(permanentDeleteUsersApi.fulfilled, (state) => {
        state.recoverUserLoader = false;
      })
      .addCase(permanentDeleteUsersApi.rejected, (state) => {
        state.recoverUserLoader = false;
      });
    //getDeletedUserFieldsApi
    builder
      .addCase(getDeletedUserFieldsApi.pending, (state) => {
        state.allUserLoader = true;
      })
      .addCase(getDeletedUserFieldsApi.fulfilled, (state, action) => {
        state.deletedUsersFields = action.payload;
        state.allUserLoader = false;
      })
      .addCase(getDeletedUserFieldsApi.rejected, (state, action) => {
        state.allUserLoader = false;
      });
  },
});

export const allDeletedUsers = (state) => state?.recoverUser?.deletedUsers;
export const deletedUsersFields = (state) =>
  state?.recoverUser?.deletedUsersFields?.users;

export default recoverUserSlice.reducer;
