import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import ToastHelper from "helper/toastHelper";
import { validationMessages } from "utils/validation/validationUtils";
import { deleteUserRole } from "../../features/userRolePermissionAction";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";

import { useTranslation } from "react-i18next";
import CommonDeletePopup from "pages/Admin-Pages/AdminComponents/CommonDeletePopup/CommonDeletePopup";

const StatusAndReassignPopup = (props) => {
  const {
    open,
    showReassign,
    setShowReassign,
    allMainTableData,
    selectValue,
    setSelectValue,
    currentRowData,
    dataState,
    setDataState,
    resetErrorStates,
    setOpen,
    rowsToBeDeleted,
    setRowsToBeDeleted,
    openedFor,
    userRoleData1,
    openDelete,
    setOpenDelete,
    handleSwitchChange,
    gridRef,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleDelete(roleId = false) {
    resetErrorStates();
    const body = {};
    if (roleId) {
      body.new_role = selectValue;
    } else {
      body.new_role = null;
    }
    const dataBody = { body, dataState, ids: rowsToBeDeleted };

    const shouldPageChange = deletePageChangeChecker(
      userRoleData1?.currentPage,
      userRoleData1?.totalDocuments,
      rowsToBeDeleted?.length,
      userRoleData1?.limit,
    );
    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange
        ? parseInt(dataState?.page) - 1
        : parseInt(dataState?.page),
    };
    dispatch(deleteUserRole(dataBody)).then(() => {
      if (shouldPageChange) setDataState(dataBody.dataState);
      gridRef.current.api.deselectAll();
    });

    handleClose();
  }

  function handleReassign() {
    if (selectValue === "-1") {
      //no new secondary group is selected
      ToastHelper("error", validationMessages.pleaseSelectRegion);
    } else {
      // props?.data?._id, //id
      // props?.value, //value
      // props?.data?.user_count, // count
      // false, //no reassing happening
      // true, //open reassign popup

      handleSwitchChange(
        currentRowData?._id, //id
        true, //inverted value
        currentRowData?.user_count, //count
        selectValue, //value of new business unit
      ).then((res) => {
        //if error happens in update api
        if (res !== "error" && openedFor === "DELETE") handleDelete(true);
      });
      handleClose();
    }
  }

  function handleNotReassign() {
    handleSwitchChange(
      currentRowData?._id, //id
      true, //the value is reversing ahead
      currentRowData?.user_count, // count
    );
    handleClose();
  }

  const handleClose = () => {
    setShowReassign(false);
    setOpen(false);
    setSelectValue("-1");
    setOpenDelete(false);
  };

  return (
    <>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        setRowsToBeDeleted={setRowsToBeDeleted}
        handleDelete={handleDelete}
        singleDeleteText={t("userRoleDeleteConfirmationText")}
        multiDeleteText={t("userRoleMultipleDeleteConfirmationText")}
        handleClose={handleClose}
      />
      <DialogCommon
        open={open}
        handleClose={handleClose}
        title={t("reassignUserRole")}
        contentText={t("reassignAssignUserRoleQuestion")}
        fullWidth={true}
      >
        <DialogActions className={"popup-button-outer"}>
          <CustomButton
            variant="contained"
            className={
              showReassign ? "btn-design-theme yes-button" : "btn-design-theme"
            }
            onClick={() => setShowReassign(true)}
          >
            {t("yes")}
          </CustomButton>
          <CustomButton
            variant="contained"
            className="btn-design-theme cancel-button"
            onClick={() => {
              if (openedFor === "REASSIGN") {
                handleNotReassign();
              } else {
                handleDelete(false);
              }
            }}
          >
            {t("no")}
          </CustomButton>
        </DialogActions>
        {showReassign && (
          <div className={"reassignDiv addNewDivSelect"}>
            <DialogContent className="userRoleText">
              <DialogContentText id="alert-dialog-description">
                {t("selectUserRoles")}
              </DialogContentText>
              <div className={"flexCenter"}>
                {t("userRole")}
                <FormControl
                  variant="outlined"
                  className={"modalSelect"}
                  size="small"
                >
                  <Select
                    className="permission-role-select text_capitalize"
                    value={selectValue}
                    onChange={(i) => {
                      setSelectValue(i?.target.value);
                    }}
                    defaultValue={"-1"}
                  >
                    <MenuItem value={"-1"} className="select-none">
                      {t("select")}
                    </MenuItem>
                    {allMainTableData &&
                      allMainTableData?.map((data, i) => {
                        if (
                          data?.enabled &&
                          data?._id !== currentRowData?._id
                        ) {
                          return (
                            <MenuItem
                              key={i}
                              value={data?._id}
                              className="text_capitalize"
                            >
                              {data?.role}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions className={"popup-button-outer"}>
              <CustomButton
                variant="contained"
                className={"btn-design-theme"}
                onClick={() =>
                  openedFor === "DELETE"
                    ? handleDelete(true)
                    : handleReassign(currentRowData)
                }
              >
                {openedFor === "DELETE" ? t("delete") : t("save")}
              </CustomButton>
              <CustomButton
                variant="contained"
                className={"btn-design-theme cancel-button"}
                onClick={handleClose}
              >
                {t("cancel")}
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </DialogCommon>
    </>
  );
};
export default StatusAndReassignPopup;
