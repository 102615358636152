import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import TextTag, { textVariants } from "components/Typography/TextTag";

const HeadingStyles = styled("div")(({ theme }) => ({
  marginBottom: "24px",
  a: {
    color: theme.palette.gray_600.main,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    svg: {
      width: "14px",
      height: "14px",
      fill: theme.palette.primaryColor.main,
    },
    "&:hover": {
      color: theme.palette.primaryColor.main,
    },
  },
}));

export const ArrowLinkComp = (props) => {
  const { link, title } = props;
  return (
    <HeadingStyles>
      <Link to={link}>
        <ArrowForwardIosRoundedIcon />
        <TextTag variant={textVariants._14px.medium}>{title}</TextTag>
      </Link>
    </HeadingStyles>
  );
};
