import React from 'react'

const MyUploadIconsSetting = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="18.728" height="18.728" viewBox="0 0 18.728 18.728">
  <path id="Icon_awesome-download" data-name="Icon awesome-download" d="M7.9,0h2.926a.876.876,0,0,1,.878.878V7.023h3.208a.73.73,0,0,1,.516,1.247L9.865,13.838a.709.709,0,0,1-1,0L3.3,8.271a.73.73,0,0,1,.516-1.247H7.023V.878A.876.876,0,0,1,7.9,0ZM18.728,13.754v4.1a.876.876,0,0,1-.878.878H.878A.876.876,0,0,1,0,17.851v-4.1a.876.876,0,0,1,.878-.878H6.244l1.792,1.792a1.874,1.874,0,0,0,2.656,0l1.792-1.792h5.366A.876.876,0,0,1,18.728,13.754Zm-4.536,3.219a.732.732,0,1,0-.732.732A.734.734,0,0,0,14.193,16.973Zm2.341,0a.732.732,0,1,0-.732.732A.734.734,0,0,0,16.534,16.973Z" fill="#383838"/>
</svg>
  
  )
}

export default MyUploadIconsSetting