import { createSlice } from "@reduxjs/toolkit";
import {
  dashboardChangeUserSettingApi,
  getdashbordApi,
  getFiveRequestApi,
} from "./dashboardAction";

const initialState = {
  requests: [],
  statsget: [],
  dashboardStatloading: false,
  dashboardSettingChanging: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiveRequestApi.pending, (state) => {})
      .addCase(getFiveRequestApi.fulfilled, (state, action) => {
        state.requests = action.payload;
      })
      .addCase(getdashbordApi.pending, (state) => {
        state.dashboardStatloading = true;
      })
      .addCase(getdashbordApi.fulfilled, (state, action) => {
        state.dashboardStatloading = false;
        state.statsget = action.payload;
      })
      .addCase(getdashbordApi.rejected, (state) => {
        state.dashboardStatloading = false;
      });
    builder
      .addCase(dashboardChangeUserSettingApi.pending, (state) => {
        state.dashboardSettingChanging = true;
      })
      .addCase(dashboardChangeUserSettingApi.fulfilled, (state, action) => {
        state.dashboardSettingChanging = false;
      })
      .addCase(dashboardChangeUserSettingApi.rejected, (state) => {
        state.dashboardSettingChanging = false;
      });
  },
});

export const fiveRequests = (state) => state?.dashboard?.requests?.pending_data;
export const dashboardapi = (state) => state?.dashboard;

export default dashboardSlice.reducer;
