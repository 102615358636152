import React from "react";
import withAuth from "../../../../Auth/withAuth";
import { routeConfigs } from "../../../../utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import CuratedGallery from "./HomeComponents/CuratedGallery/CuratedGallery";
import FindLookingForSection from "./HomeComponents/FindLookingForSection/FindLookingForSection";
import HeroSection from "./HomeComponents/HeroSection/HeroSection";
import HomeStyles from "./HomeStyle";
import ContactUsComp3 from "./HomeComponents/ContactUs/ContactUsComp3";


function HomeDuplicateNew() {
  return (
    <Layout>
      <HomeStyles>
        <HeroSection />
        <FindLookingForSection />
        {/* <MarketSegment /> */}
        {/* <FeaturedProjects /> */}
        <CuratedGallery />
        {/* <ContactUsComp /> */}
        <ContactUsComp3 />
      </HomeStyles>
      {/* <IdleModal /> */}
    </Layout>
  );
}
export default withAuth(HomeDuplicateNew, routeConfigs.userLogin);
