export const ViewColumnSvgIcon = ({ className }) => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="ViewColumnIcon"
    className={className}
  >
    <path d="M14.67 5v14H9.33V5h5.34zm1 14H21V5h-5.33v14zm-7.34 0V5H3v14h5.33z"></path>
  </svg>
);
