import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomButton from "components/Button/CustomButton";

const FieldValuesTable = (props) => {
  const {
    fieldValueArray,
    setFieldValue,
    fieldTypeKey,
    formik,
    tableError,
    setTableError,
  } = props;
  const [addTextValue, setAddTextValue] = useState();
  const [editTextValue, setEditTextValue] = useState();
  const [currentEditIndex, setCurrentEditIndex] = useState();

  const { t } = useTranslation();

  function handleFieldAdd() {
    formik.setErrors({ ...formik.errors, values: "" });
    setCurrentEditIndex();
    if (!addTextValue || addTextValue === null || addTextValue?.trim() === "") {
      setTableError({
        editError: "",
        addError: t("pleaseEnterFieldValueName"),
      });
    } else if (addTextValue.length > 50) {
      setTableError({ editError: "", addError: t("tooLong") });
    } else if (fieldValueArray?.indexOf(addTextValue) === -1) {
      setFieldValue("values", [...fieldValueArray, addTextValue]);
      setAddTextValue("");
      setTableError({ editError: "", addError: "" });
    } else {
      setTableError({ editError: "", addError: t("fieldValueAlreadyExist") });
    }
  }

  function handleFieldRemove(index) {
    setTableError({ editError: "", addError: "" });
    if (index > -1) {
      fieldValueArray.splice(index, 1);
    }
    setFieldValue(fieldValueArray);
  }

  function handleFieldEdit(index) {
    if (
      !editTextValue ||
      editTextValue === null ||
      editTextValue?.trim() === ""
    ) {
      setTableError({
        addError: "",
        editError: t("pleaseEnterFieldValueName"),
      });
    } else if (editTextValue.length > 50) {
      setTableError({ addError: "", editError: t("tooLong") });
    } else if (
      fieldValueArray?.indexOf(editTextValue) === -1 ||
      fieldValueArray?.indexOf(editTextValue) === index
    ) {
      fieldValueArray[index] = editTextValue;
      setFieldValue("values", fieldValueArray);
      setEditTextValue("");
      setCurrentEditIndex();
      setTableError({ addError: "", editError: "" });
    } else {
      setTableError({ addError: "", editError: t("fieldValueAlreadyExist") });
    }
  }

  return (
    <Box className="fieldValuesTableMain">
      <Box className="request-common">
        <label className="label">
          {t(fieldTypeKey)} {t("values")}
        </label>
        <TextField
          placeholder={t("enterFieldValue")}
          size="small"
          variant="outlined"
          type="text"
          name="fieldName"
          value={addTextValue}
          onChange={(e) => setAddTextValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              handleFieldAdd();
          }}
        />
        <span className="errorClass">{tableError.addError}</span>
        <CustomButton className="newFieldAdd" onClick={handleFieldAdd}>
          {t("addNew")}
        </CustomButton>
      </Box>
      {fieldValueArray?.length >= 1 && (
        <TableContainer className="addNewTable">
          <Table aria-label="field value table">
            <TableHead>
              <TableRow>
                <TableCell> {t("fieldValues")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldValueArray.map((data, index) => (
                <TableRow key={index}>
                  {currentEditIndex === index ? (
                    <Box className="editFieldValue">
                      <TextField
                        autoFocus
                        placeholder={t("enterFieldName")}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="fieldName"
                        value={editTextValue}
                        onBlur={() => handleFieldEdit(index)}
                        onChange={(e) => setEditTextValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.code === "Enter" || e.code === "NumpadEnter")
                            handleFieldEdit(index);
                        }}
                      />
                      <span className="errorClass">{tableError.editError}</span>
                    </Box>
                  ) : (
                    <TableCell className="fieldValueTableCell">
                      <Box
                        className="tableCellBox"
                        onClick={() => {
                          setTableError({ editError: "", addError: "" });
                          setCurrentEditIndex(index);
                          setEditTextValue(data);
                        }}
                      >
                        {data}
                      </Box>
                      <IconButton
                        onClick={() => {
                          handleFieldRemove(index);
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default FieldValuesTable;
