import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import CustomButton from "components/Button/CustomButton";
import Sorting from "utils/sorting";

const TertiaryGroupHead = (props) => {
  const { t } = useTranslation();
  const {
    selectedValue,
    setSelectedValue,
    businessData,
    setDataState,
    settingNewDepartment,
    addNewDepartment,
    departmentName,
    alphaNumErrorAdd,
    dataState,
    setSelectedRows,
  } = props;
  return (
    <Box className="table-upper customeBar_  withOutViewIcon">
      <Box className="leftSideInputs">
        <Box className={`addNewDivSelect`}>
          <FormControl
            variant="outlined"
            className={"formControl"}
            size="small"
          >
            <Select
              className="permission-role-select"
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              defaultValue={" "}
            >
              <MenuItem value={" "} className="select-none">
                {t("selectSecondary")}
              </MenuItem>
              {businessData &&
                Array.isArray(businessData) &&
                Sorting(businessData, "name")?.map((data, i) => {
                  return (
                    data?.enabled && (
                      <MenuItem key={i} value={data?._id}>
                        {data?.name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        <Grid container spacing={4} className={`addNew`}>
          <Box className={"addNewDiv"}>
            <TextField
              placeholder={t("createTertiary")}
              size="small"
              className={"addNewText"}
              variant="outlined"
              onChange={settingNewDepartment}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  addNewDepartment();
              }}
              value={departmentName}
            />
          </Box>
          <Box className={"addNewDiv1"}>
            <CustomButton onClick={() => addNewDepartment()} variant="outlined">
              {t("addTertiary")}
            </CustomButton>
          </Box>
          <span className="errorClass">{alphaNumErrorAdd}</span>
        </Grid>
        <Box className="addUserPagesBtn">
          <PaginationLimitDropDown
            dataState={dataState}
            setDataState={setDataState}
            setSelectedRows={setSelectedRows}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TertiaryGroupHead;
