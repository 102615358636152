import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

//below api is used to get all the trending searches
export const getAllSearchApi = createAsyncThunk(
  "trendingSearches/getAllSearchApi",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.getAllSearch}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.getAllSearch}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//below api will only get top 5 or so trending searches
export const getTopSearchApi = createAsyncThunk(
  "trendingSearches/getTopSearchApi",
  async (signal) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getTopSearch, { signal }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getSearchFields to get search fields
export const getSearchFieldsApi = createAsyncThunk(
  "user/getSearchFieldsApi",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.getSearchFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.getSearchFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//this will create new searches
export const createSearchApi = createAsyncThunk(
  "trendingSearches/createSearchApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.createSearch, data),
      );
      // return dispatch(getAssetType());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//below api is used to delete the trending searches
export const deleteSearchesApi = createAsyncThunk(
  "trendingSearches/deleteSearchesApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.deleteSearches, data.body),
      );
      return dispatch(getAllSearchApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//below api is used to delete the trending searches
export const updateSearchStatusApi = createAsyncThunk(
  "trendingSearches/updateSearchStatusApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateSearchStatus}/${data.body?.id}`,
          data.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//updateSearchOrder
//below api is used to update search order by row drag drop

export const updateSearchOrderApi = createAsyncThunk(
  "trendingSearches/updateSearchStatusApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateSearchOrder, data?.body),
      );
      return dispatch(getAllSearchApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
