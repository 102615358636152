import React from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import TableIconsCommonWrapper from "./TableIconsCommonWrapper";
import { useTranslation } from "react-i18next";

const TableRestoreIcon = ({ tooltipTitle, onClick }) => {
  const { t } = useTranslation();
  return (
    <TableIconsCommonWrapper
      tooltipTitle={tooltipTitle ? tooltipTitle : t("recover")}
    >
      <RestoreIcon className="gridEditIconUsers deleteIcon" onClick={onClick} />
    </TableIconsCommonWrapper>
  );
};

export default TableRestoreIcon;
