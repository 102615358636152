import { styled } from "@mui/system";

export const CuratedGalleryStyle = styled("div")(({ theme }) => ({
  ".highDiv": { height: "1000px" },

  ".horizontal-scroll": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    ".image": {
      width: "100%",
      display: "inline-block",
      margin: "16px",
      overflow: "hidden",
      flex: "0 0 100%",
      borderRadius: "15px",
      maxWidth: "21.23%",
      aspectRatio: "1/1",
      img: {
        width: "100%",
        objectFit: "cover",
        height: "100%",
        borderRadius: "5px",
      },
    },
  },

  ".galleryBlock": {
    background: "#F5F5F6",
    padding: "120px 0",
    ".curated_gallery_upper": {
      textAlign: "center",
      width: "100%",
      maxWidth: "460px",
      margin: "0 auto 40px",
      h2: {
        margin: "0 0 24px 0",
        color: theme.palette.blackColor.main,
      },

    },

    ".curated_gallery_lower": {
      textAlign: "center",
      marginTop: "40px",
      display: "flex",
      justifyContent: "center",
    },
  },
}));
