const { styled } = require("@mui/system");

const DesignSystemStyles = styled("div")((theme) => ({
  margin: "16px",
  ".transparent-button": {
    background: "#212A36",
  },
  ".component-wrapper": {
    padding: "16px",
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  },
  ".flexColumn": {
    display: "flex",
    flexDirection: "column",
  },
}));

export default DesignSystemStyles;
