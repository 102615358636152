import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getAllIptcCreators = createAsyncThunk(
  "iptcCreators/getAllIptcCreators",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetAllIptcCreators}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetAllIptcCreators}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getSingleIptcCreator = createAsyncThunk(
  "iptcCreators/getSingleIptcCreator",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminGetSingleIptcCreator}/${data?.id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const iptcCreatorsDeleteApi = createAsyncThunk(
  "iptcCreators/iptcCreatorsDeleteApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminDeleteIptcCreators, data?.body),
      );
      return dispatch(getAllIptcCreators(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const iptcCreatorsAddApi = createAsyncThunk(
  "iptcCreators/iptcCreatorsAddApi",
  async (data, { dispatch }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAddCreator, data?.values),
      );
      // return dispatch(getAllIptcCreators(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const iptcCreatorsUpdateApi = createAsyncThunk(
  "iptcCreators/iptcCreatorsUpdateApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateIptcCreators}/${data?.values?._id}`,
          data?.values,
        ),
      );
      return dispatch(getAllIptcCreators(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getIptcCreatorsFields = createAsyncThunk(
  "user/getIptcCreatorsFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetCreatorsFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetCreatorsFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
