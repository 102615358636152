import { styled } from "@mui/system";

export const CheckBoxContainer = styled("div")(({ theme }) => ({
  ".main": {
    minWidth: "425px",
    resize: "auto",
    maxWidth: "1200px",
    minHeight: "600px",
    overflow: "hidden",
    backgroundColor: theme.palette.white.main,
    "@media screen and (max-width:1366px)": {
      maxWidth: "800px",
    },
    "@media screen and (max-width:1280px)": {
      maxWidth: "max-content",
    },
    "&.email": {
      ".allFilterValus": {
        ".allFilterValue": {
          "span.MuiTypography-root": {
            textTransform: "lowercase",
          },
        },
      },
    },
    ".allFilterValus": {
      background: "rgb(0 84 182 / 9%)",
      width: "100%",
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
      borderRadius: "10px",
      overflow: "hidden",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      ".filtersChecks": {
        ".selectAll": {
          borderBottom: "1px solid #c3c3c3",
          marginBottom: "0",
          padding: "5px 0 0px",
          label: {
            fontSize: "18px",
            fontWeight: "400",
            "@media screen and (max-width:1366px)": {
              fontSize: "14px",
            },
          },
        },
        ".allFilterValue": {
          overflow: "overlay",
          textTransform: "capitalize",
          background: "rgb(0 84 182 / 9%) none repeat scroll 0 0",
          float: "left",
          height: "calc(100% - 327px)",
          marginBottom: "5px",
          overflowY: "auto",
          position: "absolute",
          width: "100%",
          maxWidth: "calc(100% - 30px)",
          paddingLeft: "0",
          left: "50%",
          transform: "translate(-50%)",
          boxSizing: "border-box",
          borderRadius: "0",
          border: "0",
          paddingBottom: "75px",
          span: {
            fontSize: "14px",
            "&.noData": {
              textAlign: "center",
              padding: "15px",
            },
          },
        },
        ".MuiFormControlLabel-root": {
          margin: "0",
          display: "flex",
          alignItems: "center",
          span: {
            ".MuiCheckbox-root": {
              top: "8px",
            },
            ".MuiFormControlLabel-label": {
              fontSize: "18px",
              color: "#000",
              textTransform: "capitalize",
              wordBreak: "break-all",
              ".MuiButtonBase-root": {
                padding: "0 15px 15px 9px",
                top: "9px",
              },
            },
          },
        },
      },
    },
    ".filterSortBy": {
      display: "flex",
      alignItems: "baseline",
      gap: "10px",
      padding: "10px 0px 15px",
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
      borderBottom: "1px solid #d1d1d1",
      flexDirection: "column",
      label: {
        flex: "0 0 20%",
        fontSize: "18px",
        color: "#000",
      },
      ".buttonsAD": {
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        flex: "0 0 100%",
        width: "100%",
        button: {
          "&.MuiButtonBase-root.MuiButton-root": {
            background: "transparent",
            color: theme.palette.primary.main,
            borderColor: "#DBDBDB",
            flex: "0 0 50%",
            margin: "0",
            maxWidth: "185px",
            justifyContent: "flex-start",
            gap: "15px",
            fontWeight: "500",
            svg: {
              width: "16px",
              height: "13px",
            },
          },
        },
      },
    },
    ".filterSearch": {
      display: "flex",
      alignItems: "baseline",
      gap: "10px",
      padding: "10px 0px 15px",
      position: "relative",
      flexDirection: "column",
      maxWidth: "calc(100% - 30px)",
      margin: "auto",
      ".searchIcon": {
        position: "absolute",
        right: "29px",
        top: "60px",
      },
      label: {
        flex: "0 0 20%",
        fontSize: "18px",
        color: "#000",
      },
      ".MuiFormControl-root": {
        width: "100%",
        input: {
          height: "33px",
          borderColor: "#262626",
        },
      },
    },
    ".title_class": {
      display: "flex",
      textAlign: "center",
      padding: "15px",
      background: theme.palette.primary.main,
      color: "#fff",
      fontSize: "19px",
      alignItems: "center",
      justifyContent: "center",
      borderTop: `5px solid ${theme.palette.dimLightGray.main}`,
      span: {
        width: "100%",
      },
      svg: {
        cursor: "pointer",
      },
    },
    ".popup-button-outer": {
      gap: "15px",
      padding: "4px",
      width: "93%",
      margin: "10px auto",
      button: {
        display: "flex",
        width: "100%",
        margin: "0",
        height: "42px",
        fontSize: "16px!important",
        whiteSpace: "pre",
        color: theme.palette.white.main,
        "&:hover": {
          color: theme.palette.info.main,
          backgroundColor: theme.palette.white.main,
        },
        "&:is(.Mui-disabled)": {
          opacity: "0.4",
        },
      },
    },
    ".closeIcon": {
      position: "absolute",
      right: "13px",
      width: "30px",
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      top: "14px",
      cursor: "pointer",
      svg: {
        fill: theme.palette.white.main,
      },
    },
    "+ .MuiDialogActions-root": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      justifyContent: "flex-end",
      background: theme.palette.white.main,
      gap: "15px",
      maxWidth: "calc(100% - 30px)",
      left: "50%",
      transform: "translate(-50%)",
      padding: "10px 0",
      button: {
        margin: "0",
        flex: "0 0 120px",
        backgroundColor: theme.palette.primary.main,
        fontWeight: 300,
        borderColor: theme.palette.primary.main,
        "&:is(.Mui-disabled)": {
          opacity: "0.4",
          color: theme.palette.white.main,
          pointerEvents: " all",
          cursor: "not-allowed",
        },
        ":hover": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.white.main,
        },
      },
    },
    ":is(.fr, .it, .de, .es)": {
      minWidth: "455px",
      "+ .MuiDialogActions-root": {
        button: {
          "&:last-child": {
            flex: "0 0 auto",
          },
        },
      },
    },
  },
}));
