import React from "react";
import { DialogActions } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { useTranslation } from "react-i18next";

const CommonDeletePopup = (props) => {
  const { t } = useTranslation();
  const {
    openDelete,
    setOpenDelete,
    rowsToBeDeleted,
    // setRowsToBeDeleted,
    handleDelete,
    singleDeleteText,
    multiDeleteText,
    handleClose, //optional
  } = props;

  const handlePopupClose = () => {
    if (handleClose) {
      handleClose();
    } else {
      setOpenDelete(false);
      // setRowsToBeDeleted([]);
    }
  };
  return (
    <DialogCommon
      open={openDelete}
      handleClose={handlePopupClose}
      contentText={
        rowsToBeDeleted?.length > 1 ? multiDeleteText : singleDeleteText
      }
      title={" "}
      fullwidth={true}
    >
      <DialogActions className={"popup-button-outer"}>
        <CustomButton variant="contained" onClick={() => handleDelete()}>
          {t("yes")}
        </CustomButton>
        <CustomButton variant="contained" onClick={handlePopupClose}>
          {t("no")}
        </CustomButton>
      </DialogActions>
    </DialogCommon>
  );
};

export default CommonDeletePopup;
