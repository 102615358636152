import { baseurlPure } from "utils/baseUrl";

const { io } = require("socket.io-client");
const { _getToken } = require("./helper");

const { token } = _getToken();
export function connectToSocket() {
  const socket = io(baseurlPure);
  socket.on("connect", () => {
    console.log("Connected to server!");
  });
  if (socket) {
    socket.emit("user_connected", token);
    return socket;
  }
}
