import React from "react";

const ItalyFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 900 600">
      <rect width="900" height="600" fill="#009246" />
      <path fill="#F1F2F1" d="M0 0h900v200H0zM0 400h900v200H0z" />
      <path fill="#CE2B37" d="M0 200h900v200H0z" />
    </svg>
  );
};

export default ItalyFlag;
