import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import withAuth from "Auth/withAuth";
import { routeConfigs } from "utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import CmsPageStyles from "./cmsPageStyles.js";
import "react-quill/dist/quill.bubble.css";
import {
  footerGeneralPageLoadingState,
  footerGeneralSinglePageData,
} from "pages/Admin-Pages/pages-cms/FooterGeneralPages/features/footerGeneralPagesSlice.js";
import { getGeneralFooterPageByTitleApi } from "pages/Admin-Pages/pages-cms/FooterGeneralPages/features/footerGeneralPagesAction.js";

const GeneralFooterCmsPage = () => {
  const dispatch = useDispatch();
  const pageData = useSelector(footerGeneralSinglePageData);
  const cmsPageLoading = useSelector(footerGeneralPageLoadingState);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getGeneralFooterPageByTitleApi(encodeURIComponent(id)));
    }
  }, [id, dispatch]);

  return (
    <Layout>
      <CmsPageStyles>
        <div className="cmsPage">
          {cmsPageLoading ? (
            "Loading..."
          ) : (
            <div className="cms_page_main">
              <div className="cms_pageTitle">
                <h1>{pageData?.title}</h1>
              </div>
              <ReactQuill
                value={pageData?.description}
                readOnly={true}
                theme={"bubble"}
              />
            </div>
          )}
        </div>
      </CmsPageStyles>
    </Layout>
  );
};

export default withAuth(GeneralFooterCmsPage, routeConfigs.userLogin);
