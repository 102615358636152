import { styled } from "@mui/system";

export const UserDetailDialogDesign = styled("div")(
  ({ theme }) => `
  .request-common.fieldSliderCheckbox .fieldSlider {
    width: 96.1%;
}
  .popup-button-outer {
    justify-content: center;

    float: left;
    width: 100%;

  }
 

  .popup-main {
    .request-common {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
    text-align: left;
    width: calc(100% / 2);
    max-width: 100%;
    float: left;
    flex-direction: column;
    padding: 0 8px;
    position: relative;
    margin-right: 0;
    span.errorClass {
      position: absolute;
      top:64px;
      font-size: 12px;
      color: red;
      // white-space: pre;
      left: 8px;
  }
  .addNewTable span.errorClass {
    left: 0;
  }
    .popup-button-outer {
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    float: left;
    width: 100%;
    .MuiDialogContent-root {
    padding: 15px 0;

  }
  }
  .addNewDivSelect {
        select.permission-role-select {
         width: 100%;
  }
    }
  }
  .addNewDivSelect select.permission-role-select {
    width: 100%;
    padding-right: 50px;
    height: 40px;
  }
  &:is(.AddUserDetails){ 
    .request-common {
      &.secondaryOrganiztion{
      span.errorClass {  
    bottom: -40px;   
    white-space: unset;
}
      }
      .inputMask {
        input[type="text"] {
          height: 40px;
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.23);
          border-radius: 5px;
          padding-left: 12px;
         
          &::placeholder{
              font-size: 16px;
            color: black;
            opacity:0.3;

          }
         
         
}
input[name="phone"]{
  font-size:16px;
}
      }
      .addNewDivSelect {
        .formControl {
          width: 100%;
          position: relative;
        }
        width: 100%;
        max-width: 100%;
      }
      &:is(.fieldValueTable){
             width:100%;
            .request-common
             {
           display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: 3fr 1fr;
    max-width: 50%;
    gap:0 8px;
    padding:0;

    .MuiFormControl-root{
      grid-row: 3;
    }
    label.label {
    text-transform: capitalize;
    font-size: 16px;
    color: #4a4a4a;
    opacity: 1;
}
    button{
      grid-row: 3;
    }
   span.errorClass {    
    left: 0;
}
             }
           }
    }
   .addNewTable{
    overflow:hidden;
    padding: 15px 0;
    table {
      thead{
         tr {
          th {
    padding: 0;
    font-weight: 800;
    font-size: 20px;
    line-height: normal;
}
         }
      }
     tbody {
     display: flex;
     flex-wrap: wrap;
     gap:15px;
     padding-top: 15px;
     tr{
       flex:0 0 100%;
       max-width:calc(100% / 2 - 16px);
       position:relative;
       border-bottom: 1px solid gray; 
       td{
        border: 0;
       }
       button{
         position: absolute;
        right: 0;
        top: 6px
       }
 
     }
 }
    }
   }
   
  
  
  }
  }

 `,
);
