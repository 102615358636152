import React from "react";
import TableIconsCommonWrapper from "./TableIconsCommonWrapper";
import { DeleteForever } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const TableDeleteIcon = ({ tooltipTitle, onClick }) => {
  const { t } = useTranslation();
  return (
    <TableIconsCommonWrapper
      tooltipTitle={tooltipTitle ? tooltipTitle : t("delete")}
    >
      <DeleteForever
        className="gridEditIconUsers deleteIcon"
        onClick={onClick}
      />
    </TableIconsCommonWrapper>
  );
};

export default TableDeleteIcon;
