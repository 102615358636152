import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getAssetType = createAsyncThunk(
  "assetType/getAssetType",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetAssetType}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetAssetType}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllAssetType = createAsyncThunk(
  "assetType/getAllAssetType",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetAssetType}?limit=5000&data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addAssetType = createAsyncThunk(
  "assetType/addAssetType",
  async (newAssetType) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAddAssetType, newAssetType),
      );
      // return dispatch(getAssetType());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteAssetType = createAsyncThunk(
  "assetType/deleteAssetType",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminBulkDeleteAssetType, data.body),
      );
      return dispatch(getAssetType(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateAssetType = createAsyncThunk(
  "assetType/updateAssetType",
  async (updatedAssetType) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateAssetType}/${updatedAssetType.body.assetype_id}`,
          updatedAssetType.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const countActiveAssetTypeApi = createAsyncThunk(
  "assetType/countActiveAssetTypeApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminCountActiveAssetType),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateAssetTypeStatus = createAsyncThunk(
  "assetType/updateAssetTypeStatus",
  async (updatedAssetType) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateAssetTypeStatus}/${updatedAssetType?.body?.assetTypeId}`,
          updatedAssetType?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
//filter API's
export const getAssetTypeFields = createAsyncThunk(
  "user/getAssetTypeFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetAssetTypeFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetAssetTypeFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
