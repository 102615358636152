import React from "react";
import { Badge as BadgeBase } from "@mui/material";
import { styled } from "@mui/system";

export const BadgeStyle = styled("div")(({ theme }) => ({
  ".badge span": {
    backgroundColor: "#ed6c02",
    color: theme.palette.white.main,
    height: "16px",
    minWidth: "16px",
    fontWeight: "600",
  },
}));

export function Badge({ children, colorBrightness, color, ...props }) {
  return (
    <BadgeStyle>
      <BadgeBase overlap="rectangular" className={"badge"} {...props}>
        {children}
      </BadgeBase>
    </BadgeStyle>
  );
}
