import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import {
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { recaptcha_site_key } from "utils/envConst";
import { routeConfigs } from "utils/routeConfig";
import { localConfig, saveDataInLocalStorage } from "utils/localStorage";

import withoutAuth from "Auth/withoutAuth";
import CommonLayout from "Layout/AuthLayout/CommonLayout";
import CustomButton from "components/Button/CustomButton";
import LogoutHelper from "helper/logoutHelper";
import { LoginSchema } from "utils/validation/validation-schemas/loginSchema";
import { encryptAES } from "utils/encrypt";
import { loginState } from "../features/userAuth";
import SignOutPopup from "./Component/SignOutPopup/SignOutPopup";

import { userLogin } from "../features/userAction";
import { LoginComponentStyles } from "../styles";

function UserLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loginLoading } = useSelector(loginState);

  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      password: "",
      reCaptcha: "",
    },
    onSubmit: (values) => {
      const encryptedPassword = encryptAES(values.password);
      const body = {
        email: values.email,
        password: encryptedPassword,
      };
      dispatch(userLogin(body))
        .then((response) => {
          setErrorMessage("");
          if (response?.payload?.status === 409) {
            setOpen(true);
            saveDataInLocalStorage(
              localConfig.logoutToken,
              response?.payload?.data?.token,
            );
          } else if (!response?.error && !response?.payload) {
            navigate(routeConfigs.userHome);
          } else {
            setErrorMessage(response?.payload?.data?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  const handlePopUpClick = () => {
    LogoutHelper(dispatch, navigate, false);
    setOpen(false);
  };

  return (
    <CommonLayout
      // headingName={t("login")}
      extraParentClass={"withoutLoginTitle"}
    >
      <LoginComponentStyles>
        <Box className="loginMain">
          <Typography color="secondary" className="errorValidation center">
            {(Object.keys(formik?.errors)[0] in formik.touched &&
              Object.values(formik?.errors)[0]) ||
              (errorMessage && errorMessage)}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="standard"
              name="email"
              value={formik.values.email}
              onChange={(e) => {
                setErrorMessage("");
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              margin="normal"
              placeholder={t("email")}
              type="text"
              fullWidth
            />
            <TextField
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name="password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={(e) => {
                setErrorMessage("");
                formik.handleChange(e);
              }}
              margin="normal"
              placeholder={t("password")}
              type={showPassword ? "text" : "password"}
              fullWidth
            />
            <ReCAPTCHA
              className={"loginRecaptcha"}
              name="reCaptcha"
              sitekey={recaptcha_site_key}
              onChange={(e) => {
                formik.setFieldValue("reCaptcha", e);
                setErrorMessage("");
              }}
              onBlur={formik.handleBlur}
            />
            <Box className={"formButtons"}>
              {loginLoading ? (
                <CircularProgress size={26} className={"loginLoader"} />
              ) : (
                <CustomButton variant="contained" type={true}>
                  {t("signIn")}
                </CustomButton>
              )}
              <CustomButton
                className="forgetPwd linkBtn"
                onClick={() => navigate(routeConfigs.userForgotPassword)}
              >
                {t("forgotPassword")}
              </CustomButton>
            </Box>
          </form>
          <Box className={"notaMemberText"}>
            {t("notAMember")}{" "}
            <Link to={routeConfigs.userRegister}>{t("registerHere")}</Link>
          </Box>
          <SignOutPopup
            open={open}
            setOpen={setOpen}
            onClick={handlePopUpClick}
          />
        </Box>
      </LoginComponentStyles>
    </CommonLayout>
  );
}

export default withoutAuth(UserLogin, routeConfigs.userHome);
