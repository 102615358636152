import React, { useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import { getBanners } from "./features/bannerSlice";
import {
  getbannerActions,
  deleteBanner,
  updateBanner,
} from "./features/bannerAction";

import CommonGrid from "components/Grid/CommonGrid";
import { hiddenAgGridColumn } from "utils/datatable/agTableOptions";
import { CommonEditTooltip } from "components/TooltipComponent/CommonEditTooltip";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import { useTranslation } from "react-i18next";
import CommonDeletePopup from "../AdminComponents/CommonDeletePopup/CommonDeletePopup";

const Banners = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bannerData } = useSelector(getBanners); // Access data from the store
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "bannerImages",
      headerName: t("banner"),
      headerClass: "fieldCenter",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellClass: "category_center",
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.value}`;
        return (
          <span key={params?.value} className="center thumbImage dblock">
            <img
              src={!params?.value ? "./logos/assetCategoryDefault.png" : url}
              alt="categoryImage"
            />
          </span>
        );
      },
    },
    {
      field: "user",
      headerName: t("author"),
      headerClass: "leftAlign",
      cellClass: "category_cell",
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      cellClass: "category_cell",
      cellRenderer: (params) => (
        <span className="email leftAlign" key={params?.value}>
          {moment(params?.value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.data?.bannerImages}`;
        return (
          <Grid container className="actionIcons">
            <TableViewIcon
              onClick={() => {
                window.open(url, "_blank");
              }}
            />
            <TableDeleteIcon
              onClick={() => {
                setOpenDelete(true);
                setRowsToBeDeleted([params?.data?._id]);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [dataState, setDataState] = useState();

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getbannerActions()).then((res) => {
      if (!res?.error) setIsLoading(false);
    });
  }, [dispatch, dataState]);

  useEffect(() => {
    const partial_image_url = `${bannerData?.image_base_url}/${bannerData?.image_container_name}`;
    if (bannerData?.banner?.banner_images_data) {
      setRowData(
        bannerData?.banner?.banner_images_data?.map((tableDataObj) => ({
          _id: tableDataObj?._id,
          bannerImages: tableDataObj?.bannerImages?.file_name,
          partial_image_url,
          user: tableDataObj?.user?.email,
          createdAt: tableDataObj?.createdAt,
        })),
      );
    } else {
      setRowData([]);
    }
  }, [bannerData]);

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };

    dispatch(deleteBanner(body)).then(() => {
      setOpenDelete(false);
    });
  }

  const handleChecked = (val) => {
    const body = {
      id:
        bannerData?.banner?.banner_data?.banner_id ||
        bannerData?.banner?.banner_data?._id,
      is_manually: val.target.value === "true",
    };
    dispatch(updateBanner(body)).then((res) => {});
  };

  const checkBoxData = [
    {
      label: t("bannerLabelOne"),
      // info: "If you select this option then the banners added to the list will be shown randomaly on the homepage as hero banner",
      value: true,
      checked: bannerData?.banner?.banner_data?.is_manually || "",
      displayName: "manual_select",
    },
    {
      label: t("bannerLabelTwo"),
      // info: "If you select this option then the system will randomaly select any asset to be displayed as hero banner on the homepage",
      value: false,
      checked: !bannerData?.banner?.banner_data?.is_manually || "",
      displayName: "random_select",
    },
  ];

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: initialColDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("banners")} />
      <span>{t("bannerTitleText")}</span>
      <Box className={`checkBoxesBlockArea`}>
        {checkBoxData?.map((val, ind) => {
          const tooltip_data = {
            module_name: bannerData?.module_type,
            tooltip_setting: {
              details: bannerData?.toolTipSetting?.details || [],
            },
          };
          const displayName = val?.displayName;
          return (
            <Box key={val?.ind} className="bannerCheckBox">
              <Box className="tooltipWrapper">
                <CommonEditTooltip
                  tooltip_data={tooltip_data}
                  displayName={displayName}
                  field_name={displayName}
                  dataState={dataState}
                  setDataState={setDataState}
                />
              </Box>
              <Box className="checkboxWrapper">
                <FormControlLabel
                  control={
                    <Checkbox
                      key={ind}
                      value={val?.value}
                      checked={val?.checked}
                      onChange={handleChecked}
                    />
                  }
                  label={val?.label}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Grid container spacing={4} className={"userReq-table "}>
        <Grid item xs={12} className="bannerTable">
          <Box className="fieldTableMain">
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              rowHeight={100}
            />
          </Box>
        </Grid>
      </Grid>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("bannerMultipleDeleteConfirmationText")}
        singleDeleteText={t("bannerDeleteConfirmationText")}
      />
    </FieldManagementStyle>
  );
};

export default Banners;
