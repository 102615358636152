import { styled } from "@mui/system";

export const UploadFileBlock = styled("div")(
  ({ theme }) => `

  .uploadAssetsClass {
    h3 {
        margin-bottom: 6px;
    }
    .uploadFileArea {
        border: 1px dashed #0056b8;
        > div[role="presentation"] {
            display: flex;
            width: 100%;
            margin: 0;
            flex-wrap: wrap;
            gap: 15px;
            // background: rgb(229 229 229);
            border: 0;
            padding: 5px;
        }
        .innerFileCards {
            flex: calc(25% - 15px);
            max-width: calc(25% - 15px);
            @media screen and (max-width:1450px){
                flex: calc(33% - 10px);
                    max-width: calc(33% - 0px);
            }
            .inputContainer {
                width: 100%;
                background: #fff;
                display: grid;
                grid-template-columns: 1fr 4fr;
                align-items: center;
                border: 1px solid rgb(112 112 112 / 23%);
                border-radius: 0;
                height: 117px;  
                padding: 15px; 
                gap: 10px;
                    input{
                        position: absolute;
                        right: 7px;
                        top: 7px;
                        opacity: 0.4;
                        accent-color: #0056b8;
                        &:checked{
                            opacity:1;
                        }
                    }
                .upladedImage{
                    width: 100%;
                    margin: auto;
                    max-width: 66px;
                }         
                .cardsButtons {
                    display: grid;
                    justify-content: flex-start;
                    text-align: left;
                    font-size: 18px;
                    gap: 10px;
                    padding-left: 15px;
                    @media screen and (max-width:1700px){
                        padding-left:0px;
                    }
                  span {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        @media screen and (max-width:1650px){
                            gap: 9px;
                             font-size: 15px;
                        }
                       svg {
                            width: 18px;
                        }
                    }
                }
            }
        }
        .uploadBtn {
            height: 117px;
            width:100%;
            cursor:pointer;
            margin-bottom:0;
            background: #e5e5e5;
            label{
                    cursor: pointer;
                }
        }
    }
  }


}
  }

`,
);
