import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeroSearch from "./Components/HeroSearch/HeroSearch";
import HeroTrendingSearches from "./Components/HeroTrendingSearches/HeroTrendingSearches";
import {
  bannerLoading,
  getFeBanner,
} from "pages/Admin-Pages/banners/features/bannerSlice";
import { feGetBannerApi } from "pages/Admin-Pages/banners/features/bannerAction";
import bannerimage from "./bannerImg.png";
import HeroSectionStyles from "./HeroSectionStyles";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const dispatch = useDispatch();
  const getFeBannerData = useSelector(getFeBanner);
  const feBannerLoading = useSelector(bannerLoading);
  const imageBaseSrc = `${getFeBannerData?.image_base_url}/${getFeBannerData?.image_container_name}`;
  const scrollRef = useRef();
  const headingRef = useRef();
  const [bannerSrc, setBannerSrc] = useState("");
  const [headingHeight, setHeadingHeight] = useState(0);
  const heroSectionRef = useRef(null);
  const [dataValue, setDataValue] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(feGetBannerApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (getFeBannerData?.banners_images?.length > 0) {
      const randomBannerIndex = Math.floor(
        Math.random() * getFeBannerData?.banners_images?.length,
      );
      const imageSrc = `${imageBaseSrc}/${getFeBannerData?.banners_images[randomBannerIndex]}`;
      if (imageSrc) {
        setBannerSrc(imageSrc);
      } else {
        setBannerSrc(bannerimage);
      }
    } else {
      setBannerSrc(bannerimage);
    }
  }, [getFeBannerData, imageBaseSrc]);

  const updateDataValue = () => {
    const data = heroSectionRef?.current?.getBoundingClientRect()?.height;
    const headingHeightz = headingRef?.current?.getBoundingClientRect()?.height;
    const top = data / 2;
    setDataValue(top);
    setHeadingHeight(headingHeightz);
  };

  useEffect(() => {
    // Run on initial render
    updateDataValue();

    // Set up event listener for window resize
    window.addEventListener("resize", updateDataValue);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDataValue);
    };
  }, []);

  useEffect(() => {
    imageCardSwap(".float-div");

    return () => {
      gsap.killTweensOf(".float-div");
    };
    //eslint-disable-next-line
  }, [dataValue]);

  function imageCardSwap(trigger) {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".heroSectionMain",
        start: "top top",
        end: "95%",
        scrub: "none",
        ease: "none",
        onUpdate: (self) => {
          const progress = self.progress;
          if (progress >= 0 && progress < 1) {
            const element = document.querySelector(".float-div");
            if (element) {
              element.classList.remove("full-width-search");
            }
          } else {
            const element = document.querySelector(".float-div");
            if (element) {
              element.classList.add("full-width-search");
            }
          }
        },
      },
    });
    timeline.fromTo(
      trigger,
      { top: dataValue + 80 + 30 + (headingHeight > 130 ? 40 : 0) },
      {
        top: 106,
      },
    );
  }

  return (
    <HeroSectionStyles bannerImage={!feBannerLoading && bannerSrc}>
      <div className="float-div" style={{}}>
        <HeroSearch />
      </div>
      <div
        className="heroSectionMain"
        id="homeHeroSection"
        ref={heroSectionRef}
      >
        <div className="aboveHeroImage">
          <div className="heroHeadingContent">
            <div className="heroHeading" ref={headingRef}>
              <HeadingTag variant={headingVariants.h1}>
                {t("heroSectionHeading")}
              </HeadingTag>
            </div>
            <div children="heroContent" className="fileOptions" ref={scrollRef}>
              <TextTag variant={textVariants._16px.regular}>
                {t("heroSectionDescription")}
              </TextTag>
            </div>
          </div>
          <HeroTrendingSearches />
        </div>
      </div>
    </HeroSectionStyles>
  );
};

export default HeroSection;
