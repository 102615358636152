export const reactQuillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "font",
  "size",
  "color",
  "background",
  "code-block",
  "align",
];

export const reactQuillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [false, "right", "justify", "center"] }],
    ["video"],
    ["image"],
    ["link"],
    [
      {
        color: [],
      },
      {
        background: [],
      },
    ],
    ["code-block"],
    ["clean"],
  ],
};

// code to generate slug from string
export const generateSlugFromString = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
