import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Close, Menu } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
} from "@mui/material";
import { validationMessages } from "utils/validation/validationUtils";
import { updateFieldSettingsApi } from "../features/fieldsManagementAction";
import { DashboardShowCardsStyle } from "pages/Admin-Pages/dashboard/styles";
import ToastHelper from "helper/toastHelper";
import { useTranslation } from "react-i18next";

const ViewColumnPopup = (props) => {
  const {
    openViewPopup,
    setOpenViewPopup,
    colDefs,
    setColDefs,
    dispatch,
    settingType,
    dataState,
    permission_category,
  } = props;
  const open = Boolean(openViewPopup);

  const { t } = useTranslation();

  const id = open ? "simple-popover" : undefined;

  const handleOnChange = (e, columnField) => {
    const colIndex = colDefs?.findIndex(
      (colData) => colData?.field === columnField,
    );

    const prevColDefs = [...colDefs];
    const showColumnLength = prevColDefs.filter(
      (colData) => !colData.hide && !colData.suppressFiltersToolPanel,
    ).length;
    if (showColumnLength <= 2 && !e.target.checked) {
      ToastHelper("error", validationMessages.twoColumnsMustBeVisible);
      return;
    }
    prevColDefs[colIndex].hide = !e.target.checked;

    setColDefs(prevColDefs);
    saveSettings(prevColDefs);
  };

  const handleClose = () => {
    setOpenViewPopup(false);
  };

  function handleOnDragEnd(result) {
    //when we stop dragging around
    //if dragged out of bounds return
    if (!result.destination) return;
    const items = Array.from(colDefs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColDefs(items);
    saveSettings(items);
  }

  function saveSettings(settingsData) {
    const newColumnOrder = settingsData.map((c, index) => ({
      key: c.field,
      is_hidden: c.hide ? true : false,
      display_index: index,
    }));
    const body = { setting_type: settingType, detail: newColumnOrder };
    if (dataState?.id) {
      //for custom tag only
      body.custom_tag_id = dataState?.id;
    }
    if (permission_category) {
      body.permission_category = permission_category;
    }
    const dataBody = { body, dataState };
    // Update column order if it's changed
    dispatch(updateFieldSettingsApi(dataBody));
  }

  return (
    <Popover
      open={openViewPopup}
      id={id}
      anchorEl={openViewPopup}
      onClose={handleClose}
      disablerestorefocus
      placement="bottom-start"
      className="showCardsPopper"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <DashboardShowCardsStyle>
        <Box className="showCardsMain">
          <Box className="showCardHeader">
            <span className="titleClass">{t("showColumn")}</span>
            <IconButton className="closeIcon" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box className="showCardBody">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided, snapshot) => {
                  return (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={
                        snapshot?.isDraggingOver ? "dragTrue" : "dragFalse"
                      }
                    >
                      {colDefs.map(
                        (item, index) =>
                          !item?.suppressFiltersToolPanel && (
                            <Draggable
                              key={item?.field}
                              draggableId={item?.field}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                let headerName = item?.headerName;
                                if (
                                  item?.headerComponentParams
                                    ?.applied_filters_data?.dataState
                                    ?.custom_tag_data?.name &&
                                  item?.field === "name"
                                ) {
                                  //for inner pages of custom tags only #edgecase
                                  headerName =
                                    item?.headerComponentParams
                                      ?.applied_filters_data?.dataState
                                      ?.custom_tag_data?.name;
                                }
                                return (
                                  <Box
                                    className={`dragBlocks ${
                                      snapshot?.isDragging
                                        ? "dragBlockTrue"
                                        : "dragBlockFalse"
                                    }`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Menu />
                                    <Box>
                                      <FormControlLabel
                                        key={index}
                                        label={headerName}
                                        control={
                                          <Checkbox checked={!item?.hide} />
                                        }
                                        onChange={(e) => {
                                          handleOnChange(e, item?.field);
                                        }}
                                        checked={!item?.hide}
                                      />
                                    </Box>
                                  </Box>
                                );
                              }}
                            </Draggable>
                          ),
                      )}
                      {provided.placeholder}
                    </Box>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </DashboardShowCardsStyle>
    </Popover>
  );
};

export default ViewColumnPopup;
