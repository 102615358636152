import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import * as Yup from "yup";

const ChangePasswordSchema = Yup.object().shape({
  existingPassword: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterExistingPassword),
  newPassword: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterNewPassword)
    .matches(
      validationRegex.checkPasswordRequirements,
      validationMessages.newPasswordMeetRequirements,
    ),
  reTypePassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      validationMessages.makeSurePasswordsMatch,
    )
    .required(validationMessages.pleaseConfirmNewPassword),
});

export default ChangePasswordSchema;
