import { createSlice } from "@reduxjs/toolkit";
import {
  addBusiness,
  deleteBusiness,
  getAllBusiness,
  getBusiness,
  getBusinessFields,
  updateBusiness,
  updateBusinessStatus,
} from "./subGroupAction";

const initialState = {
  business: [],
  fieldValues: [],
};
const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBusiness.fulfilled, (state, action) => {
        state.status = "idle";
        state.business = action.payload;
      });
    builder
      .addCase(getAllBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllBusiness.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(addBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBusiness.fulfilled, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBusiness.fulfilled, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(updateBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBusiness.fulfilled, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(updateBusinessStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBusinessStatus.fulfilled, (state) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getBusinessFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBusinessFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});
//getBusinessFields
export const selectAllBusiness = (state) => state.business.business.data;

export const businessUnitWithFilterData = (state) => {
  return state.business.business;
};

export const businessUnitFieldValueArray = (state) =>
  state?.business?.fieldValues?.business_data;

export default businessSlice.reducer;
