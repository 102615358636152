import { Info } from "@mui/icons-material";
import { Box, ClickAwayListener, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import TextfieldComp from "components/Textfield/TextfieldComp";
import { useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { checkForSuperAdmin } from "utils/permissions/checkPermission";
import { useDispatch } from "react-redux";
import { createTooltipApi } from "./tooltipAction";

//editable tooltip style
const StyledCommonEditTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 400,
    border: `1px solid ${theme.palette.lightGray.main}`,
    span: {
      "&:before": {
        backgroundColor: theme.palette.lightGray.main,
      },
    },
  },
  ".tooltip_title_wrapper button.MuiButtonBase-root": {
    padding: "0",
    height: "20px",
    background: "transparent",
    marginLeft: "10px",
  },
  ".tooltip_text_edit": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "5px 0px 15px 0px",

    "span.errorClass": {
      position: "absolute",
      bottom: "0",
    },
    ".text_wrapper ": {
      display: "flex",
      input: {},
    },
    ".icons_wrapper": {
      display: "flex",
    },
  },
  ".tooltip_title_text": {
    minWidth: "10px",
    minHeight: "10px",
  },
}));

//editable tooltip component
export const CommonEditTooltip = (props) => {
  const { children, tooltip_data, displayName, field_name } = props;

  const [tooltipData, setTooltipData] = useState([]);

  useEffect(() => {
    setTooltipData(tooltip_data?.tooltip_setting?.details);
  }, [tooltip_data]);

  const tooltipDetails = tooltip_data?.tooltip_setting?.details;

  const dispatch = useDispatch();
  const title = tooltipData?.filter(
    (tObj) => tObj.field_name === field_name,
  )?.[0]?.description;

  const module_name = tooltip_data?.module_name;

  const defaultTitle = displayName ? displayName : "Custom Tooltip Title";
  const [inEditingMode, setInEditingMode] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [error, setError] = useState("");
  const [tooltipTitle, setTooltipTitle] = useState(
    title ? title : defaultTitle,
  );

  function onTooltipEditClick() {
    //when clck on pencil edit icon
    setInEditingMode(true);
    setTooltipTitle(title ? title : tooltipTitle);
    setError("");
  }

  function handleTooltipClose() {
    //while closing the tooltip
    if (tooltipTitle?.trim()?.length === 0) {
      //if no value in textbox then revert to original text.
      setTooltipTitle(title ? title : defaultTitle);
    }
    setInEditingMode(false);
    setTooltipOpen(false);
  }

  function handleTextChange(e) {
    //when we are writing in the textfield of tooltip
    setError("");
    const textValue = e?.target?.value;
    if (textValue?.trim()?.length > 300) {
      setError(validationMessages.tooLong);
    } else if (!validationRegex.checkForDoubleSpaces.test(textValue)) {
      setError(validationMessages.noConsecutiveDoubleSpaces);
    } else {
      setTooltipTitle(textValue);
    }
  }

  const UpdateData = (fieldName, newDescription) => {
    setTooltipData((prevState) =>
      prevState.map((val) => {
        if (val?.field_name === fieldName) {
          return { ...val, description: newDescription };
        }
        return val;
      }),
    );
  };

  function updateTooltipData() {
    //when we click the tick icon to update the tooltip
    UpdateData(field_name, tooltipTitle);

    if (!tooltipTitle || tooltipTitle?.trim() === "") {
      setError(validationMessages.pleaseEnterTooltipValue);
      return;
    }

    const tooltipObj = {
      field_name,
      description: tooltipTitle,
    };
    let finalToolTipArray = [];

    if (tooltipDetails && Array.isArray(tooltipDetails)) {
      //find index in previous array
      finalToolTipArray = [...tooltipDetails];
      const fieldIndex = tooltipDetails?.findIndex(
        (toolObj) => toolObj?.field_name === field_name,
      );
      if (fieldIndex >= 0) {
        //if index found replace with new tooltip value
        finalToolTipArray[fieldIndex] = tooltipObj;
      } else {
        //push new tooltip value at end of table
        finalToolTipArray.push(tooltipObj);
      }
    } else {
      //if tooltip detail doesnot exist push tooltip detail into array
      finalToolTipArray.push(tooltipObj);
    }

    const data = {
      body: {
        module_name,
        details: finalToolTipArray,
      },
    };

    dispatch(createTooltipApi(data)).then((res) => {
      if (res?.error) {
        return;
      }

      //changing datastate so that get api and other related functions run
      // setDataState({ ...dataState, data });
      handleTooltipClose();
    });
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box
        className={"tooltip_main_outer"}
        onMouseLeave={() => {
          if (!inEditingMode) handleTooltipClose();
        }}
      >
        <StyledCommonEditTooltip
          open={tooltipOpen}
          onMouseOver={() => setTooltipOpen(true)}
          onClick={() => setTooltipOpen(true)}
          onClose={handleTooltipClose}
          arrow={true}
          minWidth={40}
          placement="top-start"
          title={
            <Box className={"tooltip_title_main"}>
              {inEditingMode ? (
                <Box className={"tooltip_text_edit"}>
                  <Box className="text_wrapper">
                    <TextfieldComp
                      value={tooltipTitle}
                      handleChange={handleTextChange}
                      onKeyDown={(e) => {
                        if (e?.code === "Enter" || e?.code === "NumpadEnter")
                          updateTooltipData();
                      }}
                      // autoFocus
                    />
                    <Box className="icons_wrapper">
                      <IconButton onClick={updateTooltipData}>
                        <CheckCircleOutlinedIcon />
                      </IconButton>
                      <IconButton
                        className="clear_button"
                        onClick={handleTooltipClose}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <span className="errorClass">{error}</span>
                </Box>
              ) : (
                <Box className={"tooltip_title_wrapper"}>
                  <span className="tooltip_title_text">
                    {title ? title : tooltipTitle}
                  </span>
                  {checkForSuperAdmin() && (
                    <IconButton size="small" onClick={onTooltipEditClick}>
                      <ModeEditIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
          }
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -15],
                  },
                },
              ],
            },
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {children ? children : <Info className="infoIcon" />}
        </StyledCommonEditTooltip>
      </Box>
    </ClickAwayListener>
  );
};
