import React from 'react'

const UploadIcon = ({className}) => {
  return (
    
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="108.016" height="108" viewBox="0 0 108.016 108">
         <path id="Icon_awesome-upload" data-name="Icon awesome-upload" d="M62.447,81H45.569a5.051,5.051,0,0,1-5.063-5.063V40.5H22A4.211,4.211,0,0,1,19.029,33.3L51.118,1.192a4.087,4.087,0,0,1,5.759,0L88.986,33.3A4.211,4.211,0,0,1,86.012,40.5H67.51V75.938A5.051,5.051,0,0,1,62.447,81Zm45.569-1.688v23.628a5.051,5.051,0,0,1-5.063,5.063H5.063A5.051,5.051,0,0,1,0,102.942V79.314A5.051,5.051,0,0,1,5.063,74.25H33.755v1.688A11.822,11.822,0,0,0,45.569,87.752H62.447A11.822,11.822,0,0,0,74.261,75.938V74.25h28.692A5.051,5.051,0,0,1,108.016,79.314ZM81.856,97.879a4.219,4.219,0,1,0-4.219,4.219A4.232,4.232,0,0,0,81.856,97.879Zm13.5,0a4.219,4.219,0,1,0-4.219,4.219A4.232,4.232,0,0,0,95.358,97.879Z" transform="translate(0 -0.005)" fill="#0056b8"/>
        </svg>

  
  )
}

export default UploadIcon