import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { deepCopy } from "helper/deepCopyFunction";
import GalleryColumn from "./GalleryColumn/GalleryColumn";
import CuratedGalleryDragAndDropStyle from "./CuratedGalleryDragAndDropStyle";
import { useDispatch } from "react-redux";
import {
  curatedGalleryUpdateIndexesApi,
  getCuratedGalleries,
} from "../features/curatedGalleryAction";

const CuratedGalleryDragDrop = (props) => {
  const { curatedGalleryData, categoryId } = props;
  const dispatch = useDispatch();

  const [initialColData, setInitialColData] = useState();

  useEffect(() => {
    if (!curatedGalleryData || curatedGalleryData.length <= 0) {
      return;
    }
    const galleryData = deepCopy(curatedGalleryData);
    galleryData.sort((prev, next) => prev.display_index - next.display_index);
    setInitialColData(galleryData);
  }, [curatedGalleryData]);

  function updateFooterDetails(details, showToast = true) {
    setInitialColData(details);

    const finalDetails = details?.map((data, index) => {
      const obj = {};
      obj.gallery_id = data?._id;
      obj.display_index = index;
      obj.link_id = data?.linkData?.map((indiData) => {
        return {
          _id: indiData?.link_id,
          target_blank: indiData?.target_blank,
          link: indiData?._id,
        };
      });
      return obj;
    });

    const body = { detail: finalDetails };
    const data = { body, showToast };
    dispatch(curatedGalleryUpdateIndexesApi(data)).then((res) => {
      if (res?.error) return;
      dispatch(getCuratedGalleries(categoryId));
    });
  }

  async function handleRemoveChild(link, colId) {
    const copiedCols = deepCopy(initialColData);
    //getting the array of lists from where the element will be removed
    const listArray = copiedCols.filter(
      (colData) => colData?._id === colId,
    )?.[0]?.linkData;

    //also find the index from where the source list array is found
    const souceListArrayIndex = initialColData.findIndex(
      (colData) => colData?._id === colId,
    );

    //finding the index of the item in the list
    const linkIndex = listArray.findIndex((a) => a?.name === link?.name);

    listArray?.splice(linkIndex, 1);

    copiedCols[souceListArrayIndex].linkData = listArray;

    updateFooterDetails(copiedCols, false);

    return;
  }

  function onDragEnd(result) {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      //copying the array
      const arr = [...initialColData];

      //finding the element we have to move
      const element = arr[source.index];

      //remove the element from its source position
      arr.splice(source.index, 1);

      //puting the element at its destination position
      arr.splice(destination.index, 0, element);

      //updating the result
      updateFooterDetails(arr);
      return;
    }

    //else reordering lists inside the column

    const clonedArray = deepCopy(initialColData); //clone the array
    const sourceColId = source?.droppableId; //column Id of source
    const sourceListIndex = source?.index; //list index of source
    const destinationColId = destination?.droppableId; //column Id of destination
    const destinationListIndex = destination?.index; //list index of destination

    //getting the array of lists from where the element will be removed
    const sourceListArray = initialColData.filter(
      (colData) => colData?._id === sourceColId,
    )?.[0]?.linkData;

    //also find the index from where the source list array is found
    const souceListArrayIndex = initialColData.findIndex(
      (colData) => colData?._id === sourceColId,
    );

    //getting the element that we have to move
    const elementToMove = sourceListArray?.[sourceListIndex];

    /*---removing the element from its source position---*/

    //copying the inner list array to make changes
    const dupSourceListArray = deepCopy(sourceListArray);

    //removing the element from innerlist
    dupSourceListArray?.splice(sourceListIndex, 1);

    //if change is in the same list do it here
    if (sourceColId === destinationColId) {
      dupSourceListArray?.splice(destinationListIndex, 0, elementToMove);
      clonedArray[souceListArrayIndex].linkData = dupSourceListArray;
      updateFooterDetails(clonedArray);
      return;
    }

    //putting the modified inner list inside the whole array
    clonedArray[souceListArrayIndex].linkData = dupSourceListArray;

    //--------------------------------------------------------

    const destinationListArray = initialColData.filter(
      (colData) => colData?._id === destinationColId,
    )?.[0]?.linkData;

    //finding the index where the destination list array is found
    const destinationListArrayIndex = initialColData.findIndex(
      (colData) => colData?._id === destinationColId,
    );

    /*---adding the element to its destination position---*/

    //copying the inner list array to make changes
    const dupDestinationListArray = deepCopy(destinationListArray);

    //adding the element to innerlist
    dupDestinationListArray?.splice(destinationListIndex, 0, elementToMove);

    // putting the modified inner list inside the whole array
    clonedArray[destinationListArrayIndex].linkData = dupDestinationListArray;

    updateFooterDetails(clonedArray);
  }

  return (
    <CuratedGalleryDragAndDropStyle>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={false}
          isCombineEnabled={false}
        >
          {(provided) => (
            <div
              className="footerMenuColumnOuter"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {initialColData?.map((columnData, index) => {
                const listData = columnData?.linkData;
                return (
                  <GalleryColumn
                    key={columnData?._id}
                    index={index}
                    title={columnData?.name}
                    id={columnData?._id}
                    listData={listData}
                    isScrollable={true}
                    isCombineEnabled={false}
                    handleRemoveChild={handleRemoveChild}
                    columnData={columnData}
                    categoryId={categoryId}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </CuratedGalleryDragAndDropStyle>
  );
};

export default CuratedGalleryDragDrop;
