import { styled } from "@mui/system";

export const DashboardShowCardsStyle = styled("div")(({ theme }) => ({
  ".showCardsMain": {
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    fontFamily: "Roboto",
    borderRadius: "4px",

    paddingTop: "0",
    minWidth: "250px",
    minHeight: "300px",
    overflowX: "hidden",
    ".showCardHeader": {
      marginBottom: "0",
      padding: "15px",
      position: "sticky",
      background: "#fff",
      zIndex: "9",
      width: "100%",
      left: "0",
      top: "0",
      " span.titleClass": {
        fontFamily: "sans-serif",
      },
      button: {
        position: "absolute",
        right: "0",
        top: "0",
      },
    },
    ".showCardBody": {
      display: "grid",
      padding: "0 16px 24px",
    },
    ".dragBlocks": {
      display: " flex",
      alignItems: "center",
      cursor: "pointer",
      gap: "15px",
      position: "relative",
      zIndex: "2",
      fontFamily: "Roboto, sans-serif",
      " > .MuiSvgIcon-root ": {
        width: "24px",
      },
      "&:is(.dragBlockTrue)": {
        cursor: "move",
        transform: "0.4s, top 0.4s",
        maxWidth: "290px",
        "&:after": {
          content: "''",
          position: "absolute",
          width: "250px",
          background: "#f7f7f7",
          top: "0",
          height: "100%",
          left: "-24px",
          zIndex: "-1",
          maxWidth: "250px",
        },
      },

      "&:hover": {
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100vw",
          background: "#f7f7f7",
          top: "0",
          height: "100%",
          left: "-24px",
          zIndex: "-1",
          maxWidth: "292px",
        },
      },
    },
  },
}));
