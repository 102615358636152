import React from "react";
import { ImEye } from "react-icons/im";
import TableIconsCommonWrapper from "./TableIconsCommonWrapper";
import { useTranslation } from "react-i18next";

const TableViewIcon = ({ tooltipTitle, onClick }) => {
  const { t } = useTranslation();
  return (
    <TableIconsCommonWrapper
      tooltipTitle={tooltipTitle ? tooltipTitle : t("view")}
    >
      <ImEye className="gridEditIconUsers viewEyeIcon" onClick={onClick} />
    </TableIconsCommonWrapper>
  );
};

export default TableViewIcon;
