import { createSlice } from "@reduxjs/toolkit";
import {
  addPagesToFooterApi,
  createCmsPageApi,
  getContentByTitleApi,
  getHierarchyofPagesCms,
  getHierarchyofPagesCmsFE,
  upDatePagesHeadName,
  updateCmsPageIndexApi,
  updateFooterMenuIndexApi,
  updateFooterSubMenuApi,
} from "./pagesCmsAction";
import {
  deleteCmsPageApi,
  getAllCmsPagesApi,
  getCmsPageFieldsApi,
  getCustomPageApi,
  getParentPagesApi,
  updateCmsPageApi,
} from "./pagesCmsAction";

const initialState = {
  parentPages: [],
  customPageData: "",
  customPageDataByTitile: "",
  allCmsPageData: [],
  allCmsPageFields: {},
  customPageLoading: true,
  pageCmsData: [],
  pageCmsLoading: false,
  updateNameLoading: false,
};

const pagesCmsSlice = createSlice({
  name: "pageCms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get hierarchy of pages
    builder
      .addCase(getHierarchyofPagesCms.pending, (state) => {
        state.pageCmsLoading = true;
      })
      .addCase(getHierarchyofPagesCms.fulfilled, (state, action) => {
        state.pageCmsData = action.payload;
        state.pageCmsLoading = false;
      })
      .addCase(getHierarchyofPagesCms.rejected, (state) => {
        state.dashboardStatloading = false;
      });
    //get hierarchy of cms pages frontend
    builder
      .addCase(getHierarchyofPagesCmsFE.pending, (state) => {
        state.pageCmsLoading = true;
      })
      .addCase(getHierarchyofPagesCmsFE.fulfilled, (state, action) => {
        state.pageCmsData = action.payload;
        state.pageCmsLoading = false;
      })
      .addCase(getHierarchyofPagesCmsFE.rejected, (state) => {
        state.dashboardStatloading = false;
      });
    //update footer pages head columns name
    builder
      .addCase(upDatePagesHeadName.pending, (state) => {
        state.updateNameLoading = true;
      })
      .addCase(upDatePagesHeadName.fulfilled, (state) => {
        state.updateNameLoading = false;
      })
      .addCase(upDatePagesHeadName.rejected, (state) => {
        state.updateNameLoading = false;
      });
    //get parent page
    builder
      .addCase(getParentPagesApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getParentPagesApi.fulfilled, (state, action) => {
        state.parentPages = action.payload;
        state.status = "idle";
      })
      .addCase(getParentPagesApi.rejected, (state) => {
        state.status = "rejected";
      });
    //cms page data
    builder
      .addCase(getCustomPageApi.pending, (state) => {
        state.customPageLoading = true;
      })
      .addCase(getCustomPageApi.fulfilled, (state, action) => {
        state.customPageData = action.payload;
        state.customPageLoading = false;
      })
      .addCase(getCustomPageApi.rejected, (state) => {
        state.customPageLoading = false;
      });
    //get pages content by the title
    builder
      .addCase(getContentByTitleApi.pending, (state) => {
        state.customPageLoading = true;
      })
      .addCase(getContentByTitleApi.fulfilled, (state, action) => {
        state.customPageDataByTitile = action.payload;
        state.customPageLoading = false;
      })
      .addCase(getContentByTitleApi.rejected, (state) => {
        state.customPageLoading = false;
      });
    //get all pages
    builder
      .addCase(getAllCmsPagesApi.pending, (state) => {
        state.customPageLoading = true;
      })
      .addCase(getAllCmsPagesApi.fulfilled, (state, action) => {
        state.allCmsPageData = action.payload;
        state.customPageLoading = false;
      })
      .addCase(getAllCmsPagesApi.rejected, (state) => {
        state.customPageLoading = false;
      });
    //filters fields
    builder
      .addCase(getCmsPageFieldsApi.pending, (state) => {
        state.customPageLoading = true;
      })
      .addCase(getCmsPageFieldsApi.fulfilled, (state, action) => {
        state.allCmsPageFields = action.payload;
        state.customPageLoading = false;
      })
      .addCase(getCmsPageFieldsApi.rejected, (state) => {
        state.customPageLoading = false;
      });
    //delete cms page
    builder
      .addCase(deleteCmsPageApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCmsPageApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteCmsPageApi.rejected, (state) => {
        state.status = "rejected";
      });
    //update cms page
    builder
      .addCase(updateCmsPageApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCmsPageApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateCmsPageApi.rejected, (state) => {
        state.status = "rejected";
      });
    //update cms page index (rearrange) old
    builder
      .addCase(updateCmsPageIndexApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCmsPageIndexApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateCmsPageIndexApi.rejected, (state) => {
        state.status = "rejected";
      });

    //add pages to footer
    builder
      .addCase(addPagesToFooterApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPagesToFooterApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(addPagesToFooterApi.rejected, (state) => {
        state.status = "rejected";
      });

    //update footer menu index (rearrange footer menu)
    builder
      .addCase(updateFooterMenuIndexApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFooterMenuIndexApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateFooterMenuIndexApi.rejected, (state) => {
        state.status = "rejected";
      });
    //update footer sub menu data
    builder
      .addCase(updateFooterSubMenuApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFooterSubMenuApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateFooterSubMenuApi.rejected, (state) => {
        state.status = "rejected";
      });
    //create cms page
    builder
      .addCase(createCmsPageApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCmsPageApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(createCmsPageApi.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const pagesCmsState = (state) => state.pageCms.pageCmsData;
export const parentPages = (state) => state.pageCms;
export const customPage = (state) => state.pageCms.customPageData;
export const customPageLoading = (state) => state.pageCms.customPageLoading;
export const allCmsPagesData = (state) => state.pageCms.allCmsPageData;
export const customPageDataByTitle = (state) =>
  state.pageCms.customPageDataByTitile.message;
export const allCmsPagesFields = (state) => state.pageCms.allCmsPageFields;

export const footerMenuLoadingStatus = (state) => state.pageCms.status;

export default pagesCmsSlice.reducer;
