import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { DialogActions, DialogContent, TextField, Box } from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";

import {
  addcollection,
  Getcollection,
  updateCollections,
} from "../../features/collectionAction";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";

const AddEditCollectionTypePopup = (props) => {
  const {
    open,
    openFor,
    setOpen,
    dataState,
    setDataState,
    currentRowData,
    setCurrentRowData,
  } = props;
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRowData?.name ?? "",
      description: currentRowData?.description ?? "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .max(50, validationMessages.tooLong)
        .matches(
          validationRegex?.checkForDoubleSpaces,
          validationMessages?.noConsecutiveDoubleSpaces,
        )
        .required(validationMessages.pleaseEnterCollection),
      description: Yup.string()
        .trim()
        .max(250, validationMessages.tooLong)
        .matches(
          validationRegex?.checkForDoubleSpaces,
          validationMessages?.noConsecutiveDoubleSpaces,
        ),
    }),
    onSubmit: (values) => {
      const body = { name: values.name, description: values.description };
      if (openFor === "EDIT") {
        //when editing collection type
        const dataBody = {
          body,
          dataState,
        };

        if (currentRowData?._id) {
          dataBody.body.id = currentRowData?._id;
        }

        dispatch(updateCollections(dataBody)).then((res) => {
          if (res?.error) {
            dispatch(Getcollection(dataState));
          }
          handleClose();
        });
      } else {
        //when adding collection type
        dispatch(addcollection(body)).then((res) => {
          if (!res?.error) {
            setDataState({
              ...dataState,
              search: "",
              limit: 20,
              page: 1,
              sortOrder: "desc",
              sortBy: "createdAt",
              column_name: "",
              column_values: "",
              filtersQueryString: [],
            });
            handleClose();
          }
        });
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setCurrentRowData({});
  };

  return (
    <DialogCommon
      open={open}
      handleClose={handleClose}
      title={openFor === "EDIT" ? t("updateType") : t("addType")}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              <form onSubmit={formik.handleSubmit}>
                <Box className="formSection">
                  <Box className="request-common">
                    <label htmlFor="name">{t("type")}</label>
                    <TextField
                      placeholder={t("createType")}
                      size="small"
                      className={"addNewText"}
                      variant="outlined"
                      onChange={formik.handleChange}
                      name={"name"}
                      id={"name"}
                      value={formik.values.name}
                    />
                    <span className="errorClass">
                      {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name}
                    </span>
                  </Box>
                  <Box className="request-common">
                    <label className="label">{t("description")}</label>
                    <TextField
                      placeholder={t("description")}
                      rows={3}
                      size="small"
                      variant="outlined"
                      type="text"
                      multiline={true}
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description ?? ""}
                    />
                    <span className="errorClass">
                      {formik.errors.description &&
                        formik.touched.description &&
                        formik.errors.description}
                    </span>
                  </Box>
                </Box>
                <DialogActions className="popup-button-outer">
                  <CustomButton variant={"contained"} type="submit">
                    {openFor === "EDIT" ? t("update") : t("save")}
                  </CustomButton>
                  <CustomButton variant={"contained"} onClick={handleClose}>
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default AddEditCollectionTypePopup;
