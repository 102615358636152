import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "components/Button/CustomButton";
import ToastHelper from "helper/toastHelper";
import {
  allUserEnableDisableApi,
  deleteAllUser,
  getAllUserApi,
  getUserFields,
} from "./features/allUserAction";
import { fieldValueArray } from "./features/allUserSlice";
import { selectAllUserRole } from "./features/userRolePermissionSlice";
import { selectAllRegion } from "../groups/features/groupSlice";
import { getRegions } from "../groups/features/groupAction";
import { getUserRoles } from "./features/userRolePermissionAction";
import AddUserPopup from "./Components/AddUserPopup/AddUserPopup";
import CsvPopup from "./Components/CsvPopup/CsvPopup";
import EditandViewUserPopup from "./Components/EditandViewUserPopup/EditandViewUserPopup";
import { getDepartments } from "../tertiaryGroup/features/departmentAction";
import PageTitle from "../AdminComponents/PageTitle/PageTitle";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "../AdminComponents/CustomComponentfilter/CustomComponentFilter";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import ToggleSwitch from "pages/Admin-Pages/AdminComponents/ToggleSwitch/ToggleSwitch";
import { validationMessages } from "utils/validation/validationUtils";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import { usersPageTabs } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";

import { useNavigate } from "react-router-dom";
import { routeConfigs } from "utils/routeConfig";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import { useTranslation } from "react-i18next";
import { checkForClientAndSuperAdmin } from "utils/permissions/checkPermission";
import CommonDeletePopup from "../AdminComponents/CommonDeletePopup/CommonDeletePopup";

const UsersAll = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const dataa = useSelector((state) => state);
  const userRoleData = useSelector(selectAllUserRole);
  const regionData = useSelector(selectAllRegion);
  const fieldValueData = useSelector(fieldValueArray); //filter
  const navigate = useNavigate();

  const usersAllData = dataa?.allUser?.allUsers;
  const gridRef = useRef();

  const checkSuperAdmin = (props) =>
    props?.data?.userRoles?.[0]?.role !== "super admin" &&
    props?.data?.userRoles?.value?.role !== "super admin";

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("status"),
    hiddenAgGridColumn("approve"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: (props) => checkSuperAdmin(props),
      headerCheckboxSelection: (props) => checkSuperAdmin(props),
      suppressFiltersToolPanel: true,
      showDisabledCheckboxes: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "first_name",
      headerName: t("firstName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "first_name",
          dispatchName: "first_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"first_name"}
                sortBy={"first_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "last_name",
      headerName: t("lastName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "last_name",
          dispatchName: "last_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"last_name"}
                sortBy={"last_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "email",
      headerName: t("email"),
      headerClass: "leftAlign",
      minWidth: 350,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "email",
          dispatchName: "email",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"email"}
                sortBy={"email"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "regions_data",
      headerName: t("primary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "regions_data.name",
          dispatchName: "regions_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"regions_data.name"}
                sortBy={"regions_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        return (
          <span className={params?.value?.[0]?.enabled ? "" : "greyDisabled"}>
            {params?.value?.name || params?.value?.[0]?.name || ""}
          </span>
        );
      },
    },
    {
      field: "businesses_data",
      headerName: t("secondary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "businesses_data.name",
          dispatchName: "businesses_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"businesses_data.name"}
                sortBy={"businesses_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        return (
          <span className={params?.value?.[0]?.enabled ? "" : "greyDisabled"}>
            {params?.value?.name || params?.value?.[0]?.name || ""}
          </span>
        );
      },
    },
    {
      field: "userDepartment",
      headerName: t("tertiary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "userDepartment.name",
          dispatchName: "userDepartment.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"userDepartment.name"}
                sortBy={"userDepartment.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        return (
          <span className={params?.value?.[0]?.enabled ? "" : "greyDisabled"}>
            {params?.value?.name || params?.value?.[0]?.name || ""}
          </span>
        );
      },
    },
    {
      field: "userRoles",
      headerName: t("userRole"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "userRoles.role",
          dispatchName: "userRoles.role",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"userRoles.role"}
                sortBy={"userRoles.role"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        return (
          <span
            className={`text_capitalize ${
              params?.value?.[0]?.enabled || params?.value?.enabled
                ? ""
                : "greyDisabled"
            }`}
          >
            {params?.value?.[0]?.role}
          </span>
        );
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign "
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          checkSuperAdmin(props) && (
            <Grid className="toggleBlockCo ">
              <ToggleSwitch
                checked={props?.value}
                value={props?.value}
                defaultChecked={props?.value}
                onChange={(e) => {
                  gridRef.current.api.deselectAll();
                  setCurrentRowData(props?.data);
                  handleSwitchChange(
                    props?.data?._id, //id
                    props?.value, //value
                    props,
                  );
                }}
              />
            </Grid>
          )
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => {
        return (
          checkSuperAdmin(props) && (
            <Grid container className="actionIcons">
              {props?.data?.status === "disapproved" ? (
                <TableViewIcon
                  onClick={() => {
                    gridRef.current.api.deselectAll();
                    setOpenViewEdit(true);
                    setCurrentRowData(props?.data);
                    SetEditTrue("view");
                  }}
                />
              ) : (
                <TableEditIcon
                  onClick={() => {
                    gridRef.current.api.deselectAll();
                    setOpenViewEdit(true);
                    setCurrentRowData(props?.data);
                    SetEditTrue("Edit");
                  }}
                />
              )}
              <TableDeleteIcon
                onClick={() => {
                  setOpenDelete(true);
                  setRowsToBeDeleted([props?.data?._id]);
                }}
              />
            </Grid>
          )
        );
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [csvOpen, setCsvOpen] = useState(false);
  const [viewEditOpen, setOpenViewEdit] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [editTrue, SetEditTrue] = useState("");

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getUserRoles());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getAllUserApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dataState, dispatch]);

  useEffect(() => {
    setPreviousColumnFilterData(usersAllData?.applied_filters);

    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = usersAllData?.[commonKey]),
    );

    setRowData(
      usersAllData?.userDataArray?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        status: tableDataObj?.status,
        approve: tableDataObj?.approve,
        first_name: tableDataObj?.first_name,
        last_name: tableDataObj?.last_name,
        email: tableDataObj?.email,
        regions_data: tableDataObj?.regions_data,
        businesses_data: tableDataObj?.businesses_data,
        userDepartment: tableDataObj?.userDepartment,
        userRoles: tableDataObj?.userRoles,
        enabled: tableDataObj?.enabled,
        other_table_data: commonDataObj,
      })),
    );
  }, [usersAllData]);

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      props,
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }

        setCurrentRowData(props?.data);

        const body = {
          enabled: !switchValue,
          id: entryId,
        };

        const isRoleAssigned =
          props?.data?.userRoles?.[0]?.enabled ||
          props?.data?.userRoles?.enabled;

        const isApproved = props?.data?.approve;

        if (!isApproved && !switchValue) {
          ToastHelper("error", validationMessages.userApprovalNecessary);
        } else if (!isRoleAssigned && !switchValue) {
          ToastHelper("error", validationMessages.pleaseAssignUserRoleToUser);
        } else {
          //updating the ui

          rowNode.setDataValue("enabled", !switchValue);

          let modifiedDataState = dataState;

          if (props?.data?.other_table_data) {
            const other_data = props?.data?.other_table_data;
            modifiedDataState = modifyDataState(other_data);
          }

          dispatch(allUserEnableDisableApi(body)).then((res) => {
            if (res?.error) {
              //reverting the change in case of api error
              dispatch(getAllUserApi(modifiedDataState));
            }
          });
        }
      });
    },
    //eslint-disable-next-line
    [],
  );

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getUserFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };
    const databody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      usersAllData?.currentPage,
      usersAllData?.totalDocuments,
      rowsToBeDeleted?.length,
      usersAllData?.limit,
    );

    databody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteAllUser(databody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(databody.dataState);
    });

    setSelectedRows(false);
    setOpenDelete(false);
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    isRowSelectable: (props) => checkSuperAdmin(props),
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("allUsers")} />
      <TabsComponent tabsDataArray={usersPageTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="addUserPagesBtn allUserPageBlock table-upper">
                {checkForClientAndSuperAdmin() && (
                  <Box className="csvBtn">
                    <CustomButton
                      onClick={() => {
                        navigate(routeConfigs.adminUserRecover);
                      }}
                    >
                      {t("recoverDeletedUsers")}
                    </CustomButton>
                  </Box>
                )}
                <Box className="csvBtn">
                  <CustomButton onClick={() => setCsvOpen(true)}>
                    {t("uploaddownloadCsv")}
                  </CustomButton>
                </Box>
                <Box className={`add_button`}>
                  <CustomButton onClick={() => setAddOpen(true)}>
                    {t("addUser")}
                  </CustomButton>
                </Box>
                <PaginationLimitDropDown
                  dataState={dataState}
                  setDataState={setDataState}
                  setSelectedRows={null} //for now
                />
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={usersAllData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("allUsersmultipledeleteConfirmationText")}
        singleDeleteText={t("allUsersdeleteConfirmationText")}
      />
      {addOpen && (
        <AddUserPopup
          open={addOpen}
          setOpen={() => setAddOpen(false)}
          regionData={regionData}
          userRoleData={userRoleData}
          dataState={dataState}
          setDataState={setDataState}
        />
      )}
      {csvOpen && (
        <CsvPopup
          open={csvOpen}
          setOpen={() => setCsvOpen(false)}
          userRoleData={userRoleData}
        />
      )}
      {viewEditOpen && (
        <EditandViewUserPopup
          open={viewEditOpen}
          setOpen={() => setOpenViewEdit(false)}
          regionData={regionData}
          userRoleData={userRoleData}
          allUserDetailData={currentRowData}
          editTrue={editTrue}
          dataState={dataState}
        />
      )}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default UsersAll;
