import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Box } from "@mui/system";
import imageOne from "./Images/contactUsImage1.png";
import imageTwo from "./Images/contactUsImage2.png";
import imageThree from "./Images/contactUsImage3.png";
import imageFour from "./Images/contactUsImage4.png";
import ContactUsStyles3 from "./contactUsStyles3";

gsap.registerPlugin(ScrollTrigger);

const ContactUsComp3 = () => {
  const { t } = useTranslation();
  const contactHeading = t("contactUsText");
  const contactText = t("contactUsHearFromYou");
  const buttonText = t("getInTouchWithUs");

  useEffect(() => {
    imageCardSwap(".imageOne", "120%", "-270%");
    imageCardSwap(".imageTwo", "88%", "-170%");
    imageCardSwap(".imageThree", "75%", "-70%");
    imageCardSwap(".imageFour", "65%", "-150%");
    headerSticky(".contentBox", "-110%", "0%");

    return () => {
      gsap.killTweensOf(".imageOne");
      gsap.killTweensOf(".imageTwo");
      gsap.killTweensOf(".imageThree");
      gsap.killTweensOf(".imageFour");
    };
  }, []);

  function imageCardSwap(trigger, startY, endY) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".contactUsMain",
          start: "-20% top",
          end: "bottom -20%",
          scrub: 0.5,
          ease: "none",
          markers: false,
        },
      })
      .fromTo(trigger, { y: startY }, { y: endY, duration: 1 });
  }


  function headerSticky(trigger, startY, endY) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".contactUsMain",
          start: "-20% top",
          end: "bottom 10%",
          scrub: 1,
          ease: "none",
          markers: false,
        },
      })
      .fromTo(trigger, { y: startY }, { y: endY, duration: 1 });
  }




  return (
    <ContactUsStyles3 ContactUsStyles2>
      <Box className="contactUsMain">
        <Box className="mainContentWrapper">
          <Box className="newImageWrapperMain">
            <Box className="imagesListBlock">
              <Box className="imageWrapper imageOne">
                <img src={imageOne} alt="animation_image_one" />
              </Box>
              <Box className="imageWrapper imageTwo">
                <img src={imageTwo} alt="animation_image_two" />
              </Box>
              <Box className="imageWrapper imageThree">
                <img src={imageThree} alt="animation_image_three" />
              </Box>
              <Box className="imageWrapper imageFour">
                <img src={imageFour} alt="animation_image_four" />
              </Box>
            </Box>
          </Box>
          <Box className="centerHeading">
            <Box className="contentBox">
              <h2>{contactHeading}</h2>
              <p>{contactText}</p>
              <button>{buttonText}</button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ContactUsStyles3>
  );
};
export default ContactUsComp3;
