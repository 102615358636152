export function agSetColumnIndexes(params) {
  //this function is used to extract the table column settings
  //from the get table data api and put those settings inside the
  //column definations

  const {
    tableDataResponse, //response form get table data api
    setSettingType, //state to set setting type which will be requied in set column settings api
    colDefs, //state which gives current column defination
    setColDefs, //state to set column definations
    dataState, //to set with applied filters
    settingCategory, //only needed for roles and permissions page
  } = params;

  //getting column settings array
  const columnSettingArray = settingCategory
    ? tableDataResponse?.payload?.[
        `${settingCategory?.toLowerCase().split(" ").join("_")}_setting`
      ]?.detail
    : tableDataResponse?.payload?.setting?.detail;
  setSettingType(tableDataResponse?.payload?.settingType);

  //sorting the existing column definations
  //according to the display_index in the column settings array
  const sortedColumns = colDefs?.slice().sort((a, b) => {
    const indexA = columnSettingArray?.findIndex(
      (item) => item.key === a.field,
    );
    const indexB = columnSettingArray?.findIndex(
      (item) => item.key === b.field,
    );
    return indexA - indexB;
  });

  const sortedWithHidden = sortedColumns?.map((cdata, index) => {
    const obj = { ...cdata };

    if (obj?.foreverShow) {
      obj.hide = false;
    } else if (obj?.initialHide) {
      obj.hide = true;
    } else {
      obj.hide = columnSettingArray?.[index]?.is_hidden ? true : false;
    }

    if (obj?.headerComponentParams?.tooltip_data) {
      //putting tooltip data
      obj.headerComponentParams.tooltip_data.module_name =
        tableDataResponse?.payload?.module_type;
      obj.headerComponentParams.tooltip_data.tooltip_setting =
        tableDataResponse?.payload?.toolTipSetting;
      obj.headerComponentParams.dataState = dataState;
    }

    if (obj?.headerComponentParams?.applied_filters_data) {
      //putting previously applied filters into columndefs
      obj.headerComponentParams.applied_filters_data = {
        applied_filters: tableDataResponse?.payload?.applied_filters,
        dataState,
      };
    }
    return obj;
  });
  //setting the column definations
  setColDefs(sortedWithHidden);
}
