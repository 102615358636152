import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";
export const secondaryGroupSchema = Yup.object().shape({
  newRegionsId: Yup.string()
    .trim()
    .required(validationMessages.pleaseSelectRegion),
  newBusiness: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(validationRegex.alphanumeric, validationMessages.alphanumericOnly)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterSecondaryGroup),
});
