import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const ContactUsSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .max(300, validationMessages.tooLong)
    .required(validationMessages.pleaseEnterEmail)
    .matches(
      validationRegex.checkForValidEmail,
      validationMessages.emailNotVaid,
    ),
  message: Yup.string()
    .trim()
    .max(500, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterMessage),
  reCaptcha: Yup.string().required(validationMessages.pleaseEnterCaptcha),
});
