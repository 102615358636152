import { styled } from "@mui/system";

const HeroSectionStyles = styled("div")(({ theme, bannerImage }) => {
  return {
    ".float-div": {
      position: "fixed",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "100%",
      maxWidth: "54rem",
      zIndex: "99",
      translate: "none",
      rotate: "none",
      scale: "none",
      transition: " max-width 0.2s linear 0s",
      "&.full-width-search": {
        maxWidth: "100%",
        ".heroSearch": {

          boxShadow: `0px -1px 0 ${theme.palette.lightGray.main}`,
          borderRadius: "0",
        },
      },
    },
    ".heroSectionMain": {
      position: "relative",
      background: `linear-gradient(0deg, rgba(0,0,0,0.32) 0%, rgba(0,0,0,0.32) 49%), url(${bannerImage})`,
      backgroundSize: "cover",
      ".aboveHeroImage": {
        textAlign: "center",
        width: "100%",
        maxWidth: "54rem",
        margin: "auto",
        height: "calc(100svh - 21.111vw)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",


        ".heroHeadingContent": {
          marginBottom: "4.719rem",

          ".heroHeading": {
            h1: {

              color: theme.palette.whiteColor.main,
              marginBottom: "0.5rem",
              marginTop: "0",
            },
          },
          ".fileOptions": {
            span: {
              display: "block",
              color: theme.palette.gray_200.main,
            },
          },
        },
      },
    },
  };
});

export default HeroSectionStyles;
