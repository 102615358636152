import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomButton from "components/Button/CustomButton";
import {
  deletecollection,
  Getcollection,
  getCollectionFields,
  updateCollections,
} from "./features/collectionAction";
import {
  collectionFieldValueArray,
  collectiostate,
} from "./features/collectionSlice";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Admin-Pages/AdminComponents/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import { AgCellEditor } from "utils/datatable/AgCellEditor";

import CommonGrid from "components/Grid/CommonGrid";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import { assetCollection } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import AddEditCollectionTypePopup from "./components/AddEditCollectionTypePopup/AddEditCollectionTypePopup";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import CommonDeletePopup from "../AdminComponents/CommonDeletePopup/CommonDeletePopup";

const AssetsCollections = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getallcollection = useSelector(collectiostate);
  const fieldValueData = useSelector(collectionFieldValueArray); //filter
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("types"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: false,
        editFunction: editcollection,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "asset_number",
      headerName: t("noOfAssets"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "asset_number",
          dispatchName: "asset_number",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"asset_number"}
                sortBy={"asset_number"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: () => <span>0</span>,
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => (
        <span className="email leftAlign" key={params?.value}>
          {moment(params?.value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (params) => <th>{params?.displayName}</th>,
      cellRenderer: (params) => (
        <Grid container className="actionIcons" key={params?.value}>
          <TableEditIcon
            onClick={() => {
              setOpen(true);
              setOpenFor("EDIT");
              setCurrentRowData(params?.data);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setSelectedRows([]);
              setRowsToBeDeleted([params?.data?._id]);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFor, setOpenFor] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [currentRowData, setCurrentRowData] = useState([]);

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    // setIsLoading(true);
    dispatch(Getcollection(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = getallcollection?.[commonKey]),
    );

    setRowData(
      getallcollection?.collections?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        asset_number: tableDataObj?.asset_number,
        createdAt: tableDataObj?.createdAt,
        other_table_data: commonDataObj,
        description: tableDataObj?.description,
      })),
    );
  }, [getallcollection]);

  function editcollection(data) {
    const body = {
      id: data?.dataObj?._id || data?.roleId,
      name: data?.updatedValue || data?.role,
    };

    if (body?.name === "") {
      return false;
    }

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = {
      body,
      dataState: modifiedDataState,
    };

    dispatch(updateCollections(dataBody)).then((res) => {
      if (res?.error) {
        dispatch(Getcollection(modifiedDataState));
      }
    });
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handleDelete = () => {
    const body = {
      ids: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      getallcollection?.currentPage,
      getallcollection?.totalDocuments,
      rowsToBeDeleted?.length,
      getallcollection?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deletecollection(dataBody)).then(() => {
      if (shouldPageChange) setDataState(dataBody.dataState);
      gridRef.current.api.deselectAll();
    });

    setOpenDelete(false);
    setSelectedRows([]);
  };

  function commonFilterOnClick(e, props) {
    const ref = e.currentTarget;
    const { dispatchGetName, dispatchName, applied_filters } = props;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getCollectionFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle className="extraSpaceAbove">
      <PageTitle title={t("collections")} />
      <TabsComponent tabsDataArray={assetCollection} />
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType collectionAssets ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={`addNewDiv1`}>
                      <CustomButton
                        onClick={() => {
                          setOpenFor("ADD");
                          setOpen(true);
                        }}
                        variant="outlined"
                      >
                        {t("addType")}
                      </CustomButton>
                    </Box>
                    <PaginationLimitDropDown
                      dataState={dataState}
                      setDataState={setDataState}
                      setSelectedRows={setSelectedRows}
                    />
                  </Grid>
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={getallcollection?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("assetCollectionMultipleDeleteConfirmationText")}
        singleDeleteText={t("assetCollectionDeleteConfirmationText")}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
      {open && (
        <AddEditCollectionTypePopup
          open={open}
          setOpen={setOpen}
          openFor={openFor}
          dataState={dataState}
          setDataState={setDataState}
          currentRowData={currentRowData}
          setCurrentRowData={setCurrentRowData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default AssetsCollections;
