import { styled } from "@mui/system";

const CuratedGalleryStyles = styled("div")(({ theme }) => ({
  ".categorySelectMain": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    margin: "25px 0",
    ".MuiFormControl-root.formControl": {
      width: "100%",
      maxWidth: "max-content",
      minWidth: "200px",
    },
  },
}));

export default CuratedGalleryStyles;
