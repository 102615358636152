import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { recaptcha_site_key } from "utils/envConst";
import { routeConfigs } from "utils/routeConfig";
import { validationRegex } from "utils/validation/validationUtils";
import withoutAuth from "Auth/withoutAuth";
import CommonLayout from "Layout/AuthLayout/CommonLayout";
import CustomButton from "components/Button/CustomButton";
import { encryptAES } from "utils/encrypt";

import { UserRegisterSchema } from "utils/validation/validation-schemas/userRegisterSchema";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterApi } from "../features/userAction";
import { loginState } from "../features/userAuth";
import { useTranslation } from "react-i18next";
import PasswordCheckPopup from "components/PasswordCheckPopup/passwordCheckPopup";

function UserRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginLoading } = useSelector(loginState);
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    validationSchema: UserRegisterSchema,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      resetPassword: "",
      reCaptcha: "",
    },
    onSubmit: (values) => {
      const encryptedPassword = encryptAES(values.password);

      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: encryptedPassword,
      };
      dispatch(userRegisterApi(body))
        .then((response) => {
          if (response.error) {
            setErrorMessage(response?.payload?.data?.message);
          } else {
            navigate(routeConfigs.userThankyouPage);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  return (
    <CommonLayout headingName={t("registerHere")}>
      <div className="loginMain c">
        <Typography color="secondary" className="errorValidation center">
          {(Object.keys(formik?.errors)[0] in formik.touched &&
            Object.values(formik?.errors)[0]) ||
            (errorMessage && errorMessage)}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="first_name"
            fullWidth
            variant="standard"
            margin="normal"
            placeholder={t("firstName")}
            value={formik.values.first_name}
            onChange={(e) => {
              setErrorMessage("");
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="last_name"
            fullWidth
            variant="standard"
            margin="normal"
            placeholder={t("lastName")}
            value={formik.values.last_name}
            onChange={(e) => {
              setErrorMessage("");
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="email"
            fullWidth
            variant="standard"
            margin="normal"
            placeholder={t("email")}
            value={formik.values.email}
            onChange={(e) => {
              setErrorMessage("");
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          <div className="passwordDiv">
            <TextField
              name="password"
              fullWidth
              variant="standard"
              placeholder={t("password")}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={formik.values.password}
              onChange={(e) => {
                if (
                  validationRegex.matchOthersExceptSpace.test(e.target.value) &&
                  e.target.value.length <= 20
                ) {
                  formik.handleChange(e);
                }
                setErrorMessage("");
              }}
              onFocus={() => setShowPasswordStrength(true)}
              onBlur={(e) => {
                setShowPasswordStrength(false);
                formik.handleBlur(e);
              }}
            />
            {showPasswordStrength && (
              <PasswordCheckPopup password={formik.values.password} />
            )}
          </div>
          <TextField
            name="resetPassword"
            fullWidth
            variant="standard"
            margin="normal"
            placeholder={t("confirmPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setshowConfirmPassword((prev) => !prev)}
                    size="large"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formik.values.resetPassword}
            onChange={(e) => {
              if (
                validationRegex.matchOthersExceptSpace.test(e.target.value) &&
                e.target.value.length <= 20
              ) {
                formik.handleChange(e);
              }
              setErrorMessage("");
            }}
            onBlur={formik.handleBlur}
            type={showConfirmPassword ? "text" : "password"}
          />

          <ReCAPTCHA
            className={"loginRecaptcha"}
            name="reCaptcha"
            sitekey={recaptcha_site_key}
            onChange={(e) => {
              formik.setFieldValue("reCaptcha", e);
              setErrorMessage("");
            }}
            onBlur={formik.handleBlur}
          />
          <div className="formButtons centerbtnR">
            {loginLoading ? (
              <CircularProgress size={26} className="loginLoader" />
            ) : (
              <CustomButton variant="contained" type>
                {t("createAccount")}
              </CustomButton>
            )}
          </div>
          <div className="notaMemberText">
            {t("alreadyaMember")}
            <Link to={routeConfigs.userLogin}>{t("loginHere")}</Link>
          </div>
        </form>
      </div>
    </CommonLayout>
  );
}

export default withoutAuth(UserRegister, routeConfigs.userHome);
