import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import TableIconsCommonWrapper from "./TableIconsCommonWrapper";

const TableEditIcon = ({ tooltipTitle, onClick }) => {
  const { t } = useTranslation();
  return (
    <TableIconsCommonWrapper
      tooltipTitle={tooltipTitle ? tooltipTitle : t("edit")}
    >
      <FaPencilAlt className="gridEditIcon" onClick={onClick} />
    </TableIconsCommonWrapper>
  );
};

export default TableEditIcon;
