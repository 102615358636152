import React from "react";
import withAuth from "../../../../Auth/withAuth";
import { routeConfigs } from "../../../../utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import CuratedGallery from "./HomeComponents/CuratedGallery/CuratedGallery";
import FindLookingForSection from "./HomeComponents/FindLookingForSection/FindLookingForSection";
import HeroSection from "./HomeComponents/HeroSection/HeroSection";
import HomeStyles from "./HomeStyle";
import { useTranslation } from "react-i18next";
import MarketSegment from "./HomeComponents/MarketSegment/MarketSegment";
import FeaturedProjects from "./HomeComponents/FeaturedProjects/FeaturedProjects";
import StockVideo from "./HomeComponents/StockVideo/StockVideo";

function HomeDuplicate() {
  const { i18n } = useTranslation();
  return (
    <Layout>
      <HomeStyles className={`homePageMain langTest ${i18n.language}`}>
        <HeroSection />
        <FindLookingForSection />
        {/* <MarketSegment /> */}
        <StockVideo />
        {/* <FeaturedProjects /> */}
        {/* 
        <CuratedGallery />
        {/* <ContactUsComp /> */}
        {/* <ContactUsComp2 /> */}
      </HomeStyles>
      {/* <IdleModal /> */}
    </Layout>
  );
}
export default withAuth(HomeDuplicate, routeConfigs.userLogin);
