import React, { useRef, useState } from "react";
import FeaturedProjectStyles from "./featuredProjectStyles";
import { register } from "swiper/element/bundle";
import image1 from "./Images/featuredImageOne.png";
import image2 from "./Images/featuredImageTwo.png";
import HomeSliderTop from "../HomeSliderTop";

register();

const FeaturedProjects = () => {
  const sectionHeading = "Featured Projects";
  const swiperElRef = useRef(null);
  const longDesc =
    "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of the document or a typeface without relying on a meaningful content.";

  const featuredProjectsData = [
    {
      project_image: image1,
      project_name: "Project Name",
      project_description: longDesc,
      project_uid: "#121582312",
    },
    {
      project_image: image2,
      project_name: "Graphics",
      project_description: longDesc,
      project_uid: "#121589735",
    },
    {
      project_image: image1,
      project_name: "Building 001",
      project_description: longDesc,
      project_uid: "#121589785",
    },
    {
      project_image: image1,
      project_name: "Bridge 01",
      project_description: longDesc,
      project_uid: "#129982312",
    },
    {
      project_image: image2,
      project_name: "Pluto",
      project_description: longDesc,
      project_uid: "#121586935",
    },
    {
      project_image: image1,
      project_name: "Jupitor",
      project_description: longDesc,
      project_uid: "#121510785",
    },
  ];

  const FeaturedProjectSlideComp = (props) => {
    const [hoverClass, setHoverClass] = useState();
    const { imgData } = props;

    return (
      <div
        className={`featured_slide_inner ${hoverClass ? "slide_hover_show" : "slide_hover_hide"
          }`}
      >
        <img
          className="featuredSlideImage"
          src={imgData?.project_image}
          alt={`swiper pic`}
          onMouseOver={() => setHoverClass(true)}
          onMouseLeave={() => setHoverClass(false)}
        />
        <div className="hoverSlide">
          <div className="slideHoverTop">
            <span>{imgData?.project_uid}</span>
          </div>
          <div className="slideHoverBottom">
            <h3>{imgData?.project_name}</h3>
            <p>{imgData?.project_description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FeaturedProjectStyles>
      <div className="featured_main">
        <HomeSliderTop
          sectionHeading={sectionHeading}
          swiperElRef={swiperElRef}
        />
        <div className="featured_slider">
          <swiper-container
            ref={swiperElRef}
            slides-per-view="3.139"
            slides-offset-before="0"
            slides-offset-after="64"
            space-between="32"
          >
            {featuredProjectsData?.map((imgData, i) => (
              <swiper-slide key={i}>
                <FeaturedProjectSlideComp imgData={imgData} />
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    </FeaturedProjectStyles>
  );
};

export default FeaturedProjects;
