import { styled } from "@mui/system";

export const EditSubCategory = styled("div")(
  ({ theme }) => `
  .request-common {
    .fields {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0 15px;
     label.label {
            grid-row: 1/3;
            position:relative;
            top:8px;
        }
        span.errorClass {
            margin-bottom: 15px;
        }
    }
        .MuiFormControl-root {
            width: 100%;
        }
    }
}
`,
);
