import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EditSubCategory } from "pages/Admin-Pages/asset-administration/AssetSubCategory/Components/EditSubCategory";
import { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Sorting from "utils/sorting";
import { TertiaryGroupSchema } from "utils/validation/validation-schemas/tertiaryGroupSchema";

const EditTertiaryGroupPopup = (props) => {
  const { t } = useTranslation();
  const {
    editOpen,
    handleEditPopClose,
    businessData,
    currentRowData,
    editDepartment,
  } = props;

  const initialValues = {
    departmentId: currentRowData?._id,
    newDepartment: currentRowData?.name,
    business: currentRowData?.business || " ",
    enabled: currentRowData?.enabled,
  };

  return (
    <DialogCommon
      fullwidth={true}
      open={editOpen}
      handleClose={handleEditPopClose}
      title={t("editTertiaryOrganization")}
      maxWidth={"md"}
    >
      <DialogContent>
        <EditSubCategory>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={TertiaryGroupSchema}
            onSubmit={(values) => editDepartment(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="request-common addNewDivSelect">
                  <Box className="fields">
                    <label className="label">
                      {t("secondaryOrganization")}
                    </label>
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        className="permission-role-select"
                        name="business"
                        value={values?.business}
                        onChange={handleChange}
                        defaultValue={" "}
                      >
                        <MenuItem value={" "}>
                          {t("selectOrganization")}
                        </MenuItem>
                        {Sorting(businessData, "name")?.map((val, index) => {
                          return (
                            (val?._id === values?.business || val?.enabled) && (
                              <MenuItem key={index + 1} value={val?._id}>
                                {val?.name}
                              </MenuItem>
                            )
                          );
                        })}
                      </Select>
                    </FormControl>
                    <span className="errorClass">
                      {errors.business && touched.business && errors.business}
                    </span>
                  </Box>
                  <Box className="fields">
                    <label className="label">{t("tertiaryOrganization")}</label>
                    <TextField
                      placeholder={t("enterTertiaryOrganization")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="newDepartment"
                      onChange={(e) => {
                        if (e.target.value?.length <= 50) {
                          setFieldValue("newDepartment", e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values?.newDepartment}
                    />
                    <span className="errorClass">
                      {errors?.newDepartment &&
                        touched?.newDepartment &&
                        errors?.newDepartment}
                    </span>
                  </Box>
                </div>
                <DialogActions className="popup-button-outer">
                  <StyledButton variant={"contained"} type="submit">
                    {t("update")}
                  </StyledButton>
                  <StyledButton
                    variant={"contained"}
                    onClick={handleEditPopClose}
                  >
                    {t("cancel")}
                  </StyledButton>
                </DialogActions>
              </form>
            )}
          </Formik>
        </EditSubCategory>
      </DialogContent>
    </DialogCommon>
  );
};

export default EditTertiaryGroupPopup;
