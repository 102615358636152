import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";
import performApiRequest from "helper/apiRequestHelper";

//get all general pages
export const getGeneralFooterPagesApi = createAsyncThunk(
  "generalFooterPages/getGeneralFooterPagesApi",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = apiRoutes.adminGetGeneralFooterPages;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetGeneralFooterPages}?filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//create general cms page
export const createGeneralFooterPageApi = createAsyncThunk(
  "generalFooterPages/createGeneralFooterPageApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminCreateGeneralFooterPage, data),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getSingleGeneralFooterPageApi = createAsyncThunk(
  "generalFooterPages/getSingleGeneralFooterPageApi",
  async (pageId) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetSingleGeneralFooterPage}/${pageId}`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getGeneralFooterPageByTitleApi = createAsyncThunk(
  "generalFooterPages/getGeneralFooterPageByTitleApi",
  async (pageTitle) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.getGeneralFooterPageByTitle}/${pageTitle}`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const editGeneralFooterPageApi = createAsyncThunk(
  "generalFooterPages/editGeneralFooterPageApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminEditGeneralFooterPage}/${body.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteGeneralFooterPageApi = createAsyncThunk(
  "generalFooterPages/deleteGeneralFooterPageApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteGeneralFooterPage, data?.body),
      );
      return dispatch(getGeneralFooterPagesApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getGeneralFooterPageFieldsApi = createAsyncThunk(
  "generalFooterPages/getGeneralFooterPageFieldsApi",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetGeneralFooterPageFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetGeneralFooterPageFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
