import React from "react";

const ImageSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M11.2647 14.4377L9.5473 12.7346C8.758 11.9519 8.3633 11.5605 7.9089 11.4137C7.5092 11.2845 7.079 11.2845 6.67922 11.4137C6.22485 11.5605 5.83017 11.9519 5.04082 12.7346L1.04193 16.7622M11.2647 14.4377L11.606 14.0991C12.412 13.2999 12.8149 12.9003 13.2773 12.7545C13.6839 12.6262 14.1208 12.6312 14.5244 12.7688C14.9832 12.9253 15.3769 13.334 16.1642 14.1515L17 15.0001M11.2647 14.4377L15.22 18.4628M1.04193 16.7622C1.07264 17.0226 1.12583 17.2271 1.21799 17.408C1.40973 17.7843 1.7157 18.0903 2.09202 18.282C2.51984 18.5 3.07989 18.5 4.2 18.5H13.8C14.4213 18.5 14.8703 18.5 15.22 18.4628M1.04193 16.7622C1 16.4066 1 15.9466 1 15.3V5.7C1 4.5799 1 4.01984 1.21799 3.59202C1.40973 3.21569 1.7157 2.90973 2.09202 2.71799C2.51984 2.5 3.07989 2.5 4.2 2.5H8M15.22 18.4628C15.5007 18.4329 15.7175 18.3791 15.908 18.282C16.2843 18.0903 16.5903 17.7843 16.782 17.408C17 16.9802 17 16.4201 17 15.3V11.5M15 7.5V4.5M15 4.5V1.5M15 4.5H18M15 4.5H12"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ImageSearchIcon;
