import { styled } from "@mui/system";

const PasswordLayout = styled("div")(({ theme }) => ({
  ".container": {
    maxWidth: "1440px",
    margin: "auto",
    width: "100%",
    "h1.typo": {
      textAlign: "left",
    },
    ".changePassWordPage": {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      " .loginMain": {
        background: theme.palette.white.main,
        maxWidth: "100%",
        margin: "auto",
        padding: "0px",
        borderRadius: "6px",
        width: "100%",
        ".buttons": {
          display: "flex",
          padding: "15px 0",
          button: {
            ":is(.loaderBtn)": {
              background: "transparent",
            },
          },
        },
        ".forgotPassText ": {
          textAlign: "center",
        },
        ".passwordDiv": {
          position: "relative",
          ".fields": {
            margin: "16px 0 30px",
          },
        },
        ".passwordReqPopup": {
          top: "95px",
        },
        ".loginRecaptcha": {
          marginTop: "25px",
        },
        ".formButtons": {
          display: "flex",
          justifyContent: "space-between",
          margin: "25px 0",
          "+ p": {
            textAlign: "center",
            a: {
              color: "#1976d2",
            },
          },
          button: {
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            ":first-of-type": {
              ":hover": {
                color: theme.palette.white.main,
              },
            },
            ":is(.forgetPwd, .linkBtn )": {
              background: "transparent",
              border: "0",
              marginRight: "0",
              color: theme.palette.primary.main,
            },
          },
          "&.centerbtnR": {
            justifyContent: "center !important",
          },
        },
        ".notaMemberText": {
          textAlign: "center",
          fontSize: "16px",
          a: {
            color: theme.palette.primary.main,
          },
        },
        ".updateButton": {
          button: {
            maxWidth: "230px",
          },
        },
      },
      ".imageBlock": {
        img: {
          width: "100%",
        },
      },
    },

    ".passwordReqPopup": {
      background: theme.palette.white.main,
      padding: "8px",
      borderRadius: "8px",
      boxShadow: "0 0 0 #000, 0 0 2px #cecbcb",
      position: "absolute",
      top: "65px",
      width: "100%",
      maxWidth: "80%",
      zIndex: "1",
      "&:after": {
        content: "''",
        width: "7px",
        height: "12px",
        borderStyle: "solid",
        borderWidth: "0 16px 11px 17px",
        borderColor: "transparent transparent #fcfcfc transparent",
        transform: "rotate(0deg)",
        position: "absolute",
        top: "-11px",
        left: "21px",
      },
      "&:before": {
        content: "''",
        width: "7px",
        height: "12px",
        borderStyle: "solid",
        borderWidth: "0 16px 11px 17px",
        borderColor: "transparent transparent #dcdcdc transparent",
        transform: "rotate(0deg)",
        position: "absolute",
        top: "-12px",
        left: "21px",
      },
      ".passwordStrengthBarOuter": {
        "> span": {
          fontSize: "14px",
          marginBottom: "9px",
          display: "block",
          "&.Strong": {
            color: "#629514",
          },
          "&.Weak": {
            color: "red",
          },
          "&.Good": {
            color: "#0056B8",
          },
          "&.medium": {
            color: "rgb(247, 163, 7)",
          },
        },
        ".passwordStrengthBar": {
          "> div": {
            display: "inline-block",
            width: "calc(100% / 4 - 4px)",
            height: "3px",
            background: "#d0d0d0",
            margin: "0 2px",
            "&.Weak": {
              background: "red",
            },
            "&.Good": {
              background: "#0056B8",
            },
            "&.medium": {
              background: "rgb(247, 163, 7)",
            },
            "&.Strong": {
              background: "#629514",
            },
          },
        },
      },
      "ul.passwordReqUl": {
        margin: "0",
        listStyle: "none",
        padding: "0",
        li: {
          fontSize: "14px",
          lineHeight: "24px",
          "&.passwordReqTrue": {
            color: "#467400",
          },
        },
      },
    },
  },
}));

export default PasswordLayout;
