import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";
import React from "react";
import { Link } from "react-router-dom";

const StyledSearchIconButton = styled(Link)(({ theme }) => ({
  borderRadius: "0.25rem",
  border: "1px solid rgba(255, 255, 255, 0.08)",
  background: "rgba(255, 255, 255, 0.08)",
  padding: "0.5rem 1rem",
  cursor: "pointer",
  color: theme.palette.white.main,
  textDecoration: "none",

  textAlign: "center",
  "&:hover": {
    background: "rgb(255 255 255 / 16%)",
  },
  "&.disabled": {
    border: "1px solid #FFFFFF33",
    background: "#FFFFFF1A",
    pointerEvents: "none",
    cursor: "default",
  },
  ".iconTitle": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    ".iconC": {
      lineHeight: 'normal',
      position: 'relative',
      top: '2px',
    },
    svg: {
      width: "14px",
      height: "14px",
    },
  },
}));

const SearchIconLink = (props) => {
  const { title, iconComp, disabled } = props;
  /**
   * buttonType = "button" || "link"
   */

  return (
    <StyledSearchIconButton {...props} className={disabled ? "disabled" : ""}>
      <div className="iconTitle">
        <span className="iconC">{iconComp}</span>
        <TextTag variant={textVariants._12px.medium}>{title}</TextTag>

      </div>
    </StyledSearchIconButton>
  );
};

export default SearchIconLink;
