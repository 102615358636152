import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { TextField } from "@mui/material";
import { Formik } from "formik";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import {
  createCmsPageApi,
  getCustomPageApi,
  updateCmsPageApi,
} from "./features/pagesCmsAction";
import { customPage } from "./features/pagesCmsSlice";
import CustomButton from "components/Button/CustomButton";
import { useQuery } from "utils/queryString";
import { routeConfigs } from "utils/routeConfig";
import {
  AddPageSchema,
  validateContentSize,
} from "utils/validation/validation-schemas/pageSchema";
import {
  generateSlugFromString,
  reactQuillFormats,
  reactQuillModules,
} from "utils/textEditorConst";
import PageAddStyle from "./PagesAddStyle";
import "react-quill/dist/quill.snow.css";

function PagesAdd() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const pageData = useSelector(customPage);
  const pageId = useQuery().get("id");
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === routeConfigs.adminPagesEdit && pageId) {
      dispatch(getCustomPageApi(pageId));
    }
  }, [dispatch, pageId]);

  const initialValues = {
    pageTitle:
      (pageId &&
        pageData?.find_sub_custom_page &&
        pageData?.find_sub_custom_page?.[0]?.title) ||
      "",
    pageHtml:
      (pageId &&
        pageData?.find_sub_custom_page &&
        pageData?.find_sub_custom_page?.[0]?.description) ||
      "",
  };

  function handleFormSubmit(values, { setErrors, resetForm, setFieldValue }) {
    const body = {
      title: values?.pageTitle,
      slug: generateSlugFromString(values?.pageTitle),
      description: values.pageHtml,
      type: "page",
      id: pageId,
    };
    if (
      window.location.pathname === routeConfigs.adminPagesEdit &&
      pageData?.find_sub_custom_page
    ) {
      dispatch(updateCmsPageApi(body)).then((res) => {
        if (!res?.error) {
          navigate(routeConfigs.adminPagesAll);
        }
      });
    } else {
      const data = { body, toast: true };
      dispatch(createCmsPageApi(data))
        .then((res) => {
          if (!res?.error) {
            resetForm();
            setFieldValue("pageHtml", "");
            navigate(routeConfigs.adminPagesAll);
          }
        })
        .catch(() => {
          console.error("An error occured");
        });
    }
  }

  return (
    <PageAddStyle>
      <PageTitle
        title={
          window.location.pathname === routeConfigs.adminPagesAdd
            ? t("addNewPage")
            : t("editPage")
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={AddPageSchema}
        onSubmit={handleFormSubmit}
        validate={validateContentSize}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={`addViewTable ${i18n.language}`}
          >
            <div className="table-upper addNewPage">
              <div className="leftSideInputs">
                <div className="request-common">
                  <TextField
                    placeholder={t("pageTitle")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="pageTitle"
                    value={values?.pageTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="errorClass">
                    {errors.pageTitle && touched.pageTitle && errors.pageTitle}
                  </span>
                </div>
              </div>
            </div>
            <div className="request-common">
              <ReactQuill
                className="richTextEditor"
                theme="snow"
                value={values?.pageHtml}
                placeholder={t("enterContentHere")}
                onChange={(e) => {
                  setFieldValue("pageHtml", e);
                }}
                name="pageHtml"
                modules={reactQuillModules}
                formats={reactQuillFormats}
              />
              <span className="errorClass">
                {errors.pageHtml && touched.pageHtml && errors.pageHtml}
              </span>
            </div>
            <div className="formButtons">
              <CustomButton variant={"contained"} type="submit">
                {t("save")}
              </CustomButton>
              <CustomButton
                variant={"contained"}
                onClick={() => {
                  navigate(routeConfigs.adminPagesAll);
                }}
              >
                {t("cancel")}
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </PageAddStyle>
  );
}

export default PagesAdd;
