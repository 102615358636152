import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const AddAssetsSchema = Yup.object().shape({
  asset_type_id: Yup.string()
    .trim()
    .required(validationMessages.pleaseSelectAssetType),
  asset_category_id: Yup.string()
    .trim()
    .required(validationMessages.pleaseSelectAssetCategory),
  chooseUpload: Yup.string(),
  subCategoryLength: Yup.boolean(),
  asset_sub_category_id: Yup.string().when("subCategoryLength", {
    is: false,
    then: () =>
      Yup.string().required(validationMessages.pleaseSelectAssetSubCategory),
    otherwise: () => Yup.string().notRequired(),
  }),
  // collection_id: Yup.string().required("Collection ID is required"),
  collection_id: Yup.string().when("chooseUpload", {
    is: (value) => value === "existing",
    then: () =>
      Yup.string().required(validationMessages.pleaseSelectAssetCollection),
    otherwise: () => Yup.string().notRequired(),
  }),
  files: Yup.array()
    .required(validationMessages.filesRequired)
    .min(1, validationMessages.pleaseUploadFile),

  //  collection_name: Yup.string().required("Collection Name is required"),

  creator_id: Yup.string()
    .trim()
    .required(validationMessages.pleaseSelectCreator),
  collection_name: Yup.string().when("chooseUpload", {
    is: (value) => value === "new",
    then: () =>
      Yup.string().required(validationMessages.pleaseSelectAssetCollection),
    otherwise: () => Yup.string().notRequired(),
  }),
  name: Yup.string()
    .required(validationMessages.pleaseEnterAssetName)
    .trim()
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .matches(validationRegex.alphanumeric, validationMessages.alphanumericOnly)
    .max(50, validationMessages.tooLong),
  credit_line: Yup.string()
    .required(validationMessages.pleaseEnterCreditLine)
    .trim()
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )

    .max(50, validationMessages.tooLong),
  copyright_notice: Yup.string()
    .required(validationMessages.pleaseEnterCopyRightNotice)
    .trim()
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )

    .max(50, validationMessages.tooLong),
  copyright_status: Yup.string()
    .required(validationMessages.pleaseEnterCopyRightStatus)
    .trim()
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )

    .max(50, validationMessages.tooLong),
  right_usage_terms: Yup.string()
    .required(validationMessages.pleaseEnterRightUsageTerms)
    .trim()
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .max(50, validationMessages.tooLong),
  keywords: Yup.string().trim(),
  description: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterDescription)

    .test("wordCount", validationMessages.maxWordsAllowed, (value) => {
      // Count words by splitting on spaces and filtering out empty strings
      const wordCount = value.trim().split(/\s+/).filter(Boolean).length;
      return wordCount <= 250;
    })
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
});
