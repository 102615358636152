import React, { useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import CustomButton from "components/Button/CustomButton";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import FirstSection from "./Components/FirstSection/FirstSection";
import UploadSection from "./Components/UploadSection/UploadSection";
import MetaData from "./Components/MetaData/MetaData";
import CustomTagsData from "./Components/CustomTags/CustomTagsData";
import DialogCommon from "components/DialogComponent/DialogCommon";
import ScheduleForLater from "./Components/ScheduleForLater/scheduleforlater";
import { useDispatch } from "react-redux";
import { creatAssetApi } from "./Components/Features/addAssetAction";
import { AddAssetsSchema } from "utils/validation/validation-schemas/addAssetsSchema";
import { useTranslation } from "react-i18next";

const AssetsAdd = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialValue = {
    email: "",
    address: "",
    country: "",
    state: "",
    businessUnit: "",
    postalCode: "",
    phone: "",
    website: "",
  };

  const [state, setState] = useState(initialValue);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [schedulePopUp, setSchedulePopUp] = useState(false);

  const [toogle, setToogle] = useState({
    assets: true,
    metadata: true,
    customtag: true,
  });

  const formik = useFormik({
    AddAssetsSchema,
    initialValues: {
      subCategoryLength: undefined,
      chooseUpload: "",
      asset_type_id: "",
      asset_category_id: "",
      asset_sub_category_id: "",
      collection_id: "",
      files: [],
      name: "",
      collection_name: "",
      keywords: "",
      description: "",
      creator_id: "",
      credit_line: "",
      copyright_notice: "",
      copyright_status: "",
      right_usage_terms: "",
      customtags: [],
      applyAll: false,
      checkboxes: {
        creator_id: false,
        credit_line: false,
        copyright_notice: false,
        copyright_status: false,
        right_usage_terms: false,
        name: false,
        keywords: false,
        description: false,
      },
    },
    onSubmit: (values) => {
      // Handle form submission with the values
      const modifiedValues = { ...values };

      const formData = new FormData();
      // formData.append("files", modifiedValues.files[0]);
      modifiedValues.files.forEach((element) => {
        formData.append("files", element);
      });
      formData.append("name", modifiedValues.name);
      formData.append("keywords", modifiedValues.keywords);
      formData.append("description", modifiedValues.description);
      formData.append("asset_type_id", modifiedValues.asset_type_id);
      formData.append("asset_category_id", modifiedValues.asset_category_id);
      formData.append(
        "asset_sub_category_id",
        modifiedValues.asset_sub_category_id,
      );
      formData.append("creator_id", modifiedValues.creator_id);
      formData.append("credit_line", modifiedValues.credit_line);
      formData.append("copyright_status", modifiedValues.copyright_status);
      formData.append("copyright_notice", modifiedValues.copyright_notice);
      formData.append("right_usage_terms", modifiedValues.right_usage_terms);
      formData.append("customtags", JSON.stringify(modifiedValues.customtags));
      formData.append("checkboxes", JSON.stringify(modifiedValues.checkboxes));

      if (formik.values.chooseUpload === "new") {
        formData.append("collection_name", modifiedValues.collection_name);
      }
      if (formik.values.chooseUpload === "existing") {
        formData.append("collection_id", modifiedValues.collection_id);
      }

      dispatch(creatAssetApi(formData)).then((res) => {
        if (!res?.error) {
          formik.resetForm();
          formik.setFieldValue("chooseUpload", "");
        }
      });
    },
  });

  return (
    <>
      <PageTitle title="Add Assets" />
      <div className="main">
        <form onSubmit={formik.handleSubmit}>
          <FirstSection formik={formik} />
          {formik?.values.asset_category_id !== "" && (
            <>
              <UploadSection
                formik={formik}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                state={state}
                setState={setState}
                initialValue={initialValue}
              />

              <MetaData
                formik={formik}
                toogle={toogle}
                setToogle={setToogle}
                state={state}
                setState={setState}
                initialValue={initialValue}
              />
              <CustomTagsData
                formik={formik}
                toogle={toogle}
                setToogle={setToogle}
              />
              <Box>
                <Box className="submit_buttons">
                  <CustomButton type="submit">{t("publishNow")}</CustomButton>
                  <Box className="buttonText">
                    <CustomButton
                      onClick={() => {
                        setSchedulePopUp(true);
                      }}
                      disabled={true}
                    >
                      {t("scheduleForLater")}
                    </CustomButton>
                    <Box>{t("toBeDevloped")}</Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </form>
      </div>
      <DialogCommon
        open={schedulePopUp}
        handleClose={() => setSchedulePopUp(false)}
        title={"Schedule For Later"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <ScheduleForLater handleClose={() => setSchedulePopUp(false)} />
      </DialogCommon>
    </>
  );
};
export default AssetsAdd;
