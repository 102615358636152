import { createSlice } from "@reduxjs/toolkit";
import {
  getAllIptcCreators,
  getIptcCreatorsFields,
  getSingleIptcCreator,
  iptcCreatorsAddApi,
  iptcCreatorsDeleteApi,
  iptcCreatorsUpdateApi,
} from "./iptcCreatorActions";

const initialState = {
  iptcCreators: [],
  iptcCreatorSingle: [],
  fieldValues: [],
};

const iptcCreatorsSlice = createSlice({
  name: "iptcCreators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllIptcCreators.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllIptcCreators.fulfilled, (state, action) => {
        state.status = "idle";
        state.iptcCreators = action.payload;
      });
    builder
      .addCase(getSingleIptcCreator.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSingleIptcCreator.fulfilled, (state, action) => {
        state.status = "idle";
        state.iptcCreatorSingle = action.payload;
      });
    builder
      .addCase(iptcCreatorsAddApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(iptcCreatorsAddApi.fulfilled, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(iptcCreatorsUpdateApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(iptcCreatorsUpdateApi.fulfilled, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(iptcCreatorsDeleteApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(iptcCreatorsDeleteApi.fulfilled, (state) => {
        state.status = "idle";
      });
    //Filters
    builder
      .addCase(getIptcCreatorsFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIptcCreatorsFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const iptcCreatorsState = (state) => state?.iptcCreators;

export const iptcCreatorsFieldValueArray = (state) => {
  return state?.iptcCreators?.fieldValues?.creator_data;
};

export default iptcCreatorsSlice.reducer;
