import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "../../../../utils/apiRoutes";
import axiosConfig from "../../../../utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getUserRequests = createAsyncThunk(
  "user/getUserRequests",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetUserRequests}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetUserRequests}?data_limit=all&filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getUserData = createAsyncThunk("user/getUserData", async () => {
  try {
    return await performApiRequest((signal) =>
      axiosConfig.get(apiRoutes.adminGetCurrentUserData, { signal }),
    );
  } catch (error) {
    return { success: false, error: error.response.data };
  }
});

export const deleteUserRequest = createAsyncThunk(
  "user/deleteUserRequest",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.userRequestsBulkDelete, data?.body),
      );

      return dispatch(getUserRequests(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getBusinessRegion = createAsyncThunk(
  "user/getBusinessRegion",
  async (region, { dispatch }) => {
    try {
      return performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetBusinessByRegionId}/${region}?data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const approveUser = createAsyncThunk(
  "user/approveUser",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.userRequestAction, body),
        body.approve ? "User Request Approved!" : "User Request Disapproved",
      );
      dispatch(getUserRequests());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getUserRequestsFields = createAsyncThunk(
  "user/getUserRequestsFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetUserRequestFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetUserRequestFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
