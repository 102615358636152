import { styled } from "@mui/system";

const HomeStyles = styled("div")(({ theme }) => ({
  "@font-face": {
    fontFamily: "Cera Regular",
    src: `url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.eot"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.eot?#iefix") format("embedded-opentype"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.woff2") format("woff2"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.woff") format("woff"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.ttf") format("truetype"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.svg#Cera Regular") format("svg")`,
  },
  ".bannerSlider ": {
    position: "relative",
    background: theme.palette.gray_200.main,
    img: {
      width: "100%",
    },
    ".bannerText": {
      position: "absolute",
      top: "50%",
      left: "50%",
      textAlign: "center",
      transform: "translate(-50%,-50%)",
      fontFamily: "Cera Regular",
      width: "100%",
      maxWidth: "max-content",
      h1: {
        color: theme.palette.white.main,
        fontSize: "50px",
        fontWeight: "400",
        margin: "0 0 15px",
      },
      p: {
        margin: "0",
        color: theme.palette.white.main,
        fontSize: "20px",
      },
    },
  },

  "&.homePageMain": {
    ".heroSectionMain": {
      aspectRatio: "36/13",
      display: "flex",
      ".aboveHeroImage": {
        height: "unset",
      },
    },
    "&.langTest": {
      ".aboveHeroImage": {
        minWidth: "54rem",
        maxWidth: "max-content",
      },
    }
  },
}));

export default HomeStyles;
