import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { _getToken } from "helper/helper";
import { addNotification } from "Layout/features/layoutSlice";
import { baseurlPure } from "utils/baseUrl";
import { routeConfigs } from "utils/routeConfig";
import { connectToSocket } from "helper/webSocketHelper";

export default function App() {
  // global
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = _getToken();

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate(routeConfigs.adminDashboard);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const socket = io(baseurlPure);
    socket.emit("user_connected", token);
    if (socket) {
      socket.on("notification", (notificationMessage) => {
        dispatch(addNotification(notificationMessage));
      });
    }
    return () => {
      const socket = connectToSocket();
      socket.off("connect", () => {
        console.log("disconnected");
      });
    };
    //eslint-disable-next-line
  }, []);

  return <></>;
}
