import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import withoutAuth from "Auth/withoutAuth";
import CommonLayout from "Layout/AuthLayout/CommonLayout";
import { routeConfigs } from "utils/routeConfig";
import { recaptcha_site_key } from "utils/envConst";
import { getCurrentEncodedDate } from "utils/dateUtils";
import CustomButton from "components/Button/CustomButton";
import { forgetPassword } from "../features/userAction";
import { ForgotPasswordSchema } from "utils/validation/validation-schemas/forgotPassword";
import { loginState } from "../features/userAuth";

function UserForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const { loginLoading } = useSelector(loginState);

  const captchaRef = useRef(null);

  const formik = useFormik({
    validationSchema: ForgotPasswordSchema,
    initialValues: {
      email: "",
      reCaptcha: "",
    },
    onSubmit: (values) => {
      const currentBaseDate = getCurrentEncodedDate();
      const body = {
        email: values.email,
        basecurruntdate: currentBaseDate,
      };
      dispatch(forgetPassword(body))
        .then((response) => {
          if (response?.error) {
            setErrorMessage(response?.payload?.data?.message);
            return;
          }
          formik.resetForm();
          setErrorMessage("");
          captchaRef.current.reset();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  return (
    <CommonLayout headingName={t("forgotPassword")}>
      <div className="loginMain">
        <Typography className="forgotPassText center">
          {t("forgotLinkText")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <React.Fragment>
            <Typography color="secondary" className="errorValidation center">
              {(Object.keys(formik?.errors)[0] in formik.touched &&
                Object.values(formik?.errors)[0]) ||
                (errorMessage && errorMessage)}
            </Typography>
            <TextField
              name="email"
              variant="standard"
              value={formik.values.email}
              onChange={(e) => {
                setErrorMessage("");
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              margin="normal"
              placeholder={t("email")}
              type="text"
              fullWidth
            />
            <ReCAPTCHA
              className={"loginRecaptcha"}
              name="reCaptcha"
              sitekey={recaptcha_site_key}
              onChange={(e) => {
                setErrorMessage("");
                formik.setFieldValue("reCaptcha", e);
              }}
              onBlur={formik.handleBlur}
              ref={captchaRef}
            />
            <div className={"formButtons"}>
              {loginLoading ? (
                <CircularProgress size={26} className={"loginLoader"} />
              ) : (
                <CustomButton type>{t("submit")}</CustomButton>
              )}
              <CustomButton
                className={"forgetButton linkBtn"}
                onClick={() => navigate(routeConfigs.userLogin)}
              >
                {t("backToLogin")}
              </CustomButton>
            </div>
          </React.Fragment>
        </form>
      </div>
    </CommonLayout>
  );
}

export default withoutAuth(UserForgotPassword);
