import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";
import Layout from "Layout/AdminLayout/Layout";
import ContactUsPageStyles from "./contactUsPageStyle";
import { userData } from "pages/Admin-Pages/user-administration/features/userSlice";
import { contactUsFormApi } from "./features/contactUsAction";
import { ContactUsSchema } from "utils/validation/validation-schemas/contactUsPageSchema";

const ContactUsPage = () => {
  const user = useSelector(userData);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: user?.[0]?.email,
      message: "",
    },
    validationSchema: ContactUsSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  function handleFormSubmit(values) {
    dispatch(contactUsFormApi(values)).then((res) => {
      if (res?.error) {
        return;
      }
      formik.resetForm();
    });
  }

  return (
    <Layout>
      <ContactUsPageStyles>
        <div className="contactHeading">Contact Us</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="field">
            <label htmlFor="message">Message</label>
            <TextField
              name="message"
              id="message"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            <span className="errorClass">
              {formik.errors.message &&
                formik.errors.message &&
                formik.errors.message}
            </span>
          </div>
          <div>
            <Button type="Submit">Submit</Button>
          </div>
        </form>
      </ContactUsPageStyles>
    </Layout>
  );
};

export default ContactUsPage;
