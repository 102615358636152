import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setupInterceptors } from "./axiosConfig";
import { useDispatch } from "react-redux";

export function InjectAxiosInterceptors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOutProps = {
    navigate,
    dispatch,
  };

  useLayoutEffect(() => {
    setupInterceptors(signOutProps);
  });

  useEffect(() => {
    setupInterceptors(signOutProps);
    //eslint-disable-next-line
  }, [navigate]);

  // not rendering anything
  return null;
}
