import React from 'react'

const SettingIconProfile = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="23.782" height="23.789" viewBox="0 0 23.782 23.789">
  <path id="Icon_ionic-ios-settings" data-name="Icon ionic-ios-settings" d="M26.319,16.391a3.06,3.06,0,0,1,1.963-2.855A12.131,12.131,0,0,0,26.815,10a3.1,3.1,0,0,1-1.245.266,3.054,3.054,0,0,1-2.793-4.3A12.094,12.094,0,0,0,19.246,4.5a3.058,3.058,0,0,1-5.71,0A12.131,12.131,0,0,0,10,5.968a3.054,3.054,0,0,1-2.793,4.3A3,3,0,0,1,5.962,10,12.4,12.4,0,0,0,4.5,13.542a3.059,3.059,0,0,1,.006,5.71,12.131,12.131,0,0,0,1.468,3.536,3.055,3.055,0,0,1,4.032,4.032,12.2,12.2,0,0,0,3.536,1.468,3.052,3.052,0,0,1,5.7,0,12.131,12.131,0,0,0,3.536-1.468,3.058,3.058,0,0,1,4.032-4.032,12.2,12.2,0,0,0,1.468-3.536A3.075,3.075,0,0,1,26.319,16.391ZM16.447,21.34A4.955,4.955,0,1,1,21.4,16.385,4.953,4.953,0,0,1,16.447,21.34Z" transform="translate(-4.5 -4.5)" fill="#041e42"/>
</svg>
  
  )
}

export default SettingIconProfile