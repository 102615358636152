import axios from "axios";
import { baseurl } from "utils/baseUrl";
import { localConfig, retrieveDataFromLocalStorage } from "utils/localStorage";
import LogoutHelper from "helper/logoutHelper";
import { apiRoutes } from "./apiRoutes";
import ToastHelper from "helper/toastHelper";
import i18n from "Assets/i18n/config";

const instance = axios.create({
  // .. where we make our configurations
  baseURL: baseurl,
});

instance.interceptors.request.use(
  (config) => {
    const token = retrieveDataFromLocalStorage(localConfig.userLoginToken);
    const logoutToken = retrieveDataFromLocalStorage(localConfig.logoutToken);
    const language = i18n.language;

    if (token || logoutToken) {
      config.headers.Authorization = token ? token : logoutToken;
    }
    config.headers["Accept-language"] = language;
    return config;
  },
  (error) => Promise.reject(error),
);

export const setupInterceptors = (signOutProps) => {
  const { navigate, dispatch } = signOutProps;
  instance.interceptors.response.use(
    (response) => {
      //handling response
      return response;
    },
    (error) => {
      if (
        error.config.url.includes(apiRoutes.adminLogout) ||
        error.config.url.includes(apiRoutes.adminLogin) ||
        error.config.url.includes(apiRoutes.userRegister)
      ) {
        //in case of faliure of
        //logout api, login api, register api
        //logout api will not run
        if (error.code === "ERR_NETWORK") {
          //logout when there is a network related error
          ToastHelper("error", "Server error");
        }
      } else {
        if (error.code === "ERR_NETWORK") {
          //logout when there is a network related error
          LogoutHelper(dispatch, navigate);
        }
        if (error.response.status === 498 || error.response.status === 401) {
          //logout when token not foud or token is invalid
          LogoutHelper(dispatch, navigate);
        }
      }

      // Returning the Rejected Promise
      return Promise.reject(error);
    },
  );
};
export default instance;
