import React from "react";

const RightSideArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      <g id="sideArrow" transform="translate(-1146 -1246)">
        <rect
          id="Rectangle_141"
          data-name="Rectangle 141"
          width="43"
          height="43"
          rx="4"
          transform="translate(1146 1246)"
        />
        <g
          id="Icon_feather-arrow-right"
          data-name="Icon feather-arrow-right"
          transform="translate(1160 1259.564)"
        >
          <path
            id="Path_12"
            data-name="Path 12"
            d="M7.5,18H23.371"
            transform="translate(-7.5 -10.064)"
            fill="none"
            stroke="#041e42"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M18,7.5l7.936,7.936L18,23.371"
            transform="translate(-10.064 -7.5)"
            fill="none"
            stroke="#041e42"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default RightSideArrowIcon;
