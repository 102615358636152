import React, { useEffect, useState } from "react";
import { Box, Checkbox, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getallCustomTags } from "../Features/addAssetAction";
import { allCustomTagsState } from "../Features/addAssetSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { MetaFileBlock } from "../MetaData/MetaDataStyle";
import Sorting from "utils/sorting";
import { useTranslation } from "react-i18next";

const CustomTagsData = ({ formik, toogle, setToogle }) => {
  const dispatch = useDispatch();
  const tags = useSelector(allCustomTagsState);
  const { t } = useTranslation();

  const [array, setArray] = useState([]);

  useEffect(() => {
    dispatch(getallCustomTags()).then((res) => {
      const initialCustomTags = res?.payload?.custom_tags?.map((category) => ({
        custom_tag_id: category._id,
        custom_tag_data_ids: [],
      }));
      setArray(initialCustomTags);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (e, categoryIndex, optionId, customTagName) => {
    if (e.target.checked) {
      //setting the checked custom tags in keywords field
      formik.setFieldValue(
        "keywords",
        `${formik.values.keywords}${
          formik.values.keywords?.trim().length > 0 ? "," : ""
        }${customTagName}`,
      );
    } else {
      //removing the unchecked custom tag value from the keywords
      if (
        formik.values.keywords?.trim().length > 0 &&
        formik.values.keywords.includes(customTagName)
      ) {
        //if the customtagname is included in the keyword string remove it
        const keyStrArr = formik.values.keywords.split(",");
        const index = keyStrArr.indexOf(customTagName);
        if (index > -1) {
          keyStrArr.splice(index, 1);
        }
        formik.setFieldValue("keywords", keyStrArr.join());
      }
    }

    // Set the value of the number field here:
    const updatedCustomTags = array?.map((tag, i) => {
      if (i === categoryIndex) {
        // Check if the optionId is already present
        const index = tag.custom_tag_data_ids.indexOf(optionId);

        if (index === -1) {
          // If not present, add it
          return {
            ...tag,
            custom_tag_data_ids: [...tag.custom_tag_data_ids, optionId],
          };
        } else {
          // If present, remove it
          const updatedOptions = [...tag.custom_tag_data_ids];
          updatedOptions.splice(index, 1);
          return { ...tag, custom_tag_data_ids: updatedOptions };
        }
      }

      return tag;
    });

    setArray(updatedCustomTags);
    // this is to send only the vales which have ids
    const finalValue = updatedCustomTags?.filter(
      (val) => val.custom_tag_data_ids.length > 0,
    );

    formik.setFieldValue("customtags", finalValue);
  };

  return (
    <MetaFileBlock>
      <Box className="  metaDataClass customTabblock ">
        <Box className="accordingWh">
          <h3>{t("customTags")}</h3>

          <Box className="selectAll">
            <span className="toggleHs">
              {" "}
              {toogle.customtag ? (
                <RemoveCircleOutlineIcon
                  onClick={() => setToogle({ ...toogle, customtag: false })}
                />
              ) : (
                <AddCircleOutlineIcon
                  onClick={() => setToogle({ ...toogle, customtag: true })}
                />
              )}
            </span>
          </Box>
        </Box>
        {toogle.customtag ? (
          <>
            {/* <RemoveCircleOutlineIcon
              onClick={() => setToogle({ ...toogle, customtag: false })}
            /> */}

            <Box className="metaDataFields ">
              {Sorting(tags?.allCustomTags?.custom_tags, "name")?.map(
                (val, index) => {
                  return (
                    <Box
                      key={val?.index || val?._id || index}
                      className="addNewDivSelect"
                    >
                      <span key={`${val?.index} key`} variant="standard">
                        {val?.name}
                      </span>
                      <Select
                        name={`customtags[${index}].custom_tag_data_ids`}
                        key={val?.index}
                        multiple
                        className="permission-role-select"
                        //   value={array?.[index]?.custom_tag_data_ids || []}
                        value={[]}
                      >
                        {Sorting(val?.custom_tag_datas, "name")?.map(
                          (value, ind) => {
                            return (
                              <MenuItem key={ind + 1} value={value?._id}>
                                <Checkbox
                                  key={ind + 1}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      index,
                                      value?._id,
                                      value?.name,
                                    )
                                  }
                                  checked={array?.[
                                    index
                                  ]?.custom_tag_data_ids?.includes(value?._id)}
                                />
                                {value?.name}
                              </MenuItem>
                            );
                          },
                        )}
                      </Select>
                    </Box>
                  );
                },
              )}
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </MetaFileBlock>
  );
};

export default CustomTagsData;
