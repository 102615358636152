import { styled } from "@mui/system";

export const CsvPopupStyle = styled("div")(({ theme }) => ({
  ".Csv_main": {
    display: "flex",
    margin: "0 -25px -20px",

    ".commanCsvBlock": {
      maxWidth: "50%",
      padding: "30px",
      flex: "0 0 50%",
      "> svg": {
        width: "60px",
        height: "60px",
        path: {
          fill: theme.palette.primary.main,
        },
      },

      ".addNewDivSelect": {
        marginBottom: "20px",
        width: "100%",
        maxWidth: "100%",

        ".MuiFormControl-root": {
          width: "100%",
        },
      },

      ".chooseFile": {
        position: "relative",
        marginBottom: "10px",

        label: {
          position: "absolute",
          display: "inline-block",
          top: "3px",
          right: "0",
          fontSize: "13px",
          pointerEvents: "none",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: "left",
          maxWidth: "calc(100% - 85px)",
          paddingLeft: "10px",
        },

        "input#csvFile": {
          appearance: "none",
          paddingLeft: "25px",
          textTransform: "capitalize",
          opacity: 0,
        },

        "& ::-webkit-file-upload-button": {
          visibility: "hidden",
        },

        span: {
          position: "absolute",
          left: "-3px",
          background: theme.palette.lightGray.main,
          color: theme.palette.primary.main,
          padding: "0 9px",
          fontSize: "12px",
          width: "auto",
          height: "26px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "0",
          fontWeight: "600",
          pointerEvents: "none",
          cursor: "pointer",
        },
      },

      ".csvIcons": {
        border: `1px solid ${theme.palette.lightGray.main}`,
        maxWidth: "77px",
        height: "77px",
        overflow: "hidden",
        padding: "8px",

        img: {
          width: "100%",
          maxWidth: "100%",
          objectFit: "cover",
        },
      },

      h3: {
        margin: "15px 0 0",
      },

      p: {
        padding: "0",
        textAlign: "left",
        margin: "5px 0 15px",
      },

      button: {
        width: "100%",
        margin: "15px 0 0",

        "+ button": {
          background: theme.palette.white.main,
          color: theme.palette.primary.main,
        },
      },
    },

    ".upload_Csv": {
      background: theme.palette.lightBlue.main,

      ".csvIcons": {
        background: theme.palette.white.main,
      },
    },

    "&:is(.addEditCategory)": {
      ".commanCsvBlock": {
        width: "100%",
        maxWidth: "100%",
        flex: "0 0 100%",
        padding: "20px 24px",

        ".request-common": {
          marginBottom: "7px",
        },

        ".popup-button-outer": {
          margin: "0 0 0 auto",
        },

        ".categoryImageInput": {
          display: "grid",
          alignItems: "baseline",
          justifyContent: "center",
          gridTemplateColumns: "1fr 2fr",
          gap: "0 30px",

          p: {
            gridRow: "1/5",
          },

          "span.errorClass": {
            marginBottom: "10px",
          },

          ".chooseFile": {
            position: "relative",
            marginBottom: "10px",
            height: "40px",
            borderRadius: "5px",
            border: `1px solid ${theme.palette.lightGray.main}`,

            "~ img": {
              marginBottom: "10px",
            },

            'input[type="file"]': {
              position: "relative",
              top: "6px",
              left: "7px",
              paddingLeft: "20px",
              width: "220px",
              overflow: "hidden",
              opacity: "0",
            },
            label: {
              top: "8px",
              paddingLeft: "20px",
            },
          },

          span: {
            left: "5px",
            top: "5px",
          },
        },

        ".image_class": {
          position: "relative",
          maxWidth: "340px",

          ".uploadCropImage": {
            height: "200px",
          },

          "p.name_image_edit": {
            fontSize: "12px",

            "~ button": {
              top: "18px",
              right: "11px",
            },
          },

          img: {
            // objectFit: "cover",
            objectFit: "contain",
            height: "unset",
            // aspectRatio: "19/12",
            width: "97%",
            maxWidth: "330px",
          },

          button: {
            position: "absolute",
            right: "-7px",
            background: theme.palette.primary.main,
            color: theme.palette.white.main,
            padding: "3px",
            top: "-5px",
            width: "25px",
            height: "25px",
            zIndex: "9;",
          },
        },

        button: {
          width: "100%",
          margin: "0 7px",
          maxWidth: "max-content",

          "+ button": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,

            "&:hover": {
              backgroundColor: theme.palette.white.main,
              color: theme.palette.primary.main,
            },
          },
        },

        ".categoryImageName": {
          maxWidth: "100%",
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "1fr 2fr",
          gap: "0 30px",

          p: {
            margin: "5px 0 15px",
            gridRow: "1/3",
          },
        },
      },
    },
  },
}));
