import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import {
  getCuratedGalleries,
  updateGalleryNameApi,
} from "../../features/curatedGalleryAction";
import AddNewLinkPopup from "./AddNewLinkPopup/AddNewLinkPopup";
import LinksDragDrop from "./LinksDragDrop/LinksDragDrop";

const GalleryColumn = (props) => {
  const { listData, title, index, id, categoryId } = props;
  const dispatch = useDispatch();

  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [editColTitle, setEditColTitle] = useState(false);
  const [colTitle, setColTitle] = useState(title);
  const [alphaNumErrorEdit, setAlphaNumErrorEdit] = useState("");

  function handleAddPageToColumn() {
    setOpenAddPopup(true);
  }

  function handleColumnTitleChange(e) {
    const value = e.target.value;

    if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setAlphaNumErrorEdit(validationMessages.noConsecutiveDoubleSpaces);
    } else if (value.length <= 50) {
      if (value.trim() === "") {
        setAlphaNumErrorEdit(validationMessages.pleaseEnterName);
        setColTitle(value);
      } else {
        setAlphaNumErrorEdit("");
        setColTitle(value);
      }
    }
    //  else if (!alphaRegex.test(value)) {
    //   setAlphaNumErrorEdit(validationMessages.onlyAlphabets);
    // }
    else if (value.length > 30) {
      setAlphaNumErrorEdit(validationMessages.tooLong);
    }
  }

  function updateColumnTitle() {
    const body = {
      name: colTitle,
    };
    const data = { body, id };
    if (alphaNumErrorEdit === "") {
      dispatch(updateGalleryNameApi(data)).then((res) => {
        if (res.error) {
          return false;
        }
        dispatch(getCuratedGalleries(categoryId));
      });
    } else {
      setColTitle(title);
    }
    setAlphaNumErrorEdit();
    setEditColTitle(false);
  }

  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`dragColumnMain ${
            snapshot.isDragging ? "columnDraggingOn" : "columnDraggingOff"
          }`}
        >
          <div className="hedingCardDrag">
            <div isDragging={snapshot.isDragging} className="columnHeader">
              <div
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
                className="columnTitle"
              >
                {editColTitle ? (
                  <div>
                    <input
                      placeholder={"Enter Column Name"}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="title"
                      value={colTitle}
                      onChange={handleColumnTitleChange}
                      onBlur={updateColumnTitle}
                      autoFocus={true}
                      onKeyDown={(e) => {
                        if (e.code === "NumpadEnter" || e.code === "Enter")
                          updateColumnTitle();
                      }}
                    />
                  </div>
                ) : (
                  <h2
                    onDoubleClick={() => {
                      setEditColTitle(true);
                    }}
                  >
                    {title}
                  </h2>
                )}
              </div>

              <div className="addPageToColumn">
                <IconButton onClick={handleAddPageToColumn}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            {editColTitle && (
              <span className="errorClass">{alphaNumErrorEdit}</span>
            )}
          </div>
          <LinksDragDrop
            listId={id}
            listType="List"
            listData={listData}
            internalScroll={props.isScrollable}
            isCombineEnabled={Boolean(props.isCombineEnabled)}
            {...props}
          />
          {openAddPopup && (
            <AddNewLinkPopup
              openAddPopup={openAddPopup}
              setOpenAddPopup={setOpenAddPopup}
              id={id}
              categoryId={categoryId}
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default GalleryColumn;
