const getCroppedImg = async (imageFile, crop, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.src = URL.createObjectURL(imageFile);

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject(new Error("2D context is not supported"));
          return;
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const canvasWidth = crop.width * scaleX;
        const canvasHeight = crop.height * scaleY;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        ctx.drawImage(
          image,
          cropX,
          cropY,
          canvasWidth,
          canvasHeight,
          0,
          0,
          canvasWidth,
          canvasHeight,
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Blob is empty"));
            return;
          }
          blob.name = fileName;
          resolve(blob);
        }, "image/jpeg");
      };

      image.onerror = (error) => {
        reject(new Error("Failed to load image"));
      };
    } catch (error) {
      console.error("Error cropping image:", error.message);
      reject(error);
    }
  });
};

export default getCroppedImg;
