import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import LinkItem from "./LinkItem";

const LinksList = (props) => {
  const {
    listId,
    ignoreContainerClipping,
    isDropDisabled,
    isCombineEnabled,
    listData,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={"LINKS"}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          <div>
            <div ref={dropProvided.innerRef}>
              {listData?.map((link, index) => (
                <Draggable
                  key={link.title}
                  draggableId={link.title}
                  index={index}
                >
                  {(dragProvided, dragSnapshot) => (
                    <LinkItem
                      key={link.title}
                      link={link}
                      isDragging={dragSnapshot.isDragging}
                      isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                      provided={dragProvided}
                      {...props}
                    />
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default LinksList;
