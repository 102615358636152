import { createSlice } from "@reduxjs/toolkit";
import {
  addCuratedGalleryLinkApi,
  createCuratedGalleryApi,
  curatedGalleryUpdateIndexesApi,
  curatedGalleryUpdateLinkTargetApi,
  deleteCuratedGalleryApi,
  getCuratedGalleries,
  updateCuratedLinkApi,
} from "./curatedGalleryAction";

const initialState = {
  curatedGalleryData: [],
  loader: false,
};

const curatedGallerySilce = createSlice({
  name: "curatedGallerySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //createCategoryApi
    builder
      .addCase(createCuratedGalleryApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(createCuratedGalleryApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(createCuratedGalleryApi.fulfilled, (state) => {
        state.loader = false;
      });
    //getCuratedGalleries
    builder
      .addCase(getCuratedGalleries.pending, (state) => {
        state.loader = true;
      })
      .addCase(getCuratedGalleries.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getCuratedGalleries.fulfilled, (state, action) => {
        state.loader = false;
        state.curatedGalleryData = action.payload;
      });
    //addCuratedGalleryLinkApi
    builder
      .addCase(addCuratedGalleryLinkApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(addCuratedGalleryLinkApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(addCuratedGalleryLinkApi.fulfilled, (state) => {
        state.loader = false;
      });
    //curatedGalleryUpdateIndexesApi
    builder
      .addCase(curatedGalleryUpdateIndexesApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(curatedGalleryUpdateIndexesApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(curatedGalleryUpdateIndexesApi.fulfilled, (state) => {
        state.loader = false;
      });
    //update footer sub menu data
    builder
      .addCase(updateCuratedLinkApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateCuratedLinkApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(updateCuratedLinkApi.rejected, (state) => {
        state.loader = false;
      });
    //curatedGalleryUpdateLinkTargetApi
    builder
      .addCase(curatedGalleryUpdateLinkTargetApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(curatedGalleryUpdateLinkTargetApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(curatedGalleryUpdateLinkTargetApi.rejected, (state) => {
        state.loader = false;
      });
    builder
      .addCase(deleteCuratedGalleryApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteCuratedGalleryApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(deleteCuratedGalleryApi.rejected, (state) => {
        state.loader = false;
      });
  },
});

export const curatedGalleriesData = (state) =>
  state?.curatedGallerySlice?.curatedGalleryData?.curatedGallery;

export const curatedGalleryLoading = (state) =>
  state?.curatedGallerySlice?.loader;

export default curatedGallerySilce.reducer;
