import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../../../utils/axiosConfig";
import { apiRoutes } from "../../../../utils/apiRoutes";
import performApiRequest from "helper/apiRequestHelper";

export const getAllUserApi = createAsyncThunk(
  "allUser/getAllUser",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = apiRoutes.adminAllUsers;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminAllUsers}?filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteAllUser = createAsyncThunk(
  "user/deleteAllUser",
  async (userIds, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.adminAllUsersBulkDelete, userIds?.body),
        userIds?.ids?.length > 1 ? "Users Deleted!" : "User Deleted!",
      );

      return dispatch(getAllUserApi(userIds?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const allUserEnableDisableApi = createAsyncThunk(
  "user/allUserEnableDisableApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminAllUsersStatusChange}/${body?.id}`,
            body,
          ),
        "User updated successfully",
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const allUserAddUserApi = createAsyncThunk(
  "user/allUserAddUserApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminAllUserAddUser, body),
        "The details has been saved",
      );
      return dispatch(getAllUserApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const createUsersApi = createAsyncThunk(
  "user/createUsersApi",
  async (formData) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminCreateUsers, formData),
        "User uploading started",
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const allUserDetailApi = createAsyncThunk(
  "user/allUserDetailApi",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminAllUserDetail}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getUsersByRoleApi = createAsyncThunk(
  "user/getUsersByRoleApi",
  async (roleID) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminGetUsersByRole}/${roleID}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
export const allUserEditUserApi = createAsyncThunk(
  "user/allUserEditUserApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminAlluserEditUserDetail}/${body?.id}`,
            body,
          ),
        "User updated successfully",
      );
      //    return dispatch(getAllUserApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const allUserApproveUserApi = createAsyncThunk(
  "user/allUserApproveUserApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(`${apiRoutes.adminAllUserUpdateStatus}`, body),
        "User approved successfully",
      );
      return dispatch(getAllUserApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getUserFields = createAsyncThunk(
  "user/getUserFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetUserFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetUserFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const userPermissionCategoriesApi = createAsyncThunk(
  "user/userPermissionCategoriesApi",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminUserPermissionCategories}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const rolesAndPermissionApi = createAsyncThunk(
  "user/rolesAndPermissionApi",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminUserRolesAndPermissions}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
