import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, FormControl, Select, MenuItem, Box } from "@mui/material";
import { CsvPopupStyle } from "./CsvPopupStyle";
import { downloadCSV } from "utils/arrayToCsv";
import {
  createUsersApi,
  getUsersByRoleApi,
} from "../../features/allUserAction";
import { allRoleUsers } from "../../features/allUserSlice";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import { validationMessages } from "utils/validation/validationUtils";
import UploadIcon from "components/Icons/UploadIcon/UploadIcon";
import DownloadIcon from "components/Icons/DownloadIcon/DownloadIcon";
import Sorting from "utils/sorting";
import { useTranslation } from "react-i18next";

const CsvPopup = ({ open, setOpen, userRoleData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRoleDataAll = useSelector(allRoleUsers);
  const [downloadRole, setDownloadRole] = useState("");
  const [uploadRole, setUploadRole] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const [roleSelectErrorMessage, setRoleSelectErrorMessage] = useState("");
  const [roleUploadErrorMessage, setRoleUploadErrorMessage] = useState("");
  const [fileMessage, setFileMessage] = useState("");
  const [fileName, setFileName] = useState(t("noFileChosen"));

  function handleRoleChange(e) {
    setRoleSelectErrorMessage(false);
    setDownloadRole(e.target.value);
    dispatch(getUsersByRoleApi(e.target.value));
  }

  function handleDownloadTemplate() {
    const templateObjArr = [
      {
        "First Name": "Jhon",
        "Last Name": "Doe",
        Email: "example1@yopmail.com",
        "Secondary Group": "",
        "Primary Group": "",
        "Tertiary Group": "",
      },
      {
        "First Name": "Example",
        "Last Name": "Name",
        Email: "example2@yopmail.com",
        "Secondary Group": "",
        "Primary Group": "",
        "Tertiary Group": "",
      },
    ];
    downloadCSV(templateObjArr, "tcdamUsersTemplateCsv");
  }

  function handleUploadChange(e) {
    setFileMessage("");
    const file = e.target.files[0];

    //checking if csv file is blank
    const reader = new FileReader();
    reader.onload = () => {
      const lines = reader.result.split("\n");
      let isCSVBlank = true;
      for (const line of lines) {
        if (line.trim().length > 1) {
          isCSVBlank = false;
          break;
        }
      }
      if (isCSVBlank) {
        setFileMessage("The CSV is blank.");
      }
    };
    reader.readAsText(file);

    const maxFileSize = 5 * 1024 * 1024; // 5mB
    if (file?.size > maxFileSize) {
      setFileMessage("The file exceeds the maximum file size of 5MB");
      return;
    }
    setUploadedFile(file);

    if (file) {
      setFileName(file?.name);
    } else {
      setFileName(t("noFileChosen"));
    }
  }

  function handleUploadRecords() {
    if (uploadRole === "" || uploadRole === undefined || uploadRole === null) {
      setRoleUploadErrorMessage(validationMessages.pleaseSelectUserRole);
      return;
    }
    if (
      uploadedFile === "" ||
      uploadedFile === undefined ||
      uploadedFile === null
    ) {
      setFileMessage(validationMessages.selectFile);
      return;
    }
    if (fileMessage !== "") {
      return;
    }
    const formData = new FormData();
    formData.append("csvFile", uploadedFile);
    formData.append("role_id", uploadRole);
    dispatch(createUsersApi(formData));
    setOpen(false);
  }

  function handleDownloadRecords() {
    if (
      downloadRole === "" ||
      downloadRole === null ||
      downloadRole === undefined
    ) {
      setRoleSelectErrorMessage(validationMessages.pleaseSelectUserRole);
      return;
    }
    const downloadRoleDataArray = [];
    if (userRoleDataAll?.users?.length < 1) {
      downloadRoleDataArray.push({ "No record available": "" });
      downloadCSV(downloadRoleDataArray, "tcdamRoleUsers");
      return;
    }
    userRoleDataAll?.users?.map((data) => {
      const downloadRoleDataObject = {};
      downloadRoleDataObject["First Name"] = data?.first_name;
      downloadRoleDataObject["Last Name"] = data?.last_name;
      downloadRoleDataObject.Email = data?.email;
      downloadRoleDataObject.Role =
        data?.userRoles?.[0]?.role || data?.userRoles?.role;
      downloadRoleDataObject["Sub-Group"] =
        data?.businesses_data?.[0]?.name || data?.businesses_data?.name || "";
      downloadRoleDataObject.Group =
        data?.regions_data?.[0]?.name || data?.regions_data?.name || "";
      downloadRoleDataObject.Department =
        data?.department_data?.[0]?.name || data?.department_data?.name || "";
      downloadRoleDataArray.push(downloadRoleDataObject);
      return downloadRoleDataObject;
    });
    downloadCSV(downloadRoleDataArray, "tcdamRoleUsers");
  }

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={() => setOpen()}
      title={t("uploaddownloadCsvTittle")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main">
          <Box className="download_Class commanCsvBlock">
            <DownloadIcon className="downloadIcon" />
            <h3 className="text">{t("dowloadCsv")}</h3>
            <p>{t("dowloadCsvText")}</p>
            <Box className="addNewDivSelect">
              <FormControl
                variant="outlined"
                className={"formControl"}
                size="small"
              >
                <Select
                  className="permission-role-select text_capitalize"
                  onChange={handleRoleChange}
                  name={"role"}
                  defaultValue={"userrole"}
                >
                  <MenuItem value={"userrole"} className="select-none">
                    {t("selectUserRole")}
                  </MenuItem>
                  {Sorting(userRoleData, "role")?.map((val, index) => {
                    return (
                      <MenuItem
                        key={index + 1}
                        value={val?._id}
                        className="text_capitalize"
                      >
                        {val?.role}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span className="errorClass">{roleSelectErrorMessage}</span>
            </Box>
            <CustomButton onClick={handleDownloadRecords}>
              {t("downloadRecords")}
            </CustomButton>
            <CustomButton onClick={handleDownloadTemplate}>
              {t("downloadTemplate")}
            </CustomButton>
          </Box>
          <Divider orientation="vertical" />
          <Box className="upload_Csv commanCsvBlock">
            <UploadIcon className="uploadClass" />
            <h3 className="text">{t("uploadCsv")}</h3>
            <p>{t("uploadCsvText")}</p>
            <Box className="addNewDivSelect">
              <FormControl
                variant="outlined"
                className={"formControl"}
                size="small"
              >
                <Select
                  className="permission-role-select text_capitalize"
                  name={"role"}
                  onChange={(e) => {
                    setUploadRole(e.target.value);
                    setRoleUploadErrorMessage("");
                  }}
                  defaultValue={"userrole"}
                >
                  <MenuItem value={"userrole"} className="select-none">
                    {t("selectUserRole")}
                  </MenuItem>
                  {Sorting(userRoleData, "role")?.map((val, index) => {
                    return (
                      val?.enabled && (
                        <MenuItem
                          key={index + 1}
                          value={val?._id}
                          className="text_capitalize"
                        >
                          {val?.role}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </FormControl>
              <span className="errorClass">{roleUploadErrorMessage}</span>
            </Box>
            <p> {t("selectCsvText")}</p>
            <Box className="chooseFile">
              <input
                type="file"
                id="csvFile"
                name="csvFile"
                accept=".csv, text/csv"
                onChange={handleUploadChange}
              />
              <label>{fileName}</label>
              <span>{t("chooseFile")}</span>
            </Box>
            <span className="errorClass">{fileMessage}</span>
            <CustomButton onClick={handleUploadRecords}>
              {t("upload")}
            </CustomButton>
            <CustomButton onClick={() => setOpen(false)}>
              {t("cancel")}
            </CustomButton>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default CsvPopup;
