import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .max(300, validationMessages.tooLong)
    .required(validationMessages.pleaseEnterEmail)
    .matches(
      validationRegex.checkForValidEmail,
      validationMessages.emailNotVaid,
    ),
  password: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterPassword),
  reCaptcha: Yup.string().required(validationMessages.pleaseEnterCaptcha),
});
