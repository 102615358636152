import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import {
  customTagUpdateApi,
  customTagdeleteApi,
  customtagEditApi,
  getCustomTags,
  getCustomTagsFields,
} from "./features/customTagAction";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import {
  allCustomtagNames,
  customTagsFieldValueArray,
} from "./features/customTagSlice";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import ToggleSwitch from "pages/Admin-Pages/AdminComponents/ToggleSwitch/ToggleSwitch";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Admin-Pages/AdminComponents/CustomComponentfilter/CustomComponentFilter";
import { createCustomtag } from "./features/customTagAction";
import CustomButton from "components/Button/CustomButton";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import { taxonomyTabs } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import CommonGrid from "components/Grid/CommonGrid";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import DialogForDownload from "./ScopeOfWork/Components/CsvPopup/DialogForDownload";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { useTranslation } from "react-i18next";

const CustomTags = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const fieldValueData = useSelector(customTagsFieldValueArray); //filter
  const customTagData = useSelector(allCustomtagNames);
  const inputRef = useRef(null);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "name",
      headerName: t("customTags"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (props) => {
        return (
          <Box key={props?.value} className="customTagCellMain d-flex">
            <Box>{props?.value}</Box>
            <Tooltip title={t("openTag")} placement="top-start">
              <IconButton
                onClick={() => {
                  navigate(`scope-of-work/${props?.data?._id}`);
                }}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editType,
      },
      cellClass: "editableCell customTagLink",
      editable: true,
    },
    {
      field: "visibility_enabled",
      headerName: t("visibility"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "visibility_enabled",
          dispatchName: "visibility_enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"visibility_enabled"}
                sortBy={"visibility_enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                handleSwitchChange(props);
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              setSelectedRows([]);
              setRowsToBeDeleted([props?.data?._id]);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  };

  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);

  const [errorState, setErrorState] = useState({
    errorText: "",
  });

  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [dataState, setDataState] = useState(initialDataState); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  const [dataPost, setDataPost] = useState({
    name: "",
  });

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getCustomTags(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    resetErrorStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(customTagData?.applied_filters);

    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = customTagData?.[commonKey]),
    );

    setRowData(
      customTagData?.custom_tags?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        visibility_enabled: tableDataObj?.visibility_enabled,
        other_table_data: commonDataObj,
      })),
    );
  }, [customTagData]);

  const resetErrorStates = () => {
    setErrorState({ ...errorState, errorText: "" });
  };

  const handleSwitchChange = useCallback(async (props) => {
    gridRef.current.api.forEachNode((rowNode) => {
      if (rowNode?.data?._id !== props?.data?._id) {
        return false;
      }

      //updating the ui
      //by toggling switchvalue
      rowNode.setDataValue("visibility_enabled", !props?.value);

      const modifiedValues = {
        body: {
          visiable: !props?.value,
        },
        id: props.data?._id,
      };

      let modifiedDataState = dataState;

      if (props?.data?.other_table_data) {
        const other_data = props?.data?.other_table_data;
        modifiedDataState = modifyDataState(other_data);
      }

      dispatch(customTagUpdateApi(modifiedValues)).then((resp) => {
        if (resp.error) {
          //reverting the change in case of api error
          dispatch(getCustomTags(modifiedDataState));
          return "error";
        }
      });
    });
    //eslint-disable-next-line
  }, []);

  function editType(data) {
    const body = {
      ids: data?.dataObj?._id || data?.ids,
      name: data?.updatedValue || data?.name?.trim(),
    };

    if (body?.name === "") {
      return false;
    }

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = {
      body,
      dataState: modifiedDataState,
    };

    dispatch(customtagEditApi(dataBody)).then((res) => {
      if (res?.error) {
        dispatch(getCustomTags(modifiedDataState));
        return;
      }
      resetErrorStates();
    });
  }

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      customTagData?.currentPage,
      customTagData?.totalDocuments,
      rowsToBeDeleted?.length,
      customTagData?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(customTagdeleteApi(dataBody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(dataBody.dataState);
    });

    setOpenDelete(false);
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handleSubmit = () => {
    setErrorState({
      errorText: "",
    });
    let hasErrors = false;
    const trimmedData = dataPost?.name?.trim();
    if (trimmedData === "") {
      setErrorState({
        ...errorState,
        errorText: validationMessages.pleaseEnterCustomTag,
      });
      hasErrors = true;
    } else if (trimmedData.length > 50) {
      setErrorState({
        ...errorState,
        errorText: validationMessages.tooLong,
      });
      hasErrors = true;
    } else if (!validationRegex?.checkForDoubleSpaces.test(trimmedData)) {
      setErrorState({
        ...errorState,
        errorText: validationMessages.noConsecutiveDoubleSpaces,
      });
      hasErrors = true;
    } else if (!validationRegex.alphanumeric.test(trimmedData)) {
      setErrorState({
        ...errorState,
        errorText: validationMessages.alphanumericOnly,
      });
      hasErrors = true;
    }

    if (!hasErrors) {
      const body = {
        name: trimmedData,
      };
      dispatch(createCustomtag(body)).then((res) => {
        if (res?.error) {
          return;
        }
        setDataPost({ ...dataPost, name: "" });
        setDataState({
          ...dataState,
          ...initialDataState,
        });
        inputRef.current.value = "";
      });
    }
  };

  const settingNewCustomTag = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (inputRef.current !== null) {
      inputRef.current.value = value;
      setDataPost({ ...dataPost, name: value });
    }
    if (value.length > 50) {
      setErrorState({
        ...errorState,
        errorText: validationMessages.tooLong,
      });
    } else if (!alphaNumericRegex.test(value)) {
      setErrorState({
        ...errorState,
        errorText: validationMessages?.alphanumericOnly,
      });
    } else if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setErrorState({
        ...errorState,
        errorText: validationMessages?.noConsecutiveDoubleSpaces,
      });
    } else {
      resetErrorStates();
    }
  };

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getCustomTagsFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      {param?.id ? (
        <Outlet />
      ) : (
        <>
          <PageTitle title={t("taxonomy")} />
          <TabsComponent tabsDataArray={taxonomyTabs} />
          <Box className="main">
            <Box className="second_section">
              <Box spacing={4} className={"userReq-table newTableBox"}>
                <Box xs={12}>
                  <Box className="fieldTableMain">
                    <Box className="fieldTableHeader">
                      <Box className="table-upper customeBar_ assestType assetCategory">
                        <Box className="leftSideInputs">
                          <Grid container className={`addNew`} spacing={4}>
                            <Box className="addNewDiv">
                              <TextField
                                placeholder={t("createCustomTag")}
                                size="small"
                                variant="outlined"
                                onKeyDown={(e) => {
                                  if (
                                    e.code === "Enter" ||
                                    e.code === "NumpadEnter"
                                  )
                                    handleSubmit();
                                }}
                                onChange={settingNewCustomTag}
                                className="addNewText"
                                inputRef={inputRef}
                              />
                            </Box>
                            <Box className={"addNewDiv1"}>
                              <CustomButton
                                variant="outlined"
                                onClick={handleSubmit}
                              >
                                {t("save")}
                              </CustomButton>
                            </Box>
                            <span className="errorClass">
                              {errorState?.errorText}
                            </span>
                          </Grid>
                          <PaginationLimitDropDown
                            dataState={dataState}
                            setDataState={setDataState}
                            setSelectedRows={setSelectedRows}
                          />
                        </Box>
                      </Box>
                      <AgViewColumn
                        colDefs={colDefs}
                        setColDefs={setColDefs}
                        gridRef={gridRef}
                        dispatch={dispatch}
                        settingType={settingType}
                        dataState={dataState}
                      />
                    </Box>
                    <CommonGrid
                      commonGridObj={commonGridObj}
                      selectedRows={selectedRows}
                      setOpenDelete={setOpenDelete}
                      setRowsToBeDeleted={setRowsToBeDeleted}
                      setSelectedRows={setSelectedRows}
                      isLoading={isLoading}
                      totalPages={customTagData?.totalPages}
                      handlePageChange={handlePageChange}
                      currentPage={dataState?.page}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <DialogForDownload
              openDelete={openDelete}
              setOpenDelete={setOpenDelete}
              rowsToBeDeleted={rowsToBeDeleted}
              handleDelete={handleDelete}
            />
          </Box>
        </>
      )}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default CustomTags;
