import { createSlice } from "@reduxjs/toolkit";
import {
  assetCategoryEditApi,
  assetCategoryEnableDisableApi,
  assetsCategorydeleteApi,
  countActiveCategoryApi,
  createCategoryApi,
  getAllAssetCategoryApi,
  getAssetCategoryApi,
  getAssetCategoryFields,
} from "./assetCategoryAction";

const initialState = {
  assetCategory: [],
  assetCategoryActiveCount: "",
  fieldValues: [],
  loadingState: false,
};

const assetCategorySlice = createSlice({
  name: "assetCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetCategoryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAssetCategoryApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.assetCategory = action.payload;
      });

    builder
      .addCase(getAllAssetCategoryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllAssetCategoryApi.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(assetCategoryEnableDisableApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assetCategoryEnableDisableApi.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(assetsCategorydeleteApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assetsCategorydeleteApi.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(createCategoryApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(createCategoryApi.fulfilled, (state, action) => {
        state.loadingState = false;
      })
      .addCase(createCategoryApi.rejected, (state, action) => {
        state.loadingState = false;
      });

    builder
      .addCase(assetCategoryEditApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(assetCategoryEditApi.fulfilled, (state, action) => {
        state.loadingState = false;
      })
      .addCase(assetCategoryEditApi.rejected, (state, action) => {
        state.loadingState = false;
      });

    builder
      .addCase(countActiveCategoryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(countActiveCategoryApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.assetCategoryActiveCount = action.payload;
      });

    builder
      .addCase(getAssetCategoryFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAssetCategoryFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const assetCategoryState = (state) =>
  state?.assetCategory?.assetCategory;

export const assetCategoryFieldValueArray = (state) =>
  state?.assetCategory?.fieldValues?.asset_categories;

export const assetCategoryLoadingState = (state) =>
  state?.assetCategory?.loadingState;

export default assetCategorySlice.reducer;
