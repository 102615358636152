import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  getHierarchyofPagesCms,
  updateFooterMenuIndexApi,
} from "../features/pagesCmsAction";
import PageColumn from "./components/PageColumn";
import { deepCopy } from "helper/deepCopyFunction";
import { pagesCmsState } from "../features/pagesCmsSlice";
import FooterMenuStyles from "./footerMenuStyles";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";

const FooterMenu = () => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const orderedPages = useSelector(pagesCmsState);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getHierarchyofPagesCms());
  }, [dispatch]);

  useEffect(() => {
    if (orderedPages?.updatedpages?.length > 0) {
      const pagesData = deepCopy(orderedPages?.updatedpages);
      pagesData?.sort((prev, next) => prev.display_index - next.display_index);
      setColumns(pagesData);
    }
  }, [orderedPages]);

  async function handleRemoveChild(link, colId) {
    const copiedCols = deepCopy(columns);
    //getting the array of lists from where the element will be removed
    const listArray = copiedCols.filter(
      (colData) => colData?._id === colId,
    )?.[0]?.FooterPagesData;

    //also find the index from where the source list array is found
    const souceListArrayIndex = columns.findIndex(
      (colData) => colData?._id === colId,
    );

    //finding the index of the item in the list
    const linkIndex = listArray.findIndex((a) => a?.title === link?.title);

    listArray?.splice(linkIndex, 1);

    copiedCols[souceListArrayIndex].FooterPagesData = listArray;

    updateFooterDetails(copiedCols);

    return;
  }

  function updateFooterDetails(details) {
    setColumns(details);
    const finalDetails = details?.map((data, index) => {
      const obj = { ...data };
      obj.footer_menu_id = data?._id;
      obj.display_index = index;
      obj.individual_page_id = data?.FooterPagesData?.map(
        (indiData, indiIndex) => {
          return {
            display_title: indiData?.title,
            _id: indiData?.link_id,
            pageId: indiData?._id,
            target_blank: indiData?.target_blank,
          };
        },
      );

      return obj;
    });

    const body = { detail: finalDetails };
    dispatch(updateFooterMenuIndexApi(body)).then((res) => {
      if (res?.error) return;
      dispatch(getHierarchyofPagesCms());
    });
  }

  function onDragEnd(result) {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      //copying the array
      const arr = [...columns];

      //finding the element we have to move
      const element = arr[source.index];

      //remove the element from its source position
      arr.splice(source.index, 1);

      //puting the element at its destination position
      arr.splice(destination.index, 0, element);

      //updating the result
      updateFooterDetails(arr);
      return;
    }

    //else reordering lists inside the column

    const clonedArray = deepCopy(columns); //clone the array
    const sourceColId = source?.droppableId; //column Id of source
    const sourceListIndex = source?.index; //list index of source
    const destinationColId = destination?.droppableId; //column Id of destination
    const destinationListIndex = destination?.index; //list index of destination

    //getting the array of lists from where the element will be removed
    const sourceListArray = columns.filter(
      (colData) => colData?._id === sourceColId,
    )?.[0]?.FooterPagesData;

    //also find the index from where the source list array is found
    const souceListArrayIndex = columns.findIndex(
      (colData) => colData?._id === sourceColId,
    );

    //getting the element that we have to move
    const elementToMove = sourceListArray?.[sourceListIndex];

    /*---removing the element from its source position---*/

    //copying the inner list array to make changes
    const dupSourceListArray = deepCopy(sourceListArray);

    //removing the element from innerlist
    dupSourceListArray?.splice(sourceListIndex, 1);

    //if change is in the same list do it here
    if (sourceColId === destinationColId) {
      dupSourceListArray?.splice(destinationListIndex, 0, elementToMove);
      clonedArray[souceListArrayIndex].FooterPagesData = dupSourceListArray;
      updateFooterDetails(clonedArray);
      return;
    }

    //putting the modified inner list inside the whole array
    clonedArray[souceListArrayIndex].FooterPagesData = dupSourceListArray;

    //--------------------------------------------------------

    const destinationListArray = columns.filter(
      (colData) => colData?._id === destinationColId,
    )?.[0]?.FooterPagesData;

    //finding the index where the destination list array is found
    const destinationListArrayIndex = columns.findIndex(
      (colData) => colData?._id === destinationColId,
    );

    /*---adding the element to its destination position---*/

    //copying the inner list array to make changes
    const dupDestinationListArray = deepCopy(destinationListArray);

    //adding the element to innerlist
    dupDestinationListArray?.splice(destinationListIndex, 0, elementToMove);

    // putting the modified inner list inside the whole array
    clonedArray[destinationListArrayIndex].FooterPagesData =
      dupDestinationListArray;

    updateFooterDetails(clonedArray);
  }

  return (
    <FooterMenuStyles>
      <PageTitle title={t("footerMenu")} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={false}
          isCombineEnabled={false}
        >
          {(provided) => (
            <div
              className="footerMenuColumnOuter"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {columns.map((columnData, index) => {
                const listData = columnData?.FooterPagesData;

                return (
                  <PageColumn
                    key={columnData?._id}
                    index={index}
                    title={columnData?.name}
                    id={columnData?._id}
                    listData={listData}
                    isScrollable={true}
                    isCombineEnabled={false}
                    handleRemoveChild={handleRemoveChild}
                    columnData={columnData}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FooterMenuStyles>
  );
};

export default FooterMenu;
