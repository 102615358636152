import { createSlice } from "@reduxjs/toolkit";
import {
  createSearchApi,
  deleteSearchesApi,
  getAllSearchApi,
  getSearchFieldsApi,
  getTopSearchApi,
  updateSearchStatusApi,
} from "./trendingSearchesAction";

const initialState = {
  topSearches: [], //top five searches or so
  trendingSearches: [], //all trending searches
  fieldValues: [],
};

const trendingSearchesSlice = createSlice({
  name: "trendingSearchesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopSearchApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopSearchApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.topSearches = action.payload;
      })
      .addCase(getTopSearchApi.rejected, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(getAllSearchApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllSearchApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.trendingSearches = action.payload;
      })
      .addCase(getAllSearchApi.rejected, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteSearchesApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSearchesApi.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(deleteSearchesApi.rejected, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(createSearchApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSearchApi.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(createSearchApi.rejected, (state, action) => {
        state.status = "idle";
      });
    //updateSearchStatusApi
    builder
      .addCase(updateSearchStatusApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSearchStatusApi.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(updateSearchStatusApi.rejected, (state, action) => {
        state.status = "idle";
      });
    //getSearchFieldsApi
    builder
      .addCase(getSearchFieldsApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSearchFieldsApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      })
      .addCase(getSearchFieldsApi.rejected, (state, action) => {
        state.status = "idle";
      });
  },
});

export const topSearches = (state) =>
  state.trendingSearchesSlice.topSearches.search;
export const allSearches = (state) =>
  state.trendingSearchesSlice.trendingSearches;
export const searchFieldData = (state) =>
  state.trendingSearchesSlice.fieldValues.trending_search;

export default trendingSearchesSlice.reducer;
