import { MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PaginationLimitDropDown = (props) => {
  const { dataState, setDataState, setSelectedRows } = props;
  function handleChange(e) {
    setDataState({
      ...dataState,
      page: 1,
      limit: parseInt(e.target.value),
    });
    setSelectedRows && setSelectedRows([]);
  }

  return (
    <Box className="addNewDivSelect">
      <Select
        className="permission-role-select"
        value={dataState?.limit}
        onChange={(e) => handleChange(e)}
        defaultValue={20}
      >
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={40}>40</MenuItem>
        <MenuItem value={80}>80</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </Box>
  );
};

export default PaginationLimitDropDown;
