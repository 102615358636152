import { createSlice } from "@reduxjs/toolkit";
import {
  createGeneralFooterPageApi,
  getGeneralFooterPageByTitleApi,
  getGeneralFooterPageFieldsApi,
  getGeneralFooterPagesApi,
  getSingleGeneralFooterPageApi,
} from "./footerGeneralPagesAction";

const initialState = {
  footerGeneralPagesData: [],
  footerGeneralPagesFields: [],
  singlePageDataByTitle: "",
  pageDataLoading: false,
};

const footerGeneralPagesSlice = createSlice({
  name: "footerGeneralPagesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralFooterPagesApi.pending, (state) => {
        state.pageDataLoading = true;
      })
      .addCase(getGeneralFooterPagesApi.fulfilled, (state, action) => {
        state.footerGeneralPagesData = action.payload;
        state.pageDataLoading = false;
      })
      .addCase(getGeneralFooterPagesApi.rejected, (state) => {
        state.pageDataLoading = false;
      });

    builder
      .addCase(createGeneralFooterPageApi.pending, (state) => {
        state.pageDataLoading = true;
      })
      .addCase(createGeneralFooterPageApi.fulfilled, (state) => {
        state.pageDataLoading = false;
      })
      .addCase(createGeneralFooterPageApi.rejected, (state) => {
        state.pageDataLoading = false;
      });

    //getGeneralFooterPageFieldsApi
    builder
      .addCase(getGeneralFooterPageFieldsApi.pending, (state) => {
        state.pageDataLoading = true;
      })
      .addCase(getGeneralFooterPageFieldsApi.fulfilled, (state, action) => {
        state.footerGeneralPagesFields = action.payload;
        state.pageDataLoading = false;
      })
      .addCase(getGeneralFooterPageFieldsApi.rejected, (state) => {
        state.pageDataLoading = false;
      });

    //getGeneralFooterPageByTitleApi
    builder
      .addCase(getGeneralFooterPageByTitleApi.pending, (state) => {
        state.pageDataLoading = true;
      })
      .addCase(getGeneralFooterPageByTitleApi.fulfilled, (state, action) => {
        state.singlePageDataByTitle = action.payload;
        state.pageDataLoading = false;
      })
      .addCase(getGeneralFooterPageByTitleApi.rejected, (state) => {
        state.pageDataLoading = false;
      });

    //getSingleGeneralFooterPageApi
    builder
      .addCase(getSingleGeneralFooterPageApi.pending, (state) => {
        state.pageDataLoading = true;
      })
      .addCase(getSingleGeneralFooterPageApi.fulfilled, (state, action) => {
        state.singlePageDataByTitle = action.payload;
        state.pageDataLoading = false;
      })
      .addCase(getSingleGeneralFooterPageApi.rejected, (state) => {
        state.pageDataLoading = false;
      });
  },
});

export const footerGeneralPagesData = (state) =>
  state?.footerGeneralPagesSlice?.footerGeneralPagesData;

export const footerGeneralPagesFieldsData = (state) =>
  state?.footerGeneralPagesSlice?.footerGeneralPagesFields?.uniqueFooter;

export const footerGeneralSinglePageData = (state) =>
  state?.footerGeneralPagesSlice?.singlePageDataByTitle?.footer;

export const footerGeneralPageLoadingState = (state) =>
  state?.footerGeneralPagesSlice?.pageDataLoading;

export default footerGeneralPagesSlice.reducer;
