import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { Logout } from "@mui/icons-material";
import { checkForAdminPermission } from "utils/permissions/checkPermission";
import { routeConfigs } from "utils/routeConfig";
import LogoutHelper from "helper/logoutHelper";
import { userData } from "pages/Admin-Pages/user-administration/features/userSlice";
import AvatarComp from "components/AvatarComp/AvatarComp";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { HeaderMenuStyles } from "./HeaderMenuStyles";

const LinkTextComp = (props) => {
  const { title, link, onClick, className } = props;
  return (
    <Link to={link} onClick={onClick} className={className}>
      <TextTag variant={textVariants._14px.medium}>{title}</TextTag>
    </Link>
  );
};

const HeaderMenu = ({ isOpenMenu, setisOpenMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAllData = useSelector(userData);

  const handleCloseFunction = () => {
    setisOpenMenu(false);
  };

  return (
    <HeaderMenuStyles>
      <header className="headerMenuClass">
        <nav className={`menu ${isOpenMenu ? "openMenu" : "closeMenu"}`}>
          <ul className="mainSiderBlock">
            <li className="userDetails">
              <div className="userInfo">
                <div className="avatarWrapper">
                  <AvatarComp extraClass={"headerSideMenuAvatar"} />
                </div>
                <div className="menuUserInfo">
                  <div className="userNameInfo">
                    <TextTag variant={textVariants._14px.medium}>
                      {userAllData?.[0]?.first_name}{" "}
                      {userAllData?.[0]?.last_name}
                    </TextTag>
                  </div>
                  <div className="emailClass">
                    <TextTag variant={textVariants._12px.medium}>
                      {userAllData?.[0]?.email}
                    </TextTag>
                  </div>
                </div>
              </div>
            </li>
            {checkForAdminPermission() && (
              <li className="items adminDash">
                <LinkTextComp
                  link={routeConfigs.adminDashboard}
                  onClick={handleCloseFunction}
                  title={t("adminDashBoard")}
                />
              </li>
            )}
            <li className="items">
              <ul>
                <li>
                  <LinkTextComp
                    onClick={handleCloseFunction}
                    title={t("downloadHistory")}
                  />
                </li>
                <li>
                  <LinkTextComp
                    link={`${routeConfigs.adminProfileSettings}?id=1`}
                    onClick={handleCloseFunction}
                    title={t("accountDetails")}
                    className="profileMenuItem headerMenuItem"
                  />
                </li>
                <li>
                  <LinkTextComp
                    link={`${routeConfigs.adminProfileSettings}?id=3`}
                    onClick={handleCloseFunction}
                    title={t("changePassword")}
                    className="profileMenuItem headerMenuItem"
                  />
                </li>
                <li>
                  <LinkTextComp
                    link={routeConfigs.userHome}
                    onClick={handleCloseFunction}
                    title={t("assetManager")}
                    className="profileMenuItem headerMenuItem"
                  />
                </li>
                <li>
                  <LinkTextComp title={t("contactUs")} />
                </li>
              </ul>
            </li>
            <li className="items signOut">
              <span
                className="profileMenuItem headerMenuItem"
                onClick={() => {
                  handleCloseFunction();
                  LogoutHelper(dispatch, navigate);
                }}
              >
                <Logout />{" "}
                <TextTag variant={textVariants._14px.medium}>
                  {t("signOut")}
                </TextTag>
              </span>
            </li>
          </ul>
        </nav>
      </header>
    </HeaderMenuStyles>
  );
};

export default HeaderMenu;
