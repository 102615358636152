import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProfileSettings from "./ProfilePage/ProfileSettings";
import MyUploadIconsSetting from "components/Icons/MyUploads/MyUploadIcons";
import ProfileIconSetting from "components/Icons/ProfileIcon/ProfileIconSetting";
import DownloadIconSetting from "components/Icons/DownloadHistory/DownloadHistoryIcon";
import ChangePasswordIcon from "components/Icons/ChangePassword/ChangePasswordIcon";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import SettingIconProfile from "components/Icons/SettingIcon/SettingIcon";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useQuery } from "utils/queryString";
import { routeConfigs } from "utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import ProfileSettingPagesStyles from "./ProfileSettingPagesStyle";
import { useTranslation } from "react-i18next";

const ProfileSettingPages = () => {
  const [page, setPage] = useState(1);
  const { t, i18n } = useTranslation();

  const id = useQuery().get("id");

  useEffect(() => {
    setPage(parseInt(id));
  }, [id]);

  return (
    <Layout>
      <Box>
        <ProfileSettingPagesStyles>
          <Box className={`main ${i18n.language}`}>
            <Box className="container">
              <PageTitle title={t("settings")} icon={<SettingIconProfile />} />
              <Box className={`profileSetting ${i18n.language}`}>
                <Box className="sideTabs">
                  <ul>
                    <li className={page === 0 ? "activeTab" : " "}>
                      <Link to={`${routeConfigs.adminProfileSettings}?id=0`}>
                        <MyUploadIconsSetting className="downloadIcon" />
                        {t("myUpload")}
                      </Link>
                      <ChevronRightIcon className="rightArrowIcon" />
                    </li>
                    <li className={page === 1 ? "activeTab" : " "}>
                      <Link to={`${routeConfigs.adminProfileSettings}?id=1`}>
                        <ProfileIconSetting className="profileIcon " />
                        {t("profileSettings")}
                      </Link>
                      <ChevronRightIcon className="rightArrowIcon" />
                    </li>
                    <li className={page === 2 ? "activeTab" : " "}>
                      <Link to={`${routeConfigs.adminProfileSettings}?id=2`}>
                        <DownloadIconSetting className="downloadIcon" />
                        {t("downloadHistory")}
                      </Link>
                      <ChevronRightIcon className="rightArrowIcon" />
                    </li>
                    <li className={page === 3 ? "activeTab" : " "}>
                      <Link to={`${routeConfigs.adminProfileSettings}?id=3`}>
                        <ChangePasswordIcon className="downloadIcon" />
                        {t("changePassword")}
                      </Link>
                      <ChevronRightIcon className="rightArrowIcon" />
                    </li>
                  </ul>
                </Box>
                <Box className="tabPages">
                  {page === 1 && <ProfileSettings />}
                  {page === 3 && <ChangePassword />}
                </Box>
              </Box>
            </Box>
          </Box>
        </ProfileSettingPagesStyles>
      </Box>
    </Layout>
  );
};

export default ProfileSettingPages;
