import React from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FranceFlag from "components/Icons/FlagIcons/FranceFlag";
import SpanishFlag from "components/Icons/FlagIcons/SpanishFlag";
import GermanFlag from "components/Icons/FlagIcons/GermanFlag";
import ItalyFlag from "components/Icons/FlagIcons/ItalyFlag";
import AmericaFlag from "components/Icons/FlagIcons/AmericaFlag";
import { FooterLanguageStyle } from "./FooterLanguageStyle";
import TextTag, { textVariants } from "components/Typography/TextTag";

const FooterLanguageChange = ({ className }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const language = i18n.language;

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("i18nextLng", selectedLanguage);
    navigate(0);
  };

  const flagJson = [
    { lang: "English", code: "en", flag: <AmericaFlag /> },
    { lang: "Français", code: "fr", flag: <FranceFlag /> },
    { lang: "Español", code: "es", flag: <SpanishFlag /> },
    { lang: "Deutsch", code: "de", flag: <GermanFlag /> },
    { lang: "Italiano", code: "it", flag: <ItalyFlag /> },
  ];

  return (
    <FooterLanguageStyle>
      <Box className={`footerLanguageChange ${className ? className : ""}`}>
        <FormControl variant="outlined" className={"formControl"} size="small">
          <Select
            className="permission-role-select text_capitalize"
            name={"role"}
            value={language}
            IconComponent={() => (
              <KeyboardArrowDownRoundedIcon className="languageDownIcon" />
            )}
            onChange={changeLanguage}
          >
            {flagJson?.map((val, index) => {
              return (
                <MenuItem className="svgOption" value={val?.code} key={index}>
                  {val?.flag}
                  <TextTag variant={textVariants._14px.medium}>{val?.lang}</TextTag>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </FooterLanguageStyle>
  );
};

export default FooterLanguageChange;
