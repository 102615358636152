import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  FormControl,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { getBusinessRegion } from "pages/Admin-Pages/user-administration/features/userAction";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import {
  allUserApproveUserApi,
  allUserEditUserApi,
  getAllUserApi,
} from "../../features/allUserAction";
import { UserSchema } from "utils/validation/validation-schemas/userSchema";
import Sorting from "utils/sorting";
import { getDepartemtsByBusinessUnitApi } from "pages/Admin-Pages/tertiaryGroup/features/departmentAction";
import { validationMessages } from "utils/validation/validationUtils";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";

const EditandViewUserPopup = ({
  open,
  setOpen,
  regionData,
  userRoleData,
  allUserDetailData,
  editTrue,
  dataState,
}) => {
  const [businessData, setBusinessData] = useState([]);
  const [error, setError] = useState("");
  const [tertiaryError, setTertiaryError] = useState("");
  const [tertiaryGroupData, setTertiaryGroupData] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      allUserDetailData?.regions_data?.[0]?._id ||
      allUserDetailData?.regions_data?._id
    ) {
      dispatch(
        getBusinessRegion(
          allUserDetailData?.regions_data?.[0]?._id ||
            allUserDetailData?.regions_data?._id,
        ),
      ).then((response) => {
        const secondaryDataArr = response?.payload?.business;
        const filteredSecondaryDataArr = fiterGroupArr(
          secondaryDataArr,
          allUserDetailData?.businesses_data?.[0]?._id ||
            allUserDetailData?.businesses_data?._id,
        );
        if (filteredSecondaryDataArr?.length > 0) {
          setBusinessData(filteredSecondaryDataArr);
          handleSecondaryGroupChange(
            allUserDetailData?.businesses_data?.[0]?._id ||
              allUserDetailData?.businesses_data?._id,
            allUserDetailData?.userDepartment?.[0]?._id ||
              allUserDetailData?.userDepartment?._id,
          );
        } else {
          setError(validationMessages.noSecondaryGroupAssociated);
        }
      });
    }
    //eslint-disable-next-line
  }, [allUserDetailData]);

  const handleRegionChange = (primaryGroupId, secondaryGroupId) => {
    setBusinessData([]);
    setError("");
    setTertiaryGroupData([]);
    setTertiaryError("");

    dispatch(getBusinessRegion(primaryGroupId)).then((response) => {
      const secondaryDataArr = response?.payload?.business;
      const filteredSecondaryDataArr = fiterGroupArr(
        secondaryDataArr,
        secondaryGroupId,
      );

      if (filteredSecondaryDataArr?.length > 0) {
        setBusinessData(filteredSecondaryDataArr);
      } else {
        setError(validationMessages.noSecondaryGroupAssociated);
      }
    });
  };

  const handleSecondaryGroupChange = (secondaryGroupId, tertiaryGroupId) => {
    setTertiaryGroupData([]);
    setTertiaryError("");
    const data = {
      id: secondaryGroupId,
    };
    dispatch(getDepartemtsByBusinessUnitApi(data)).then((resp) => {
      if (resp?.error) {
        return;
      }
      const tertiaryArr = resp?.payload?.business_units?.[0]?.departments;

      const filteredTertiaryDataArr = fiterGroupArr(
        tertiaryArr,
        tertiaryGroupId,
      );
      if (filteredTertiaryDataArr?.length > 0) {
        setTertiaryGroupData(filteredTertiaryDataArr);
      } else {
        setTertiaryError(validationMessages.noTertiaryGroupAssociated);
      }
    });
  };

  function fiterGroupArr(filterArr, groupId) {
    //filtering enabled group and also checking if initial value exist in array
    return filterArr?.filter(
      (filterData) => filterData?.enabled || filterData?._id === groupId,
    );
  }

  return (
    <DialogCommon
      fullwidth={true}
      maxWidth={"md"}
      open={open}
      handleClose={() => setOpen()}
      title={editTrue === "Edit" ? t("editUserDetails") : t("viewUserDetails")}
    >
      <UserDetailDialogDesign>
        <div className="popup-main AddUserDetails">
          <DialogContent>
            <div className="requesOuter">
              <Formik
                initialValues={{
                  first_name: allUserDetailData?.first_name,
                  last_name: allUserDetailData?.last_name,
                  email: allUserDetailData?.email,
                  region:
                    allUserDetailData?.regions_data?.[0]?._id ||
                    allUserDetailData?.regions_data?._id ||
                    " ",
                  business_unit:
                    allUserDetailData?.businesses_data?.[0]?._id ||
                    allUserDetailData?.businesses_data?._id ||
                    " ",
                  department:
                    allUserDetailData?.userDepartment?.[0]?._id ||
                    allUserDetailData?.userDepartment?._id ||
                    " ",
                  role:
                    allUserDetailData?.userRoles?.[0]?._id ||
                    allUserDetailData?.userRoles?._id ||
                    " ",
                }}
                enableReinitialize
                validationSchema={UserSchema}
                onSubmit={(values) => {
                  const modifiedValues = {};
                  //putting null if entries are "" or undefined
                  for (const objVal in values) {
                    modifiedValues[objVal] =
                      values[objVal]?.trim() !== "" &&
                      values[objVal]?.trim() !== undefined
                        ? values[objVal]
                        : null;
                  }

                  if (editTrue === "Edit") {
                    delete modifiedValues.email;
                    modifiedValues.id = allUserDetailData?._id;
                  } else {
                    modifiedValues.approve = true;
                  }

                  if (editTrue === "Edit") {
                    dispatch(allUserEditUserApi(modifiedValues)).then((res) => {
                      if (!res.error) {
                        dispatch(getAllUserApi(dataState));
                      }
                    });
                  } else {
                    dispatch(allUserApproveUserApi(modifiedValues)).then(
                      (res) => {
                        if (!res.error) {
                          dispatch(getAllUserApi(dataState));
                        }
                      },
                    );
                  }

                  setOpen();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="request-common">
                      <label className="label">{t("firstName")}</label>
                      <TextField
                        placeholder={t("enterFirstName")}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                      />

                      <span className="errorClass">
                        {errors.first_name &&
                          touched.first_name &&
                          errors.first_name}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("lastName")}</label>
                      <TextField
                        placeholder={t("enterLastName")}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.last_name}
                      />
                      <span className="errorClass">
                        {errors.last_name &&
                          touched.last_name &&
                          errors.last_name}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("userRole")}</label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select text_capitalize"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.role}
                            name={"role"}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectUserRole")}
                            </MenuItem>
                            {Array.isArray(userRoleData) &&
                              Sorting(userRoleData, "role")?.map(
                                (val, index) => {
                                  return (
                                    (val?._id === values?.role ||
                                      val?.enabled) && (
                                      <MenuItem
                                        key={index + 1}
                                        value={val?._id}
                                        className="text_capitalize"
                                      >
                                        {val?.role}
                                      </MenuItem>
                                    )
                                  );
                                },
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">
                        {errors.role && touched.role && errors.role}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("email")}</label>
                      <TextField
                        placeholder={t("enterEmail")}
                        size="small"
                        variant="outlined"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled
                      />
                      <span className="errorClass">
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                    <div className="request-common">
                      <label> {t("primaryOrganization")}</label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            name="region"
                            className="permission-role-select"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("business_unit", " ");
                              setFieldValue("department", " ");
                              handleRegionChange(
                                e.target.value,
                                values?.business_unit,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values?.region}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Sorting(regionData, "name")?.map((val, index) => {
                              return (
                                (val?._id === values?.region ||
                                  val?.enabled) && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="request-common">
                      <label className="label">
                        {t("secondaryOrganization")}
                      </label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            onChange={(e) => {
                              handleChange(e);
                              handleSecondaryGroupChange(
                                e.target.value,
                                values?.department,
                              );
                              setFieldValue("department", " ");
                            }}
                            onBlur={handleBlur}
                            value={values?.business_unit || " "}
                            name={"business_unit"}
                            disabled={
                              values?.region === "" ||
                              values?.region === null ||
                              businessData?.length === 0 ||
                              !businessData
                            }
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Array.isArray(businessData) &&
                              Sorting(businessData, "name")?.map(
                                (val, index) => {
                                  return (
                                    (val?._id === values?.business_unit ||
                                      val?.enabled) && (
                                      <MenuItem
                                        key={index + 1}
                                        value={val?._id}
                                      >
                                        {val?.name}
                                      </MenuItem>
                                    )
                                  );
                                },
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">{error}</span>
                    </div>
                    <div className="request-common">
                      <label className="label">
                        {t("tertiaryOrganization")}
                      </label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.department || " "}
                            name={"department"}
                            defaultValue={" "}
                            disabled={
                              values?.business_unit === "" ||
                              values?.business_unit === null ||
                              tertiaryGroupData?.length === 0 ||
                              !tertiaryGroupData
                            }
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Sorting(tertiaryGroupData, "name")?.map(
                              (val, index) => {
                                return (
                                  (val?._id === values?.department ||
                                    val?.enabled) && (
                                    <MenuItem key={index + 1} value={val?._id}>
                                      {val?.name}
                                    </MenuItem>
                                  )
                                );
                              },
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">{tertiaryError}</span>
                    </div>
                    <DialogActions className="popup-button-outer">
                      {editTrue === "Edit" ? (
                        <>
                          <StyledButton type="submit" disabled={isSubmitting}>
                            {t("save")}
                          </StyledButton>
                          <CustomButton onClick={() => setOpen()}>
                            {t("cancel")}
                          </CustomButton>
                        </>
                      ) : (
                        <StyledButton type="submit" disabled={isSubmitting}>
                          {t("approve")}
                        </StyledButton>
                      )}
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </div>
          </DialogContent>
        </div>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default EditandViewUserPopup;
