import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: localStorage.getItem("i18nextLng") || "en",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
    fr: {
      translations: require("./locales/fr/translations.json"),
    },
    de: {
      translations: require("./locales/de/translations.json"),
    },
    it: {
      translations: require("./locales/it/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
  },
});

i18n.languages = ["en", "es", "fr", "de", "it"];

export default i18n;
