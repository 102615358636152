import { createSlice } from "@reduxjs/toolkit";
import { contactUsFormApi } from "./contactUsAction";

const initialState = {
  loadingState: false,
};

const contactUsSlice = createSlice({
  name: "contactUsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUsFormApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(contactUsFormApi.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(contactUsFormApi.rejected, (state, action) => {
        state.loginLoading = false;
      });
  },
});

export default contactUsSlice.reducer;
