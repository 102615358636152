import { styled } from "@mui/system";

const AddPageToFooterStyles = styled("div")(({ theme }) => ({
  ".addPagePopupMain": {
    marginTop: "16px",
    ".dropDownSection": {
      display: "flex",
      flexDirection: "column",
      marginBottom: "16px",
      position: "relative",
      "span.errorClass": {
        position: "absolute",
        bottom: "-19px",
      },
    },
  },
}));

export default AddPageToFooterStyles;
