import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  TextField,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CommonDeletePopup from "pages/Admin-Pages/AdminComponents/CommonDeletePopup/CommonDeletePopup";
import {
  addCuratedGalleryLinkApi,
  curatedGalleryUpdateLinkTargetApi,
  deleteCuratedGalleryApi,
  getCuratedGalleries,
  updateCuratedLinkApi,
} from "../../../features/curatedGalleryAction";
import { curatedGalleryLoading } from "../../../features/curatedGallerySlice";
import AddPageToFooterStyles from "pages/Admin-Pages/pages-cms/footerMenu/components/AddPageToFooter/AddPageToFooterStyles";

const AddNewLinkPopup = (props) => {
  const {
    openAddPopup,
    setOpenAddPopup,
    id,
    openFor,
    handleRemoveChild,
    link,
    categoryId,
  } = props;

  const [openDelete, setOpenDelete] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const galleryLoadingState = useSelector(curatedGalleryLoading);

  console.log(galleryLoadingState);

  const initialValues = {
    title: link && link?.name ? link?.name : "",
    href: link && link?.url ? link?.url : "",
    target_blank: link && link?.target_blank ? link?.target_blank : false,
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .trim()
        .required(validationMessages.pleaseAddTitle)
        .max(50, validationMessages.tooLong)
        .matches(
          validationRegex?.checkForDoubleSpaces,
          validationMessages?.noConsecutiveDoubleSpaces,
        ),
      href: Yup.string()
        .trim()
        .required(validationMessages.pleaseEnterHyperlink)
        .url(validationMessages.pleaseEnterValidHyperlink),
    }),
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleClose() {
    setOpenAddPopup(false);
    formik.resetForm();
  }

  function handleSubmit(values) {
    if (openFor === "EDIT") {
      //when editing link
      const body = {
        name: values?.title,
        url: values?.href,
        id: link?._id,
      };

      dispatch(updateCuratedLinkApi(body)).then((res) => {
        if (res?.error) return;
        const tbody = { target_blank: values?.target_blank, id: link?.link_id };
        dispatch(curatedGalleryUpdateLinkTargetApi(tbody)).then((res) => {
          if (res?.error) return;
          dispatch(getCuratedGalleries(categoryId));
        });
        handleClose();
      });
    } else {
      //when adding link
      const body = {
        name: values.title,
        url: values.href,
        target_blank: Boolean(values?.target_blank),
      };
      const data = { body, id };
      dispatch(addCuratedGalleryLinkApi(data)).then((res) => {
        if (res?.error) return;
        dispatch(getCuratedGalleries(categoryId));
        handleClose();
      });
    }
  }

  function handleDelete() {
    setOpenDelete(false);

    const body = {
      ids: [link?.link_id],
    };
    const databody = { body, colId: id };
    dispatch(deleteCuratedGalleryApi(databody)).then((res) => {
      if (res?.error) {
        return;
      }

      handleRemoveChild(link, id).then(() => {
        handleClose();
      });
    });
  }

  return (
    <DialogCommon
      open={openAddPopup}
      handleClose={handleClose}
      title={openFor === "EDIT" ? t("Update Hyperlink") : t("Add Hyperlink")}
      extraPaperClasses={`addNewLinkMain ${i18n.language}`}
      fullWidth={true}
    >
      <AddPageToFooterStyles>
        <div className="addPagePopupMain">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="dropDownSection">
                <span>{t("title")}</span>
                <TextField
                  placeholder={t("title")}
                  size="small"
                  variant="outlined"
                  type="text"
                  name="title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="errorClass">
                  {formik.errors.title &&
                    formik.touched.title &&
                    formik.errors.title}
                </span>
              </div>
              <div className="dropDownSection">
                <span>{t("url")}</span>
                <TextField
                  placeholder={t("url")}
                  size="small"
                  variant="outlined"
                  type="text"
                  name="href"
                  value={formik?.values?.href}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="errorClass">
                  {formik.errors.href &&
                    formik.touched.href &&
                    formik.errors.href}
                </span>
              </div>
              <div className="dropDownSection">
                <FormControlLabel
                  control={
                    <Checkbox
                      key="target_blank"
                      value={formik.values.target_blank}
                      checked={formik.values.target_blank}
                      name="target_blank"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={"Open in new tab"}
                />
              </div>
              <DialogActions className="popup-button-outer">
                <StyledButton
                  variant={"contained"}
                  type="submit"
                  disabled={galleryLoadingState}
                >
                  {openFor === "EDIT" ? t("update") : t("save")}
                </StyledButton>
                {openFor === "EDIT" && (
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpenDelete(true)}
                    disabled={galleryLoadingState}
                  >
                    {t("delete")}
                  </CustomButton>
                )}
                <CustomButton
                  variant={"contained"}
                  onClick={handleClose}
                  disabled={galleryLoadingState}
                >
                  {t("cancel")}
                </CustomButton>
              </DialogActions>
            </form>
          </FormikProvider>
        </div>
        {openDelete && (
          <CommonDeletePopup
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            rowsToBeDeleted={[link?.pageId]}
            singleDeleteText={"Are you sure you want to delete?"}
            multiDeleteText={"Are you sure you want to delete?"}
            handleClose={() => setOpenDelete(false)}
            handleDelete={handleDelete}
          />
        )}
      </AddPageToFooterStyles>
    </DialogCommon>
  );
};

export default AddNewLinkPopup;
