import { createSlice } from "@reduxjs/toolkit";
import { profileSettingApi } from "./profileAction";

const initialState = {
  profileLoading: false,
};

const profileSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(profileSettingApi.pending, (state) => {
        state.profileLoading = true;
      })
      .addCase(profileSettingApi.fulfilled, (state, action) => {
        state.profileLoading = false;
      })
      .addCase(profileSettingApi.rejected, (state, action) => {
        state.profileLoading = false;
      });
  },
});

export const profileSettingState = (state) => state.profile;
export default profileSlice.reducer;
