import React from "react";
import PageTitle from "../AdminComponents/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

export default function FeaturedAssets() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t("featuredAssets")} />
    </>
  );
}
