import { styled } from "@mui/system";

const CuratedGalleryDragAndDropStyle = styled("div")(({ theme }) => ({
  ".footerMenuColumnOuter": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    gap: "15px",
    flexWrap: "wrap",
    "@media screen and (max-width:1440px)": {
      gap: "10px",
    },

    ".dragColumnMain": {
      flex: "0 0 100%",
      maxWidth: "calc(100% / 4 - 15px)",
      width: "100%",
      backgroundColor: theme.palette.white.main,
      "@media screen and (max-width:1440px)": {
        maxWidth: "calc(100% / 3 - 10px)",
      },
      "&:is(.columnDraggingOn)": {
        boxShadow: "0 0 0 #000, 0 0 9px #cacaca",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      },
      ".hedingCardDrag": {
        marginBottom: "15px",
        position: "relative",
        ".columnHeader": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: theme.palette.primary.main,
          borderRadius: "5px",
          " .columnTitle ": {
            width: "100%",
          },
          ".addPageToColumn": { button: { svg: { fill: "#fff" } } },
          h2: {
            margin: "0",
            padding: "16px",
            fontSize: "16px",
            textTransform: "capitalize",
            color: theme.palette.white.main,
          },
          input: {
            margin: "0",
            padding: "16px",
            fontSize: "16px",
            textTransform: "capitalize",
            color: theme.palette.white.main,
            outline: "unset",
            backgroundColor: "transparent",
            border: "0",
            minHeight: "56px",
          },
        },
        "span.errorClass": {
          position: "absolute",
        },
      },

      ".linkMain": {
        padding: "12px 15px",
        borderBottom: "1px solid rgb(221, 221, 221)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.white.main,
        button: {
          padding: "0",
        },
        "&:is(.linkDraggingOn)": {
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          backgroundColor: "#f7f7f7",
          borderColor: "#f7f7f7",
          PointerEvent: "all",
        },
        "&:is(:hover)": {
          borderColor: "#f7f7f7",
          backgroundColor: "#f7f7f7",
          cursor: "all",
        },

        ".dragIconText": { display: "flex", alignItems: "center", gap: "10px" },
      },
    },
  },
}));

export default CuratedGalleryDragAndDropStyle;
