import React from "react";
import ToggleSwitchStyle from "./ToggleSwitchStyle";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const ToggleSwitch = ({
  data,
  checked,
  value,
  onChange,
  disabled,
  className,
  defaultChecked,
}) => {
  const { t } = useTranslation();
  return (
    <ToggleSwitchStyle activeText={t("active")} inactiveText={t("inActive")}>
      <Box className="app-cover">
        <Box className="toggle-button-cover">
          <Box className="button-cover">
            <Box className="button r" id="button-1">
              <input
                type="checkbox"
                className={`checkbox ${className ? className : ""}`}
                name="color_mode"
                id="color_mode"
                onChange={onChange}
                value={value}
                checked={value}
                disabled={disabled}
                defaultChecked={defaultChecked}
              />
              <Box className="knobs" />
              <Box className="layer" />
            </Box>
          </Box>
        </Box>
      </Box>
    </ToggleSwitchStyle>
  );
};

export default ToggleSwitch;
