import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { getContentByTitleApi } from "pages/Admin-Pages/pages-cms/features/pagesCmsAction.js";
import {
  customPageDataByTitle,
  customPageLoading,
} from "pages/Admin-Pages/pages-cms/features/pagesCmsSlice.js";
import withAuth from "Auth/withAuth";
import { routeConfigs } from "utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import CmsPageStyles from "./cmsPageStyles.js";
import "react-quill/dist/quill.bubble.css";

const CmsPage = () => {
  const dispatch = useDispatch();
  const pageData = useSelector(customPageDataByTitle);
  const cmsPageLoading = useSelector(customPageLoading);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getContentByTitleApi(encodeURIComponent(id)));
    }
  }, [id, dispatch]);

  return (
    <Layout>
      <CmsPageStyles>
        <div className="cmsPage">
          {cmsPageLoading ? (
            "Loading..."
          ) : (
            <div className="cms_page_main">
              <div className="cms_pageTitle">
                <h1>{pageData?.title}</h1>
              </div>
              <ReactQuill
                value={pageData?.description}
                readOnly={true}
                theme={"bubble"}
              />
            </div>
          )}
        </div>
      </CmsPageStyles>
    </Layout>
  );
};

export default withAuth(CmsPage, routeConfigs.userLogin);
