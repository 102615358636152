import { styled } from "@mui/system";

const ArrowStyles = styled("div")(({ theme }) => ({
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    svg: {
        width: "0.875rem",
        height: "0.875rem",
    },
}));

export default ArrowStyles