import { styled } from "@mui/system";

export const TypeCategory = styled("div")(
  ({ theme }) => `

  .first_Sec {
   h3 {
        margin-bottom: 6px;
    }
    .TypeCategoryFields {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
       .addNewDivSelect {
        flex: calc(25% - 15px);
        max-width: calc(25% - 15px);
        margin: 0;
            margin: 0;
            > span{
                display:block;
                margin-bottom:7px;
            }
           .MuiInputBase-root {
                width: 100%;
                background: #fff;
               
            }
        }
        .addNewDivSelect::firstletter{
            text-transform:uppercase;
        } 
    }
  }

`,
);
