export function deletePageChangeChecker(
  currentPage,
  totalDocuments,
  numberToBeDeleted,
  limit,
) {
  //this function checks if page needs to change when deleting records
  if (parseInt(currentPage) === 1) {
    return false;
  }
  if (
    parseInt(totalDocuments) - parseInt(numberToBeDeleted) <=
    parseInt(limit) * (parseInt(currentPage) - 1)
  ) {
    return true;
  }
  return false;
}
