import React, { useState, useEffect, useCallback } from "react";
import Cropper from "react-easy-crop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import getCroppedImg from "../../pages/Admin-Pages/asset-administration/AssetCategory/Components/AddEditPopUp/getCroppedImg";

const ImageCropModal = ({ open, onClose, image, formik, setImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState(undefined);

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    if (image && image !== undefined && typeof image === "object") {
      setImageUrl(URL.createObjectURL(image));
    }
  }, [open, image]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //problematic function, is called infinitely
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropComplete = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        image?.name,
      );
      const file = new File([croppedImage], image?.name, {
        type: croppedImage.type,
      });

      formik.setFieldValue("profile", file);
    } catch (error) {
      console.error("Error cropping image", error.message);
    }
    setImage();
    onClose();
  };

  return (
    <DialogCommon open={open} onClose={onClose}>
      <IconButton
        className="dialog_close"
        onClick={() => {
          onClose();
          setImage();
        }}
      >
        <Close />
      </IconButton>
      <Box
        className="crpeText"
        sx={{
          height: 440,
          width: 500,
        }}
      >
        {image && image !== undefined && typeof image === "object" ? (
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropComplete}
          />
        ) : (
          <p>No image selected for cropping</p>
        )}
      </Box>
      {image && image !== undefined && typeof image === "object" ? (
        <div className="cropBtn">
          <Button
            className="cropButton"
            variant="contained"
            onClick={() => handleCropComplete()}
          >
            Fit to size
          </Button>
        </div>
      ) : (
        ""
      )}
    </DialogCommon>
  );
};

export default ImageCropModal;
