import { styled } from "@mui/system";

const FeaturedProjectStyles = styled("div")(({ theme }) => ({
  fontFamily: "poppins",
  padding: "4.625rem 0 7.5rem 4rem",
  "swiper-container": {
    // height: "430px",
    ".featured_slide_inner": {


      position: 'relative',
      overflow: 'hidden',
      borderRadius: '10px',
      height: '27.778vw',
      "img.featuredSlideImage": { cursor: "pointer", width: "100%" },
      "&.slide_hover_hide .hoverSlide": { display: "none" },
      "&.slide_hover_show .hoverSlide": {
        display: "flex",
        position: "absolute",
        top: "0",
        width: "100%",
        minHeight: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        color: "white",
        backgroundImage: "linear-gradient(0deg, #00000036, transparent)",
        borderRadius: "10px",
        ".slideHoverTop": { padding: "30px 25px" },
        ".slideHoverBottom": { padding: "25px", p: { fontSize: "13px" } },
      },
      ".hoverSlide": { pointerEvents: "none" },
    },
  },
}));

export default FeaturedProjectStyles;
