import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import PageTitle from "../AdminComponents/PageTitle/PageTitle";
import {
  businessUnitFieldValueArray,
  businessUnitWithFilterData,
  selectAllBusiness,
} from "./features/subGroupSlice";
import {
  addBusiness,
  getActiveBusiness,
  getAllBusiness,
  getBusiness,
  getBusinessFields,
  updateBusiness,
  updateBusinessStatus,
} from "./features/subGroupAction";
import { selectAllRegion } from "../groups/features/groupSlice";
import { getRegions } from "../groups/features/groupAction";
import CustomButton from "../../../components/Button/CustomButton";
import ToastHelper from "../../../helper/toastHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import ToggleSwitch from "pages/Admin-Pages/AdminComponents/ToggleSwitch/ToggleSwitch";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Admin-Pages/AdminComponents/CustomComponentfilter/CustomComponentFilter";
import Sorting from "utils/sorting";
import EditSecondaryGroupPopup from "./Components/EditSecondaryGroupPopup";
import RassignSecondaryGroup from "./Components/RassignSecondaryGroup";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import { groupsTabs } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import CommonGrid from "components/Grid/CommonGrid";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { useTranslation } from "react-i18next";

export default function SubGroup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessData = useSelector(selectAllBusiness);
  const businessData1 = useSelector(businessUnitWithFilterData);
  const regions = useSelector(selectAllRegion);
  const fieldValueData = useSelector(businessUnitFieldValueArray); //filter
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("department_count"),
    hiddenAgGridColumn("region"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("secondary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editBusiness,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "regions",
      headerName: t("primary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "regions.name",
          dispatchName: "regions.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"regions.name"}
                sortBy={"regions.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setOpenedFor("REASSIGN");
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props?.data?.department_count, // count
                  false, //no reassing happening
                  true, //open reassign popup
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              gridRef.current.api.deselectAll();
              setEditOpen(true);
              setCurrentRowData(props?.data);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setOpenedFor("DELETE");
              dispatch(getActiveBusiness()).then((response) => {
                if (
                  response?.payload?.total_bussiness <= 1 &&
                  props?.data?.enabled
                ) {
                  //if only one record is enabled and the record we are deleting is also enabled
                  //means we are deleting last enabled record then show toast
                  ToastHelper(
                    "error",
                    validationMessages.oneSecondaryGroupShouldBeEnabled,
                  );
                } else {
                  if (props?.data?.department_count > 0) {
                    setOpen(true);
                  } else {
                    setOpenDelete(true);
                  }
                }
              });
              setCurrentRowData(props?.data);
              setRowsToBeDeleted([props?.data?._id]);
              setSelectedRows([]);
              resetErrorStates();
            }}
          />
        </Grid>
      ),
    },
  ];

  const [businessName, setBusinessName] = useState("");
  const [selectedValue, setSelectedValue] = useState("-1");
  const [open, setOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState("");
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [selectValue, setSelectValue] = useState("-1");
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openedFor, setOpenedFor] = useState("");
  const [showReassign, setShowReassign] = useState(false);
  const [allSecondaryGroups, setAllSecondaryGroups] = useState(false);
  const [rowData, setRowData] = useState(businessData);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllBusiness()).then((res) => {
      if (res?.error) {
        return;
      }
      setAllSecondaryGroups(res?.payload?.data);
    });
  }, [dispatch, open, openDelete]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getBusiness(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = businessData1?.[commonKey]),
    );
    setRowData(
      businessData1?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        enabled: tableDataObj?.enabled,
        department_count: tableDataObj?.department_count,
        region: tableDataObj?.region,
        regions: tableDataObj?.regions?.[0]?.name,
        action: "",
        business_count: tableDataObj?.business_count,
        other_table_data: commonDataObj,
      })),
    );
  }, [businessData1]);

  useEffect(() => {
    setPreviousColumnFilterData(businessData1?.applied_filters);
  }, [businessData1]);

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  function handleEditPopClose() {
    setEditOpen(false);
  }

  const settingNewBusiness = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (!validationRegex?.checkForDoubleSpaces.test(e.target.value)) {
      setAlphaNumErrorAdd(validationMessages.noConsecutiveDoubleSpaces);
    } else if (e.target.value.length <= 50 && alphaNumericRegex.test(value)) {
      resetErrorStates();
      setBusinessName(e.target.value);
    } else if (!alphaNumericRegex.test(value)) {
      setAlphaNumErrorAdd(validationMessages.alphanumericOnly);
    }
  };

  function editBusiness(data) {
    const body = {
      businessId: data?.dataObj?._id || data?.businessId,
      newBusiness: data?.updatedValue || data?.newBusiness,
      enabled: data?.dataObj?.enabled || data?.enabled ? true : false,
      newRegionsId: data?.dataObj?.region || data?.newRegionsId,
    };

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = { body, dataState: modifiedDataState };
    dispatch(updateBusiness(dataBody)).then((res) => {
      dispatch(getBusiness(modifiedDataState));
      if (res?.error) {
        return;
      }
      setEditOpen(false);
    });
  }

  function addNewBusiness() {
    const trimmedAsset = !!businessName && businessName.trim();
    if (
      selectedValue === "" ||
      selectedValue === "-1" ||
      selectedValue === -1
    ) {
      ToastHelper("error", validationMessages.pleaseSelectRegion);
      return;
    } else if (!!trimmedAsset && businessName.trim() !== "") {
      const body = { name: trimmedAsset, region: selectedValue };
      dispatch(addBusiness(body)).then((res) => {
        if (res?.error) {
          return;
        }
        setDataState({
          ...dataState,
          search: "",
          limit: 20,
          page: 1,
          sortOrder: "desc",
          sortBy: "createdAt",
          column_name: "",
          column_values: "",
        });
        setSelectedValue(-1);
        setBusinessName("");
        resetErrorStates();
      });
    } else {
      ToastHelper("error", validationMessages.pleaseEnterBusinessUnit);
    }
  }

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      count, //number of child entries, enter 0 if do not want to open reassign popup
      newBusinessUnit, //new entry for reassign, only enter when required
      shouldPopupOpen = false,
      props, //grid data, will use datastate if not available
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }

        resetErrorStates();
        setSelectedRows([]);
        setRowsToBeDeleted([]);
        setOpen(false);

        if (switchValue && count > 0 && shouldPopupOpen) {
          //when deactivating if there are child elements open reassign popup
          //also only open the popup if there is more than one active
          dispatch(getActiveBusiness())
            .then((response) => {
              if (response?.payload?.total_bussiness > 1) {
                setOpen(true);
              } else {
                ToastHelper(
                  "error",
                  validationMessages.oneSecondaryGroupShouldBeEnabled,
                );
              }
            })
            .catch((e) => console.error(e));
          return;
        }

        //updating the ui
        //by toggling switchvalue and setting count
        rowNode.setDataValue("enabled", !switchValue);
        rowNode.setDataValue("department_count", count);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const dataBody = {
          body: {
            businessId: entryId,
            enabled: !switchValue,
          },
          dataState: modifiedDataState,
        };

        if (newBusinessUnit) {
          //this means we are also reassigning new business unit while updating the status
          dataBody.body.new_business_unit = newBusinessUnit;
        }

        //runnign the actual update api
        dispatch(updateBusinessStatus(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            dispatch(getBusiness(modifiedDataState));
            return "error";
          } else if (newBusinessUnit) {
            dispatch(getBusiness(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function dispatchGetSelectedData(data) {
    setDataState(data);
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getBusinessFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: true,
    //if true bulk delete will not check for extra conditions
    childCountExtension: "department_count",
    setOpenedFor,
    getActiveEntries: getActiveBusiness,
    activeTableEntriesExtension: "total_bussiness",
    lastRecordValidationMessage:
      validationMessages.oneSecondaryGroupShouldBeEnabled,
    getAllEntries: getAllBusiness,
    allEntriesExtension: "data",
    setOpen,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("organization")} />
      <TabsComponent tabsDataArray={groupsTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Grid container spacing={4} className="newTableBox">
          <Grid item xs={12}>
            <Box className="fieldTableMain">
              <Box className="fieldTableHeader">
                <Box className="table-upper customeBar_ withOutViewIcon">
                  <Box className="leftSideInputs">
                    <Box className={`addNewDivSelect`}>
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                          defaultValue={"-1"}
                        >
                          <MenuItem value={"-1"} className="select-none">
                            {t("selectPrimary")}
                          </MenuItem>
                          {regions &&
                            Array.isArray(regions) &&
                            Sorting(regions, "name")?.map((data, i) => {
                              return (
                                data?.enabled && (
                                  <MenuItem key={i} value={data?._id}>
                                    {data?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Grid container spacing={4} className={`addNew`}>
                      <Box className={"addNewDiv"}>
                        <TextField
                          placeholder={t("createSecondary")}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          onChange={settingNewBusiness}
                          onKeyDown={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter")
                              addNewBusiness();
                          }}
                          value={businessName}
                        />
                      </Box>
                      <Box className={"addNewDiv1"}>
                        <CustomButton
                          onClick={() => addNewBusiness()}
                          variant="outlined"
                        >
                          {t("addSecondary")}
                        </CustomButton>
                      </Box>
                      <span className="errorClass">{alphaNumErrorAdd}</span>
                    </Grid>
                    <Box className="addUserPagesBtn">
                      <PaginationLimitDropDown
                        dataState={dataState}
                        setDataState={setDataState}
                        setSelectedRows={setSelectedRows}
                      />
                    </Box>
                  </Box>
                </Box>
                <AgViewColumn
                  colDefs={colDefs}
                  setColDefs={setColDefs}
                  gridRef={gridRef}
                  dispatch={dispatch}
                  settingType={settingType}
                  dataState={dataState}
                />
              </Box>
              <CommonGrid
                commonGridObj={commonGridObj}
                selectedRows={selectedRows}
                setOpenDelete={setOpenDelete}
                setRowsToBeDeleted={setRowsToBeDeleted}
                setSelectedRows={setSelectedRows}
                isLoading={isLoading}
                totalPages={businessData1?.totalPages}
                handlePageChange={handlePageChange}
                currentPage={dataState?.page}
                {...extraBulkDeleteProps}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          dispatchGetSelectedData={dispatchGetSelectedData}
          fieldValueData={fieldValueData}
        />
      )}
      <EditSecondaryGroupPopup
        editOpen={editOpen}
        handleEditPopClose={handleEditPopClose}
        regions={regions}
        currentRowData={currentRowData}
        editBusiness={editBusiness}
      />
      <RassignSecondaryGroup
        open={open}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        resetErrorStates={resetErrorStates}
        allMainTableData={allSecondaryGroups}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        currentRowData={currentRowData}
        setOpen={setOpen}
        dataState={dataState}
        setDataState={setDataState}
        rowsToBeDeleted={rowsToBeDeleted}
        businessData={allSecondaryGroups}
        openedFor={openedFor}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        setSelectedRows={setSelectedRows}
        handleSwitchChange={handleSwitchChange}
        gridRef={gridRef}
      />
    </FieldManagementStyle>
  );
}
