import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../../../utils/axiosConfig";
import { apiRoutes } from "../../../../utils/apiRoutes";
import performApiRequest from "helper/apiRequestHelper";

export const profileSettingApi = createAsyncThunk(
  "dashboard/getFiveRequestApi",
  async (body) => {
    try {
      let apiUrl = `${apiRoutes.profileChange}`;
      return await performApiRequest(() => axiosConfig.post(apiUrl, body));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
