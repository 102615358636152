export function downloadCSV(jsonData, filename) {
  // Get the headers from the JSON object.
  const headers = Object.keys(jsonData[0]);
  // Create a CSV string.
  let csvData = "";
  csvData += headers.join(",") + "\n";

  // Iterate over the JSON object and add each row to the CSV string.
  jsonData.forEach((row) => {
    for (const header in row) {
      const value = row[header];
      csvData += JSON.stringify(value) + ",";
    }
    csvData += "\n";
  });
  // Remove the trailing comma from the CSV string.
  csvData = csvData.slice(0, -1);

  // Create a Blob object from the CSV data.
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create an anchor element and set its href attribute to the Blob object.
  const anchor = document.createElement("a");
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = filename;

  // Append the anchor element to the body of the document and click it to download the CSV file.
  document.body.appendChild(anchor);
  anchor.click();

  // Remove the anchor element from the body of the document.
  document.body.removeChild(anchor);
}
