import { createSlice } from "@reduxjs/toolkit";
import {
  allUserAddUserApi,
  allUserApproveUserApi,
  allUserDetailApi,
  allUserEditUserApi,
  allUserEnableDisableApi,
  createUsersApi,
  deleteAllUser,
  getAllUserApi,
  getUserFields,
  getUsersByRoleApi,
  rolesAndPermissionApi,
  userPermissionCategoriesApi,
} from "./allUserAction";

const initialState = {
  allUsers: [],
  allUserLoader: false,
  addUserLoader: false,
  roleUsers: [],
  allUserDetail: [],
  fieldValues: [],
  rolesAndPermissionsData: [],
  rolesAndPermissionsDataLoading: false,
  permissionCategory: [],
  perMissionCategoryLoading: false,
};

const allUserSlice = createSlice({
  name: "allUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserApi.pending, (state) => {
        state.allUserLoader = true;
      })
      .addCase(getAllUserApi.fulfilled, (state, action) => {
        state.allUsers = action.payload;
        state.allUserLoader = false;
      })
      .addCase(getAllUserApi.rejected, (state, action) => {
        state.allUserLoader = false;
      });

    builder
      .addCase(deleteAllUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAllUser.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(allUserEnableDisableApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allUserEnableDisableApi.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(allUserAddUserApi.pending, (state) => {
        state.addUserLoader = true;
      })
      .addCase(allUserAddUserApi.fulfilled, (state, action) => {
        state.addUserLoader = false;
      })
      .addCase(allUserAddUserApi.rejected, (state, action) => {
        state.addUserLoader = false;
      });
    builder
      .addCase(createUsersApi.pending, (state) => {
        state.addUserLoader = true;
      })
      .addCase(createUsersApi.fulfilled, (state, action) => {
        state.addUserLoader = false;
      })
      .addCase(createUsersApi.rejected, (state, action) => {
        state.addUserLoader = false;
      });
    builder
      .addCase(getUsersByRoleApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersByRoleApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.roleUsers = action.payload;
      });
    builder
      .addCase(allUserDetailApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allUserDetailApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.allUserDetail = action.payload;
      });
    builder
      .addCase(allUserEditUserApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allUserEditUserApi.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(allUserApproveUserApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allUserApproveUserApi.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getUserFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
    builder
      .addCase(rolesAndPermissionApi.pending, (state) => {
        state.rolesAndPermissionsDataLoading = true;
      })
      .addCase(rolesAndPermissionApi.fulfilled, (state, action) => {
        state.rolesAndPermissionsData = action.payload;
        state.rolesAndPermissionsDataLoading = false;
      })
      .addCase(rolesAndPermissionApi.rejected, (state, action) => {
        state.rolesAndPermissionsDataLoading = false;
      });
    builder
      .addCase(userPermissionCategoriesApi.pending, (state) => {
        state.perMissionCategoryLoading = true;
      })
      .addCase(userPermissionCategoriesApi.fulfilled, (state, action) => {
        state.permissionCategory = action.payload;
        state.perMissionCategoryLoading = false;
      })
      .addCase(userPermissionCategoriesApi.rejected, (state, action) => {
        state.perMissionCategoryLoading = false;
      });
  },
});

export const allUserState = (state) => state?.allUser?.allUsers;
export const allRoleUsers = (state) => state?.allUser?.roleUsers;
export const allUserDetails = (state) => state?.allUser?.allUserDetail?.user;
export const fieldValueArray = (state) => state?.allUser?.fieldValues?.users;
export const rolesAndPermisiion = (state) => state?.allUser;

export default allUserSlice.reducer;
