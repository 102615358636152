import { styled } from "@mui/system";

const PaginationStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  ".paginationComp": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ul: {
      li: {
        button: {
          height: "35px",
          width: "35px",
          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: theme.palette.white.main,
            borderRadius: "50%",
          },
          "&:hover": {
            background: theme.palette.white.main,
            boxShadow: `0 0 0px 1px ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            borderRadius: "50%",
          },
        },
      },
    },
  },
  ".addNewDivSelect": {
    margin: "0",
    "select.permission-role-select": {
      width: "auto",
      paddingRight: "40px",
      background: theme.palette.white.main,
    },
  },
}));

export default PaginationStyle;
