import { adminPermissionsPidArr, preDefinedRoles } from "./permissionUtils";
import store from "../../store/store";

export const checkForAdminPermission = () => {
  //check if user can access the admin dashboard
  const storeData = store.getState();
  const userPermissions = storeData?.user?.getPermissionData;
  const result = userPermissions?.some((permissionObj) => {
    return adminPermissionsPidArr?.includes(permissionObj?.pid);
  });
  return result;
};

export const checkPermissionFunction = (permissionId) => {
  const storeData = store.getState();
  const userPermissions = storeData?.user?.getPermissionData;
  return userPermissions?.some((permissionObj) => {
    return permissionObj?.pid === permissionId;
  });
};

const CheckPermissionComponent = (props) => {
  //show or hide children based on permission
  const { permissionId, children } = props;
  return checkPermissionFunction(permissionId) && children;
};

export default CheckPermissionComponent;

export const checkForSuperAdmin = () => {
  //check if user is super admin
  const storeData = store.getState();
  const userRole = storeData?.user?.getUserData?.role_data;
  return userRole === preDefinedRoles.superAdmin;
};

export const checkForClientAndSuperAdmin = () => {
  const storeData = store.getState();
  const userRole = storeData?.user?.getUserData?.role_data;
  return (
    userRole === preDefinedRoles.superAdmin ||
    userRole === preDefinedRoles.clientAdmin
  );
};
