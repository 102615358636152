import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getbannerActions = createAsyncThunk(
  "banner/getBannerData,",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes?.getBannerdata),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//feGetBanners
export const feGetBannerApi = createAsyncThunk(
  "banner/feGetBannerApi,",
  async (signal) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes?.feGetBanners, { signal }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteBanner = createAsyncThunk(
  "banner/delete",
  async (ids, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteBanner, ids),
      );

      return dispatch(getbannerActions());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateBanner = createAsyncThunk(
  "banner/updateBanner",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.bannerUpdate}${body.id}`, body),
      );

      return dispatch(getbannerActions());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
