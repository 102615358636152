import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const IptcCreatorSchema = (statesArray, citiesArray) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, validationMessages.tooLong)
      .matches(
        validationRegex.alphanumeric,
        validationMessages.alphanumericOnly,
      )
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      )
      .required(validationMessages.pleaseEnterCreatorName),
    job_title: Yup.string()
      .trim()
      .max(50, validationMessages.tooLong)
      .matches(
        validationRegex.alphanumeric,
        validationMessages.alphanumericOnly,
      )
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      )
      .required(validationMessages.pleaseEnterJobTitle),
    address: Yup.string()
      .required(validationMessages.pleaseEnterAddress)
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      )
      .max(50, validationMessages.tooLong),
    post_code: Yup.string()
      .trim()
      .required(validationMessages.pleaseEnterPostalCode)
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      )
      .matches(
        validationRegex.alphanumeric,
        validationMessages.alphanumericOnly,
      )
      .max(10, validationMessages.tooLong),
    phone: Yup.string()
      .required(validationMessages.pleaseEnterPhone)
      .test(
        "is-10-digits-or-valid-format",
        validationMessages.pleaseEnterPhoneValidFormat,
        (value) => {
          return (
            validationRegex.exactlyTenDigits.test(value) ||
            validationRegex.validPhoneNumber.test(value)
          );
        },
      ),
    website: Yup.string().trim().max(100, validationMessages.tooLong),
    email: Yup.string()
      .trim()
      .matches(
        validationRegex.checkForValidEmail,
        validationMessages.emailNotVaid,
      )
      .required(validationMessages.pleaseEnterEmail),
    country: Yup.string()
      .trim()
      .required(validationMessages.pleaseSelectCountry),
    state: Yup.string().when([], {
      is: () => statesArray.length > 0,
      then: () =>
        Yup.string().trim().required(validationMessages.pleaseSelectState),
    }),
    city: Yup.string().when([], {
      is: () => citiesArray.length > 0,
      then: () =>
        Yup.string().trim().required(validationMessages.pleaseSelectCity),
    }),
  });
