import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, TextField, Tooltip, Box, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../AdminComponents/PageTitle/PageTitle";
import {
  addUserRole,
  getActiveUserRoles,
  getAllUserRoles,
  getUserRoleFields,
  getUserRoles,
  updateUserRole,
  updateUserRoleStatus,
  userAndPermissionUpdateApi,
} from "./features/userRolePermissionAction";
import {
  selectAllUserRoleAll,
  userRolefieldValueArray,
  userRolesWithFilterData,
} from "./features/userRolePermissionSlice";
import {
  rolesAndPermissionApi,
  userPermissionCategoriesApi,
} from "./features/allUserAction";
import CustomButton from "../../../components/Button/CustomButton";
import ToastHelper from "../../../helper/toastHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Admin-Pages/AdminComponents/CustomComponentfilter/CustomComponentFilter";
import ToggleSwitch from "pages/Admin-Pages/AdminComponents/ToggleSwitch/ToggleSwitch";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import StatusAndReassignPopup from "./Components/UserRoleStatusReassignPopup/statusAndReassignPopup";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import { rolesAndPermisiion } from "./features/allUserSlice";
import { routeConfigs } from "utils/routeConfig";
import { useQuery } from "utils/queryString";
import {
  AgViewColumn,
  commonRowDataKeys,
  commonTooltipHeaderParams,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import CommonGrid from "components/Grid/CommonGrid";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import { CommonEditTooltip } from "components/TooltipComponent/CommonEditTooltip";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { useTranslation } from "react-i18next";

export default function UserRolesAndPermissions() {
  const { t } = useTranslation();
  const gridRef = useRef();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userRoleData1 = useSelector(userRolesWithFilterData);
  const fieldValueData = useSelector(userRolefieldValueArray); //filter
  const allMainTableData = useSelector(selectAllUserRoleAll); // reassign user role
  const data = useSelector(rolesAndPermisiion);
  const tabId1 = useQuery().get("id");

  let tabId = "Role";
  try {
    tabId = tabId1 ? atob(tabId1) : "Role";
  } catch (e) {}

  const categoryPermissionArray = data?.rolesAndPermissionsData?.permissions;

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  };

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("permissions"),
    hiddenAgGridColumn("editable"),
    hiddenAgGridColumn("user_count"),
    {
      field: "sl",
      headerName: "#",
      suppressFiltersToolPanel: true,
      headerClass: "srNo",
      maxWidth: 80,
      lockPosition: "left",
      checkboxSelection: false,
      headerCheckboxSelection: false,
      suppressNavigable: true,
      headerComponent: (params) => {
        return <th className="center">{params?.displayName}</th>;
      },
      cellRenderer: (params) => {
        return <span className="center">{params?.rowIndex + 1}</span>;
      },
      cellClass: "center",
    },
    {
      field: "role",
      headerName: t("userRoles"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "role",
          dispatchName: "role",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"role"}
                sortBy={"role"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: (params) => {
        return <div className="text_capitalize">{params?.value}</div>;
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editUserRoles,
      },
      cellClass: "editableCell",
      editable: (params) => params?.data?.editable,
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      hide: tabId !== "Role" && true,
      initialHide: tabId !== "Role" && true,
      suppressFiltersToolPanel: tabId !== "Role" && true,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          props?.data?.editable && (
            <Grid className="toggleBlockCo">
              <ToggleSwitch
                checked={props?.value}
                value={props?.value}
                defaultChecked={props?.value}
                onChange={(e) => {
                  setOpenedFor("REASSIGN");
                  setCurrentRowData(props?.data);
                  handleSwitchChange(
                    props?.data?._id, //id
                    props?.value, //value
                    props?.data?.user_count, // count
                    false, //no reassing happening
                    true, //open reassign popup
                  );
                }}
              />
            </Grid>
          )
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      hide: tabId !== "Role" && true,
      suppressFiltersToolPanel: tabId !== "Role" && true,
      initialHide: tabId !== "Role" && true,
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) =>
        props?.data?.editable && (
          <Grid container className="actionIcons">
            <TableDeleteIcon
              onClick={() => {
                setCurrentRowData(props?.data);
                setRowsToBeDeleted([props?.data?._id]);
                resetErrorStates();
                if (props?.data?.user_count > 0) {
                  dispatch(getAllUserRoles());
                  setOpenedFor("DELETE");
                  setOpen(true);
                } else {
                  setOpenDelete(true);
                }
              }}
            />
          </Grid>
        ),
    },
  ];

  const [colDefs, setColDefs] = useState(initialColDefs);
  const [rowData, setRowData] = useState([]);
  const [settingType, setSettingType] = useState();
  const [isLoading, setIsLoading] = useState();

  const [tabsDataArray, setTabsDataArray] = useState();
  const [userRoleName, setUserRoleName] = useState("");
  const [showReassign, setShowReassign] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("-1");
  const [currentRowData, setCurrentRowData] = useState(null);
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [openedFor, setOpenedFor] = useState("");
  const [openDelete, setOpenDelete] = useState();
  const [dataState, setDataState] = useState(initialDataState); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  //getting permission categories (Tabs)
  useEffect(() => {
    // setIsLoading(true);
    dispatch(userPermissionCategoriesApi()).then((resp) => {
      if (resp?.error) {
        return;
      }
      const dataArray = [
        {
          _id: "Role",
          value: routeConfigs.adminUserRolesAndPermisiions,
          label: t("role"),
        },
      ];

      resp?.payload?.permissions_category?.map((dataObj) => {
        dataArray.push({
          _id: dataObj,
          value: routeConfigs.adminUserRolesAndPermisiions,
          label: dataObj,
        });

        return false;
      });

      if (!window.location.search) {
        //set first tab when page opens for the first time
        navigate({
          pathname: window.location.pathname,
          search: `?id=${btoa(dataArray?.[0]?._id)}`,
          replace: true,
        });
      }
      setTabsDataArray(dataArray);
    });
    //eslint-disable-next-line
  }, [dispatch, searchParams]);

  //getting permissions (TableColumns)
  useEffect(() => {
    // setIsLoading(true);
    dispatch(rolesAndPermissionApi(tabId)).then((res) => {
      if (res?.error) {
        return;
      }
      const newColumns = res?.payload?.permissions
        ? insert(
            initialColDefs,
            6,
            res?.payload?.permissions?.map((permissionData) => {
              return {
                field: permissionData?.permissionName,
                headerName: permissionData?.permissionName,
                headerClass: "fieldCenter",
                headerComponentParams: commonTooltipHeaderParams,
                minWidth: 250,
                headerComponent: (props) => (
                  <th>
                    <span className="withIcon">
                      {props.displayName}

                      <CommonEditTooltip
                        tooltip_data={props?.tooltip_data}
                        displayName={props?.displayName}
                        field_name={props?.column?.colId}
                        dataState={props?.dataState}
                        setDataState={setDataState}
                      />
                    </span>
                  </th>
                ),
                cellClass: "category_center",
                cellRenderer: (props) => {
                  const colId = props?.column?.colId;
                  const colPermissionId = props?.data?.[colId];
                  const isChecked =
                    props?.data?.permissions?.includes(colPermissionId);

                  return (
                    <Box className="center">
                      <Tooltip
                        disableHoverListener={props?.data?.editable}
                        placement="top-start"
                        title={t("nonEditableField")}
                      >
                        <span>
                          <Checkbox
                            disabled={!props?.data?.editable}
                            defaultChecked={isChecked}
                            onChange={(e) => {
                              handletableCheckbox(
                                !e.target.checked,
                                colPermissionId,
                                props?.data?._id,
                              );
                            }}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  );
                },
              };
            }),
          )
        : initialColDefs;

      dispatch(getUserRoles(dataState)).then((res) => {
        if (res?.error) {
          return;
        }

        const paramsObj = {
          tableDataResponse: res, //response form get table data api
          setSettingType, //state to set setting type which will be requied in set column settings api
          colDefs: newColumns, //state which gives current column defination
          setColDefs, //state to set column definations
          dataState,
          settingCategory: tabId,
        };
        agSetColumnIndexes(paramsObj);
        setIsLoading(false);
      });
    });

    //eslint-disable-next-line
  }, [tabId, dispatch, dataState]);

  //setting previous column filter data
  useEffect(() => {
    setPreviousColumnFilterData(userRoleData1?.applied_filters);
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = userRoleData1?.[commonKey]),
    );
    setRowData(
      userRoleData1?.role_data?.map((fieldData) => {
        const dataObj = {
          _id: fieldData?._id,
          name: fieldData?.role, //for edit functionality
          permissions: fieldData?.permissions,
          editable: fieldData?.editable,
          user_count: fieldData?.user_count,
          role: fieldData?.role,
          enabled: fieldData?.enabled,
          other_table_data: commonDataObj,
        };
        categoryPermissionArray?.map((permData) => {
          dataObj[permData.permissionName] = permData?._id;
          return false;
        });
        return dataObj;
      }),
    );
  }, [userRoleData1, categoryPermissionArray]);

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const settingUserRole = (e) => {
    const value = e.target.value;
    if (inputRef.current !== null) {
      inputRef.current.value = value;
      setUserRoleName(e.target.value);
    }
    if (value.length > 50) {
      setAlphaNumErrorAdd(validationMessages.tooLong);
    } else if (!validationRegex.alphanumeric.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
    } else if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
    } else {
      resetErrorStates();
    }
  };

  function editUserRoles(data) {
    const body = {
      roleId: data?.dataObj?._id || data?.roleId,
      role: data?.updatedValue || data?.role,
      isenabled: data?.dataObj?.enabled || data?.isenabled ? true : false,
      permissions: data?.dataObj?.permissions || data?.permissions,
    };

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = { body, dataState: modifiedDataState };
    dispatch(updateUserRole(dataBody)).then((res) => {
      if (res?.error) {
        dispatch(getUserRoles(dataState));
        return;
      }
    });
  }

  function addNewUserRole() {
    const trimmedAsset = !!userRoleName && userRoleName.trim();
    if (!!trimmedAsset && userRoleName.trim() !== "") {
      if (trimmedAsset.length > 50) {
        setAlphaNumErrorAdd(validationMessages.tooLong);
      } else if (!validationRegex.alphanumeric.test(trimmedAsset)) {
        setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
      } else if (!validationRegex?.checkForDoubleSpaces.test(trimmedAsset)) {
        setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
      } else {
        const body = { role: trimmedAsset };
        dispatch(addUserRole(body)).then((res) => {
          if (!res?.error) {
            setDataState({
              ...dataState,
              search: "",
              limit: 20,
              page: 1,
              sortOrder: "desc",
              sortBy: "createdAt",
              column_name: "",
              column_values: "",
              filtersQueryString: [],
            });
            inputRef.current.value = "";
            setUserRoleName("");
            resetErrorStates();
          }
        });
      }
    } else {
      setAlphaNumErrorAdd(validationMessages.pleaseEnterUserRole);
    }
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      count, //number of child entries, enter 0 if do not want to open reassign popup
      newRoleId, //new entry for reassign, only enter when required
      shouldPopupOpen = false,
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        resetErrorStates();

        if (switchValue && count > 0 && shouldPopupOpen) {
          //when deactivating if there are child elements open reassign popup
          //also only open the popup if there is more than one active
          //keeping active case for future use
          //if the only predefined role is superadmin
          dispatch(getActiveUserRoles())
            .then((response) => {
              if (response?.payload?.total_roles > 1) {
                dispatch(getAllUserRoles());
                setOpen(true);
              } else {
                ToastHelper("error", validationMessages.oneRoleShouldBeEnabled);
              }
            })
            .catch((e) => {
              console.error(e);
            });
          return;
        }

        const dataBody = {
          body: {
            roleId: entryId,
            enabled: !switchValue,
          },
          dataState,
        };

        if (newRoleId) {
          //set the newRoleId key in the api body to tell the we are reassigning
          dataBody.body.newRoleId = newRoleId;
        }
        //updating the ui without reassigning
        //by toggling switchvalue and setting count

        rowNode.setDataValue("enabled", !switchValue);
        rowNode.setDataValue("user_count", count);

        //running the update status api
        dispatch(updateUserRoleStatus(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            //this will also revert the ui
            dispatch(getUserRoles(dataState));
            return "error";
          } else if (newRoleId) {
            //only run get api in case of reassign
            dispatch(getUserRoles(dataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getUserRoleFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const handletableCheckbox = (value, permissionId, roleId) => {
    const body = {
      roleid: roleId,
      permission_id: permissionId,
      status: !value,
    };
    dispatch(userAndPermissionUpdateApi(body));
  };

  /*
   *The following code is to insert columns according to permission
   *dynamically in the roles and permissions table
   */
  const insert = (arr, index, newItem) => [
    // part of the columns array before the specified index
    ...arr.slice(0, index),
    // inserted new dynamic columns
    ...newItem,
    // part of the columns array after the specified index
    ...arr.slice(index),
  ];

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <Box className="headingWBtn">
        <PageTitle title={t("rolesAndPermission")} />
      </Box>
      <TabsComponent
        tabsDataArray={tabsDataArray}
        setDataState={setDataState}
        initialDataState={initialDataState}
      />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType collectionAssets">
                <Box className="leftSideInputs">
                  <Box className="addUserPagesBtn">
                    <Grid container spacing={4} className={"addNew"}>
                      <div className={`addNewDiv`}>
                        <TextField
                          placeholder={t("createUserRole")}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          onChange={settingUserRole}
                          onKeyDown={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter")
                              addNewUserRole();
                          }}
                          inputRef={inputRef}
                        />
                        <span
                          style={{ display: "flex" }}
                          className="errorClass"
                        >
                          {alphaNumErrorAdd}
                        </span>
                      </div>
                      <div className={`addNewDiv1`}>
                        <CustomButton
                          onClick={() => addNewUserRole()}
                          variant="outlined"
                        >
                          {t("save")}
                        </CustomButton>
                      </div>
                      <PaginationLimitDropDown
                        dataState={dataState}
                        setDataState={setDataState}
                        setSelectedRows={null}
                      />
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
                permission_category={tabId}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              isLoading={isLoading}
              totalPages={userRoleData1?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <StatusAndReassignPopup
        open={open}
        setOpen={setOpen}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        dataState={dataState}
        setDataState={setDataState}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        resetErrorStates={resetErrorStates}
        allMainTableData={allMainTableData}
        currentRowData={currentRowData}
        rowsToBeDeleted={rowsToBeDeleted}
        setRowsToBeDeleted={setRowsToBeDeleted}
        openedFor={openedFor}
        userRoleData1={userRoleData1}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        categoryPermissionArray={categoryPermissionArray}
        handleSwitchChange={handleSwitchChange}
        gridRef={gridRef}
      />
      {/* Filter */}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}
