import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EditSubCategory } from "pages/Admin-Pages/asset-administration/AssetSubCategory/Components/EditSubCategory";
import { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Sorting from "utils/sorting";
import { secondaryGroupSchema } from "utils/validation/validation-schemas/secondaryGroupSchema";

const EditSecondaryGroupPopup = (props) => {
  const { t } = useTranslation();
  const {
    editOpen,
    handleEditPopClose,
    regions,
    currentRowData,
    editBusiness,
  } = props;

  const initialValues = {
    businessId: currentRowData?._id,
    newBusiness: currentRowData?.name,
    newRegionsId: currentRowData?.region || " ",
    enabled: currentRowData?.enabled,
  };

  return (
    <DialogCommon
      fullwidth={true}
      open={editOpen}
      handleClose={handleEditPopClose}
      title={t("enterSecondaryOrganization")}
      maxWidth={"md"}
    >
      <DialogContent>
        <EditSubCategory>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={secondaryGroupSchema}
            onSubmit={(values) => {
              editBusiness(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="request-common addNewDivSelect">
                  <Box className="fields">
                    <label className="label">{t("primaryOrganization")}</label>
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        className="permission-role-select"
                        name="newRegionsId"
                        value={values?.newRegionsId}
                        onChange={handleChange}
                        defaultValue={" "}
                      >
                        <MenuItem value={" "}>{t("selectPrimary")}</MenuItem>
                        {Sorting(regions, "name")?.map((val, index) => {
                          return (
                            (val?._id === values?.newRegionsId ||
                              val?.enabled) && (
                              <MenuItem key={index + 1} value={val?._id}>
                                {val?.name}
                              </MenuItem>
                            )
                          );
                        })}
                      </Select>
                    </FormControl>
                    <span className="errorClass">
                      {errors.newRegionsId &&
                        touched.newRegionsId &&
                        errors.newRegionsId}
                    </span>
                  </Box>
                  <Box className="fields">
                    <label className="label">
                      {t("secondaryOrganization")}
                    </label>
                    <TextField
                      placeholder={t("enterSecondaryOrganization")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="newBusiness"
                      onChange={(e) => {
                        if (e.target.value?.length <= 50) {
                          setFieldValue("newBusiness", e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values?.newBusiness}
                    />
                    <span className="errorClass">
                      {errors?.newBusiness &&
                        touched?.newBusiness &&
                        errors?.newBusiness}
                    </span>
                  </Box>
                </div>
                <DialogActions className="popup-button-outer">
                  <StyledButton variant={"contained"} type="submit">
                    {t("update")}
                  </StyledButton>
                  <StyledButton
                    variant={"contained"}
                    onClick={handleEditPopClose}
                  >
                    {t("cancel")}
                  </StyledButton>
                </DialogActions>
              </form>
            )}
          </Formik>
        </EditSubCategory>
      </DialogContent>
    </DialogCommon>
  );
};

export default EditSecondaryGroupPopup;
