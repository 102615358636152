import { createSlice } from "@reduxjs/toolkit";
import {
  deleteNotificationApi,
  getAllNotificationsApi,
  readAllNotificationsApi,
  readSingleNotificationApi,
} from "./layoutAction";

const initialState = {
  notifications: [],
  getNotificationsLoadingState: false,
  putNotificationsLoadingState: false,
  heroSearchFixed: false,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.notifications = action.payload;
    },
    fixHeroSearch: (state, action) => {
      state.heroSearchFixed = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get all notifications
    builder
      .addCase(getAllNotificationsApi.pending, (state) => {
        state.getNotificationsLoadingState = true;
      })
      .addCase(getAllNotificationsApi.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.getNotificationsLoadingState = false;
      })
      .addCase(getAllNotificationsApi.rejected, (state) => {
        state.getNotificationsLoadingState = false;
      });
    //mark single notification as read
    builder
      .addCase(readSingleNotificationApi.pending, (state) => {
        state.putNotificationsLoadingState = true;
      })
      .addCase(readSingleNotificationApi.fulfilled, (state, action) => {
        state.putNotificationsLoadingState = false;
      })
      .addCase(readSingleNotificationApi.rejected, (state) => {
        state.putNotificationsLoadingState = false;
      });
    //mark all notification as read
    builder
      .addCase(readAllNotificationsApi.pending, (state) => {
        state.putNotificationsLoadingState = true;
      })
      .addCase(readAllNotificationsApi.fulfilled, (state, action) => {
        state.putNotificationsLoadingState = false;
      })
      .addCase(readAllNotificationsApi.rejected, (state) => {
        state.putNotificationsLoadingState = false;
      });
    //delete notification
    builder
      .addCase(deleteNotificationApi.pending, (state) => {
        state.putNotificationsLoadingState = true;
      })
      .addCase(deleteNotificationApi.fulfilled, (state, action) => {
        state.putNotificationsLoadingState = false;
      })
      .addCase(deleteNotificationApi.rejected, (state) => {
        state.putNotificationsLoadingState = false;
      });
  },
});

export const { addNotification, fixHeroSearch } = notificationsSlice.actions;
export const dataStateNotifications = (state) => state.notifications;
export const isHeaderFixed = (state) => state.notifications.heroSearchFixed;

export default notificationsSlice.reducer;
