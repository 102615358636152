import { createSlice } from "@reduxjs/toolkit";
import {
  addUserRole,
  deleteUserRole,
  getUserRoles,
  updateUserRole,
  updateUserRoleStatus,
  getUserPermissionsAll,
  updateRolePermissions,
  getSingleRoleData,
  getActiveUserRoles,
  getUserRoleFields,
  getAllUserRoles,
  userAndPermissionUpdateApi,
} from "./userRolePermissionAction";

const initialState = {
  userRoles: [],
  userPermissionsAll: [],
  fieldValues: [],
  userRolesAll: [],
  permissionLoading: false,
};

const userRolePermissionSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRoles = action.payload;
      });
    //reassign user role  getAllUserRoles
    builder
      .addCase(getAllUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllUserRoles.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRolesAll = action.payload;
      });
    builder
      .addCase(addUserRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUserRole.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteUserRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserRole.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateUserRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateUserRoleStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserRoleStatus.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(getUserPermissionsAll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserPermissionsAll.fulfilled, (state, action) => {
        state.status = "idle";
        state.userPermissionsAll = action.payload.message;
      });
    builder
      .addCase(updateRolePermissions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRolePermissions.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(getSingleRoleData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSingleRoleData.fulfilled, (state, action) => {
        state.status = "idle";
        state.rolePermissionsList = action.payload.role_data;
      });

    builder
      .addCase(getActiveUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveUserRoles.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getUserRoleFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserRoleFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
    builder
      .addCase(userAndPermissionUpdateApi.pending, (state) => {
        state.permissionLoading = true;
      })
      .addCase(userAndPermissionUpdateApi.fulfilled, (state, action) => {
        state.permissionLoading = false;
      })
      .addCase(userAndPermissionUpdateApi.rejected, (state, action) => {
        state.permissionLoading = false;
      });
  },
});

export const selectAllUserRole = (state) => {
  return state.userRole.userRoles.role_data;
};

//reassign all user roles
export const selectAllUserRoleAll = (state) => {
  return state.userRole.userRolesAll.role_data;
};

export const userRolesWithFilterData = (state) => {
  return state.userRole.userRoles;
};

export const userRolefieldValueArray = (state) =>
  state?.userRole?.fieldValues?.roles;

export default userRolePermissionSlice.reducer;
