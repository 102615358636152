import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const createCuratedGalleryApi = createAsyncThunk(
  "curatedGallery/createCuratedGalleryApi",
  async (formData) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminCreateCuratedGallery, formData),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminGetCuratedGalleries
export const getCuratedGalleries = createAsyncThunk(
  "curatedGallery/getCuratedGalleries",
  async (id) => {
    try {
      return await performApiRequest(
        () => axiosConfig.get(`${apiRoutes.adminGetCuratedGalleries}/${id}`),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateGalleryName
export const updateGalleryNameApi = createAsyncThunk(
  "curatedGallery/updateGalleryNameApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateGalleryName}/${data?.id}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminAddCuratedGalleryLink
export const addCuratedGalleryLinkApi = createAsyncThunk(
  "curatedGAllery/addCuratedGalleryLinkApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAddCuratedGalleryLink}/${data?.id}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminCuratedGalleryUpdateIndexes
export const curatedGalleryUpdateIndexesApi = createAsyncThunk(
  "curatedGAllery/curatedGalleryUpdateIndexesApi",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminCuratedGalleryUpdateIndexes}`,
            data?.body,
          ),
        null,
        data?.showToast,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCuratedLinkApi = createAsyncThunk(
  "curatedGAllery/updateCuratedLinkApi",
  async (body) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminCuratedGalleryUpdateLink}/${body.id}`,
            body,
          ),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminCuratedGalleryUpdateLinkTarget

export const curatedGalleryUpdateLinkTargetApi = createAsyncThunk(
  "curatedGAllery/curatedGalleryUpdateLinkTargetApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminCuratedGalleryUpdateLinkTarget}/${body.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteCuratedGalleryApi = createAsyncThunk(
  "curatedGAllery/deleteCuratedGalleryApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminCuratedLinkDelete}/${data?.colId}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
