/*
 * We should be able to use this inline cell editor for ag-grid-react
 * Almost everywhere we need
 */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;
const KEY_ESC = 27;

export const AgCellEditor = forwardRef((props, ref) => {
  const { checkAlphaNumericValidation, editFunction } = props;

  const refInput = useRef(null);

  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();

  const [value, setValue] = useState(initialState.value);
  const [error, setError] = useState("");
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
    initialState.highlightAllOnFocus,
  );

  useEffect(() => {
    // focus on the input
    setTimeout(() => {
      if (refInput && refInput.current) {
        refInput.current.focus();
      }
    });
  }, []);

  const isLeftOrRight = (event) => {
    return [37, 39].indexOf(event.keyCode) > -1;
  };

  const getCharCodeFromEvent = (event) => {
    return typeof event.which === "undefined" ? event.keyCode : event.which;
  };

  const deleteOrBackspace = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_DELETE || charCode === KEY_BACKSPACE;
  };

  const finishedEditingPressed = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return (
      charCode === KEY_ENTER || charCode === KEY_TAB || charCode === KEY_ESC
    );
  };

  const onKeyDown = (event) => {
    if (isLeftOrRight(event) || deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    //I don't know why, but I am unable to get rid of thsi !true without breaking anything
    if (!finishedEditingPressed(event) && !true && event.preventDefault)
      event.preventDefault();
  };

  const handleChange = (e) => {
    if (
      checkAlphaNumericValidation &&
      !validationRegex.alphanumeric.test(e.target.value)
    ) {
      setError(validationMessages.alphanumericOnly);
    } else if (!validationRegex.checkForDoubleSpaces.test(e.target.value)) {
      setError(validationMessages.noConsecutiveDoubleSpaces);
    } else {
      setError("");
      setValue(e.target.value);
    }
  };

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      afterGuiAttached() {
        // get ref from React component
        const eInput = refInput.current;
        if (eInput) {
          eInput.focus();
          if (highlightAllOnFocus) {
            eInput.select();

            setHighlightAllOnFocus(false);
          } else {
            // when we started editing, we want the caret at the end, not the start.
            // comes into play in two scenarios: a) when user hits F2 and b)
            // when user hits a printable character, then on IE (and only IE) the caret
            // was placed after the first character, thus 'apply' would end up as 'pplea'
            const length = eInput.value ? eInput.value.length : 0;
            if (length > 0) {
              eInput.setSelectionRange(length, length);
            }
          }
        }
      },
      // the final value to send to the grid, on completion of editing
      getValue() {
        //can run edit api function from here

        if (!value || value?.trim() === "") {
          return props?.data?.name;
        }
        const data = {
          updatedValue: value,
          dataObj: props?.data,
        };
        editFunction(data);
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        if (props.charPress && "1234567890".indexOf(props.charPress) < 0) {
          return true;
        }
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return (
    <Box className="cellEditMain">
      <input
        type="text"
        ref={refInput}
        value={value}
        onChange={handleChange}
        maxLength={50}
        onKeyDown={(event) => onKeyDown(event)}
        name="AgCellInput"
      />
      <span className="errorClass">{error}</span>
    </Box>
  );
});
