import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

//get all notification list
export const getAllNotificationsApi = createAsyncThunk(
  "notification/getAllNotificationsApi",
  async (signal) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getAllNotifications, { signal }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//mark single notification as read
export const readSingleNotificationApi = createAsyncThunk(
  "notification/readSingleNotificationApi",
  async (id, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(`${apiRoutes.markSingleNotificationRead}/${id}`),
        "",
        false,
      );
      return dispatch(getAllNotificationsApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//mark all notifications as read
export const readAllNotificationsApi = createAsyncThunk(
  "notification/readAllNotificationsApi",
  async (_, { dispatch }) => {
    //underscore is there because dispatch is the second parameter
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.markAllNotificationRead),
        "",
        false,
      );
      return dispatch(getAllNotificationsApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//delete notifications
export const deleteNotificationApi = createAsyncThunk(
  "notification/deleteNotificationApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.deleteNotifications, body),
        "",
        false,
      );
      return dispatch(getAllNotificationsApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
