import i18n from "Assets/i18n/config";

const t = i18n.t;

export const footerDamMetrics = [
  { metric_name: t("totalAssets"), metric_value: 7230 },
  { metric_name: `DAM ${t("views")}`, metric_value: 31748 },
];

export const footerContentPages = [
  {
    sectionHeading: "Quick Links",
    pages: [
      { pageName: "Vectors", pageLink: "/vectors" },
      { pageName: "Photos", pageLink: "/photos" },
      { pageName: "Ai Images", pageLink: "/ai-images" },
      { pageName: "Icons", pageLink: "/icons" },
      { pageName: "Logos", pageLink: "/logos" },
      { pageName: "Videos", pageLink: "/videos" },
    ],
  },
  {
    sectionHeading: "Support",
    pages: [
      { pageName: "Contact us", pageLink: "/contact-us" },
      { pageName: "FAQ", pageLink: "/faq" },
      { pageName: "Site map ", pageLink: "/site-map" },
    ],
  },
];

export const rightsReservedContent = `${t(
  "allContents",
)} © 1902-2024 Turner Construction Company. ${t("allRightReserved")}.`;
