import { styled } from "@mui/system";
import React from "react";

export const textVariants = {
  _18px: {
    medium: "18px-Medium",
  },
  _16px: {
    regular: "16px-Regular",
    semiBold: "16px-SemiBold",
  },
  _14px: {
    regular: "14px-Regular",
    medium: "14px-Medium",
    semiBold: "14px-SemiBold",
  },
  _13px: {
    regular: "13px-Regular",
  },
  _12px: {
    medium: "12px-Medium",
    semiBold: "12px-SemiBold",
  },
  _10px: {
    medium: "10px-Medium",
    semiBold: "10px-SemiBold",
  },
};

const commonStyles = ({ theme }) => {
  return {
    fontFamily: "poppins",
    // color: theme.palette.blackColor.main,
    letterSpacing: "-0.01em",
  };
};

const HeadingStyles = styled("span")(({ theme, variant }) => {
  return {
    ...commonStyles({ theme }),
    //18px
    ...(variant === textVariants._18px.medium && {
      fontWeight: "500",
      fontSize: "1.125rem",
      lineHeight: "1.44rem",
    }),
    //16px
    ...(variant === textVariants._16px.regular && {
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    }),
    ...(variant === textVariants._16px.semiBold && {
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    }),
    //14px
    ...(variant === textVariants._14px.regular && {
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
    }),
    ...(variant === textVariants._14px.medium && {
      fontWeight: "500",
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
    }),
    ...(variant === textVariants._14px.semiBold && {
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
    }),
    //13px
    ...(variant === textVariants._13px.regular && {
      fontWeight: "400",
      fontSize: "0.8125rem",
      lineHeight: "1.2188rem",
    }),
    //12px
    ...(variant === textVariants._12px.medium && {
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
    }),
    ...(variant === textVariants._12px.semiBold && {
      fontWeight: "600",
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
    }),
    //10px
    ...(variant === textVariants._10px.medium && {
      fontWeight: "500",
      fontSize: "0.625rem",
      lineHeight: "0.9375rem",
    }),
    ...(variant === textVariants._10px.semiBold && {
      fontWeight: "500",
      fontSize: "0.625rem",
      lineHeight: "0.9375rem",
    }),
    ...(variant === "default" && {
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    }),
  };
});

const TextTag = (props) => {
  const { children } = props;
  return <HeadingStyles {...props}>{children}</HeadingStyles>;
};

export default TextTag;
