import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterFirstName),
  last_name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterLastName),
  email: Yup.string()
    .trim()
    .max(76, validationMessages.tooLong)
    .matches(
      validationRegex.checkForValidEmail,
      validationMessages.emailNotVaid,
    )
    .required(validationMessages.pleaseEnterEmail),
  role: Yup.string().trim().required(validationMessages.pleaseSelectUserRole),
  region: Yup.string().trim(),
  business_unit: Yup.string().trim(),
  department: Yup.string().trim(),
});
