import { styled } from "@mui/system";

export const MetaFileBlock = styled("div")(({ theme }) => ({
    
  ".metaDataClass": {
    ".accordingWh": {
      h3: {
        marginBottom: 6,
      },
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      marginBottom: 19,
      ".selectAll": {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      },
      ".addNewDivSelect": {
        maxWidth: 356,
        flex: "0 0 100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        span: {
          fontWeight: 400,
          marginBottom: 10,
          "&.errorClass": {
            marginBottom: 0,
          },
        },
        ".MuiInputBase-root": {
          width: "100%",
          maxWidth: 356,
          background: "#fff",
        },
        "span.MuiButtonBase-root.MuiCheckbox-root": {
          position: "absolute",
          right: 0,
          padding: 0,
        },
      },
      "span.toggleHs": {
        marginLeft: 15,
        cursor: "pointer",
      },
    },
    "&.customTabblock": {
      ".accordingWh": {
        marginBottom: 0,
      },
      ".metaDataFields": {
        backgroundColor: "transparent",
        padding: 0,
      },
    },
    ".metaDataFields": {
      background: "#e5e5e5",
      padding: "20px 31px 0",
      display: "flex",
      flexWrap: "wrap",
      gap: "0 30px",
      ".addNewDivSelect": {
        maxWidth: "calc(100% / 4 - 23px)",
        margin: "0 0 28px",
        display: "flex",
        flexDirection: "column",
        span: {
          fontWeight: 400,
          marginBottom: 10,
          "&.errorClass": {
            marginBottom: 0,
          },
        },
        "span.MuiButtonBase-root.MuiCheckbox-root": {
          position: "absolute",
          right: 0,
          padding: 0,
        },
        ".MuiInputBase-root": {
          width: "100%",
          height: 40,
          background: "#fff",
          borderRadius: 4,
          input: {
            maxWidth: 326,
          },
        },
      },
    },
    ".form": {
      ".newBlock": {
        ".addNewDivSelect": {
          display: "grid",
          width: "100%",
          maxWidth: 356,
          marginBottom: 25,
          "span.MuiButtonBase-root.MuiCheckbox-root": {
            position: "absolute",
            right: 0,
            padding: 0,
            top: 0,
          },
        },
      },
      ".metaDataFields": {
        paddingTop: 20,
        marginTop: 25,
        ".addNewDivSelect": {
          position: "relative",
          "span.MuiButtonBase-root.MuiCheckbox-root": {
            position: "absolute",
            right: 0,
            top: 0,
            padding: 0,
            margin: 0,
          },
        },
        "&.lastFileds": {
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 30,
          ".addNewDivSelect": {
            "&:nth-of-type(1)": {
              flex: "0 0 100%",
              maxWidth: "100%",
              ".MuiInputBase-root input": {
                maxWidth: "100%",
              },
              "&.collectionName": {
                maxWidth: "calc(50% - 15px)",
              },
            },
            "&:not(:first-of-type)": {
              maxWidth: "calc(50% - 15px)",
              textarea: {
                height: 120,
                borderColor: "#cfcdcd",
                padding: 15,
                borderRadius: 4,
                outlineColor: "#0056b8",
                resize: "unset",
                fontSize: 18,
                "&:hover": {
                  borderColor: "#000",
                },
                "&::placeholder": {
                  fontSize: 17,
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  opacity: 0.5,
                },
              },
            },
          },
          ".collectionFildes": {
            maxWidth: "calc(50% - 15px)",
            width: "100%",
            ".addNewDivSelect": {
              "&:nth-of-type(1)": {
                maxWidth: "100%",
              },
            },
          },
        },
      },
    },
  
  },

  ".metaDataClass.activeHide": {
    ".accordingWh": {
      ".addNewDivSelect": {
        display: "none",
      },
      "~ .metaDataFields": {
        display: "none",
      },
    },
    form: {
      display: "none",
    },
    ".applyBottomBtn": {
      display: "none",
    },
  },

}));

export default MetaFileBlock;
