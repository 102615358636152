import React from 'react'

const TrashIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="17.898" height="20.455" viewBox="0 0 17.898 20.455">
  <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1.278,18.537A1.918,1.918,0,0,0,3.2,20.455H14.7a1.918,1.918,0,0,0,1.918-1.918V5.114H1.278ZM12.145,8.31a.639.639,0,0,1,1.278,0v8.949a.639.639,0,0,1-1.278,0Zm-3.835,0a.639.639,0,1,1,1.278,0v8.949a.639.639,0,0,1-1.278,0Zm-3.835,0a.639.639,0,0,1,1.278,0v8.949a.639.639,0,0,1-1.278,0ZM17.259,1.278H12.464L12.089.531A.959.959,0,0,0,11.23,0H6.664a.948.948,0,0,0-.855.531l-.376.747H.639A.639.639,0,0,0,0,1.918V3.2a.639.639,0,0,0,.639.639H17.259A.639.639,0,0,0,17.9,3.2V1.918A.639.639,0,0,0,17.259,1.278Z"/>
</svg>
  
  )
}

export default TrashIcon