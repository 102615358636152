import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../../../utils/axiosConfig";
import { apiRoutes } from "../../../../utils/apiRoutes";
import performApiRequest from "helper/apiRequestHelper";

export const getFiveRequestApi = createAsyncThunk(
  "dashboard/getFiveRequestApi",
  async () => {
    try {
      const apiUrl = `${apiRoutes.dashboardPendingRequests}?data_limit=all`;

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getdashbordApi = createAsyncThunk(
  "dashboard/getdashboardApi",
  async () => {
    try {
      const apiUrl = `${apiRoutes.dashbordgetdata}`;
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const dashboardChangeUserSettingApi = createAsyncThunk(
  "dashboard/dashboardChangeUserSettingApi",
  async (body) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.dashBoardChangeUserSettings, body),
        "_",
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
