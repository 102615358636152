import React from "react";

const SpanishFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1000 600"
    >
      <rect width="1000" height="600" fill="#FFC400" />
      <path fill="#C60B1E" d="M0 0h1000v200H0zM0 400h1000v200H0z" />
    </svg>
  );
};

export default SpanishFlag;
