import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "../../../../utils/apiRoutes";
import axiosConfig from "../../../../utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getBusiness = createAsyncThunk(
  "business/getBusiness",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetBusiness}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetBusiness}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllBusiness = createAsyncThunk(
  "business/getAllBusiness",
  async () => {
    try {
      return performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetBusiness}?limit=5000&data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addBusiness = createAsyncThunk(
  "business/addBusiness",
  async (newBusiness) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminAddBusiness, newBusiness),
        "Business Added!",
      );
      // return dispatch(getBusiness());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteBusiness = createAsyncThunk(
  "business/deleteBusiness",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.adminDeleteBusiness, data?.body),
        "Business Deleted!",
      );
      return dispatch(getBusiness(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateBusiness = createAsyncThunk(
  "business/updateBusiness",
  async (data, { dispatch }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminUpdateBusiness, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateBusinessStatus = createAsyncThunk(
  "business/updateBusinessStatus",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateBusinessStatus}/${data?.body?.businessId}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getActiveBusiness = createAsyncThunk(
  "business/getActiveBusiness",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminGetActiveBusiness),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getBusinessFields = createAsyncThunk(
  "user/getBusinessFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetBusinessFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetBusinessFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
