import React from "react";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { ArrowLinkComp } from "components/Links/ArrowLinkComp";
import MarketSegmentStyles from "./MarketSegmentStyles";
import { useTranslation } from "react-i18next";

const MarketSegment = () => {
  const { t } = useTranslation();

  const dummyData = {
    sectionHeading1: t("marketSectionHeading1"),
    sectionHeading2: t("marketSectionHeading2"),
    desc: t("marketSectionDesc"),
    segmentArr: [
      { name: "Photos", href: "#" },
      { name: "Cultural", href: "#" },
      { name: "Education", href: "#" },
      { name: "Entertainment", href: "#" },
      { name: "Pharmaceutical", href: "#" },
      { name: "FinancialServices", href: "#" },
      { name: "Healthcare", href: "#" },
      { name: "Hospitality", href: "#" },
      { name: "Manufacturing", href: "#" },
      { name: "Residential", href: "#" },
      { name: "Retail", href: "#" },
      { name: "Speciality", href: "#" },
      { name: "Sports", href: "#" },
      { name: "Theater/Studio", href: "#" },
    ],
  };

  return (
    <MarketSegmentStyles>
      <div className="marketSegmentMain">
        <div className="marketLeftSection">
          <div className="marketHeading">
            <HeadingTag variant={headingVariants.h2}>
              {dummyData?.sectionHeading1}
            </HeadingTag>
            <HeadingTag variant={headingVariants.h2}>
              {dummyData?.sectionHeading2}
            </HeadingTag>
          </div>
          <div className="marketDescription">
            <TextTag variant={textVariants._16px.regular}>
              {dummyData?.desc}
            </TextTag>
          </div>
        </div>
        <div className="marketRightSection">
          <div className="linksBlock">
            <ul>
              {dummyData?.segmentArr?.map(
                (data, index) =>
                  index < 5 && (
                    <ArrowLinkComp
                      link={data?.href}
                      title={data?.name}
                      key={index}
                    />
                  ),
              )}
            </ul>
            <ul>
              {dummyData?.segmentArr?.map(
                (data, index) =>
                  index >= 5 &&
                  index < 10 && (
                    <ArrowLinkComp
                      link={data?.href}
                      title={data?.name}
                      key={index}
                    />
                  ),
              )}
            </ul>
            <ul>
              {dummyData?.segmentArr?.map(
                (data, index) =>
                  index >= 10 &&
                  index <= 15 && (
                    <ArrowLinkComp
                      link={data?.href}
                      title={data?.name}
                      key={index}
                    />
                  ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </MarketSegmentStyles>
  );
};

export default MarketSegment;
