import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { selectCurrentUser } from "pages/Admin-Pages/user-administration/features/userSlice";

import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { getUserData } from "pages/Admin-Pages/user-administration/features/userAction";
import { useTranslation } from "react-i18next";
import { userSignOut } from "pages/General-Pages/WithoutAuthPages/features/userAction";

const ForceResetPasswordDialog = () => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
  };

  const handleResetPassword = (url) => {
    dispatch(userSignOut())
      .then((response) => {
        if (!response) {
          return;
        }
        handleClose();
        localStorage.clear();
        window.location.assign(url);
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  return (
    user?.is_temp_password === true && (
      <DialogCommon
        open={true}
        handleClose={handleClose}
        title={t("resetPassword")}
        hideclone={true}
        fullWidth={true}
      >
        <Box
          className={"resetButtonPopUP"}
          style={{ textAlign: "center", padding: "35px 0 15px" }}
        >
          <Box sx={{ mb: 2 }}>{t("changePasswordText")}</Box>
          <CustomButton onClick={() => handleResetPassword(user.reset_url)}>
            {t("resetPassword")}
          </CustomButton>
        </Box>
      </DialogCommon>
    )
  );
};

export default ForceResetPasswordDialog;
