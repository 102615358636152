import { createSlice } from "@reduxjs/toolkit";
import {
  addDepartment,
  deleteDepartment,
  getActiveDeartment,
  getAllDepartments,
  getDepartemtsByBusinessUnitApi,
  getDepartmentFields,
  getDepartments,
  updateDepartment,
  updateDepartmentStatus,
} from "./departmentAction";

const initialState = {
  departments: [],
  activeDepartments: "",
  fieldValues: [],
  businessDepartments: [],
};
const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.status = "idle";
        state.departments = action.payload;
      });
    //getAllDepartments
    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(addDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateDepartmentStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDepartmentStatus.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(getActiveDeartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveDeartment.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //getDepartemtsByBusinessUnitApi
    builder
      .addCase(getDepartemtsByBusinessUnitApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartemtsByBusinessUnitApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessDepartments = action.payload;
      });
    //filter
    builder
      .addCase(getDepartmentFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartmentFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});
//getDepartmentFields
export const selectAllDepartment = (state) =>
  state.department.departments.departments;
export const allDeprtMentsWithFilterData = (state) =>
  state.department.departments;

export const departmentfieldValueArray = (state) =>
  state?.department?.fieldValues?.departments;
export default departmentSlice.reducer;

export const businessDepartmentsData = (state) =>
  state?.department?.businessDepartments;
