import React from 'react'

const TcdamBlueText = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="25" viewBox="0 0 105 25" fill="none">
            <path d="M9.81094 0.00407248V4.82485H14.9789V9.00232H9.77205V17.4753C9.77205 19.3442 10.7598 20.2603 12.2024 20.2603C12.9257 20.2603 13.7618 20.0201 14.4423 19.6618L15.7333 23.7985C14.4034 24.3563 13.3029 24.5925 11.8952 24.6332C7.82775 24.792 5.16795 22.3653 5.16795 17.4713V8.99825H0V4.82078H5.16795V0H9.80316L9.81094 0.00407248Z" fill="#0056B8" />
            <path d="M32.6098 21.897C30.4438 24.1242 28.1651 24.9996 25.3887 24.9996C19.9563 24.9996 15.4338 21.5794 15.4338 14.617C15.4338 7.65452 19.9563 4.23438 25.3887 4.23438C28.0485 4.23438 30.1016 5.03241 32.1509 7.13743L29.2267 10.3581C28.1262 9.32388 26.7574 8.80679 25.4664 8.80679C22.3517 8.80679 20.0691 11.1927 20.0691 14.617C20.0691 18.3588 22.4994 20.3457 25.3887 20.3457C26.8702 20.3457 28.3518 19.906 29.4911 18.713L32.6059 21.897H32.6098Z" fill="#0056B8" />
            <path d="M52.22 0.00390625V24.401H47.5848V21.6974C46.1383 24.0427 43.8207 24.8407 41.5419 24.8407C36.0318 24.8407 31.8904 21.0215 31.8904 14.5762C31.8904 7.81325 35.9579 4.31166 41.4292 4.31166C43.4046 4.31166 46.4844 5.42728 47.5848 7.45494V0.00797562H52.22V0.00390625ZM36.5257 14.5762C36.5257 17.9963 38.8821 20.3456 41.8453 20.3456C44.8084 20.3456 47.2776 18.1185 47.2776 14.5762C47.2776 11.0339 44.7695 8.84743 41.8453 8.84743C38.921 8.84743 36.5257 11.0339 36.5257 14.5762Z" fill="#0056B8" />
            <path d="M69.0497 4.78924H73.6499V24.4062H69.0497V21.5398C67.9881 23.8484 65.06 24.96 62.9718 25.0007C57.4228 25.0414 53.3203 21.4584 53.3203 14.5774C53.3203 7.69637 57.6133 4.27214 63.0846 4.31286C65.5927 4.31286 67.9881 5.54656 69.0497 7.49685V4.79331V4.78924ZM57.9555 14.5774C57.9555 18.3151 60.4248 20.5464 63.5046 20.5464C70.7996 20.5464 70.7996 8.64912 63.5046 8.64912C60.4248 8.64912 57.9555 10.8356 57.9555 14.5774Z" fill="#0056B8" />
            <path d="M88.1273 24.405V13.9002C88.1273 11.3148 86.8363 8.96546 84.2893 8.96546C81.7423 8.96546 80.2996 11.3107 80.2996 13.9002V24.405H75.6644V4.75134H80.2996V7.13731C81.2873 5.14629 83.4532 4.42969 85.242 4.42969C87.4857 4.42969 89.7256 5.38652 90.791 8.09006C92.4631 5.30508 94.6291 4.50705 97.0594 4.50705C102.379 4.50705 105 7.9272 105 13.8188V24.4009H100.365V13.8188C100.365 11.2333 99.3382 9.04282 96.83 9.04282C94.3219 9.04282 92.7625 11.3107 92.7625 13.8962V24.4009H88.1273V24.405Z" fill="#0056B8" />
        </svg>
    )
}

export default TcdamBlueText