import { createSlice } from "@reduxjs/toolkit";
import {
  forgetPassword,
  getUserDataApi,
  resetPassword,
  userLogin,
  userRegisterApi,
  userSignOut,
} from "./userAction";

const initialState = {
  userAuth: [],
  isError: "",
  loginLoading: false,
  userData: {},
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.userAuth = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loginLoading = false;
        state.isError = action.payload;
      });
    builder
      .addCase(userSignOut.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userSignOut.fulfilled, (state, action) => {
        state.status = "idle";
        state.isError = "";
      })
      .addCase(userSignOut.rejected, (state, action) => {
        state.status = "idle";
        state.isError = action.payload;
      });
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.loginLoading = false;
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loginLoading = false;
      });
    builder
      .addCase(getUserDataApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDataApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.userData = action.payload;
      });
    builder
      .addCase(userRegisterApi.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(userRegisterApi.fulfilled, (state, action) => {
        state.loginLoading = false;
      })
      .addCase(userRegisterApi.rejected, (state, action) => {
        state.loginLoading = false;
      });
  },
});

export const loginState = (state) => state.userAuth;

export default userAuthSlice.reducer;
