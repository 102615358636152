import { createSlice } from "@reduxjs/toolkit";
import {
  approveUser,
  deleteUserRequest,
  getBusinessRegion,
  getUserData,
  getUserRequests,
  getUserRequestsFields,
} from "./userAction";

const initialState = {
  userRequests: [],
  fieldValues: [],
  getPermissionData: [],
  user: [],
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserRequests.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRequests = action.payload;
      });
    builder
      .addCase(getUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = "idle";
        state.getUserData = action.payload.data;
        state.getPermissionData = action?.payload?.user?.[0]?.userPermissions;
        state.user = action.payload;
      });
    builder
      .addCase(deleteUserRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserRequest.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(getBusinessRegion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBusinessRegion.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(approveUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveUser.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getUserRequestsFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserRequestsFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const selectAllUserRequest = (state) => state.user.userRequests;
export const selectCurrentUser = (state) => state.user.getUserData;
export const userPermissions = (state) => state.user.getPermissionData;
export const userData = (state) => state.user.user.user;

export const userRequestsFieldValueArray = (state) => {
  return state?.user?.fieldValues?.users;
};

export default userSlice.reducer;
