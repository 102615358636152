import React from 'react'

const ChangePasswordIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.395" height="22.166" viewBox="0 0 19.395 22.166">
    <path id="Icon_awesome-lock" data-name="Icon awesome-lock" d="M17.317,9.7H16.278V6.58a6.58,6.58,0,1,0-13.161,0V9.7H2.078A2.079,2.079,0,0,0,0,11.776v8.312a2.079,2.079,0,0,0,2.078,2.078H17.317A2.079,2.079,0,0,0,19.4,20.088V11.776A2.079,2.079,0,0,0,17.317,9.7Zm-4.5,0H6.58V6.58a3.117,3.117,0,1,1,6.234,0Z" fill="#383838"/>
  </svg>
  
  )
}

export default ChangePasswordIcon