import { styled } from "@mui/system";

export const LoginComponentStyles = styled("div")(
  ({ theme }) => `
   .paper {
    text-align: center;
}
.
`,
);

export const DialogChildren = styled("div")(
  ({ theme }) => `
   .paper {
    text-align: center;
    button{
      margin-top:15px;
    }
}
.
`,
);
