import React from 'react'

const ImageIconProfileSetting = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.455" viewBox="0 0 25 20.455">
  <path id="Icon_material-photo-size-select-actual" data-name="Icon material-photo-size-select-actual" d="M24.227,4.5H3.773A2.444,2.444,0,0,0,1.5,6.773V22.682a2.279,2.279,0,0,0,2.273,2.273H24.227A2.444,2.444,0,0,0,26.5,22.682V6.773A2.444,2.444,0,0,0,24.227,4.5ZM6.045,20.409,10.023,15.3l2.841,3.42,3.977-5.125,5.114,6.818Z" transform="translate(-1.5 -4.5)"/>
</svg>
  
  )
}

export default ImageIconProfileSetting