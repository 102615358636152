import { styled } from "@mui/system";

export const HeaderMenuStyles = styled("div")(({ theme }) => ({
  "nav.menu": {
    // position: "absolute",
    // width: "calc(100% + 289px)",
    // top: "0",
    // right: "calc(-100% + -300px)",
    // height: "100vh",
    // zIndex: "99999",
    // display: "flex",
    // transition: "all 0.3s linear",
    // overflow: "auto",
    // "> ul": {
    //   height: '100vh',
    //   paddingBottom: '0',
    // },

    " ul": {
      width: "100%",
      maxWidth: "288px",
      background: "#fff",
      margin: "0 0 0 auto",
      padding: "32px 16px",
      listStyle: "none",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      li: {
        padding: "0 16px",
        "span, a": {
          color: theme.palette.blackColor.main,
          textDecoration: "none",
          cursor: "pointer",
        },
        "&:is(.signOut)": {
          margin: "auto 0 0 0",
          borderRadius: "8px",
          span: {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            lineHeight: "37px",
          },
          "&:hover": {
            backgroundColor: "rgba(245, 245, 246, 1)",
          },
        },
        "&.adminDash": {
          padding: "24px 16px",
          border: "1px solid #E9E9E9",
          borderRight: "0",
          borderLeft: "0",
        },
        ul: {
          padding: "16px 0",
          li: {
            marginBottom: "16px",
            cursor: "pointer",
            padding: "0 16px",
            background: theme.palette.white.main,
            lineHeight: "37px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgba(245, 245, 246, 1)",
            },
          },
        },
        "&.userDetails": {
          ".userInfo ": {
            display: "flex",
            alignItems: "flex-start",
            gap: "15px",
            paddingBottom: "24px",
            ".userNameInfo": {
              " .avatarWrapper": {
                ".MuiAvatar-root": {
                  width: "32px",
                  height: "32px",
                },
              },
            },
            ".emailClass": {
              wordBreak: "break-all",
              span: {
                color: "rgba(129, 134, 140, 1)",
              },
            },
          },
          ".avatarClass": {
            minWidth: "40px",
            height: "40px",
            background: "#F5F5F6",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Roboto , sans-serif",
          },
        },
        "&.items:nth-of-type(3)": {
          padding: "0",
        },
      },
      // "&.mainSiderBlock": {
      //   height: '100vh',
      //   overflow: 'auto',
      // },
    },
  },
}));
