import React from "react";
import { Button, IconButton, Box, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { readSingleNotificationApi } from "Layout/features/layoutAction";
import i18n from "Assets/i18n/config";

export default function Notification({
  setNotificationsMenu,
  deleteNotification,
  ...props
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = i18n;

  function isJSON(str) {
    try {
      JSON.stringify(JSON.parse(str));
      return true;
    } catch (e) {
      return false;
    }
  }

  const message = isJSON(props?.message)
    ? JSON?.parse(props?.message)?.[language]
    : "";

  function handleNotificationClick() {
    //read single notification
    if (!props?.is_read) dispatch(readSingleNotificationApi(props?._id));
    props?.notification_detail !== "" && navigate(props?.notification_detail);
    setNotificationsMenu(null);
  }

  return (
    <Box
      className={`notificationContainer ${props.is_read ? "read" : "unread"}`}
    >
      <Box className="notificationCard" onClick={handleNotificationClick}>
        <Box className={`messageContainer`}>
          <Typography className={"containedTypography"}>{message}</Typography>

          {props.extraButton && props.extraButtonClick && (
            <Button
              onClick={props.extraButtonClick}
              disableRipple
              className={"extraButton"}
            >
              {props.extraButton}
            </Button>
          )}
        </Box>
      </Box>

      <IconButton onClick={() => deleteNotification(props?._id)}>
        <HighlightOffIcon />
      </IconButton>
    </Box>
  );
}
