import { styled } from "@mui/system";

const FindLookingForStyles = styled("div")(({ theme }) => ({
  ".findLookingForSectionMain": {
    background: "rgba(245, 245, 246, 1)",
    padding: "7.5rem 0 7.375rem 4rem",
    ".featured_slider": {
      ".findLookingForSlideMain": {
        position: "relative",
        cursor: "pointer",
        height: "13.333vw",
        ".findLookingSlide": {
          position: "relative",
          overflow: "hidden",
          borderRadius: "10px",
          height: "13.333vw",
          span: {
            position: "absolute",
            bottom: "26px",
            left: "32px",
            color: theme.palette.whiteColor.main,
            zIndex: "2",
            textTransform: "capitalize",
          },
          img: {
            width: "100%",
            height: "13.333vw",
            objectFit: "cover",
            aspectRatio: "19/12",
          },
          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            // background: "rgb(0,0,0)",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.4682247899159664) 0%, rgba(0,0,0,0) 49%)",
            zIndex: "1",
            transition: "all 0.2s linear",
            borderRadius: "10px",
          },
          "&:hover": {
            "&:after": {
              opacity: "1",
              zIndex: "0",
              background:
                "linear-gradient(0deg, rgb(16 20 25 / 65%) 0%, rgb(0 0 0 / 9%) 100%)",
            },
          },
        },
        ".hoverSlide": {
          position: "absolute",
          top: "-28px",
          left: "32px",
          transition: "all 0.2s linear",
          ".slideHoverTop": {
            span: {
              color: theme.palette.whiteColor.main,
              opacity: "0.4",
              fontFamily: "Poppins",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            },
          },
        },
        "&:hover": {
          ".hoverSlide": {
            top: "28px",
          },
        },
      },
    },
  },
}));

export default FindLookingForStyles;
