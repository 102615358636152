import React, { useEffect } from "react";
import { Box, Checkbox, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllIptcCreators } from "pages/Admin-Pages/aui-form-fields/IptcCoreFields/features/iptcCreatorActions";
import { iptcCreatorsState } from "pages/Admin-Pages/aui-form-fields/IptcCoreFields/features/iptcCreatorSlice";
import { MenuItem } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import TextfieldComp from "components/Textfield/TextfieldComp";
import { getallCustomTags } from "../Features/addAssetAction";
import { MetaFileBlock } from "./MetaDataStyle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useTranslation } from "react-i18next";

const MetaData = ({
  formik,
  toogle,
  setToogle,
  setState,
  state,
  initialValue,
}) => {
  const dispatch = useDispatch();
  const iptcCreatorsData = useSelector(iptcCreatorsState);
  const { t } = useTranslation();

  // const [state, setState] = useState(initialValue);

  useEffect(() => {
    dispatch(getAllIptcCreators());
    dispatch(getallCustomTags());
  }, [dispatch]);

  const handleClearButton = () => {
    formik.setValues({
      ...formik.values,
      checkboxes: Object.fromEntries(
        Object.keys(formik.values.checkboxes).map((key) => [key, false]),
      ),
    });
    formik.setFieldValue("creator_id", "");
    formik.setFieldValue("credit_line", "");
    formik.setFieldValue("copyright_notice", "");
    formik.setFieldValue("copyright_status", "");
    formik.setFieldValue("right_usage_terms", "");
    formik.setFieldValue("keywords", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("name", "");
    formik.setFieldValue("collection_name", "");
    setState(initialValue);
  };

  const handleCheckboxChange = (checkboxName) => {
    //When a checkbox in metadata section is clicked

    if (checkboxName === "applyAll") {
      //when click on apply all checkbox

      formik.setValues((prevValues) => {
        //looping through checkboxes and making them all checked or unchecked based on applyAllValue
        if (prevValues.applyAll) {
          //making all checkboxes false
          Object.keys(prevValues.checkboxes).forEach((key) => {
            prevValues.checkboxes[key] = false;
          });
        } else {
          //making all checkboxes true
          Object.keys(prevValues.checkboxes).forEach((key) => {
            prevValues.checkboxes[key] = true;
          });
        }
        return { ...prevValues, applyAll: !prevValues.applyAll };
      });
    } else {
      //when click on other checkboxes except applyAll and collection_name_checkbox
      formik.setValues((values) => {
        const updatedCheckboxes = {
          ...values.checkboxes,
          [checkboxName]: !values.checkboxes[checkboxName],
        };

        //checking if all the checkboxes are checked or not to make all checkbox checked
        let allCheckBoxes = true;
        Object.keys(updatedCheckboxes).forEach((key) => {
          if (!updatedCheckboxes[key]) {
            allCheckBoxes = false;
          }
        });

        return {
          ...values,
          applyAll: allCheckBoxes,
          checkboxes: updatedCheckboxes,
        };
      });
    }
  };

  const handleChangeCreator = (value) => {
    let data = iptcCreatorsData?.iptcCreators?.data?.find(
      (val) => val._id === value,
    );
    if (value === "") {
      setState(initialValue);
    } else {
      setState({
        ...state,
        email: data?.email,
        address: data?.address,
        country: data?.country,
        state: data?.state,
        businessUnit: data?.creator_business?.name,
        postalCode: data?.post_code,
        phone: data?.phone,
        website: data?.website,
      });
    }
  };

  return (
    <MetaFileBlock>
      <Box
        className={`metaDataClass ${
          toogle?.metadata ? "activeShow" : "activeHide"
        } `}
      >
        <Box className="accordingWh">
          <h3>{t("metaData")}</h3>
          <Box className="selectAll">
            <Checkbox
              name="checkboxes.applyAll"
              onChange={() => handleCheckboxChange("applyAll")}
              checked={formik?.values?.applyAll}
            />
            <span variant="standard">{t("applyForAll")}</span>
            <span className="toggleHs">
              {" "}
              {toogle?.metadata ? (
                <RemoveCircleOutlineIcon
                  onClick={() => setToogle({ ...toogle, metadata: false })}
                />
              ) : (
                <AddCircleOutlineIcon
                  onClick={() => setToogle({ ...toogle, metadata: true })}
                />
              )}
            </span>
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creator")}</span>
            <Checkbox
              name="checkboxes.creator_id"
              onChange={() => handleCheckboxChange("creator_id")}
              checked={formik.values?.checkboxes.creator_id}
              className="permission-role-select"
            />
            <Select
              name="creator_id"
              className="permission-role-select"
              value={formik?.values?.creator_id || " "}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (e.target.value === " ") {
                  handleChangeCreator("");
                  formik.setFieldValue("creator_id", "");
                } else {
                  handleChangeCreator(e.target.value);
                  formik.handleChange(e);
                }
              }}
            >
              <MenuItem value={" "}>{t("selectCreator")}</MenuItem>
              {iptcCreatorsData?.iptcCreators?.data?.map((val, index) => {
                return (
                  <MenuItem key={val?._id} value={val?._id}>
                    {val?.name}
                  </MenuItem>
                );
              })}
            </Select>

            <span className="errorClass">
              {formik?.touched?.creator_id && formik?.errors?.creator_id}
            </span>
          </Box>
        </Box>

        <Box className="metaDataFields  lastFileds">
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorEmail")}</span>
            <TextfieldComp
              name="email"
              value={state?.email}
              placeholder={t("email")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorCountry")}</span>
            <TextfieldComp
              value={state?.address}
              name="address"
              placeholder={t("address")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorCountry")}</span>
            <TextfieldComp
              name="country"
              value={state?.country}
              placeholder={t("country")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorState")}</span>
            <TextfieldComp
              name="state"
              value={state?.state}
              placeholder={t("state")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorSecondaryOrganization")}</span>
            <TextfieldComp
              name="businessUnit"
              value={state?.businessUnit}
              placeholder={`Secondary Organization`}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorPostalCode")}</span>
            <TextfieldComp
              name="postalCode"
              value={state?.postalCode}
              placeholder={t("postalCode")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorPhone")}</span>
            <TextfieldComp
              name="phone"
              value={state?.phone}
              placeholder={t("phone")}
              disabled={true}
            />
          </Box>
          <Box className="addNewDivSelect">
            <span variant="standard">{t("creatorWebsite")}</span>
            <TextfieldComp
              name="website"
              value={state?.website}
              placeholder={t("website")}
              disabled={true}
            />
          </Box>
        </Box>

        <Box className="form">
          <Box className="metaDataFields ">
            <Box className="addNewDivSelect">
              <span>{t("creditLine")}</span>
              <TextfieldComp
                name="credit_line"
                value={formik?.values?.credit_line}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                placeholder={t("enterCreditTitle")}
              />
              <Checkbox
                name="checkboxes.credit_line"
                onChange={() => handleCheckboxChange("credit_line")}
                checked={formik.values?.checkboxes.credit_line}
              />
              <span className="errorClass">
                {formik?.touched?.credit_line && formik?.errors?.credit_line}
              </span>
            </Box>
            <Box className="addNewDivSelect">
              <span>{t("copyrightNotice")}</span>
              <TextfieldComp
                name="copyright_notice"
                value={formik?.values?.copyright_notice}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                placeholder={t("enterCopyrightNotice")}
              />
              <Checkbox
                name="checkboxes.copyright_notice"
                onChange={() => handleCheckboxChange("copyright_notice")}
                checked={formik.values?.checkboxes.copyright_notice}
              />
              <span className="errorClass">
                {formik.touched.copyright_notice &&
                  formik?.errors?.copyright_notice}
              </span>
            </Box>
            <Box className="addNewDivSelect">
              <span>{t("copyrightStatus")}</span>
              <TextfieldComp
                name="copyright_status"
                value={formik?.values?.copyright_status}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                placeholder={t("enterCopyrightStatus")}
              />
              <Checkbox
                name="checkboxes.copyright_status"
                onChange={() => handleCheckboxChange("copyright_status")}
                checked={formik.values?.checkboxes.copyright_status}
              />
              <span className="errorClass">
                {formik.touched.copyright_status &&
                  formik?.errors?.copyright_status}
              </span>
            </Box>
            <Box className="addNewDivSelect">
              <span>{t("rightUsageTerms")}</span>
              <TextfieldComp
                name="right_usage_terms"
                value={formik?.values?.right_usage_terms}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                placeholder={t("enterRightUsageTerms")}
              />
              <Checkbox
                name="checkboxes.right_usage_terms"
                onChange={() => handleCheckboxChange("right_usage_terms")}
                checked={formik.values?.checkboxes.right_usage_terms}
              />
              <span className="errorClass">
                {formik.touched.right_usage_terms &&
                  formik?.errors?.right_usage_terms}
              </span>
            </Box>
          </Box>
          <Box className="metaDataFields lastFileds">
            {formik.values.chooseUpload === "new" && (
              <Box className="addNewDivSelect collectionName">
                <span>{t("collectionType")}</span>
                <TextfieldComp
                  name="collection_name"
                  value={formik?.values?.collection_name}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  placeholder={t("enterCollectionType")}
                />

                <span className="errorClass">
                  {formik.touched.collection_name &&
                    formik?.errors?.collection_name}
                </span>
              </Box>
            )}
            <Box className="addNewDivSelect">
              <span>{t("assetName")}</span>
              <TextfieldComp
                name="name"
                value={formik?.values?.name}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                placeholder={t("enterAssetName")}
              />
              <Checkbox
                name="checkboxes.name"
                onChange={() => handleCheckboxChange("name")}
                checked={formik.values?.checkboxes.name}
              />
              <span className="errorClass">
                {formik.touched.name && formik?.errors?.name}
              </span>
            </Box>
            <Box className="addNewDivSelect">
              <span>{t("keywords")}</span>
              <textarea
                name="keywords"
                value={formik?.values?.keywords}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t("enterKeywords")}
              />
              <Checkbox
                name="checkboxes.keywords"
                onChange={() => handleCheckboxChange("keywords")}
                checked={formik.values?.checkboxes.keywords}
              />
              <span className="errorClass">{formik?.errors?.keywords}</span>
            </Box>
            <Box className="addNewDivSelect">
              <span>{t("description")}</span>
              <textarea
                name="description"
                value={formik?.values?.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t("enterDescription")}
              />
              <Checkbox
                name="checkboxes.description"
                onChange={() => handleCheckboxChange("description")}
                checked={formik.values?.checkboxes.description}
              />
              <span className="errorClass">
                {formik.touched.description && formik?.errors?.description}
              </span>
            </Box>
          </Box>
        </Box>
        <Box className="applyBottomBtn">
          <CustomButton>{t("apply")}</CustomButton>
          <CustomButton onClick={handleClearButton}>{t("clear")}</CustomButton>
        </Box>
      </Box>
    </MetaFileBlock>
  );
};

export default MetaData;
