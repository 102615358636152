import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import {
  addPagesToFooterApi,
  createCmsPageApi,
  deleteCmsPageApi,
  getAllCmsPagesApi,
  getHierarchyofPagesCms,
  updateFooterSubMenuApi,
} from "pages/Admin-Pages/pages-cms/features/pagesCmsAction";
import {
  allCmsPagesData,
  footerMenuLoadingStatus,
} from "pages/Admin-Pages/pages-cms/features/pagesCmsSlice";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import AddPageToFooterStyles from "./AddPageToFooterStyles";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CommonDeletePopup from "pages/Admin-Pages/AdminComponents/CommonDeletePopup/CommonDeletePopup";

const AddNewPagePopup = (props) => {
  const {
    openAddPopup,
    setOpenAddPopup,
    id,
    link,
    openFor,
    handleRemoveChild,
  } = props;
  const [openDelete, setOpenDelete] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const pagesData = useSelector(allCmsPagesData);

  const footerMenuLoadingState = useSelector(footerMenuLoadingStatus);

  const wantToAddObj = {
    addNewPage: "addNewPage",
    addHyperlink: "addHyperLink",
  };

  const wantToAddData = [
    { name: t("addNewPage"), value: wantToAddObj.addNewPage },
    { name: t("addHyperlink"), value: wantToAddObj.addHyperlink },
  ];

  const initialValues = {
    wantToAdd:
      link?.type === "page"
        ? wantToAddObj.addNewPage
        : link?.type === "href"
        ? wantToAddObj.addHyperlink
        : " ",
    pageId: link && link?._id ? link?._id : " ",
    title: link && link?.title ? link?.title : "",
    href: link && link?.description ? link?.description : "",
    target_blank: link && link?.target_blank ? link?.target_blank : false,
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      wantToAdd: Yup.string()
        .trim()
        .required(validationMessages.pleaseSelectWhatToAdd),
      title: Yup.string()
        .trim()
        .required(validationMessages.pleaseAddTitle)
        .max(50, validationMessages.tooLong)
        .matches(
          validationRegex?.checkForDoubleSpaces,
          validationMessages?.noConsecutiveDoubleSpaces,
        ),
      pageId: Yup.string().when("wantToAdd", {
        is: wantToAddObj.addNewPage,
        then: () =>
          Yup.string().trim().required(validationMessages.pleaseSelectPage),
      }),
      href: Yup.string().when("wantToAdd", {
        is: wantToAddObj.addHyperlink,
        then: () =>
          Yup.string()
            .trim()
            .required(validationMessages.pleaseEnterHyperlink)
            .url(validationMessages.pleaseEnterValidHyperlink),
      }),
    }),
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (openAddPopup) {
      dispatch(getAllCmsPagesApi());
    }
  }, [dispatch, openAddPopup]);

  function handleClose() {
    setOpenAddPopup(false);
    formik.resetForm();
  }

  function handleSubmit(values) {
    if (values?.wantToAdd === wantToAddObj.addNewPage) {
      //if we want to add/edit new page
      if (openFor === "EDIT") {
        //when editing page
        const body = {
          title: values?.title,
          page_id: values?.pageId,
          target_blank: values?.target_blank,
          type: link?.type,
          colId: link?.link_id,
        };
        dispatch(updateFooterSubMenuApi(body)).then((res) => {
          if (res?.error) return;
          dispatch(getHierarchyofPagesCms());
          handleClose();
        });
      } else {
        //when adding page
        const body = {
          id: values.pageId,
          colId: id,
          display_title: values?.title,
          target_blank: values?.target_blank,
        };
        dispatch(addPagesToFooterApi(body)).then((res) => {
          if (res?.error) return;
          dispatch(getHierarchyofPagesCms());
          handleClose();
        });
      }
    } else if (values?.wantToAdd === wantToAddObj.addHyperlink) {
      //if we want to add/edit  a new hyperlink
      if (openFor === "EDIT") {
        //when editing hyperlink
        const body = {
          title: values?.title,
          page_id: values?.pageId,
          target_blank: values?.target_blank,
          type: link?.type,
          colId: link?.link_id,
          description: values.href,
        };
        dispatch(updateFooterSubMenuApi(body)).then((res) => {
          if (res?.error) return;
          dispatch(getHierarchyofPagesCms());
          handleClose();
        });
      } else {
        //when adding
        const body = {
          description: values.href,
          title: values.title,
          type: "href",
          target_blank: values?.target_blank,
        };
        const data = { body, toast: false };
        dispatch(createCmsPageApi(data)).then((res) => {
          if (res?.error) return;

          const body = {
            id: res?.payload?.data?._id,
            colId: id,
            display_title: values?.title,
            target_blank: values?.target_blank,
          };
          dispatch(addPagesToFooterApi(body)).then((res) => {
            if (res?.error) return;
            dispatch(getHierarchyofPagesCms());
            handleClose();
          });
        });
      }
    }
  }

  function handleDelete() {
    setOpenDelete(false);
    if (link?.type === "page") {
      handleRemoveChild(link, id).then(() => {
        dispatch(getHierarchyofPagesCms());
        handleClose();
      });
    } else if (link?.type === "href") {
      const body = {
        ids: [formik.values.pageId],
      };
      const databody = { body, toast: false };
      dispatch(deleteCmsPageApi(databody)).then((res) => {
        if (res?.error) {
          return;
        }
        handleRemoveChild(link, id).then(() => {
          dispatch(getHierarchyofPagesCms());
          handleClose();
        });
      });
    }
  }

  return (
    <DialogCommon
      open={openAddPopup}
      handleClose={handleClose}
      title={
        openFor === "EDIT"
          ? t("editNewPageOrHyperlink")
          : t("addNewPageOrHyperlink")
      }
      extraPaperClasses={`addHyperLinkAndpage ${i18n.language}`}
    >
      <AddPageToFooterStyles>
        <div className="addPagePopupMain">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="dropDownSection">
                <span>{t("whatYouWantToAdd")}</span>
                <FormControl
                  variant="outlined"
                  className={"formControl"}
                  size="small"
                >
                  <Select
                    className="permission-role-select"
                    name="wantToAdd"
                    value={formik.values.wantToAdd}
                    onChange={formik.handleChange}
                    disabled={openFor === "EDIT"}
                  >
                    <MenuItem value={" "}>{t("select")}</MenuItem>
                    {wantToAddData?.map((data, index) => (
                      <MenuItem value={data?.value} key={index}>
                        {data?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span className="errorClass">
                  {formik.errors.wantToAdd &&
                    formik.touched.wantToAdd &&
                    formik.errors.wantToAdd}
                </span>
              </div>
              {formik.values.wantToAdd === wantToAddObj.addNewPage && (
                <div className="dropDownSection">
                  <span>{t("selectPage")}</span>
                  <FormControl
                    variant="outlined"
                    className={"formControl"}
                    size="small"
                  >
                    <Select
                      className="permission-role-select"
                      name="pageId"
                      value={formik.values.pageId}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={" "}>{t("select")}</MenuItem>
                      {pagesData?.sub_custom_page?.map((pageData, index) => (
                        <MenuItem value={pageData?._id} key={index}>
                          {pageData?.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="errorClass">
                    {formik.errors.pageId &&
                      formik.touched.pageId &&
                      formik.errors.pageId}
                  </span>
                </div>
              )}
              {formik.values.wantToAdd === wantToAddObj.addHyperlink && (
                <div className="dropDownSection">
                  <span>{t("addUrl")}</span>
                  <TextField
                    placeholder={t("addUrl")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="href"
                    value={formik?.values?.href}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="errorClass">
                    {formik.errors.href &&
                      formik.touched.href &&
                      formik.errors.href}
                  </span>
                </div>
              )}
              {(formik.values.wantToAdd === wantToAddObj.addNewPage ||
                formik.values.wantToAdd === wantToAddObj.addHyperlink) && (
                <>
                  <div className="dropDownSection">
                    <span>{t("title")}</span>
                    <TextField
                      placeholder={t("title")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="title"
                      value={formik?.values?.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="errorClass">
                      {formik.errors.title &&
                        formik.touched.title &&
                        formik.errors.title}
                    </span>
                  </div>
                  <div className="dropDownSection">
                    <FormControlLabel
                      control={
                        <Checkbox
                          key="target_blank"
                          value={formik.values.target_blank}
                          checked={formik.values.target_blank}
                          name="target_blank"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      }
                      label={"Open in new tab"}
                    />
                  </div>
                </>
              )}
              <DialogActions className="popup-button-outer">
                <StyledButton
                  variant={"contained"}
                  type="submit"
                  disabled={footerMenuLoadingState === "loading"}
                >
                  {openFor === "EDIT" ? t("update") : t("save")}
                </StyledButton>
                {openFor === "EDIT" && (
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpenDelete(true)}
                    disabled={footerMenuLoadingState === "loading"}
                  >
                    {t("delete")}
                  </CustomButton>
                )}
                <CustomButton
                  variant={"contained"}
                  onClick={handleClose}
                  disabled={footerMenuLoadingState === "loading"}
                >
                  {t("cancel")}
                </CustomButton>
              </DialogActions>
            </form>
          </FormikProvider>
        </div>
        {openDelete && (
          <CommonDeletePopup
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            rowsToBeDeleted={[link?.pageId]}
            singleDeleteText={"Are you sure you want to delete?"}
            multiDeleteText={"Are you sure you want to delete?"}
            handleClose={() => setOpenDelete(false)}
            handleDelete={handleDelete}
          />
        )}
      </AddPageToFooterStyles>
    </DialogCommon>
  );
};

export default AddNewPagePopup;
