import { styled } from "@mui/system";

export const CommonLayoutStyled = styled("div")(({ theme }) => ({
  ".commanloginPages": {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    overflow: "hidden",
    background: theme.palette.lightBlue.main,
    ".sideImage": {
      flex: "0 0 50%",
      maxWidth: "50%",
      ".logotypeContainer": {
        width: "100%",
        height: "100vh",
        objectFit: "cover",
      },
    },
    ".rightContainer": {
      width: "100%",
      height: "100vh",
      overflow: "auto",
      ".loginFields": {
        width: "100%",
        height: "calc(100vh - 75px)",
        display: "grid",
        alignItems: "self-end",
        ".inputFieldsBlock": {
          ".logoBlock": {
            textAlign: "center",
            ".logoImg": {
              width: "100%",
              maxWidth: "165px",
              margin: "auto",
            },
          },
          ".childrenHeading": {
            maxWidth: "400px",
            margin: "25px auto 0",
            padding: "15px 20px",
            ".commonChildren": {
              ".loginMain": {
                ".forgotPassText ": {
                  textAlign: "center",
                },
                ".passwordDiv": {
                  position: "relative",
                },
                ".loginRecaptcha": {
                  marginTop: "25px",
                },
                ".formButtons": {
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "25px 0",
                  "+ p": {
                    textAlign: "center",
                    a: {
                      color: "#1976d2",
                    },
                  },
                  button: {
                    background: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    ":first-of-type": {
                      ":hover": {
                        color: theme.palette.white.main,
                      },
                    },
                    ":is(.forgetPwd, .linkBtn )": {
                      background: "transparent",
                      border: "0",
                      marginRight: "0",
                      color: theme.palette.primary.main,
                      padding: "0",
                    },
                  },
                  "&.centerbtnR": {
                    justifyContent: "center !important",
                  },
                },
                ".notaMemberText": {
                  textAlign: "center",
                  fontSize: "16px",
                  a: {
                    color: theme.palette.primary.main,
                  },
                },
              },
            },
            ".passwordReqPopup": {
              background: theme.palette.white.main,
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0 0 0 #000, 0 0 2px #cecbcb",
              position: "absolute",
              top: "65px",
              width: "100%",
              maxWidth: "80%",
              zIndex: "1",
              "&:after": {
                content: "''",
                width: "7px",
                height: "12px",
                borderStyle: "solid",
                borderWidth: "0 16px 11px 17px",
                borderColor: "transparent transparent #fcfcfc transparent",
                transform: "rotate(0deg)",
                position: "absolute",
                top: "-11px",
                left: "21px",
              },
              "&:before": {
                content: "''",
                width: "7px",
                height: "12px",
                borderStyle: "solid",
                borderWidth: "0 16px 11px 17px",
                borderColor: "transparent transparent #dcdcdc transparent",
                transform: "rotate(0deg)",
                position: "absolute",
                top: "-12px",
                left: "21px",
              },
              ".passwordStrengthBarOuter": {
                "> span": {
                  fontSize: "14px",
                  marginBottom: "9px",
                  display: "block",
                  "&.Strong": {
                    color: "#629514",
                  },
                  "&.Weak": {
                    color: "red",
                  },
                  "&.Good": {
                    color: "#0056B8",
                  },
                  "&.medium": {
                    color: "rgb(247, 163, 7)",
                  },
                },
                ".passwordStrengthBar": {
                  "> div": {
                    display: "inline-block",
                    width: "calc(100% / 4 - 4px)",
                    height: "3px",
                    background: "#d0d0d0",
                    margin: "0 2px",
                    "&.Weak": {
                      background: "red",
                    },
                    "&.Good": {
                      background: "#0056B8",
                    },
                    "&.medium": {
                      background: "rgb(247, 163, 7)",
                    },
                    "&.Strong": {
                      background: "#629514",
                    },
                  },
                },
              },
              "ul.passwordReqUl": {
                margin: "0",
                listStyle: "none",
                padding: "0",
                li: {
                  fontSize: "14px",
                  lineHeight: "24px",
                  "&.passwordReqTrue": {
                    color: "#467400",
                  },
                },
              },
            },
          },
        },
        ".copyRights": {
          textAlign: "center",
          p: {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    "&.withoutLoginTitle": {
      ".rightContainer": {
        " .loginFields": {
          " .inputFieldsBlock ": {
            ".childrenHeading": {
              marginTop: "0",
            },
          },
        },
      },
      " .headingB": {
        margin: "0",
      },
    },
  },
  button: {
    fontSize: "14px",
    textTransform: "capitalize",
    boxShadow: "unset!important",
  },
  ".headingB": {
    color: theme.palette.primary.main,
    margin: "0 auto 25px",
    maxWidth: "max-content",
    borderBottom: `1px solid ${theme.palette.primary.main}`, // Adjust color
    width: "100%",
    fontSize: "21px",
    fontWeight: "500",
  },
  ".errorValidation": {
    fontSize: "17px",
    color: "red",
    margin: "16px 0px",
    minHeight: "48px",
    lineHeight: "1.4",
  },
}));

export default CommonLayoutStyled;
