/**
 * Common html wrapper for all table icons
 */

import { Grid, Tooltip } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const TableIconsCommonWrapper = ({ children, tooltipTitle }) => {
  const FieldManagementStyle = styled("div")(({ theme }) => ({
    ".gridEditIcon": {
      background: ["gray", "rgba(0, 0, 0, 0.5)"],
      color: theme.palette.white.main,
      cursor: "pointer",
      padding: "3px 5px",
      borderRadius: "4px",
      fontSize: "22px",
    },
    ".gridEditIconUsers.deleteIcon": {
      cursor: "pointer",
      background: "transparent",
      fill: "#7b7b7f",
      transform: "scale(1.4)",
    },
    "svg.viewEyeIcon": {
      background: "transparent",
      fill: "#707070",
    },
    svg: {
      width: "20px",
      height: "20px",
      background: theme.palette.dimGray.main,
      borderRadius: "3px",
      fill: theme.palette.white.main,
      margin: "0 10px",
      cursor: "pointer",
    },
  }));

  return (
    <FieldManagementStyle>
      <Tooltip placement="top-start" title={tooltipTitle}>
        <Grid item display="flex">
          {children}
        </Grid>
      </Tooltip>
    </FieldManagementStyle>
  );
};

export default TableIconsCommonWrapper;
