import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";
import performApiRequest from "helper/apiRequestHelper";

export const getHierarchyofPagesCms = createAsyncThunk(
  "pagesCms/getHierarchyofPagesCms",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminGetHierarchy),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//feGetPagesWithParent

export const getHierarchyofPagesCmsFE = createAsyncThunk(
  "pagesCms/getHierarchyofPagesCmsFE",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.feGetPagesWithParent),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllCmsPagesApi = createAsyncThunk(
  "pageCms/getAllCmsPagesApi",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetAllCmsPages}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetAllCmsPages}?filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const createCmsPageApi = createAsyncThunk(
  "pageCms/createCmsPageApi",
  async (data) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminCreateCustomPage, data?.body),
        null,
        data?.toast,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getParentPagesApi = createAsyncThunk(
  "pageCms/getParentPagesApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminGetParentPages),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCustomPageApi = createAsyncThunk(
  "pageCms/getCustomPageApi",
  async (pageId) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getCustomPage}/${pageId}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getContentByTitleApi = createAsyncThunk(
  "pageCms/getContentByTitleApi",
  async (pageTitle) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getContentBySlug}/${pageTitle}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const upDatePagesHeadName = createAsyncThunk(
  "pagesCms/upDatePagesHeadName",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.adminUpdatePageNameApi}/${body?.id}`, {
          name: body.name,
        }),
      );
      return dispatch(getHierarchyofPagesCms());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateCmsPageIndex

export const updateCmsPageIndexApi = createAsyncThunk(
  "pagesCms/updateCmsPageIndexApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.adminUpdateCmsPageIndex}`, {
          data: body,
        }),
      );
      return dispatch(getHierarchyofPagesCms());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCmsPageApi = createAsyncThunk(
  "pageCms/updateCmsPageApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.adminCmsPageUpdate}/${body.id}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteCmsPageApi = createAsyncThunk(
  "pageCms/deleteCmsPageApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.adminCmsPageDelete, data?.body),
        null,
        data?.toast ? data?.toast : false,
      );
      return dispatch(getAllCmsPagesApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//add pages or links to the footer parent or column
export const addPagesToFooterApi = createAsyncThunk(
  "pageCms/addPagesToFooterApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAddPagesToFooter}/${body.colId}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateFooterSubMenu

export const updateFooterSubMenuApi = createAsyncThunk(
  "pageCms/updateFooterSubMenuApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateFooterSubMenu}/${body.colId}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateFooterMenuIndex
export const updateFooterMenuIndexApi = createAsyncThunk(
  "pageCms/updateFooterMenuIndexApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminUpdateFooterMenuIndex, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getCmsPageFieldsApi = createAsyncThunk(
  "pageCms/getCmsPageFieldsApi",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminCmsPageFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminCmsPageFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
