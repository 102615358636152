import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { useTranslation } from "react-i18next";

const DialogChildren = styled("div")(
  ({ theme }) => `
   .paper {
    text-align: center;
    button{
      margin-top:15px;
    }
}
.
`,
);

const SignOutPopup = ({ open, setOpen, onClick }) => {
  const { t } = useTranslation();
  return (
    <DialogCommon
      open={open}
      onClose={() => setOpen(false)}
      contentText={`${t("youHaveSigned")}</br> ${t("pleaseSignOut")}`}
      fullWidth={false}
    >
      <DialogChildren>
        <Box className={"paper"}>
          <CustomButton
            variant="contained"
            onClick={onClick}
            className={"signOutButton"}
          >
            {t("signOutOfAllDevices")}
          </CustomButton>
        </Box>
      </DialogChildren>
    </DialogCommon>
  );
};

export default SignOutPopup;
