import { styled } from "@mui/system";

export const HeaderNewStyles = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "0",
  zIndex: "9",
  background: theme.palette.whiteColor.main,
  ".headerMain": {
    height: "80px",
    display: "flex",
    gap: "2.5rem",
    alignItems: "center",
    padding: "1.25rem 2.5rem",
    backgroundColor: theme.palette.white.main,
    span: {
      color: theme.palette.gray_600.main,
      cursor: "pointer",
    },
    ".headerTextItem": {
      display: "flex",
      alignItems: "center",
      gap: "0.375rem",
      cursor: "pointer",
      "&:is(:hover, .active)": {
        span: {
          color: "rgba(0, 86, 184, 1)",
        },
        svg: {
          path: {
            stroke: theme.palette.primaryColor.main,
          },
        },
      },
    },
    " .headerRight": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 0 auto",
      gap: "24px",
      ".contributeHeader": {
        span: {
          "&:hover": {
            color: theme.palette.primaryColor.main,
          },
        },
      },
      " .headerUserAvatar": {
        paddingLeft: "1.5rem",
        borderLeft: "1px solid rgba(233, 233, 233, 1)",
        cursor: "pointer",
      },
      ".notificationButton": {
        ".headerMenuButton": {
          "svg.headerIcon": {
            "&:hover": {
              fill: theme.palette.primaryColor.main,
            },
          },
        },
      },
    },
    ".headerMiddle": {
      display: "flex",
      gap: "1.5rem",
      ".headerCollections": {
        padding: "0 1.5rem",
        borderRight: "1px solid rgba(233, 233, 233, 1)",
        borderLeft: "1px solid rgba(233, 233, 233, 1)",
      },
      ".headerTypes": {
        display: "flex",
        gap: "1.5rem",
      },
    },
  },
}));

export default HeaderNewStyles;
