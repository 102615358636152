import {
  addcollection,
  deletecollection,
  Getcollection,
  getCollectionFields,
  updateCollections,
} from "./collectionAction";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  collectionss: [],
  fieldValues: [],
};

const collectionSlice = createSlice({
  name: "collectionassets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Getcollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Getcollection.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionss = action.payload;
      });
    builder
      .addCase(updateCollections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCollections.fulfilled, (state, action) => {
        state.status = "idle";
        // state.collectionss=action.payload;
      });
    builder
      .addCase(addcollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addcollection.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deletecollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletecollection.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getCollectionFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCollectionFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const collectiostate = (state) => state?.collectionss?.collectionss;

export const collectionFieldValueArray = (state) => {
  return state?.collectionss?.fieldValues?.asset_collections;
};

export default collectionSlice.reducer;
