import { borderTop, styled } from "@mui/system";

const HeroSearchStyles = styled("div")(({ theme }) => ({
  "&:has(.heroSearchFixed )": {
    position: "fixed",
    top: "4vw",
    width: "100%",
    left: "0",
    zIndex: "10",
    transition: "all 0.2s linear",
    ".heroSearch": {
      borderRadius: "0",
      margin: "0",
      transition: "all 0.2s linear",
      borderTop: `1px solid ${theme.palette.gray_250.main}`,
    },
  },
  ".heroSearch": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    background: theme.palette.whiteColor.main,
    borderRadius: "0.5rem",
    height: "55px",
    // margin: "48px 0",
    "&:has(.searchTypeDropDown.active)": {
      borderBottomLeftRadius: "0",

    },
    ".searchTypeSelect": {
      width: "100%",
      maxWidth: "9.5rem",
      height: "3.438rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      cursor: "pointer",
      ".searchTypeMain": {
        width: "100%",
        ".searchTypeButton": {
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          alignItems: "center",
          svg: {
            width: "1rem",
          },

          span: {
            fontWeight: "500",
            color: theme.palette.gray_600.main,
          },
        },
        ".searchTypeDropDown": {
          position: 'absolute',
          background: '#FFFFFF',
          left: '0',
          width: '14.438rem',
          top: '54px',
          zIndex: '6',
          boxShadow: '0px 11px 15px 0px rgba(0, 0, 0, 0.07)',
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
          borderTop: '1px solid #E9E9E9',

          ".searchType": {
            padding: "0.875rem 1rem",
            textAlign: "left",
            "&.searchCatsMain": {
              paddingLeft: "3rem",
            },
            span: {
              fontFamily: "Poppins",
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              letterSpacing: "-0.14px",
              color: theme.palette.gray_600.main,
            },
            "&.selectedType ": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              span: {
                fontWeight: "600",
                color: theme.palette.blackColor.main,
              },
            },
            ".selectedTypeIcon": {
              svg: {
                width: "0.972vw",
                height: "0.972vw",
              },
            },
            "&:hover": {
              backgroundColor: theme.palette.gray_200.main,
            },
          },
        },
      },
    },
    ".searchAndSimilar": {
      flex: "1",
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: "100%",
      ".searchInput": {
        WebkitFlex: '1',
        MsFlex: '1',
        flex: '1',
        position: 'relative',
        height: 'calc(100% - 2px)',
        input: {
          height: "100%",
          border: "0",
          width: "100%",
          borderRight: `1px solid ${theme.palette.gray_250.main}`,
          borderLeft: `1px solid ${theme.palette.gray_250.main}`,
          fontFamily: "Poppins",
          fontSize: "0.875rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          paddingLeft: "3rem",
          outline: "unset",
          "&::placeholder": {
            color: theme.palette.gray_400.main,
          },
        },
        svg: {
          position: "absolute",
          top: "50%",
          left: "1.5rem",
          width: "1rem",
          height: "1rem",
          transform: "translate(-50%,-50%)",
          path: {
            stroke: theme.palette.gray_600.main,
          }
        },
      },
      "button.similarSearchButton": {
        height: "100%",
        background: "transparent",
        border: "0",
        fontFamily: "Poppins",
        fontSize: "0.875rem!important",
        fontStyle: "normal",
        fontWeight: "500!important",
        lineHeight: "normal",
        letterSpacing: "-0.14px",
        width: "100%",
        minWidth: "10.25rem",
        maxWidth: "max-content",
        color: theme.palette.gray_600.main,
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        cursor: "pointer",
      },
    },
    "&.fullHeaderSearch": {
      margin: "0",
      position: "absolute",
      width: "100%",
      borderRadius: "0",

      transition: "all 0.5s linear",
      transform: "translateY(-70px)",
      zIndex: "-1",
      borderTop: `1px solid ${theme.palette.gray_250.main}`,

      "&.heroSearchFixed": {
        transform: "translateY(0px)",
      },
    },
  },
}));

export default HeroSearchStyles;
