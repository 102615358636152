import React from "react";

const FranceFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 383 202">
      <path fill="#002654" d="M-.5-.5h128v255H-.5V-.5z" />
      <path fill="#fefffe" d="M127.5-.5h127v255h-127V-.5z" />
      <path fill="#ce1226" d="M254.5-.5h128v255h-128V-.5z" />
    </svg>
  );
};

export default FranceFlag;
