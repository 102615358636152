import { userSignOut } from "pages/General-Pages/WithoutAuthPages/features/userAction";
import { connectToSocket } from "./webSocketHelper";
import { routeConfigs } from "utils/routeConfig";

const LogoutHelper = (dispatch, navigate, pageRefresh = true) => {
  dispatch(userSignOut())
    .then((response) => {
      if (response) {
        localStorage.clear();
        const socket = connectToSocket();
        socket.off("connect", () => {
          console.log("disconnected");
        });
        pageRefresh
          ? window.location.assign(routeConfigs.userLogin)
          : navigate(routeConfigs.userLogin);
      }
    })
    .catch((e) => {
      localStorage.clear();
      pageRefresh
        ? window.location.assign(routeConfigs.userLogin)
        : navigate(routeConfigs.userLogin);
    });
};
export default LogoutHelper;
