import { padding, styled } from "@mui/system";

const MarketSegmentStyles = styled("div")(({ theme }) => ({
  padding: "7.5rem 0",
  ".marketSegmentMain": {
    display: "flex",
    fontFamily: "Poppins",
    marginLeft: "4rem",
    marginRight: "4rem",
    justifyContent: "space-between",
    " .marketLeftSection": {
      maxWidth: "528px",
      ".marketHeading": {
        marginBottom: "1.5rem",
        h2: {
          color: theme.palette.blackColor.main,
          margin: 0,
        },
      },
      " .marketDescription": {
        span: {
          paddingRight: "18px",
          display: "block",
        },
      },
    },
    " .marketRightSection": {
      flex: "1",
      ".linksBlock": {
        maxWidth: 'calc(100% - 144px)',
        margin: '0 0 0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        ul: {
          padding: '0',
          flex: '0 0 auto',
        }

      },
    },
  },
}));

export default MarketSegmentStyles;
