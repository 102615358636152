import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const Getcollection = createAsyncThunk(
  "collection/collections",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.assetsGetcollection}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.assetsGetcollection}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCollections = createAsyncThunk(
  "collection/updateCollection",
  async (collectionedit, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.assetsUpdateCollection}${collectionedit.body.id}`,
          collectionedit.body,
        ),
      );
      return dispatch(Getcollection(collectionedit?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addcollection = createAsyncThunk(
  "collections/addcollection",
  async (newcollection, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(`${apiRoutes.assetsPostcollection}`, newcollection),
      );
      return dispatch(Getcollection());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deletecollection = createAsyncThunk(
  "collections/deletecollection",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.assetsDeletecollection, data?.body),
      );
      return dispatch(Getcollection(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getCollectionFields = createAsyncThunk(
  "user/getCollectionFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetCollectionFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetCollectionFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
