import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const ResetPassword = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterPassword)
    .max(20, validationMessages.tooLong)
    .matches(
      validationRegex.checkPasswordRequirements,
      validationMessages.passwordMeetsRequirements,
    ),
  confirmPassword: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterConfirmPassword)
    .max(20, validationMessages.tooLong)
    .oneOf([Yup.ref("password")], validationMessages.makeSurePasswordsMatch),
  reCaptcha: Yup.string().required(validationMessages.pleaseEnterCaptcha),
});
