import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getDeletedUserApi = createAsyncThunk(
  "allUser/getDeletedUserApi",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = apiRoutes.adminGetDeletedUsers;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetDeletedUsers}?filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const recoverUserApi = createAsyncThunk(
  "user/recoverUserApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminRecoverUsers, data?.body),
      );

      return dispatch(getDeletedUserApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminPermanentDeleteUsers
export const permanentDeleteUsersApi = createAsyncThunk(
  "user/permanentDeleteUsersApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminPermanentDeleteUsers, data?.body),
      );

      return dispatch(getDeletedUserApi(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getDeletedUserFieldsApi = createAsyncThunk(
  "user/getDeletedUserFieldsApi",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetDeleteUserFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetDeleteUserFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
