import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, MenuItem, Select } from "@mui/material";
import { getAssetType } from "pages/Admin-Pages/asset-administration/AssetType/features/typeAction";
import { getAssetCategoryApi } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import { Getcollection } from "../../../collections/features/collectionAction";
import { selectAllAssetType } from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";
import { assetCategoryState } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import { collectiostate } from "../../../collections/features/collectionSlice";
import { getSubcategoryById } from "../Features/addAssetAction";
import { TypeCategory } from "./FirstSectionStyle";
import Sorting from "utils/sorting";
import { useTranslation } from "react-i18next";

const FirstSection = ({ formik }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [subCategoryData, setSubCategoryData] = useState([]);

  const typeData = useSelector(selectAllAssetType);
  const assetCategoryData = useSelector(assetCategoryState);
  const getallcollection = useSelector(collectiostate);

  useEffect(() => {
    dispatch(getAssetType());
    dispatch(getAssetCategoryApi());
    dispatch(Getcollection());
  }, [dispatch]);

  return (
    <TypeCategory>
      <Box className="first_Sec">
        <h3>{t("selectTypeAndCategory")}</h3>
        <Box className="TypeCategoryFields">
          <Box className="addNewDivSelect">
            <span variant="standard">{t("chooseUpload")}</span>
            <Select
              className="permission-role-select"
              value={formik.values.chooseUpload || " "}
              onChange={(e) => {
                formik.resetForm();
                e.target.value === " "
                  ? formik.setFieldValue("chooseUpload", "")
                  : formik.setFieldValue("chooseUpload", e.target.value);
              }}
            >
              <MenuItem value={" "}>{t("selectUpload")}</MenuItem>
              <MenuItem value={"new"}>{t("addNewCollectionType")}</MenuItem>
              <MenuItem value={"existing"}>
                {t("addToExistingCollectionType")}
              </MenuItem>
              <MenuItem value={"single"}>{t("uploadAnAsset")}</MenuItem>
            </Select>
            {formik.values.chooseUpload === "" && (
              <span className="errorClass">
                {t("youMustSelectAnUploadTypeBeforeUploadingNewFiles")}
              </span>
            )}
          </Box>

          {formik.values.chooseUpload !== "" && (
            <>
              {formik.values.chooseUpload === "existing" && (
                <Box className="addNewDivSelect">
                  <span variant="standard">{t("collectionType")}</span>
                  <Select
                    name="collection_id"
                    className="permission-role-select"
                    value={formik?.values?.collection_id || " "}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      e.target.value === " "
                        ? formik.setFieldValue("collection_id", "")
                        : formik.setFieldValue(
                            "collection_id",
                            e?.target?.value,
                          );
                    }}
                  >
                    <MenuItem value={" "}>{t("selectCollectionType")}</MenuItem>
                    {Sorting(getallcollection?.collections, "name")?.map(
                      (val, index) => {
                        return (
                          <MenuItem key={index + 1} value={val?._id}>
                            {val?.name}
                          </MenuItem>
                        );
                      },
                    )}
                  </Select>

                  <span className="errorClass">
                    {formik.touched.collection_id &&
                      formik?.errors?.collection_id}
                  </span>
                </Box>
              )}

              <Box className="addNewDivSelect">
                <span variant="standard">{t("type")}</span>
                <Select
                  className="permission-role-select"
                  name="asset_type_id"
                  value={formik?.values?.asset_type_id || " "}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    e.target.value === " "
                      ? formik.setFieldValue("asset_type_id", "")
                      : formik.handleChange(e);
                  }}
                  disabled={
                    formik.values.chooseUpload === "existing" &&
                    formik?.values?.collection_id === ""
                  }
                >
                  <MenuItem value={" "}>{t("selectType")}</MenuItem>
                  {Sorting(typeData?.asset_type, "name")?.map((val, index) => {
                    if (val?.enabled) {
                      return (
                        <MenuItem key={index + 1} value={val?._id}>
                          {val?.name}
                        </MenuItem>
                      );
                    }
                    return false;
                  })}
                </Select>
                <span className="errorClass">
                  {formik.touched.asset_type_id &&
                    formik?.errors?.asset_type_id}
                </span>
              </Box>
              <Box className="addNewDivSelect">
                <span variant="standard">{t("category")}</span>
                <Select
                  className="permission-role-select"
                  name="asset_category_id"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.asset_category_id || " "}
                  onChange={(e) => {
                    e.target.value === " "
                      ? formik.setFieldValue("asset_category_id", "")
                      : formik.handleChange(e);
                    dispatch(getSubcategoryById(e.target.value)).then((res) => {
                      formik.setFieldValue("asset_sub_category_id", " ");
                      //checking if there is any enabled subcategory
                      let enabledSubCat = false;
                      if (res?.payload) {
                        for (const subCatData of res?.payload
                          ?.sub_category_data) {
                          if (subCatData?.enabled) {
                            enabledSubCat = true;
                          }
                        }
                      }

                      if (
                        res?.payload?.sub_category_data.length === 0 ||
                        !enabledSubCat
                      ) {
                        formik.setFieldValue("subCategoryLength", true);

                        setSubCategoryData([]);
                      } else {
                        setSubCategoryData(res?.payload?.sub_category_data);
                        formik.setFieldValue("subCategoryLength", false);
                      }
                    });
                  }}
                  disabled={formik?.values?.asset_type_id === ""}
                >
                  {" "}
                  <MenuItem value=" ">{t("selectCategory")}</MenuItem>
                  {Sorting(assetCategoryData?.asset_type, "name")?.map(
                    (val, index) => {
                      if (val?.enabled) {
                        return (
                          <MenuItem key={index + 1} value={val?._id}>
                            {val?.name}
                          </MenuItem>
                        );
                      }
                      return false;
                    },
                  )}
                </Select>
                <span className="errorClass">
                  {formik.touched.asset_category_id &&
                    formik?.errors?.asset_category_id}
                </span>
              </Box>
              <Box className="addNewDivSelect">
                <span variant="standard">{t("subCategory")}</span>
                <Select
                  className="permission-role-select"
                  name="asset_sub_category_id"
                  value={formik?.values?.asset_sub_category_id || " "}
                  onBlur={formik.handleBlur}
                  // onBlur={formik.onBlur ? formik.handleBlur : undefined}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      formik.setFieldValue("asset_sub_category_id", "");
                    } else {
                      formik.handleChange(e);
                      formik?.setFieldValue("subCategoryLength", false);
                    }

                    formik?.setFieldValue("files", []);
                  }}
                  disabled={
                    formik?.values?.asset_category_id === "" ||
                    formik?.values?.subCategoryLength
                  }
                >
                  <MenuItem value={" "}>{t("selectSubCategory")}</MenuItem>
                  {Sorting(subCategoryData, "name")?.map((val, index) => {
                    if (val?.enabled) {
                      return (
                        <MenuItem key={index + 1} value={val?._id}>
                          {val?.name}
                        </MenuItem>
                      );
                    }
                    return false;
                  })}
                </Select>
                <span className="errorClass">
                  {formik.touched.asset_sub_category_id &&
                    formik?.errors?.asset_sub_category_id}
                </span>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </TypeCategory>
  );
};

export default FirstSection;
