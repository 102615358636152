import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "../../../../utils/apiRoutes";
import axiosConfig from "../../../../utils/axiosConfig";
import {
  localConfig,
  saveDataInLocalStorage,
} from "../../../../utils/localStorage";
import performApiRequest from "helper/apiRequestHelper";
import axios from "axios";

export const userLogin = createAsyncThunk(
  "userAuth/userLogin",
  async (adminCredentials, { rejectWithValue }) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminLogin, adminCredentials),
        null,
        false,
      ).then((data) => {
        if (data) {
          saveDataInLocalStorage(localConfig.userLoginToken, data?.data?.token);
          saveDataInLocalStorage(
            "admin_check",
            String(data?.data?.admin_login),
          );
          saveDataInLocalStorage(localConfig.userEmail, data?.data?.email);
        }
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const userSignOut = createAsyncThunk(
  "userAuth/userSignOut",
  async (_, { rejectWithValue }) => {
    try {
      await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminLogout),
        null,
        null,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const forgetPassword = createAsyncThunk(
  "userAuth/forgetPassword",
  async (body, { rejectWithValue }) => {
    try {
      await performApiRequest(
        () => axiosConfig.post(apiRoutes.adminForgotPassword, body),
        false,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "userAuth/resetPassword",
  async (body, { rejectWithValue }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(apiRoutes.adminResetPassword, body),
        false,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserDataApi = createAsyncThunk(
  "userAuth/getUserDataApi",
  async (headers, { rejectWithValue }) => {
    //we are not using axiosconfig here because token is coming from url
    try {
      await performApiRequest(
        () =>
          axios.get(apiRoutes.adminGetUserData, {
            headers,
          }),
        false,
        false,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const userRegisterApi = createAsyncThunk(
  "userAuth/userRegisterApi",
  async (body, { rejectWithValue }) => {
    try {
      await performApiRequest(
        () => axiosConfig.post(apiRoutes.userRegister, body),
        false,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
