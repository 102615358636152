import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTopSearchApi } from "pages/Admin-Pages/cms-changes/trending-searches/features/trendingSearchesAction";
import { topSearches } from "pages/Admin-Pages/cms-changes/trending-searches/features/trendingSearchesSlice";
import HeroTrendingSearchesStyle from "./HeroTrendingSearchesStyle";
import SearchIconLink from "components/Button/SearchIconLink";
import SearchIcon from "../Icons/SearchIcon";
import { routeConfigs } from "utils/routeConfig";

const HeroTrendingSearches = () => {
  const dispatch = useDispatch();
  const allTrendingSearches = useSelector(topSearches);
  const trendingSearches = allTrendingSearches?.filter((a) => a.enabled);
  useEffect(() => {
    const controller = new AbortController();
    dispatch(getTopSearchApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  return (
    <HeroTrendingSearchesStyle>
      <div className="heroTrendingSearchMain">
        {trendingSearches?.map(
          (search, index) =>
            index < 7 && (
              <SearchIconLink to={`${routeConfigs.searchPage}?s=${search.search}`} iconComp={<SearchIcon />} title={search.search} />
            ),
        )}
      </div>
    </HeroTrendingSearchesStyle>
  );
};

export default HeroTrendingSearches;
