import { routeConfigs } from "utils/routeConfig";

export const preDefinedRoles = {
  clientAdmin: "client admin",
  superAdmin: "super admin",
};

export const permissionCategoryObj = {
  accounts: "Accounts",
  cmsAccess: "CMS Access",
  assets: "Assets",
};

//list of all the permissions
export const permissionIdObject = {
  userRolesManagement: {
    permissionName: "Roles Management",
    permissionCategory: permissionCategoryObj.accounts,
    pid: "1",
    permissionRoutes: [routeConfigs.adminUserRolesAndPermisiions],
  },
  usersManagement: {
    permissionName: "User Management",
    permissionCategory: permissionCategoryObj.accounts,
    pid: "2",
    permissionRoutes: [
      routeConfigs.adminUserAll,
      routeConfigs.adminUserRequests,
    ],
  },
  taxonomy: {
    permissionName: "Taxonomy",
    permissionCategory: permissionCategoryObj.cmsAccess,
    pid: "3",
    permissionRoutes: [
      routeConfigs.adminAuiCustomTags,
      routeConfigs.adminAuiIptcCorefields,
    ],
  },
  groupsManagement: {
    permissionName: "Groups Management",
    permissionCategory: permissionCategoryObj.cmsAccess,
    pid: "4",
    permissionRoutes: [
      routeConfigs.adminRegions,
      routeConfigs.adminBusinessunits,
      routeConfigs.adminDepartments,
    ],
  },
  cmsManagement: {
    permissionName: "CMS Management",
    permissionCategory: permissionCategoryObj.cmsAccess,
    pid: "5",
    permissionRoutes: [
      routeConfigs.adminCmschangesFeaturedassets,
      routeConfigs.adminCmschangesCuratedgallery,
      routeConfigs.adminCmschangesSearchbyfilters,
      routeConfigs.adminBanners,
      routeConfigs.adminCmsFooterMenu,
    ],
  },
  //   banners: {
  //     permissionName: "Banners",
  //     permissionCategory: permissionCategoryObj.cmsAccess,
  //     pid: "6",
  //     permissionRoutes: [routeConfigs.adminBanners],
  //   },
  pageManagement: {
    permissionName: "Page Management",
    permissionCategory: permissionCategoryObj.cmsAccess,
    pid: "7",
    permissionRoutes: [
      routeConfigs.adminPagesAdd,
      routeConfigs.adminPagesAll,
      routeConfigs.adminPagesEdit,
      routeConfigs.adminPagesFooterGeneralPages,
    ],
  },
  assetAdmininstration: {
    permissionName: "Asset Administration",
    permissionCategory: permissionCategoryObj.assets,
    pid: "8",
    permissionRoutes: [
      routeConfigs.adminAssetsAll,
      routeConfigs.adminAssetType,
      routeConfigs.adminAssetCategory,
      routeConfigs.adminAssetSubcategory,
      routeConfigs.adminAssetsAdd,
    ],
  },
  collections: {
    permissionName: "Collections",
    permissionCategory: permissionCategoryObj.assets,
    pid: "9",
    permissionRoutes: [routeConfigs.adminAssetsCollections],
  },
  contribute: {
    permissionName: "Contribute",
    permissionCategory: permissionCategoryObj.assets,
    pid: "10",
    permissionRoutes: [routeConfigs.adminAssetsAdd],
  },
  viewAssets: {
    permissionName: "View Assets",
    permissionCategory: permissionCategoryObj.assets,
    pid: "11",
  },
  downloadAssets: {
    permissionName: "Download Assets",
    permissionCategory: permissionCategoryObj.assets,
    pid: "12",
  },
  shareAssets: {
    permissionName: "Share Assets",
    permissionCategory: permissionCategoryObj.assets,
    pid: "13",
  },
};

/*
 * Below is the array of pids of permissions.
 * If any one of the permissions mentioned in the below array is available to the user,
 * then the user can access the admin dashboard
 */
export const adminPermissionsPidArr = [
  permissionIdObject?.userRolesManagement?.pid,
  permissionIdObject?.usersManagement?.pid,
  permissionIdObject?.taxonomy?.pid,
  permissionIdObject?.cmsManagement?.pid,
  permissionIdObject?.banners?.pid,
  permissionIdObject?.pageManagement?.pid,
  permissionIdObject?.assetAdmininstration?.pid,
  permissionIdObject?.collections?.pid,
];
