import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import axiosConfig from "utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";

export const contactUsFormApi = createAsyncThunk(
  "userAuth/contactUsFormApi",
  async (body, { rejectWithValue }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.contactUsApi, body),
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
