import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";
// const allowedFormats = ["image/jpeg", "image/jpg", "image/bmp", "image/png"];

export const ProfileSettingSchemas = Yup.object().shape({
  profile: Yup.mixed()
    .test("fileSize", validationMessages.pleaseUploadImageUptoOneMB, (file) => {
      return file?.size ? file?.size <= 1100000 : true;
    })
    .test("fileSize", validationMessages.fileCorrupt, (file) => {
      return file?.size === 0 ? false : true;
    })
    .notRequired(),
  first_name: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterFirstName)
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
  last_name: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterLastName)
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex.onlyAlphabetsSpace,
      validationMessages.onlyAlphabets,
    )
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
});
