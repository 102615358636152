import { styled } from "@mui/system";

const ToggleSwitchStyle = styled("div")(
  ({ theme, activeText, inactiveText }) => ({
    ".app-cover": {
      display: "table",
      counterReset: "button-counter",
      width: "100%",
      height: "100%",
      ".toggle-button-cover": {
        display: "table-cell",
        position: "relative",
        boxSizing: "border-box",
        ".button-cover": {
          backgroundColor: theme.palette.white.main,
          boxShadow: "0 10px 20px -8px #c5d6d6",
          borderRadius: "4px",
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          ".button": {
            position: "relative",
            width: "80px",
            height: "33px",
            margin: "0 auto",
            overflow: "hidden",
            background: "#efefef",
            border: "1px solid #aba5a5",
            top: "7px",

            "&.r": {
              borderRadius: "15px",
              ".layer": {
                borderRadius: "100px",
              },
            },
            ".checkbox": {
              position: "relative",
              width: "100%",
              height: "100%",
              padding: "0",
              margin: "0",
              opacity: "0",
              cursor: "pointer",
              zIndex: "3",
              "&:checked": {
                "+": {
                  ".knobs": {
                    // background: `rgba(${theme.palette.primary.main}, 0.1)`,
                    "&:before": {
                      content: `"${activeText}"`,
                      left: "29px",
                      backgroundColor: "#00D20E",
                    },
                  },
                },
                "~": {
                  ".layer": {
                    backgroundColor: "rgb(235, 235, 235)",
                  },
                },
              },
            },
            ".knobs": {
              "&:before": {
                content: `"${inactiveText}"`,
                position: "absolute",
                top: "2px",
                left: "3px",
                width: "60%",
                height: "27px",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "300",
                textAlign: "center",
                lineHeight: "1",
                padding: "7px 6px",
                backgroundColor: "#aba5a5",
                borderRadius: "30px",
                transition:
                  "0.1s cubic-bezier(0.18, 0.89, 0.35, 1.15) all, 0.3s ease all",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "@media screen and (max-width:1366px)": {
                  fontSize: "12px",
                },
              },
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              transition: "0.3s ease all",
              position: "absolute",
              inset: "0px",
              zIndex: "2",
            },
            ".layer": {
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              zIndex: "1",
              transition: "0.4s ease all",
              position: "absolute",
              inset: "0px",
              width: "100%",
              backgroundColor: "rgb(235, 235, 235)",
            },
          },
        },
      },
    },
  }),
);

export default ToggleSwitchStyle;
