import { combineReducers, configureStore } from "@reduxjs/toolkit";

import depatmentSlice from "pages/Admin-Pages/tertiaryGroup/features/depatmentSlice";
import businessSlice from "pages/Admin-Pages/sub-groups/features/subGroupSlice";
import userRolePermissionSlice from "pages/Admin-Pages/user-administration/features/userRolePermissionSlice";
import userSlice from "pages/Admin-Pages/user-administration/features/userSlice";
import allUserSlice from "pages/Admin-Pages/user-administration/features/allUserSlice";
import dashboardSlice from "pages/Admin-Pages/dashboard/Features/dashboardSlice";
import assetCategorySlice from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import assetTypeSlice from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";
import assetSubCategorySlice from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import iptcCreatorSlice from "pages/Admin-Pages/aui-form-fields/IptcCoreFields/features/iptcCreatorSlice";
import customTagSlice from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagSlice";
import collectionSlice from "pages/Admin-Pages/collections/features/collectionSlice";
import addAssetSlice from "pages/Admin-Pages/contribute-assets/Components/Features/addAssetSlice";
import viewAssetsAllSlice from "pages/Admin-Pages/asset-administration/AssetsAll/features/viewSlice";
import bannerSlice from "pages/Admin-Pages/banners/features/bannerSlice";
import profileSlice from "pages/Admin-Pages/profile-pages/features/profileSlice";
import pagesCmsSlice from "pages/Admin-Pages/pages-cms/features/pagesCmsSlice";
import notificationsSlice from "Layout/features/layoutSlice";
import fieldsManagementSlice from "pages/Admin-Pages/fields-management/features/fieldsManagementSlice";
import recoverUserSlice from "pages/Admin-Pages/user-administration/features/recoverUserSlice";
import contactUsSlice from "pages/User-Pages/UserPages/contactUs/features/contactUsSlice";
import trendingSearchesSlice from "pages/Admin-Pages/cms-changes/trending-searches/features/trendingSearchesSlice";
import footerGeneralPagesSlice from "pages/Admin-Pages/pages-cms/FooterGeneralPages/features/footerGeneralPagesSlice";
import regionSlice from "pages/Admin-Pages/groups/features/groupSlice";
import userAuth from "pages/General-Pages/WithoutAuthPages/features/userAuth";
import curatedGallerySlice from "pages/Admin-Pages/cms-changes/CuratedGallery/features/curatedGallerySlice";

// Combine your individual reducers
const rootReducer = combineReducers({
  userAuth,
  department: depatmentSlice,
  region: regionSlice,
  business: businessSlice,
  userRole: userRolePermissionSlice,
  user: userSlice,
  allUser: allUserSlice,
  recoverUser: recoverUserSlice,
  dashboard: dashboardSlice,
  assetCategory: assetCategorySlice,
  assetType: assetTypeSlice,
  subAssetCategory: assetSubCategorySlice,
  iptcCreators: iptcCreatorSlice,
  customtags: customTagSlice,
  collectionss: collectionSlice,
  addAssets: addAssetSlice,
  viewAssets: viewAssetsAllSlice,
  banner: bannerSlice,
  profile: profileSlice,
  pageCms: pagesCmsSlice,
  notifications: notificationsSlice,
  fieldManagementStore: fieldsManagementSlice,
  contactUs: contactUsSlice,
  trendingSearchesSlice,
  footerGeneralPagesSlice,
  curatedGallerySlice,
});

const store = configureStore({
  reducer: rootReducer,

  middleware: (getdefaultMiddleware) => {
    return getdefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export default store;
