import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import { Country, State, City } from "country-state-city";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getBusiness } from "pages/Admin-Pages/sub-groups/features/subGroupAction";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import {
  iptcCreatorsAddApi,
  iptcCreatorsUpdateApi,
} from "../features/iptcCreatorActions";
import { validationRegex } from "utils/validation/validationUtils";
import { IptcCreatorSchema } from "utils/validation/validation-schemas/iptcCreatorsSchema";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";

const AddEditIptcCreatorsPopup = (props) => {
  const { open, setOpen, editTrue, currentRowData, dataState, setDataState } =
    props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [countriesArray, setCountriesArray] = useState([]);
  const [currentCountryCode, setCurrentCountryCode] = useState(false);
  const [statesArray, setStatesArray] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: IptcCreatorSchema(statesArray, citiesArray),
    initialValues: {
      _id: currentRowData?._id,
      name: currentRowData?.name || "",
      job_title: currentRowData?.job_title || "",
      address: currentRowData?.address || "",
      post_code: currentRowData?.post_code || "",
      phone: currentRowData?.phone || "",
      website: currentRowData?.website || "",
      email: currentRowData?.email || "",
      country: currentRowData?.country ? currentRowData?.country : " ",
      state: currentRowData?.state ? currentRowData?.state : " ",
      city: currentRowData?.city ? currentRowData?.city : " ",
    },
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(getBusiness());
  }, [dispatch]);

  useEffect(() => {
    if (!editTrue) {
      formik.resetForm();
    }
    if (!open) {
      return;
    }
    //putting names of country in an array to show them in dropdown
    const countriesData = [];
    Country.getAllCountries().map((countryData) => {
      // if (State.getStatesOfCountry(countryData.isoCode).length > 0)
      countriesData.push(countryData?.name);
      return false;
    });
    setCountriesArray(countriesData);

    if (editTrue) {
      /*
       *setting initial value of country and state/region and city
       */
      //get country code from country name
      const countryCode = Country.getAllCountries().filter(
        (countryData) => countryData.name === currentRowData?.country,
      )?.[0]?.isoCode;
      setCurrentCountryCode(countryCode);

      //using country code get states of that country
      //extract state names from that into an array
      const stateNameArray = [];
      State.getStatesOfCountry(countryCode).map((stateData) => {
        // if (City.getCitiesOfState(countryCode, stateData.isoCode).length > 0)
        stateNameArray.push(stateData?.name);
        return false;
      });
      setStatesArray(stateNameArray);

      //get state code from the sate name
      const stateCode = State.getStatesOfCountry(countryCode).filter(
        (stateData) => stateData.name === currentRowData?.state,
      )?.[0]?.isoCode;

      setCitiesArray(
        City.getCitiesOfState(countryCode, stateCode).map(
          (cityData) => cityData?.name,
        ),
      );
      return;
    }
    setStatesArray([]);
    setCitiesArray([]);
    //eslint-disable-next-line
  }, [editTrue, open]);

  function handleCountryChange(countryName) {
    formik.setFieldValue("country", countryName);
    formik.setFieldValue("state", " ");
    formik.setFieldValue("city", " ");
    //when we change the country in the drop down
    setStatesArray([]);
    setCitiesArray([]);
    //get country code from country name
    const countryCode = Country.getAllCountries().filter(
      (countryData) => countryData.name === countryName,
    )?.[0]?.isoCode;
    setCurrentCountryCode(countryCode);
    //getting states of country and
    //putting names of states in an array to show them in dropdown
    const stateNameArray = [];
    State.getStatesOfCountry(countryCode).map((stateData) => {
      // if (City.getCitiesOfState(countryCode, stateData.isoCode).length > 0)
      stateNameArray.push(stateData?.name);
      return false;
    });
    setStatesArray(stateNameArray);
  }

  function handleStateChange(stateName) {
    //when we change the state in the drop down
    setCitiesArray([]);
    //get state code from the sate name
    const stateCode = State.getStatesOfCountry(currentCountryCode).filter(
      (stateData) => stateData.name === stateName,
    )?.[0]?.isoCode;
    //get all cities from state code and country code and
    //get all the city names in an array
    setCitiesArray(
      City.getCitiesOfState(currentCountryCode, stateCode).map(
        (cityData) => cityData?.name,
      ),
    );
  }

  function handleSubmit(values) {
    const cleanedPhoneNumber = values?.phone?.replace(
      validationRegex.parenthesisCheckRemove,
      "",
    );
    const modifiedValues = { ...values };
    modifiedValues.phone = cleanedPhoneNumber;
    if (editTrue) {
      const dataBody = { values: modifiedValues, dataState };
      dispatch(iptcCreatorsUpdateApi(dataBody)).then((res) => {
        if (!res?.error) {
          handleClose();
        } else {
          formik.resetForm();
        }
      });
    } else {
      const dataBody = {
        values: modifiedValues,
        dataState: { ...dataState, page: 1 },
      };
      dispatch(iptcCreatorsAddApi(dataBody)).then((res) => {
        if (!res?.error) {
          setDataState({
            ...dataState,
            search: "",
            limit: 20,
            page: 1,
            sortOrder: "desc",
            sortBy: "createdAt",
            column_name: "",
            column_values: "",
            filtersQueryString: [],
          });
          handleClose();
        }
      });
    }
  }

  function handleClose() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <DialogCommon
      fullwidth={true}
      maxWidth={"md"}
      open={open}
      handleClose={() => handleClose()}
      title={editTrue ? t("editCreator") : t("addCreator")}
    >
      <UserDetailDialogDesign>
        <div className="popup-main AddUserDetails">
          <DialogContent>
            <div className="requesOuter">
              <form onSubmit={formik.handleSubmit}>
                <div className="request-common">
                  <label className="label">{t("creatorName")}</label>
                  <TextField
                    placeholder={t("enterCreatorname")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="name"
                    value={formik.values?.name}
                    onChange={(e) => {
                      if (e.target.value?.length <= 50) {
                        formik.setFieldValue("name", e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <span className="errorClass">
                    {formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name}
                  </span>
                </div>
                <div className="request-common  addNewDivSelect">
                  <label className="label">{t("jobTitle")}</label>
                  <TextField
                    placeholder={t("enterTitle")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="job_title"
                    value={formik.values?.job_title}
                    onChange={(e) => {
                      if (e.target.value?.length <= 50) {
                        formik.setFieldValue("job_title", e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <span className="errorClass">
                    {formik.errors.job_title &&
                      formik.touched.job_title &&
                      formik.errors.job_title}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("address")}</label>
                  <TextField
                    placeholder={t("enterAddress")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  <span className="errorClass">
                    {formik.errors.address &&
                      formik.touched.address &&
                      formik.errors.address}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("country")}</label>
                  <Box className="addNewDivSelect">
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        name="country"
                        className="permission-role-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        defaultValue={" "}
                        value={formik.values?.country}
                      >
                        <MenuItem
                          value={" "}
                          onClick={() => handleCountryChange(" ")}
                        >
                          {t("selectCountry")}
                        </MenuItem>
                        {Array?.isArray(countriesArray) &&
                          countriesArray?.map((val, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={val}
                                onClick={() => handleCountryChange(val)}
                              >
                                {val}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <span className="errorClass">
                    {formik.errors.country &&
                      formik.touched.country &&
                      formik.errors.country}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("stateProvince")}</label>
                  <Box className="addNewDivSelect">
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        name="state"
                        className="permission-role-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values?.state ? formik.values?.state : " "
                        }
                        disabled={!statesArray || statesArray?.length === 0}
                        defaultValue={" "}
                      >
                        <MenuItem
                          onClick={() => {
                            handleStateChange(" ");
                            formik.setFieldValue("city", " ");
                          }}
                          value={" "}
                        >
                          {t("selectStateProvince")}
                        </MenuItem>
                        {Array.isArray(statesArray) &&
                          statesArray?.map((val, index) => {
                            return (
                              <MenuItem
                                key={index + 1}
                                value={val}
                                onClick={() => {
                                  handleStateChange(val);
                                  formik.setFieldValue("city", " ");
                                }}
                              >
                                {val}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <span className="errorClass">
                    {formik.values.country?.trim() !== "" &&
                      formik.errors.state &&
                      formik.touched.state &&
                      formik.errors.state}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("city")}</label>
                  <Box className="addNewDivSelect">
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        name="city"
                        className="permission-role-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.city ? formik.values?.city : " "}
                        disabled={!citiesArray || citiesArray?.length === 0}
                        defaultValue={" "}
                      >
                        <MenuItem value={" "}>{t("selectCity")}</MenuItem>
                        {Array.isArray(citiesArray) &&
                          citiesArray?.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val}>
                                {val}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <span className="errorClass">
                    {formik.values.state?.trim() !== "" &&
                      formik.errors.city &&
                      formik.touched.city &&
                      formik.errors.city}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("postalCode")}</label>
                  <TextField
                    placeholder={t("enterPostalCode")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="post_code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.post_code}
                  />
                  <span className="errorClass">
                    {formik.errors.post_code &&
                      formik.touched.post_code &&
                      formik.errors.post_code}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("phone")}</label>
                  <Box className="inputMask">
                    <InputMask
                      placeholder={t("enterPhone")}
                      mask="(999) 999-9999"
                      maskChar="_"
                      type="text"
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                  </Box>
                  <span className="errorClass">
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("email")}</label>
                  <TextField
                    placeholder={t("enterEmail")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <span className="errorClass">
                    {formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email}
                  </span>
                </div>
                <div className="request-common">
                  <label className="label">{t("website")}</label>
                  <TextField
                    placeholder={t("enterWebsite")}
                    size="small"
                    variant="outlined"
                    type="text"
                    name="website"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.website}
                  />
                  <span className="errorClass">
                    {formik.errors.website &&
                      formik.touched.website &&
                      formik.errors.website}
                  </span>
                </div>
                <DialogActions className="popup-button-outer">
                  <StyledButton variant={"contained"} type="submit">
                    {t("save")}
                  </StyledButton>
                  <CustomButton variant={"contained"} onClick={handleClose}>
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </div>
          </DialogContent>
        </div>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default AddEditIptcCreatorsPopup;
