import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import {
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CommonLayout from "Layout/AuthLayout/CommonLayout";
import CustomButton from "components/Button/CustomButton";

import { getUserDataApi, resetPassword } from "../features/userAction";
import { loginState } from "../features/userAuth";
import { encryptAES } from "utils/encrypt";
import { routeConfigs } from "utils/routeConfig";
import { recaptcha_site_key } from "utils/envConst";
import { ResetPassword } from "utils/validation/validation-schemas/resetPasswordSchema";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { useTranslation } from "react-i18next";
import PasswordCheckPopup from "components/PasswordCheckPopup/passwordCheckPopup";

function UserResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const { loginLoading } = useSelector(loginState);
  const captchaRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [linkExpired, setLinkExpired] = useState(null);
  const [token, setToken] = useState("");
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  useEffect(() => {
    setErrorMessage("");
    const tok = searchParams.get("token");
    if (tok) {
      setToken(tok);
      getUserData(tok);
      setLinkExpired(false);
    } else {
      setErrorMessage(validationMessages.expiredPasswordLink);
      setLinkExpired(true);
      setToken("");
    }
    //eslint-disable-next-line
  }, [location.search]);

  const formik = useFormik({
    validationSchema: ResetPassword,
    initialValues: { password: "", confirmPassword: "", reCaptcha: "" },
    onSubmit: (values) => {
      if (!token) {
        setErrorMessage(validationMessages.resetPasswordLinkNotValid);
      }
      const encryptedPassword = encryptAES(values.password);
      const body = {
        token,
        newPassword: encryptedPassword,
      };
      dispatch(resetPassword(body))
        .then((response) => {
          if (response.error) {
            setErrorMessage(response?.payload?.data?.error);
          } else {
            formik.resetForm();
            setErrorMessage("");
            captchaRef.current.reset();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  function getUserData(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    dispatch(getUserDataApi(headers))
      .then((response) => {
        if (response.error) {
          setErrorMessage(validationMessages.expiredPasswordLink);
          setToken("");
          setLinkExpired(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <CommonLayout headingName={t("resetPassword")}>
      <div className="loginMain">
        <form onSubmit={formik.handleSubmit}>
          <Typography color="secondary" className="errorValidation center">
            {(Object.keys(formik?.errors)[0] in formik.touched &&
              Object.values(formik?.errors)[0]) ||
              (errorMessage && errorMessage)}
          </Typography>
          {linkExpired === false ? (
            <>
              <div className={"passwordDiv"}>
                <TextField
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  value={formik.values.password}
                  onChange={(e) => {
                    if (
                      validationRegex.matchOthersExceptSpace.test(
                        e.target.value,
                      ) &&
                      e.target.value.length <= 20
                    ) {
                      formik.handleChange(e);
                    }
                    setErrorMessage("");
                  }}
                  onBlur={(e) => {
                    setShowPasswordStrength(false);
                    formik.handleBlur(e);
                  }}
                  margin="normal"
                  placeholder={t("newPassword")}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  maxLength="10"
                  onFocus={() => setShowPasswordStrength(true)}
                />
                {showPasswordStrength ? (
                  <PasswordCheckPopup password={formik.values.password} />
                ) : null}
              </div>
              <TextField
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() => setShowPassword1(!showPassword1)}
                        size="large"
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={(e) => {
                  if (
                    validationRegex.matchOthersExceptSpace.test(
                      e.target.value,
                    ) &&
                    e.target.value.length <= 20
                  ) {
                    formik.handleChange(e);
                  }
                  setErrorMessage("");
                }}
                onBlur={formik.handleBlur}
                margin="normal"
                placeholder={t("confirmPassword")}
                type={showPassword1 ? "text" : "password"}
                fullWidth
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                maxLength="10"
              />
              <ReCAPTCHA
                className={"loginRecaptcha"}
                name="reCaptcha"
                sitekey={recaptcha_site_key}
                onChange={(e) => {
                  formik.setFieldValue("reCaptcha", e);
                  setErrorMessage("");
                }}
                onBlur={formik.handleBlur}
                ref={captchaRef}
              />
              <div className={"formButtons"}>
                {loginLoading ? (
                  <CircularProgress size={26} className={"loginLoader"} />
                ) : (
                  <CustomButton variant="contained" type={true}>
                    {t("submit")}
                  </CustomButton>
                )}
                <CustomButton
                  className="linkBtn"
                  onClick={() => navigate(routeConfigs.userLogin)}
                >
                  {t("backoToLogin")}
                </CustomButton>
              </div>
            </>
          ) : (
            <div className={"center"}>
              <CustomButton
                className="linkBtn"
                onClick={() => navigate(routeConfigs.userLogin)}
              >
                {t("backoToLogin")}
              </CustomButton>
            </div>
          )}
        </form>
      </div>
    </CommonLayout>
  );
}

export default UserResetPassword;
