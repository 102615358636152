import React, { lazy, Suspense } from "react";
import withAuth from "../../../../Auth/withAuth";
import { routeConfigs } from "../../../../utils/routeConfig";
import Layout from "Layout/AdminLayout/Layout";
import HeroSection from "./HomeComponents/HeroSection/HeroSection";
import { Loader } from "components/Loader/Loader";
import HomeStyles from "./HomeStyle";

const FindLookingForSection = lazy(() =>
  import("./HomeComponents/FindLookingForSection/FindLookingForSection"),
);
const MarketSegment = lazy(() =>
  import("./HomeComponents/MarketSegment/MarketSegment"),
);

const CuratedGallery = lazy(() =>
  import("./HomeComponents/CuratedGallery/CuratedGallery"),
);

function Home() {
  return (
    <Layout>
      <HomeStyles className="homePageMain">
        <HeroSection />
        <Suspense fallback={<Loader />}>
          <FindLookingForSection />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <MarketSegment />
        </Suspense>
        {/* <FeaturedProjects /> */}
        <Suspense fallback={<Loader />}>
          <CuratedGallery />
        </Suspense>
        {/* <ContactUsComp /> */}
        {/* <Suspense fallback={<Loader />}>
          <ContactUsComp />
        </Suspense> */}
      </HomeStyles>
      {/* <IdleModal /> */}
    </Layout>
  );
}
export default withAuth(Home, routeConfigs.userLogin);
