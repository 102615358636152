import { styled } from "@mui/system";

export const SidebarStyles = styled("div")(({ theme }) => ({
  ".dashboardData": {
    // marginTop: "40px",
    padding: "15px",
  },
  ".sideBarClosed ul div .active": { background: "#f6f7ff" },
  ".sideBarClosed main": { maxWidth: "calc(100% - 100px)" },
  ".sidebarOpened": {
    ".MuiDrawer-root": { zIndex: 1 },
    overflow: "hidden",
    ".MuiDrawer-root > .MuiDrawer-paperAnchorLeft": {
      // paddingBottom: "80px",
    },
  },
  ".singleLink": {
    "> a.link": {
      ":hover": {
        background: theme.palette.lightBlue.main,
      },
    },
    "> div": {
      "> .link:hover": {
        background: theme.palette.lightBlue.main,
      },
    },
    li: {
      ".linkIcon": {
        justifyContent: "center",
        "&.active": {
          svg: {
            fill: "#041E42",
          },
        },
      },
      ".InnerText": {
        paddingLeft: "18px;",
      },
      " &.active": {
        background: theme.palette.lightBlue.main,
      },
    },
  },
  a: {
    color: theme.palette.black.main,
    ".linkIcon": {
      justifyContent: "center",
      svg: {
        width: "30px",
        height: "30px",
      },
    },
    ".InnerText": {
      paddingLeft: "18px",
      "@media screen and (max-width:1366px)": {
        span: {
          fontSize: "14px",
        },
      },
    },
    ":is(.active)": {
      background: theme.palette.lightBlue.main,
      svg: {
        fill: theme.palette.primary.main,
        width: "30px",
        height: "30px",
      },
    },
    ":not(.active)": {
      color: theme.palette.dimGray.main,
    },
    ".linkText": {
      svg: {
        width: "28px",
        height: "28px",
      },
    },
  },
  ".dropDownBlock": {
    ".linkIcon": {
      minWidth: "30px",
      svg: {
        width: "28px",
        height: "28px",
      },
    },
    ".link": {
      padding: "4px 15px 4px 80px",
      ".InnerText": {
        paddingLeft: "0",
        margin: "0",
      },
    },
  },
  ".sidebarLanguage": {
    margin: "auto 0 0 0",
    textAlign: "center",
    ".adminLanguageChange ": {
      textAlign: "left",
      paddingLeft: "36px",
    },
  },
}));

export default SidebarStyles;
