const { styled } = require("@mui/system");

export const StyledDivViewIcon = styled("div")(({ theme }) => ({
  ".viewColumnButtonInner svg": {
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    display: "inline-block",
    fill: "currentColor",
    WebkitFlexShrink: "0",
    msFlexNegative: "0",
    flexShrink: 0,
    WebkitTransition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "1.5rem",
    width: "34px",
    height: "34px",
    position: "relative",
    top: "3px",
  },
  ".viewColumnButtonInner:hover": {
    color: theme.palette.primary.main,
    background: "transparent",
  },
}));
