import { ClickAwayListener } from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const dummySearchTypes = [
  {
    name: "Image",
    cats: [{ name: "Photos" }, { name: "Logos" }, { name: "Icons" }],
  },
  { name: "Video", cats: [{ name: "B-Roll" }] },

  { name: "Applications", cats: [] },
];

export const searchFlagType = {
  all: "ALL",
  type: "TYPE",
  cat: "CAT",
};

const SearchTypeSelect = (props) => {
  const { openSearchType, setOpenSearchType, searchType, setSearchType } =
    props;

  const handleChangeSearch = (searchObj, searchFlag) => {
    setSearchType({ name: searchObj?.name, searchFlag });
    setOpenSearchType(false);
  };

  return (
    openSearchType && (
      <ClickAwayListener
        onClickAway={() => {
          setOpenSearchType(false);
        }}
      >
        <div className={`searchTypeDropDown ${openSearchType ? "active" : ""}`}>
          <div
            className={`searchType AllTypes ${
              searchType?.searchFlag === searchFlagType?.all
                ? "selectedType"
                : ""
            }`}
            onClick={() =>
              handleChangeSearch({ name: "All Content" }, searchFlagType?.all)
            }
          >
            <span>{"All Content"}</span>
            {searchType?.searchFlag === searchFlagType?.all && (
              <div className="selectedTypeIcon">
                <CheckRoundedIcon />
              </div>
            )}
          </div>
          {dummySearchTypes?.map((searchTypeObj, index) => (
            <div key={index} className={`searchTypeParent`}>
              <div
                className={`searchType boldSearchType  ${
                  searchType?.searchFlag === searchFlagType?.type &&
                  searchType?.name === searchTypeObj?.name
                    ? "selectedType"
                    : ""
                }`}
                onClick={() =>
                  handleChangeSearch(searchTypeObj, searchFlagType?.type)
                }
              >
                <span>{searchTypeObj?.name}</span>
                {searchType?.searchFlag === searchFlagType?.type &&
                  searchType?.name === searchTypeObj?.name && (
                    <div className="selectedTypeIcon">
                      <CheckRoundedIcon />
                    </div>
                  )}
              </div>
              <div className="searchTypeCats">
                {searchTypeObj?.cats?.map((catsObj, catIndex) => (
                  <div
                    className={`searchType searchCatsMain  ${
                      searchType?.searchFlag === searchFlagType?.cat &&
                      searchType?.name === catsObj?.name
                        ? "selectedType"
                        : ""
                    }`}
                    onClick={() =>
                      handleChangeSearch(catsObj, searchFlagType?.cat)
                    }
                    key={catIndex}
                  >
                    <span>{catsObj?.name}</span>
                    {searchType?.searchFlag === searchFlagType?.cat &&
                      searchType?.name === catsObj?.name && (
                        <div className="selectedTypeIcon">
                          <CheckRoundedIcon />
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ClickAwayListener>
    )
  );
};

export default SearchTypeSelect;
