import React from 'react'

const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="108" viewBox="0 0 108 108">
    <path id="Icon_awesome-download" data-name="Icon awesome-download" d="M45.563,0H62.438A5.05,5.05,0,0,1,67.5,5.063V40.5H86a4.211,4.211,0,0,1,2.974,7.193L56.89,79.8a4.087,4.087,0,0,1-5.759,0l-32.126-32.1A4.211,4.211,0,0,1,21.98,40.5H40.5V5.063A5.05,5.05,0,0,1,45.563,0ZM108,79.313v23.625A5.05,5.05,0,0,1,102.938,108H5.063A5.05,5.05,0,0,1,0,102.938V79.313A5.05,5.05,0,0,1,5.063,74.25H36.007L46.343,84.586a10.809,10.809,0,0,0,15.314,0L71.993,74.25h30.945A5.05,5.05,0,0,1,108,79.313ZM81.844,97.875a4.219,4.219,0,1,0-4.219,4.219A4.231,4.231,0,0,0,81.844,97.875Zm13.5,0a4.219,4.219,0,1,0-4.219,4.219A4.231,4.231,0,0,0,95.344,97.875Z" fill="#0056b8"/>
  </svg>
  
  )
}

export default DownloadIcon