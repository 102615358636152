import { createSlice } from "@reduxjs/toolkit";
import {
  addRegion,
  deleteRegion,
  getActiveRegions,
  getAllRegions,
  getRegionFields,
  getRegions,
  updateRegion,
  updateRegionStatus,
} from "./groupAction";

const initialState = {
  regions: [],
  fieldValues: [],
  regionsAll: [],
};
const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        state.status = "idle";
        state.regions = action.payload;
      });
    //in case of all the groups
    builder
      .addCase(getAllRegions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.status = "idle";
        state.regionsAll = action.payload;
      });
    builder
      .addCase(addRegion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addRegion.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteRegion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRegion.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateRegion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRegion.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(updateRegionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRegionStatus.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(getActiveRegions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveRegions.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getRegionFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRegionFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const selectAllRegion = (state) => state.region.regions.regions;

export const selectAllRegionAll = (state) => state.region.regionsAll.regions;

export const regionsWithFilterData = (state) => {
  return state.region.regions;
};

export const regionsFieldValueArray = (state) => {
  return state?.region?.fieldValues?.regions;
};

export default regionSlice.reducer;
