import { baseurl } from "utils/baseUrl";

export const apiRoutes = {
  // admin
  adminLogin: `${baseurl}/login`, //post
  adminVerifyCaptcha: `${baseurl}/captcha`, //only required if need to verify captcha from backend
  adminLogout: `${baseurl}/logout`,
  adminForgotPassword: `${baseurl}/forgotpassword`, //post
  adminResetPassword: `${baseurl}/resetpassword`,
  // user register
  userRegister: `${baseurl}/contributer-register`, //post
  adminCreateTooltip: `${baseurl}/create-tooltip`,

  // admin users
  adminGetUserData: `${baseurl}/user-withtoken`, //used in reset password

  // admin user roles
  adminGetUserRoles: `${baseurl}/roles`,
  adminEditUserRole: `${baseurl}/update-role`,
  adminGetSingleUserRole: `${baseurl}/role`,
  adminAddUserRole: `${baseurl}/create-role`,
  adminGetActiveUserRole: `${baseurl}/active-roles`,
  adminUpdateUserRoleStatus: `${baseurl}/role-status`,
  admingetUserRoleFields: `${baseurl}/get-role-fields`, //get
  adminDeleteUserRoles: `${baseurl}/delete-role`, //send id
  adminUserRolesAndPermissions: `${baseurl}/permissions-by-category`,
  adminUserPermissionCategories: `${baseurl}/permissions-categories `,
  adminUserAndPermissionUpdate: `${baseurl}/update-role-permissions`,

  //admin asset type
  adminGetAssetType: `${baseurl}/asset-types`, //get asset types
  adminAddAssetType: `${baseurl}/create-asset-type`,
  adminUpdateAssetType: `${baseurl}/update-asset-type`,
  adminBulkDeleteAssetType: `${baseurl}/delete-asset-types`,
  adminUpdateAssetTypeStatus: `${baseurl}/asset-type-status`,
  adminCountActiveAssetType: `${baseurl}/active-asset-type`,
  adminGetAssetTypeFields: `${baseurl}/get-asset-type-fields`,

  // admin departments
  adminGetDepartment: `${baseurl}/departments`,
  adminAddDepartment: `${baseurl}/create-department`,
  adminDeleteDepartment: `${baseurl}/delete-departments`,
  adminUpdateDepartment: `${baseurl}/update-department`,
  adminUpdateDepartmentStatus: `${baseurl}/department-status`,
  adminGetActiveDepartments: `${baseurl}/active-department`,
  adminGetDepartmentFields: `${baseurl}/get-dept-fields`, //filters
  adminGetDepartemtsByBusinessUnit: `${baseurl}/get-department`, //business id required

  // admin Groups
  adminGetRegion: `${baseurl}/regions`,
  adminUpdateRegion: `${baseurl}/update-region`,
  adminDeleteRegion: `${baseurl}/delete-region`,
  adminAddRegion: `${baseurl}/create-region`,
  adminUpdateRegionStatus: `${baseurl}/region-status`,
  adminGetActiveRegions: `${baseurl}/active-region`,
  adminGetRegionFields: `${baseurl}/get-region-fields`, //filters

  // admin sub-groups
  adminGetBusiness: `${baseurl}/business-units`,
  adminUpdateBusiness: `${baseurl}/update-business-unit`,
  adminDeleteBusiness: `${baseurl}/delete-business-units`,
  adminAddBusiness: `${baseurl}/create-business-unit`,
  adminUpdateBusinessStatus: `${baseurl}/business-unit-status`,
  adminGetActiveBusiness: `${baseurl}/get-active-business`,
  adminGetBusinessFields: `${baseurl}/get-business-fields`, //filters

  // admin permissions
  adminGetAllPermissions: `${baseurl}/permissions`,
  adminUpdateRolePermissions: `${baseurl}/update-role-permissions`,

  // user requests
  userRequestsBulkDelete: `${baseurl}/delete-requests`,
  userRequestAction: `${baseurl}/update-request`,
  adminGetUserRequests: `${baseurl}/business-requests`,
  adminGetCurrentUserData: `${baseurl}/current-user-data`,
  adminGetUserRequestFields: `${baseurl}/get-user-request-fields`,
  adminGetBusinessByRegionId: `${baseurl}/business-unit`,

  // All users admin
  adminAllUsers: `${baseurl}/all_users`,
  adminAllUsersBulkDelete: `${baseurl}/delete-users`,
  adminAllUsersStatusChange: `${baseurl}/change-status`,
  adminRegister: `${baseurl}/admin-register`,
  adminAllUsersUpdate: `${baseurl}/update-user`, // update-user/:id
  adminAllUserAddUser: `${baseurl}/admin-register`,
  adminCreateUsers: `${baseurl}/create-users`,
  adminGetUsersByRole: `${baseurl}/get-users-by-roles`,
  adminAllUserDetail: `${baseurl}/user`,
  adminAlluserEditUserDetail: `${baseurl}/update-user-details`,
  adminAllUserUpdateStatus: `${baseurl}/update-user-status`,
  adminGetUserFields: `${baseurl}/get-user-fields`, //user filters
  adminGetDeletedUsers: `${baseurl}/get-all-deleted-users`,
  adminRecoverUsers: `${baseurl}/recover-users`,
  adminPermanentDeleteUsers: `${baseurl}/permanent-delete-users`,
  adminGetDeleteUserFields: `${baseurl}/get-deleted-user-fields`, //deleted User Filter

  // DashBoard
  dashboardPendingRequests: `${baseurl}/pending-user-requests`,
  dashbordgetdata: `${baseurl}/get-dashboard-stats`,
  dashBoardChangeUserSettings: `${baseurl}/change-user-setting`,

  // Assets category
  adminGetAssetCategory: `${baseurl}/asset-categories`,
  adminAssetCategoryStatusChange: `${baseurl}/asset-category-status`,
  adminAssetCategoryDelete: `${baseurl}/delete-asset-category`,
  adminAssetCategoryAdd: `${baseurl}/create-asset-category`,
  adminAssetCategoryUpdate: `${baseurl}/update-asset-category`,
  adminCountActiveCategory: `${baseurl}/active-cateogry`,
  adminGetAssetCategroyFields: `${baseurl}/get-asset-cateogry-fields`, //filters

  // Assets SubCategory
  adminGetSubAssetCategory: `${baseurl}/asset-subcategories`,
  adminAssetSubCategoryStatusChange: `${baseurl}/asset-subcategory-status`,
  adminAssetSubCategoryDelete: `${baseurl}/delete-asset-subcateogry`,
  adminAssetSubCategoryAdd: `${baseurl}/create-asset-subcategory`,
  adminAssetSubCategoryUpdate: `${baseurl}/update-asset-subcategory`,
  adminAssetSubCategoryDetail: `${baseurl}/asset-subcategory`,
  adminCountActiveSubCategory: `${baseurl}/active-subcateogry`,
  adminGetSubCategoryFields: `${baseurl}/get-subcateogry-fields`, //filters
  feGetAllSubCategories: `${baseurl}/get-all-subcategories-fe`,

  //iptc core fields (creator details)
  adminGetAllIptcCreators: `${baseurl}/creators`,
  adminAddCreator: `${baseurl}/create-creator`,
  adminGetSingleIptcCreator: `${baseurl}/creator`,
  adminUpdateIptcCreators: `${baseurl}/update-creator`,
  adminDeleteIptcCreators: `${baseurl}/delete-creators`, //put
  adminGetCreatorsFields: `${baseurl}/get-creators-fields`,

  //customTags
  adminGetCustomtags: `${baseurl}/custom-tags`,
  adminCreateCustomTag: `${baseurl}/create-custom-tag`,
  adminDeleteCustomtags: `${baseurl}/delete-custom-tags`,
  adminUpdateCustomName: `${baseurl}/update-custom-tag`,
  adminCustomTagUpdate: `${baseurl}/visibility-custom-tags`,
  adminRequiredTagUpdate: `${baseurl}/required-custom-tags`,
  adminCustomtagVisibilityCount: `${baseurl}/get-custom-tags-visiblity-count`,
  adminCustomtagRequiredCount: `${baseurl}/get-custom-tags-required-count`,
  adminGetCustomTagDetail: `${baseurl}/get-custom-tag-datas-by-customtag`,
  adminAddCustomTagData: `${baseurl}/create-custom-tag-data`,
  adminEditCustomTagData: `${baseurl}/update-custom-tag-data/`,
  adminDeleteCustomTagData: `${baseurl}/delete-custom-tag-data`,
  adminCustomTagCsvUpload: `${baseurl}/create-custom-tag-datas/`,
  adminGetCustomTagsFileds: `${baseurl}/get-custom-tags-fields`, //Filters
  adminGetCustomTagsDataFields: `${baseurl}/get-custom-tags-data-fields`, //Filters customTags Data

  //Assets Collection
  assetsGetcollection: `${baseurl}/collections`,
  assetsUpdateCollection: `${baseurl}/update-collection/`,
  assetsPostcollection: `${baseurl}/create-collection`,
  assetsDeletecollection: `${baseurl}/delete-collections`,
  adminGetCollectionFields: `${baseurl}/get-collection-fields`,

  //Field Management
  adminGetFieldsById: `${baseurl}/get-fields`, //id required
  adminGetFieldsDataByCollectionId: `${baseurl}/get-fields-data`, //collection id requred after /
  adminGetFieldType: `${baseurl}/get-field-types`, //get list of field type
  adminUpdateFieldPermission: `${baseurl}/update-field-permission`,
  adminUpdateField: `${baseurl}/update-field`,
  adminCreateField: `${baseurl}/create-field`,
  adminDeleteFields: `${baseurl}/delete-fields`, //put delete [ids]
  adminUpdateFieldRowsIndex: `${baseurl}/update-rows-fields`, //when dragging rows on field management page
  adminFieldHardDelete: `${baseurl}/delete-field`, //hard(permanent delete fields)
  adminUpdateFieldSettings: `${baseurl}/change-settings`,

  //Add assets
  adminAddAssetsGetAllCustomTags: `${baseurl}/get-all-custom-tags`,
  adminSubCategoryByCategory: `${baseurl}/get-subcateogry-by-category/`,
  adminAddAssetsGetMetaData: `${baseurl}/get-meta-data`,
  adminCreateAsset: `${baseurl}/create-asset`,

  //page cms
  adminGetParentPages: `${baseurl}/get-parent-pages`,
  adminCreateCustomPage: `${baseurl}/create-sub-custom-page`,
  adminGetAllCmsPages: `${baseurl}/sub-custom-pages`,
  getCustomPage: `${baseurl}/sub-custom-page`, //need /id:
  adminCmsPageFields: `${baseurl}/get-sub-custom-pages-fields`,
  adminCmsPageDelete: `${baseurl}/delete-sub-custom-pages`,
  adminCmsPageUpdate: `${baseurl}/update-sub-custom-page`, // /id required
  adminGetHierarchy: `${baseurl}/sub-custom-pages-with-parent`,
  feGetPagesWithParent: `${baseurl}/sub-custom-pages-with-parent-fe`,
  adminUpdatePageNameApi: `${baseurl}/update-parent-page`,
  adminUpdateCmsPageIndex: `${baseurl}/update-sub-custom-pages-index`,
  getContentBySlug: `${baseurl}/get-content-by-slug`,
  adminCreateGeneralFooterPage: `${baseurl}/create-footer`,
  adminGetGeneralFooterPages: `${baseurl}/footers`,
  adminGetSingleGeneralFooterPage: `${baseurl}/footer`, // /id required
  adminEditGeneralFooterPage: `${baseurl}/update-footer`, // put
  adminDeleteGeneralFooterPage: `${baseurl}/delete-footer`, // put
  adminGetGeneralFooterPageFields: `${baseurl}/column-fields-footer`, // get fileter fields
  getGeneralFooterPageByTitle: `${baseurl}/get-footer-by-title`, //frontend   /title
  adminAddPagesToFooter: `${baseurl}/add-footer-menu`, //page or link id required
  adminUpdateFooterMenuIndex: `${baseurl}/update-footer-menu-index`,
  adminUpdateFooterSubMenu: `${baseurl}/update-footer-sub-menu`,

  //View Assets
  adminViewAllAssets: `${baseurl}/get-all-assets`,

  // admin banners
  getBannerdata: `${baseurl}/admin-banners`,
  adminDeleteBanner: `${baseurl}/delete-banner`,
  bannerUpdate: `${baseurl}/update-banner/`,
  feGetBanners: `${baseurl}/banners-fe`,

  //profile settings
  profileChange: `${baseurl}/profile-setting`,
  changePassword: `${baseurl}/change-password`,

  //header notifications
  getAllNotifications: `${baseurl}/notifications`,
  markSingleNotificationRead: `${baseurl}/read-notification`, // .../:id
  markAllNotificationRead: `${baseurl}/read-all-notification`,
  deleteNotifications: `${baseurl}/remove-notification`,

  //contact-us
  contactUsApi: `${baseurl}/contact-us`,

  //trending-searches
  createSearch: `${baseurl}/create-search`,
  getTopSearch: `${baseurl}/search`,
  getAllSearch: `${baseurl}/get-all-search`,
  deleteSearches: `${baseurl}/delete-search`,
  updateSearchStatus: `${baseurl}/trending-search-update-status`,
  getSearchFields: `${baseurl}/get-search-fields`,
  updateSearchOrder: `${baseurl}/update-column-search`,

  //curated-gallery
  adminCreateCuratedGallery: `${baseurl}/create-gallery`, //add curated gallery based on category id
  adminGetCuratedGalleries: `${baseurl}/get-change-column-index`, //get all galleries data based on category id
  adminUpdateGalleryName: `${baseurl}/update-gallery`, //update gallery column name with gallery id
  adminAddCuratedGalleryLink: `${baseurl}/create-link`, //create link and add in column in curated gallery
  adminCuratedGalleryUpdateIndexes: `${baseurl}/update-gallery-column-indexes`, //update column indexes after drag drop
  adminCuratedGalleryUpdateLink: `${baseurl}/update-link`,
  adminCuratedGalleryUpdateLinkTarget: `${baseurl}/update-link-target`, //update target_blank
  adminCuratedLinkDelete: `${baseurl}/delete-gallery`,
};
